import { getRequest, postRequest } from './utils';
import { BASE_URL } from './constant';
import { SignedUrlData } from '../model/signedUrlData';
import { CEFRLevel } from '../types/cefr';
import { AccessCodeType } from '../model/accessCode';

export const ADMIN_API_PATH_PREFIX = '/admin';

export const getSignedUrlByTestInstanceId = async (
  testInstanceId: string
): Promise<SignedUrlData[]> => {
  try {
    const fetchUrl = new URL(
      `${ADMIN_API_PATH_PREFIX}/testinstances/${testInstanceId}/audio-file/signed-urls`,
      BASE_URL
    );
    const data = await getRequest(fetchUrl);
    const parsedData = SignedUrlData.parseArray(data);

    return parsedData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSignedUrlByTestInstanceIds = async (
  testInstanceIds: string[]
): Promise<Record<string, SignedUrlData[]>> => {
  const fetchUrl = new URL(
    `${ADMIN_API_PATH_PREFIX}/testinstances/audio-file/signed-urls`,
    BASE_URL
  );

  testInstanceIds.forEach((testInstanceId) => {
    fetchUrl.searchParams.append('testInstanceIds', testInstanceId);
  });

  try {
    const data = await getRequest(fetchUrl);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createAccessCodeFromTester = async (prefix: string, testers: string[]) => {
  try {
    const fetchUrl = new URL(`${ADMIN_API_PATH_PREFIX}/tester/access-code`, BASE_URL);
    const csvText = await postRequest(fetchUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        codePrefix: prefix,
        testers: testers,
      }),
    });

    return csvText;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadNewSchoolLogo = async (schoolId: string, file: File) => {
  try {
    const fetchUrl = new URL(`${ADMIN_API_PATH_PREFIX}/school/logo/${schoolId}`, BASE_URL);
    const formData = new FormData();
    formData.append('file', file, file.name);
    const respond = await postRequest(fetchUrl, {
      headers: new Headers({
        enctype: 'multipart/form-data',
      }),
      body: formData,
    });

    return respond;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCustomCefr = async (testId: string, cefr: CEFRLevel) => {
  try {
    const fetchUrl = new URL(`/tests/${testId}/custom-cefr/${cefr}`, BASE_URL);
    const response = await postRequest(fetchUrl);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

interface CreateEmptyCodeParams {
  schoolId: string;
  codePrefix: string;
  number: number;
  type: AccessCodeType;
  speakingTestId?: string | undefined;
}

export const createEmptyAccessCodeForSchool = async (createParams: CreateEmptyCodeParams) => {
  try {
    const fetchUrl = new URL(`/access-codes/empty_batch`, BASE_URL);
    const csvText = await postRequest(fetchUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        schoolId: createParams.schoolId,
        codePrefix: createParams.codePrefix,
        number: createParams.number,
        type: createParams.type,
        speakingTestId: createParams.speakingTestId,
      }),
    });
    return csvText;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
