import { useTranslation } from 'react-i18next';
import { Modal, Loading, Text, Image, Heading } from '../../../../components/base';

interface SubmittingAnswerModalProps {
  open: boolean;
  isTimeup: boolean;
}

const SubmittingAnswerModal = ({ open, isTimeup }: SubmittingAnswerModalProps) => {
  const { t } = useTranslation('assessment');
  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Image
          src={isTimeup ? '/assets/images/modal-timeup.png' : '/assets/images/modal-submitting.png'}
          alt="submitting-modal"
          className="w-[180px] mb-6"
        />
        <Heading variant="h1" className="mb-2">
          {isTimeup
            ? t('assessment_submitting_modal_is_timeup')
            : t('assessment_submitting_modal_is_not_timeup')}
        </Heading>
        <Loading text={t('assessment_submitting_modal_loading')} />
      </div>
    </Modal>
  );
};

export default SubmittingAnswerModal;
