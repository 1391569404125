import React from 'react';
import { Button, Heading, Text, Image, Loading } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { useState } from 'react';
import AccountModal from './AccountModal';
import FinishModal from './FinishModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { assessmentAppRoutes, reportAppRoutes } from '../../../router';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import { useTranslation } from 'react-i18next';

const CtaSection = ({
  isBeta,
  testId,
  onGoToHome,
}: {
  isBeta: boolean;
  testId?: string;
  onGoToHome: () => void;
}) => {
  const { t } = useTranslation('assessment');
  if (!isBeta || !testId)
    return (
      <>
        <Text variant="md" className="text-center font-medium text-grey-700">
          {t('assessment_finish_page_text_result')}
        </Text>
        <div className="flex gap-1 justify-center mb-6">
          <Text variant="md" className="text-center text-grey-500">
            {t('assessment_finish_page_text_context')}
          </Text>
          <Image src="/assets/images/line-icon.png" alt="line" />
          <Text variant="md" className="text-center text-grey-500">
            @edsy
          </Text>
        </div>
        <Button variant="primary" onClick={onGoToHome} className="mx-auto">
          {t('assessment_finish_page_button_text')}
        </Button>
      </>
    );
  return (
    <>
      <Text variant="md" className="text-center font-medium text-grey-700 mb-2">
        {t('assessment_finish_page_you_can_see_your_result')}
      </Text>
      <div className="flex gap-1 justify-center mb-6">
        <Text variant="md" className="text-center text-grey-500">
          {t('assessment_finish_page_text_context')}
        </Text>
        <Image src="/assets/images/line-icon.png" alt="line" />
        <Text variant="md" className="text-center text-grey-500">
          @edsy
        </Text>
      </div>
      <div className="flex gap-4 justify-center items-center">
        <Button variant="outlined" onClick={onGoToHome}>
          {t('assessment_finish_page_button_text')}
        </Button>
        <Button
          variant="primary"
          isRouterLink={true}
          to={reportAppRoutes.ReportDetail.getRoute({ testId })}
          target="_blank"
        >
          {t('assessment_finish_page_button_text_see_report')}
        </Button>
      </div>
    </>
  );
};

const FinishPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('assessment');
  const [openAccountModal, toggleAccountModal] = useState<boolean>(false);
  const [openFinishModal, toggleFinishModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [testId, setTestId] = useState<string>();
  const [isBeta, setIsBeta] = useState<boolean>(false);
  // const [allow];

  React.useEffect(() => {
    setLoading(true);

    setIsBeta(searchParams.get('beta') === 'true' ? true : false);
    setTestId(searchParams.get('testId') || undefined);

    setLoading(false);
  }, []);

  const handleGoToHome = () => {
    navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
  };

  return (
    <LayoutCard className="my-10">
      <CardHeader
        variant={CardHeaderVariant.SECONDARY}
        subtitle={t('assessment_finish_page_cardheader_subtitle')}
        title={t('assessment_finish_page_cardheader_title')}
        className="mb-6"
      />
      <Image
        src="/assets/images/assessment-app-finish.png"
        alt="finsh"
        className="mx-auto w-[280px] mb-6"
      />
      {loading ? (
        <Loading />
      ) : (
        <CtaSection isBeta={isBeta} testId={testId} onGoToHome={handleGoToHome} />
      )}

      {/* <div className="rounded-xl bg-primary-50 p-8">
        <Heading variant="h4" is="h4" className="text-primary-500 text-center">
          คุณเป็นสมาชิก EDSY หรือยัง?
        </Heading>
        <Text variant="md" className="text-center">
          หากคุณเป็นสมาชิก EDSY คุณสามารถ เข้าสู่ระบบเพื่อบันทึกผลการทดสอบได้
        </Text>
        <Button variant="primary" onClick={() => toggleAccountModal(true)} className="mx-auto mt-4">
          บันทึก การทดสอบกับบัญชี EDSY
        </Button>
        <Button
          variant="flatgrey"
          onClick={handleGoToHome}
          className="mx-auto mt-4 !text-primary !font-light"
        >
          ไปหน้าแรก
        </Button>
      </div> */}
      {/* <AccountModal
        open={openAccountModal}
        next={() => toggleFinishModal(true)}
        toggle={() => toggleAccountModal(false)}
      />
      <FinishModal
        open={openFinishModal}
        toggle={() => toggleFinishModal(false)}
        name={'Nantanit'}
        code={'ED0234562'}
        date={'20/11/2566'}
      /> */}
    </LayoutCard>
  );
};

export default FinishPage;
