import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import { PronunciationSubCategoryName, Report, ResultCategoryName } from '../../../../types/report';
import { StarsCount } from '../../../../types/starsRating';
import { scoreToStarCount, starsCountToText } from '../../../../utils';
import { encloseWithDoubleQuote, encloseWithSlash } from '../../../../utils/string';
import CategoryRatingCard from './CategoryRatingCard';
import CategorySummaryCard from './CategorySummaryCard';
import { isKeyOf } from '../../../../utils/object';

interface Props {
  report: Report;
}
const CategorySection: FC<Props> = ({ report }) => {
  const { t, i18n } = useTranslation('report');

  const { t: tStarsRatingLabel } = useTranslation('common', { keyPrefix: 'stars_rating_label' });

  const phonemeSound = t('pronunciation_phoneme_sound', {
    ns: 'pronunciation',
    returnObjects: true,
  });

  const fluencyResult = report.result.category[ResultCategoryName.Fluency];
  const fluencyStarsCount = scoreToStarCount(fluencyResult.score);
  const fluencyStarsCountString = starsCountToText(fluencyStarsCount);

  const vocabularyResult = report.result.category[ResultCategoryName.Vocabulary];
  const vocabularyStarsCount = scoreToStarCount(vocabularyResult.score);
  const vocabularyStarsCountString = starsCountToText(vocabularyStarsCount);

  const pronunciationResult = report.result.category[ResultCategoryName.Pronunciation];
  const pronunciationStarsCount = scoreToStarCount(pronunciationResult.score);
  const pronunciationStarsCountString = starsCountToText(pronunciationStarsCount);
  const incorrectPhonetics = pronunciationResult[PronunciationSubCategoryName.IncorrectPhonetics];

  return (
    <div className="flex flex-col gap-y-5 bg-primary-50 rounded-3xl p-8">
      <Heading is="h2" variant="h1" className="text-center">
        <span>{t('report_detail_category_section_title_first_line')}</span>
        <br />
        <span className="text-gradient-gd-1">
          {t('report_detail_category_section_title_second_line')}
        </span>
      </Heading>
      <div className="flex flex-col sm:flex-row gap-4">
        <CategoryRatingCard
          category={ResultCategoryName.Fluency}
          score={fluencyStarsCount}
          fullScore={5}
          starLabel={tStarsRatingLabel(fluencyStarsCountString)}
        />
        <CategoryRatingCard
          category={ResultCategoryName.Vocabulary}
          score={vocabularyStarsCount}
          fullScore={5}
          starLabel={tStarsRatingLabel(vocabularyStarsCountString)}
        />
        <CategoryRatingCard
          category={ResultCategoryName.Pronunciation}
          score={pronunciationStarsCount}
          fullScore={5}
          starLabel={tStarsRatingLabel(pronunciationStarsCountString)}
        />
      </div>
      <CategorySummaryCard
        testId={report.testId}
        category={ResultCategoryName.Fluency}
        starsCount={scoreToStarCount(fluencyResult.score) as StarsCount}
      />
      <CategorySummaryCard
        testId={report.testId}
        category={ResultCategoryName.Vocabulary}
        starsCount={scoreToStarCount(vocabularyResult.score) as StarsCount}
      />
      <CategorySummaryCard
        testId={report.testId}
        category={ResultCategoryName.Pronunciation}
        starsCount={scoreToStarCount(pronunciationResult.score) as StarsCount}
      >
        <div className="flex flex-col gap-y-4">
          <Text className="text-green-600 font-semibold">
            {t('report_detail_incorrect_phonetic_top_incorrect_pronunciation_outof', {
              top: 3,
              outOf: 10,
            })}
          </Text>
          <div>
            {incorrectPhonetics.map((incorrectPhonetic, index) => {
              const { phonetic } = incorrectPhonetic;
              const phoneme = encloseWithSlash(phonetic);
              const soundText = isKeyOf(phonetic, phonemeSound) ? phonemeSound[phonetic] : null;
              return (
                <Text className="text-grey-600" key={index}>
                  &bull; {t('sound', { ns: 'common' })}{' '}
                  {soundText && i18n.language === 'th' ? encloseWithDoubleQuote(soundText) : null}{' '}
                  {phoneme}
                </Text>
              );
              // TODO: check what to be used in desktop
              // <IncorrectPhoneticCard
              //   key={index}
              //   incorrectPhonetic={incorrectPhonetics[0]}
              //   className="border border-grey-100 rounded-xl"
              //   hideDetailedAnalysis
              // />
            })}
          </div>
        </div>
      </CategorySummaryCard>
    </div>
  );
};

export default CategorySection;
