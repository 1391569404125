import React from 'react';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Form.module.css';

interface TextAreaProps extends BaseProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  disabled?: boolean;
}

const TextArea = ({ value, onChange, disabled, placeholder, className }: TextAreaProps) => {
  return (
    <textarea
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      className={toClassNames([style.base, style['base-spacing'], style.textarea, className])}
    />
  );
};

export default TextArea;
