import React from 'react';
import { useTranslation } from 'react-i18next';
import AssessmentNavBar, { ASSESSMENT_NAVBAR_HEIGHT } from './components/AssessmentNavBar';
import { assessmentAppRoutes } from '../router/routes';
import { Container, Text } from '../components/base';
import AssessmentSessionAppMenu from './components/AssessmentSessionAppMenu';
import { SelectLangaugeButton } from '../components/i18n/SelectLanguageButton';
import { toClassNames } from '../utils';

export const ASSESSMENT_FOOTER_HEIGHT = 36;
export const ASSESSMENT_BOTTOM_OFFSET = 36;

const AssessmentLayout = ({
  children,
  isSessionApp,
  showContactInfo,
}: {
  children?: React.ReactNode;
  isSessionApp?: boolean;
  showContactInfo?: boolean;
}) => {
  const { t } = useTranslation('assessment');
  return (
    <div
      style={{
        backgroundImage:
          'url("/assets/images/assessment-app-background.svg"), linear-gradient(180deg, #0E34A0 0%, rgba(39, 93, 248, 0.8) 100%)',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        paddingTop: `${ASSESSMENT_NAVBAR_HEIGHT}px`,
        paddingBottom: `${ASSESSMENT_BOTTOM_OFFSET}px`,
      }}
      className={toClassNames(['min-w-full min-h-[100vh]'])}
    >
      <div className="fixed top-0 left-0 right-0 z-10">
        {/* <div className="text-white text-center py-2 px-2 bg-green-500 font-semibold text-xs">
          Beta version 0.1 สำหรับโรงเรียนในโครงการความร่วมมือทางวิชาการ
        </div> */}
        <AssessmentNavBar logoLinkUrl={assessmentAppRoutes.AssessmentAppHome.getRoute()}>
          <div className="hidden sm:flex justify-between items-center">
            <Text variant="lg" className="text-grey-400">
              EdSpeak <span className="hidden sm:inline">(AI English Speaking Assessment)</span>
            </Text>
          </div>
          <div className="ml-auto self-stretch flex items-center gap-x-2 sm:gap-x-3">
            {showContactInfo && (
              <div className="hidden sm:block">
                <Text variant="2xs" className="text-grey-400 whitespace-pre-line text-end">
                  {t('assessment_layout_contact_information')}
                </Text>
              </div>
            )}
            {isSessionApp && (
              <>
                <div>
                  <AssessmentSessionAppMenu />
                </div>
                <div className="self-stretch bg-grey-200 w-[1px] sm:hidden" />
              </>
            )}
            <SelectLangaugeButton className="justify-self-end bg-primary rounded-lg p-[2px] h-fit" />
          </div>
        </AssessmentNavBar>
      </div>
      {children}

      {!isSessionApp && (
        <Container size="lg" style={{ height: `${ASSESSMENT_FOOTER_HEIGHT}px` }}>
          <Text variant="sm" className="text-white px-4 sm:px-12 text-center font-light">
            {t('assessment_app_footer')}
          </Text>
        </Container>
      )}
    </div>
  );
};

export default AssessmentLayout;
