import React from 'react';
import { Outlet } from 'react-router-dom';
import AssessmentLayout from '../../../layouts/AssessmentLayout';
import useAssessmentAppStorage from '../../../utils/useAssessmentAppStorage';

const AssessmentRegistrationAppRoot = () => {
  const { clearTestId, clearMicrophoneChecked } = useAssessmentAppStorage();

  /**
   * Make sure to clear testId before starting a new session
   */
  React.useEffect(() => {
    clearTestId();
    clearMicrophoneChecked();
  }, []);

  return (
    <AssessmentLayout showContactInfo={true}>
      <Outlet />
    </AssessmentLayout>
  );
};

export default AssessmentRegistrationAppRoot;
