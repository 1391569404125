import { TesterType } from './tester';

export enum RegistrationFormDataKey {
  prefix = 'prefix',
  firstName = 'firstName',
  lastName = 'lastName',
  grade = 'grade',
  room = 'room',
  phoneNo = 'phoneNo',
  email = 'email',
  type = 'type',
  organization = 'organization',
}

export interface RegistrationFormData {
  [RegistrationFormDataKey.prefix]: string;
  [RegistrationFormDataKey.firstName]: string;
  [RegistrationFormDataKey.lastName]: string;
  [RegistrationFormDataKey.grade]: string;
  [RegistrationFormDataKey.room]: string;
  [RegistrationFormDataKey.phoneNo]: string;
  [RegistrationFormDataKey.email]: string;
  [RegistrationFormDataKey.type]: TesterType;
  [RegistrationFormDataKey.organization]: string;
}
