export enum RecordingPanelState {
  Default = 'Default',
  Preparing = 'Preparing',
  Recording = 'Recording',
  Loading = 'Loading',
  Completed = 'Completed',
}

export enum RecordingPanelFeatures {
  PreparationTimer,
  RecordingTimer,
  RecordingButton,
  VoiceGraph,
  FinishButton,
}

export const recordingPanelStateFeatures: Record<
  RecordingPanelState,
  Record<RecordingPanelFeatures, boolean>
> = {
  [RecordingPanelState.Default]: {
    [RecordingPanelFeatures.PreparationTimer]: false,
    [RecordingPanelFeatures.RecordingTimer]: false,
    [RecordingPanelFeatures.RecordingButton]: false,
    [RecordingPanelFeatures.VoiceGraph]: true,
    [RecordingPanelFeatures.FinishButton]: false,
  },
  [RecordingPanelState.Preparing]: {
    [RecordingPanelFeatures.PreparationTimer]: true,
    [RecordingPanelFeatures.RecordingTimer]: false,
    [RecordingPanelFeatures.RecordingButton]: true,
    [RecordingPanelFeatures.VoiceGraph]: false,
    [RecordingPanelFeatures.FinishButton]: false,
  },
  [RecordingPanelState.Recording]: {
    [RecordingPanelFeatures.PreparationTimer]: false,
    [RecordingPanelFeatures.RecordingTimer]: true,
    [RecordingPanelFeatures.RecordingButton]: false,
    [RecordingPanelFeatures.VoiceGraph]: true,
    [RecordingPanelFeatures.FinishButton]: true,
  },
  [RecordingPanelState.Loading]: {
    [RecordingPanelFeatures.PreparationTimer]: false,
    [RecordingPanelFeatures.RecordingTimer]: false,
    [RecordingPanelFeatures.RecordingButton]: false,
    [RecordingPanelFeatures.VoiceGraph]: true,
    [RecordingPanelFeatures.FinishButton]: false,
  },
  [RecordingPanelState.Completed]: {
    [RecordingPanelFeatures.PreparationTimer]: false,
    [RecordingPanelFeatures.RecordingTimer]: true,
    [RecordingPanelFeatures.RecordingButton]: false,
    [RecordingPanelFeatures.VoiceGraph]: true,
    [RecordingPanelFeatures.FinishButton]: false,
  },
};
