import { useTranslation } from 'react-i18next';
import { ContentComponentProps } from '../helpers';
import ContentHeader from './ContentHeader';
import RenderQuestion from './RenderQuestion';

const DescribingContent = ({
  questionNumber,
  totalQuestion,
  preparationTimeLimit,
  question,
}: ContentComponentProps) => {
  const { t } = useTranslation('assessment');
  return (
    <>
      <ContentHeader
        questionType={question.type}
        questionNumber={questionNumber}
        totalQuestion={totalQuestion}
        timeLimit={question.timeLimit || 60}
        title={t('assessment_speaking_test_quiz_describing_content_title')}
        description={t('assessment_speaking_test_quiz_describing_content_description', {
          preparationTimeLimit: preparationTimeLimit.toString(),
        })}
      />
      <div>
        <RenderQuestion prompts={question.prompt} />
      </div>
    </>
  );
};

export default DescribingContent;
