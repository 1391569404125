import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../router';
import useAssessmentAppStorage from './useAssessmentAppStorage';

/**
 * The intention of this hook is to contain helpers function for the whole
 * AssessmentApp Registration flow.
 */
const useRegistrationFlowHelpers = () => {
  const navigate = useNavigate();
  const assessmentAppStorage = useAssessmentAppStorage();

  const navigateToAssessmentSessionAppFromRegistrationFlow = (testId: string) => {
    /**
     * To go to AssessmentApp, testId needs to be set in sessionStorage.
     * Set entryMethod to "Registration" to let AssessmentSessionApp know
     */
    assessmentAppStorage.setTestId(testId);
    navigate(`${assessmentAppRoutes.AssessmentAppInstruction.getRoute()}?entryMethod=Registration`);
  };

  return { navigateToAssessmentSessionAppFromRegistrationFlow };
};

export default useRegistrationFlowHelpers;
