import { Loading } from '../../../components/base';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import LayoutCard from '../../../layouts/components/LayoutCard';
import React from 'react';
import SpeakingTestQuizInstruction from './SpeakingTestQuizInstruction';
import { getInstructionData } from './data';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';

const SpeakingTestQuizInstructionContainer = () => {
  const navigate = useNavigate();
  const {
    speakingTestQuestions,
    startOrContinueSpeakingTestQuiz,
    currentSpeakingTestQuestionIndex,
    isSpeakingTestReady,
    checkHasOnlySpeakingTest,
  } = useAssessmentContext();

  /**
   * Start speakingTest if it is not ready
   */
  React.useEffect(() => {
    const initializeData = async () => {
      await startOrContinueSpeakingTestQuiz();
    };
    if (!isSpeakingTestReady) {
      initializeData();
    }
  }, [isSpeakingTestReady]);

  const handleNext = () => {
    navigate(assessmentAppRoutes.AssessmentAppSpeakingTestQuiz.getRoute());
  };

  if (
    !isSpeakingTestReady ||
    speakingTestQuestions === undefined ||
    currentSpeakingTestQuestionIndex === undefined
  ) {
    return (
      <LayoutCard className="flex justify-center items-center my-10">
        <Loading />
      </LayoutCard>
    );
  }

  return (
    <SpeakingTestQuizInstruction
      instructionData={getInstructionData(speakingTestQuestions[currentSpeakingTestQuestionIndex])}
      questionNumber={currentSpeakingTestQuestionIndex + 1}
      totalQuestion={speakingTestQuestions.length}
      onNext={handleNext}
      partNo={checkHasOnlySpeakingTest() ? '1' : '2'}
    />
  );
};

export default SpeakingTestQuizInstructionContainer;
