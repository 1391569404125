import {
  SpeakingTestQuestion,
  SpeakingTestQuestionType,
} from '../../../../model/speakingTestQuestion';
import DescribingContent from './DescribingContent';
import OpenEndedContent from './OpenEndedContent';
import ReadingContent from './ReadingContent';

const QuestionComponent = {
  [SpeakingTestQuestionType.DESCRIBE]: DescribingContent,
  [SpeakingTestQuestionType.OPEN_ENDED]: OpenEndedContent,
  [SpeakingTestQuestionType.READING]: ReadingContent,
  [SpeakingTestQuestionType.DISCUSSION]: OpenEndedContent,
  [SpeakingTestQuestionType.ELABORATE]: OpenEndedContent,
};

const QuestionContent = ({
  questionNumber,
  question,
  totalQuestion,
}: {
  question: SpeakingTestQuestion;
  questionNumber: number;
  totalQuestion: number;
}) => {
  const Component = QuestionComponent[question.type];
  return (
    <Component
      questionNumber={questionNumber}
      totalQuestion={totalQuestion}
      question={question}
      preparationTimeLimit={question.preparationTimeLimit || 60}
    />
  );
};

export default QuestionContent;
