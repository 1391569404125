import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image } from '../../../../../../components/base';
import { RecordingPanelState } from '../helpers';

export enum VoiceGraphVariant {
  Loading = 'Loading',
  Recording = 'Recording',
  Completed = 'Completed',
}
export const getVoiceGraphVariant = (state: RecordingPanelState) => {
  switch (state) {
    case RecordingPanelState.Recording:
      return VoiceGraphVariant.Recording;
    case RecordingPanelState.Completed:
      return VoiceGraphVariant.Completed;
    default:
      return VoiceGraphVariant.Loading;
  }
};
const voiceGraphData: Record<
  VoiceGraphVariant,
  { imageUrl: string; textKey: 'loading' | 'recording' | 'completed' }
> = {
  [VoiceGraphVariant.Loading]: {
    imageUrl: '/assets/images/voice-graph/voice-graph-inactive-grey.svg',
    textKey: 'loading',
  },
  [VoiceGraphVariant.Recording]: {
    imageUrl: '/assets/images/voice-graph/voice-graph-active.svg',
    textKey: 'recording',
  },
  [VoiceGraphVariant.Completed]: {
    imageUrl: '/assets/images/voice-graph/voice-graph-inactive.svg',
    textKey: 'completed',
  },
};

const VoiceGraph = ({ variant }: { variant: VoiceGraphVariant }) => {
  const { t } = useTranslation('assessment');
  const { imageUrl, textKey } = voiceGraphData[variant];
  return (
    <div className="flex flex-col items-center">
      <Image src={imageUrl} className="w-[64px] sm:w-[96px] mb-1" alt="voice graph" />
      <div className="flex items-center gap-1">
        {variant === VoiceGraphVariant.Recording && (
          <Image
            src="/assets/images/recording-dot.gif"
            className="w-[12px]"
            alt="recording dot animation"
          />
        )}

        <Text variant="xs" className="text-primary text-center">
          {t(`assessment_app_recording_panel_voice_graph.${textKey}`)}
        </Text>
      </div>
    </div>
  );
};
export default VoiceGraph;
