import React from 'react';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import SpeakingTestQuiz from './SpeakingTestQuiz';
import { Navigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';

const SpeakingTestQuizContainer = () => {
  const { speakingTestQuestions, currentSpeakingTestQuestionIndex, isSpeakingTestReady } =
    useAssessmentContext();

  const questionNumber = React.useMemo(
    () => (currentSpeakingTestQuestionIndex ? currentSpeakingTestQuestionIndex + 1 : 1),
    [isSpeakingTestReady]
  );

  if (
    !isSpeakingTestReady ||
    currentSpeakingTestQuestionIndex === undefined ||
    speakingTestQuestions === undefined ||
    speakingTestQuestions.length <= currentSpeakingTestQuestionIndex
  ) {
    return (
      <Navigate to={assessmentAppRoutes.AssessmentAppSpeakingTestQuizInstruction.getRoute()} />
    );
  }

  return (
    <SpeakingTestQuiz
      question={speakingTestQuestions[currentSpeakingTestQuestionIndex]}
      totalQuestion={speakingTestQuestions.length}
      questionNumber={questionNumber}
    />
  );
};

export default SpeakingTestQuizContainer;
