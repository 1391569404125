import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getStatisticBySchoolCode } from '../../../api';
import { getReportByTestId } from '../../../api/report';
import { Container, Loading } from '../../../components/base';
import { reportAppRoutes } from '../../../router';
import { Report, validateResultCategoryName } from '../../../types/report';
import { SchoolReportStatistic } from '../../../types/reportStatistic';
import { ReportDetailCategoryView } from './ReportDetailCategoryView.view';

const ReportDetailCategory: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const testId = params.testId;
  const categoryName = params.category;
  const [report, setReport] = useState<Report>();
  const [schoolStatistic, setSchoolStatistic] = useState<SchoolReportStatistic>();
  const [loading, setLoading] = useState(true);

  // * This is a validation side-effect
  useEffect(() => {
    if (!navigate) return;
    const getReportData = async () => {
      const isCategoryNameValid = !!categoryName && validateResultCategoryName(categoryName);
      if (!isCategoryNameValid) {
        // * This components should not be rendered without a valid category name
        navigate(reportAppRoutes.ReportDetail.getRoute({ testId: testId }));
        return;
      }
    };
    getReportData();
  }, [navigate, testId, categoryName]);

  // * We want to fetch only once, to prevent...
  // * - unnecessary API calls
  // * - unwanted loading when category changed
  useEffect(() => {
    if (!testId) return;
    const getReportData = async () => {
      try {
        setLoading(true);
        // * Get report data
        const reportData = await getReportByTestId(testId);
        if (reportData) {
          setReport(reportData);
        }
        // * Get school statistic data
        const schoolStatisticData = await getStatisticBySchoolCode('MOCK');
        setSchoolStatistic(schoolStatisticData);
        setLoading(false);
      } catch (error: any) {
        console.error(error);
        throw error;
      }
    };
    getReportData();
  }, [testId]);

  if (loading || !report || !schoolStatistic) {
    return (
      <Container className={`p-8 flex-1`}>
        <Loading />
      </Container>
    );
  }
  return <ReportDetailCategoryView report={report} schoolStatistic={schoolStatistic} />;
};

export default ReportDetailCategory;
