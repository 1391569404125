import dayjs from 'dayjs';
import React from 'react';
import { getSchoolById, getTesterById } from '../../../../api/assessment';
import { Modal, Text, Image, Heading, Button, Loading } from '../../../../components/base';
import { AccessCode } from '../../../../model/accessCode';
import { School } from '../../../../model/school';
import { Test } from '../../../../model/test';
import { Tester } from '../../../../model/tester';
import { useTranslation } from 'react-i18next';

interface ContinueModalProps {
  open: boolean;
  test: Test;
  accessCode?: AccessCode;
  onPrimaryCtaClick: () => void;
  onSecondaryCtaClick: () => void;
}

const ContinueModal = ({
  open,
  test,
  accessCode,
  onSecondaryCtaClick,
  onPrimaryCtaClick,
}: ContinueModalProps) => {
  const { t } = useTranslation('assessment');
  const [tester, setTester] = React.useState<Tester>();
  const [school, setSchool] = React.useState<School>();

  React.useEffect(() => {
    const initializeData = async () => {
      const [tester, school] = await Promise.all([
        getTesterById(test.testerId),
        accessCode?.schoolId ? getSchoolById(accessCode.schoolId) : undefined,
      ]);

      setTester(tester);
      setSchool(school);
    };
    initializeData();
  }, []);

  if (!tester || !accessCode) {
    return <Loading />;
  }
  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Image
          src={'/assets/images/modal-confirm.png'}
          alt="continue-session-modal"
          className="w-[180px] mb-6"
        />
        <Heading variant="h1" className="mb-2">
          {t('assessment_continue_modal_heading')}
        </Heading>
        <Text className="text-grey-500 text-center px-4 mb-8">
          {t('assessment_continue_modal_text_access_code')}
          <span className="font-semibold mx-1">{accessCode.code}</span>
          {t('assessment_continue_modal_text_coutinue_question')}
        </Text>
        <ul className="mb-8">
          <li>
            <Text className="text-grey-500">
              <span className="font-semibold mr-1">
                {t('assessment_continue_modal_text_school')}
              </span>{' '}
              {school?.name || '-'}
            </Text>
          </li>
          <li>
            <Text className="text-grey-500">
              <span className="font-semibold mr-1">
                {t('assessment_continue_modal_text_fullname')}
              </span>{' '}
              {tester.prefix} {tester.fullName}
            </Text>
          </li>
          {tester.grade && (
            <li>
              <Text className="text-grey-500">
                <span className="font-semibold mr-1">
                  {t('assessment_continue_modal_text_grade')}
                </span>{' '}
                {tester.grade}
                {t('assessment_continue_modal_text_room')} {tester.room || '-'}
                {tester.room}
              </Text>
            </li>
          )}
          <li>
            <Text className="text-grey-500">
              <span className="font-semibold mr-1">
                {t('assessment_continue_modal_text_created_at')}
              </span>{' '}
              {dayjs(test.createdAt).format('D MMMM BBBB')}
            </Text>
          </li>
        </ul>
        <div className="flex gap-6 mb-4">
          <Button variant="secondary" onClick={onSecondaryCtaClick}>
            {t('assessment_continue_modal_button_cancel')}
          </Button>
          <Button variant="primary" onClick={onPrimaryCtaClick}>
            {t('assessment_continue_modal_button_continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContinueModal;
