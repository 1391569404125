import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { cefrLevels } from '../../../data/cefr';
import { gradeToMoe, moeToCefr } from '../../../data/moe';
import { useDefaultDateFormat } from '../../../hooks/useDefaultDateFormat';
import { School } from '../../../model/school';
import { CEFRLevel } from '../../../types/cefr';
import { MOELevel } from '../../../types/moe';
import { Report, ResultCategoryName } from '../../../types/report';
import {
  formatEdSpeakScore,
  scoreToStarCount,
  starsCountToText,
  toClassNames,
} from '../../../utils';
import { isKeyOf } from '../../../utils/object';
import { Image } from '../../base';
import CEFRLevelChart from './components/CEFRLevelChart';
import style from './Template3.module.css';

const StudentInfoListItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <p className="capitalize">
      <span style={{ fontWeight: 600 }}>{label}:</span> {value}
    </p>
  );
};

type StudentInfoProps = {
  fullName: string;
  school: string;
  grade?: string;
  room?: string;
  testDate: Date;
  testerId?: string;
};

const StudentInfo = ({ fullName, school, grade, room, testDate }: StudentInfoProps) => {
  const { t } = useTranslation('common');
  const dateFormat = useDefaultDateFormat();
  const testerGradeLabelMap = t('tester_grade_short', { returnObjects: true });
  const gradeText = grade && isKeyOf(grade, testerGradeLabelMap) ? testerGradeLabelMap[grade] : '-';
  const roomText = room ? `${t('room')} ${room}` : '';
  return (
    <div className={style['report-print-student-info']}>
      <div className="text-primary-700 font-bold">{fullName}</div>
      <StudentInfoListItem label={t('school')} value={school} />
      <StudentInfoListItem label={t('grade')} value={`${gradeText} ${roomText}`} />
      <StudentInfoListItem label={t('test_date')} value={dayjs(testDate).format(dateFormat)} />
    </div>
  );
};

const TestQRCodeSection = ({ testId }: { testId: string }) => {
  const { t } = useTranslation('summaryReport');
  const QRCodeSectionListData = [
    t('sum_report_template3_qr_list_1'),
    t('sum_report_template3_qr_list_2'),
    t('sum_report_template3_qr_list_3'),
  ];
  return (
    <>
      <div className={'flex gap-[4mm] items-center'}>
        <div className={style['report-print-qr-code-container']}>
          <QRCode
            value={`https://edspeak.edsy.co/reports/${testId}`}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            size={256}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div>
          <h5 className={style['report-print-qr-code-desc']}>
            {t('sum_report_template3_qr_list_title')}
          </h5>
          <ul className={style['checkmark-list']}>
            {QRCodeSectionListData.map((text) => (
              <li className={style['checkmark-list-item']} key={text}>
                {text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

enum OverallLevel {
  NeedImprovement = 'need_improvement',
  Good = 'good',
  Excellent = 'excellent',
}

const getOverallLevel = (grade: number | undefined, cefr: CEFRLevel): OverallLevel | null => {
  if (!grade) return null;

  const moeLevel: MOELevel | undefined = gradeToMoe[grade];
  if (!moeLevel) return null;

  const expectedCefr = moeToCefr[moeLevel];
  const expectedCefrIndex = cefrLevels.indexOf(expectedCefr);
  const actualCefrIndex = cefrLevels.indexOf(cefr);

  if (actualCefrIndex > expectedCefrIndex) return OverallLevel.Excellent;
  if (actualCefrIndex === expectedCefrIndex) return OverallLevel.Good;
  return OverallLevel.NeedImprovement;
};

const starImageMap = {
  full: '/assets/images/stars/star-full.svg',
  half: '/assets/images/stars/star-half.svg',
  empty: '/assets/images/stars/star-empty.svg',
};
const Stars = ({ score, fullScore }: { score: number; fullScore: number }) => {
  const emptyStarArr = new Array(fullScore).fill('');
  const starArr = emptyStarArr.map((_, index) => {
    const currStarCount = index + 1;
    const diff = score - currStarCount;
    if (diff >= -0.25) return starImageMap.full; // 0.75+ => show full star
    if (diff < -0.25 && diff >= -0.75) return starImageMap.half; // 0.25 - 0.74 => show half star
    if (currStarCount === 1 && diff < -0.75 && diff > -1) return starImageMap.half; // Case score is less than 0.25, we still want to show 0.5 stars
    return starImageMap.empty;
  });

  return (
    <div className="flex gap-[1mm]">
      {starArr.map((imgSrc, index) => {
        return (
          <img
            src={imgSrc}
            className="w-[5mm] drop-shadow-[0_0.75mm_1.5mm_rgba(0,0,0,0.1)]"
            alt={`star-${index}`}
            key={index}
          />
        );
      })}
    </div>
  );
};
interface ScoreBoxProps {
  header: string;
  subHeader: string;
  score: number;
  scoreText?: string;
  category: ResultCategoryName;
  description?: string;
  recommendation?: string;
  primaryColor: string;
}
const ScoreBox = ({
  category,
  header,
  subHeader,
  score,
  scoreText,
  description,
  recommendation,
  primaryColor,
}: ScoreBoxProps) => {
  // * return Fragment because its container is a `grid`
  return (
    <>
      <div className={style['score-box']}>
        <div
          className={toClassNames([
            style['score-box-header'],
            'text-white flex flex-col items-center',
          ])}
          style={{ backgroundColor: primaryColor }}
        >
          <div className={toClassNames([style['score-box-header-title']])}>{header}</div>
          <div className={toClassNames([style['score-box-header-subtitle'], 'mb-[2mm]'])}>
            {subHeader}
          </div>
        </div>
        <div className={toClassNames([style['score-box-description'], 'text-center'])}>
          <Stars score={score} fullScore={5} />
          <div
            className={toClassNames([
              style['score-box-header-score-desc'],
              'font-semibold mt-[2mm]',
            ])}
            style={{ color: primaryColor }}
          >
            {scoreText}
          </div>
          <div>{description}</div>
        </div>
      </div>
      <div className={style['score-box-recommendation']}>
        <img src="/assets/images/lightbulb.svg" alt="lightbulb" className="w-[3mm] mt-[0.25mm]" />
        <div>{recommendation}</div>
      </div>
    </>
  );
};

interface CefrScoreBoxProps {
  cefrLevel: string;
  score: number;
  fullScore: number;
}
const CefrScoreBox = ({ cefrLevel, score, fullScore }: CefrScoreBoxProps) => {
  const { t } = useTranslation('summaryReport');
  return (
    <div className={toClassNames([style['cefr-score-box'], 'flex flex-col items-center'])}>
      <div
        className={toClassNames([
          style['cefr-score-box-cefr-score'],
          'bg-white text-primary-700 rounded-full w-fit',
        ])}
      >
        CEFR <span className="font-semibold">{cefrLevel}</span>
      </div>
      <div className={toClassNames([style['cefr-score-box-title'], 'text-white'])}>
        {t('sum_report_template3_cefr_box_edspeak_score')}
      </div>
      <div className={toClassNames([style['cefr-score-box-score'], 'text-yellow-400'])}>
        {formatEdSpeakScore(score)}
      </div>
      <div className={toClassNames([style['cefr-score-box-subtitle'], 'text-white'])}>
        {t('sum_report_template3_cefr_box_from_fullscore', { fullScore: fullScore })}
      </div>
    </div>
  );
};

const ReportsPrint = ({ report, school }: { report: Report; school: School }) => {
  const { t, i18n } = useTranslation('summaryReport');

  const cefrLevelDescription = t('cefr_level_description', {
    ns: 'cefr',
    returnObjects: true,
  });

  const overallLevel = getOverallLevel(
    report.tester.grade ? parseInt(report.tester.grade) : undefined,
    report.result.englishStandard.cefr as CEFRLevel
  );

  const overallRecommendation = overallLevel
    ? t(`sum_report_template3_overall_recommendation_${overallLevel}`)
    : null;

  const fluencyStarsCount = scoreToStarCount(report.result.category.fluency.score);
  const fluencyStarsText = starsCountToText(fluencyStarsCount);
  const vocabularyStarsCount = scoreToStarCount(report.result.category.vocabulary.score);
  const vocabularyStarsText = starsCountToText(vocabularyStarsCount);
  const pronunciationStarsCount = scoreToStarCount(report.result.category.pronunciation.score);
  const pronunciationStarsText = starsCountToText(pronunciationStarsCount);

  if (!report) return null;
  return (
    <div className={style['report-print-wrapper']}>
      <div className={style['report-print-inner-background']}>
        <img src={school.logoUrl} alt="school-logo" className={style['school-logo']} />
        <img src="/assets/images/edsy-logo.png" alt="edsy-logo" className={style['edsy-logo']} />
        <div className={style['report-print-header']}>
          {t('sum_report_template3_title')}
          <span className="text-primary ml-1">EdSpeak by Edsy</span>
        </div>
        <div className={style['report-print-overall-info']}>
          <StudentInfo
            fullName={report.tester.fullName}
            school={school.name}
            grade={report.tester.grade}
            room={report.tester.room}
            testDate={report.date}
            testerId={report.tester.id}
          />
          <TestQRCodeSection testId={report.testId} />
        </div>

        <div className="flex gap-[4mm] w-full mb-[9mm]">
          <CefrScoreBox
            cefrLevel={report.result.englishStandard.cefr}
            score={report.result.overall.score}
            fullScore={230}
          />
          <div className={style['score-box-container']}>
            <ScoreBox
              category={ResultCategoryName.Fluency}
              header="Fluency"
              subHeader={t('sum_report_template3_score_box_fluency')}
              score={report.result.category.fluency.score}
              scoreText={t(`stars_rating_label.${fluencyStarsText}`, { ns: 'common' })}
              description={t(`sum_report_fluency_stars_rating_description.${fluencyStarsText}`)}
              recommendation={t(
                `sum_report_fluency_stars_rating_recommendation.${fluencyStarsText}`
              )}
              primaryColor="#5178E1"
            />
            <ScoreBox
              category={ResultCategoryName.Vocabulary}
              header="Vocabulary"
              subHeader={t('sum_report_template3_score_box_vocabulary')}
              score={report.result.category.vocabulary.score}
              scoreText={t(`stars_rating_label.${vocabularyStarsText}`, { ns: 'common' })}
              description={t(
                `sum_report_vocabulary_stars_rating_description.${vocabularyStarsText}`
              )}
              recommendation={t(
                `sum_report_vocabulary_stars_rating_recommendation.${vocabularyStarsText}`
              )}
              primaryColor="#AF7AFF"
            />
            <ScoreBox
              category={ResultCategoryName.Pronunciation}
              header="Pronunciation"
              subHeader={t('sum_report_template3_score_box_pronunciation')}
              score={report.result.category.pronunciation.score}
              scoreText={t(`stars_rating_label.${pronunciationStarsText}`, { ns: 'common' })}
              description={t(
                `sum_report_pronunciation_stars_rating_description.${pronunciationStarsText}`
              )}
              recommendation={t(
                `sum_report_pronunciation_stars_rating_recommendation.${pronunciationStarsText}`
              )}
              primaryColor="#4BBABE"
            />
          </div>
        </div>
        <div className="flex items-center w-full">
          <div className={toClassNames([style['cefr-chart-section-header'], 'text-primary'])}>
            {t('sum_report_template3_cefr_chart_header_title')}
          </div>
          <div className={toClassNames([style['cefr-chart-section-cefr-description']])}>
            {t('sum_report_template3_cefr_chart_what_is_cefr')}
          </div>
        </div>
        <div className={toClassNames([style['cefr-chart-section'], 'bg-white'])}>
          <div className={toClassNames([style['cefr-chart-section-chart-container'], 'relative'])}>
            <div className={toClassNames([style['cefr-chart-section-your-score-label-container']])}>
              <Image
                src="/assets/images/star-icon.svg"
                alt="star icon"
                className="inline w-[5mm]"
              />
              <span>=</span>
              <span className="capitalize">{t('your_level', { ns: 'common' })}</span>
            </div>
            <CEFRLevelChart
              level={report.result.englishStandard.cefr as CEFRLevel}
              showCEFRScale={true}
              showMOEScale={true}
              showIELTSScale={true}
            />
          </div>
          <div className={toClassNames([style['cefr-chart-section-desecription-container']])}>
            <div className={toClassNames([style['cefr-chart-section-description-level']])}>
              {t('level', { ns: 'common' })} {report.result.englishStandard.cefr}
            </div>
            <div className={toClassNames([style['cefr-chart-section-description-cefr-desc']])}>
              {cefrLevelDescription[report.result.englishStandard.cefr as CEFRLevel] ||
                'เราไม่สามารถระบุระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณได้'}
            </div>
            {overallRecommendation && (
              <div className={toClassNames([style['cefr-chart-section-description-motivation']])}>
                {overallRecommendation}
              </div>
            )}
          </div>
        </div>
        <img
          src={`/assets/images/summary-report/template-3-footer-banner_${i18n.language}.png`}
          alt="footer-banner"
          className={style['footer-banner']}
        />
      </div>
    </div>
  );
};

export default ReportsPrint;
