import { Text, Image, Button } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import { useTranslation } from 'react-i18next';

const SpeakingTestInstruction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');

  const handleNext = () => {
    navigate(assessmentAppRoutes.AssessmentAppSpeakingTestTestMic.getRoute());
  };

  return (
    <LayoutCard className="my-10 flex flex-col items-center">
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle="Speaking test"
        title={t('assessment_speaking_test_test_mic_instruction_cardheader_title')}
        description={t('assessment_speaking_test_test_mic_instruction_cardheader_description')}
        className="mb-6"
      />
      <Image
        src="/assets/images/speaking-test/test-mic-instruction.png"
        alt="test mic instruction"
        className="mx-auto w-[280px] mb-6"
      />
      <Text variant="md" className="text-center mb-8">
        {t('assessment_speaking_test_test_mic_instruction_text')}
      </Text>
      <Button variant="primary" onClick={handleNext} className="mt-auto">
        {t('assessment_speaking_test_test_mic_instruction_button')}
      </Button>
    </LayoutCard>
  );
};

export default SpeakingTestInstruction;
