import { FC, PropsWithChildren, ReactNode } from 'react';
import { toClassNames } from '../../../utils';

const placementClass = {
  top: 'bottom-full left-1/2 -translate-x-1/2 origin-top mb-1',
  bottom: 'top-full left-1/2 -translate-x-1/2 origin-bottom mt-1',
  left: 'right-full top-1/2 -translate-y-1/2 origin-left mr-1',
  right: 'left-full top-1/2 -translate-y-1/2 origin-right ml-1',
};

const animationClass = {
  fade: 'opacity-0 group-hover:opacity-100',
};

const tooltipArrowBaseClass =
  'after:content-[""] after:absolute after:border-4 after:border-transparent';
const tooltipArrowClass = {
  top: 'after:top-full after:left-1/2 after:-translate-x-1/2 after:border-t-inherit',
  bottom: 'after:bottom-full after:left-1/2 after:-translate-x-1/2 after:border-b-inherit',
  left: 'after:left-full after:top-1/2 after:-translate-y-1/2 after:border-l-inherit',
  right: 'after:right-full after:top-1/2 after:-translate-y-1/2 after:border-r-inherit',
};

export enum TooltipVariant {
  Primary = 'primary',
  Disabled = 'disabled',
}

const tooltipClass: Record<TooltipVariant, string> = {
  [TooltipVariant.Primary]: 'bg-primary-500 text-white border-primary-500',
  [TooltipVariant.Disabled]: 'bg-grey-500 text-white border-grey-500',
};

interface Props {
  content: ReactNode;
  animation?: 'fade';
  placement?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  arrow?: boolean;
  tooltipClassName?: string;
  variant?: TooltipVariant;
}
const Tooltip: FC<PropsWithChildren<Props>> = ({
  className = '',
  tooltipClassName = '',
  content,
  children,
  placement = 'top',
  animation = 'fade',
  arrow = true,
  variant = TooltipVariant.Primary,
}) => {
  return (
    <div className={toClassNames([className, 'group relative'])}>
      {children}
      <div
        className={toClassNames([
          tooltipClassName,
          'absolute py-2 px-2 rounded-md shadow-md text-xs',
          tooltipClass[variant],
          // animation
          animationClass[animation],
          'transition-all duration-150',
          // placement
          placementClass[placement],
          // arrow
          arrow ? tooltipArrowBaseClass : '',
          arrow ? tooltipArrowClass[placement] : '',
        ])}
      >
        {content}
      </div>
    </div>
  );
};

export default Tooltip;
