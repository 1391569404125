import { CEFRLevel } from '../types/cefr';
import { MOELevel } from '../types/moe';

export const moeLevels: MOELevel[] = [
  MOELevel.PrimarySchool,
  MOELevel.LowerSecondarySchool,
  MOELevel.UpperSecondarySchool,
  MOELevel.Undergraduate,
];

export const gradeToMoe: { [key: number]: MOELevel } = {
  1: MOELevel.PrimarySchool,
  2: MOELevel.PrimarySchool,
  3: MOELevel.PrimarySchool,
  4: MOELevel.PrimarySchool,
  5: MOELevel.PrimarySchool,
  6: MOELevel.PrimarySchool,
  7: MOELevel.LowerSecondarySchool,
  8: MOELevel.LowerSecondarySchool,
  9: MOELevel.LowerSecondarySchool,
  10: MOELevel.UpperSecondarySchool,
  11: MOELevel.UpperSecondarySchool,
  12: MOELevel.UpperSecondarySchool,
  13: MOELevel.Undergraduate,
};

/**
 * Expected CEFR for MOE level
 */
export const moeToCefr: { [key in MOELevel]: CEFRLevel } = {
  [MOELevel.PrimarySchool]: CEFRLevel.PreA1,
  [MOELevel.LowerSecondarySchool]: CEFRLevel.A1,
  [MOELevel.UpperSecondarySchool]: CEFRLevel.A2,
  [MOELevel.Undergraduate]: CEFRLevel.B1,
};
