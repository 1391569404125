/**
 * Match Error code with backend
 */
enum ERROR_CODE {
  BAD_REQUEST = 'BAD-REQUEST',
  NOT_FOUND = 'NOT-FOUND',
  INTERNAL_ERROR = 'INTERNAL-ERROR',
  UNKNOWN = 'UNKNOWN',
  UNSUPPORTED_CONTENT_TYPE = 'UNSUPPORTED-CONTENT-TYPE',
}

class Error2 extends Error {
  code?: ERROR_CODE;
}

const validateErrorCode = (code: string): boolean => {
  return (Object as any).values(ERROR_CODE).includes(code);
};

const fetch2 = async (fetchUrl: string | URL, requestOptions?: RequestInit) => {
  try {
    const rawResponse = await fetch(fetchUrl, requestOptions);
    const data = await getResponseData(rawResponse);
    return data;
  } catch (err: any) {
    if (validateErrorCode(err.code)) {
      throw err;
    } else {
      /**
       * Handle other unknown errors.
       */
      const error = new Error2();
      error.code = ERROR_CODE.UNKNOWN;
      error.message = 'Something went wrong';
      throw error;
    }
  }
};

const getRequestOption = (methodStr: string, options?: RequestInit) => {
  if (!options) return { method: methodStr };

  // Extract method from options to prevent overriding method value.
  const { method, ...restOptions } = options;
  options = {
    method: methodStr,
    ...restOptions,
  };
  return options;
};

export const getRequest = async (fetchUrl: string | URL, requestOptions?: RequestInit) => {
  const options = getRequestOption('GET', requestOptions);
  return fetch2(fetchUrl, options);
};

export const postRequest = async (fetchUrl: string | URL, requestOptions?: RequestInit) => {
  const options = getRequestOption('POST', requestOptions);
  return fetch2(fetchUrl, options);
};

const getResponseData = async (rawResponse: Response) => {
  const contentType = rawResponse.headers.get('Content-Type');
  if (contentType === 'application/json') {
    const response = await rawResponse.json();
    if (response.success) {
      return response.data;
    } else {
      /**
       * Throw an error from response.
       */
      const error = new Error2();
      error.code = response.errorCode;
      error.message = response.errorMessage;
      throw error;
    }
  } else if (contentType === 'text/csv; charset=utf-8') {
    return await rawResponse.text();
  } else {
    const error = new Error2();
    error.code = ERROR_CODE.UNSUPPORTED_CONTENT_TYPE;
    error.message = `${contentType} Content-Type is not supported.`;
    throw error;
  }
};
