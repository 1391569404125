import React from 'react';
import { Link } from 'react-router-dom';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Button.module.css';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'flat'
  | 'flatgrey'
  | 'outlined-gray'
  | 'on-dark';
type SizeVariant = 'small' | 'normal' | 'large' | 'xlarge' | 'custom';

interface ButtonProps extends BaseProps {
  variant: ButtonVariant;
  size?: SizeVariant;
  disabled?: boolean;
  onClick?: () => void;
  /**
   * Allow using "a" element
   */
  is?: 'a' | 'button';
  href?: string;
  target?: string;
  rel?: string;
  /**
   * Allow using react router Link component
   */
  isRouterLink?: boolean;
  to?: string;
}

const Button = ({
  variant,
  size = 'normal',
  disabled,
  is = 'button',
  href,
  target,
  rel,
  isRouterLink,
  to,
  className,
  children,
  onClick,
  dataAnalyticId,
  dataAnalyticDetail,
  dataAnalyticLocation,
}: ButtonProps) => {
  const classes = toClassNames([
    style[variant],
    style[size],
    style.button, // Base style
    className,
  ]);

  /**
   * Link comopnent and <a> tag does not accept disabled attribute, therefore, we switch to normal button if it is disabled.
   */
  const elementType = disabled ? 'button' : is;
  if (isRouterLink && to && !disabled)
    return (
      <Link
        to={to}
        className={classes}
        target={target}
        rel={rel}
        data-analytic-id={dataAnalyticId}
        data-analytic-detail={dataAnalyticDetail}
        data-analytic-location={dataAnalyticLocation}
      >
        {children}
      </Link>
    );

  return React.createElement(
    elementType,
    {
      className: classes,
      disabled: disabled,
      href: href,
      rel: is === 'a' ? rel || 'noreferrer noopener' : '',
      target: is === 'a' ? target : '',
      onClick: onClick,
      'data-analytic-id': dataAnalyticId,
      'data-analytic-detail': dataAnalyticDetail,
      'data-analytic-location': dataAnalyticLocation,
    },
    children
  );
};

export default Button;
