import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../router';
import { Container, Image, Text } from '../../components/base';
import { toClassNames } from '../../utils';

export const ASSESSMENT_NAVBAR_HEIGHT = 80;
const AssessmentNavBar = ({
  children,
  logoLinkUrl = routes.Home.getRoute(),
}: {
  children?: React.ReactNode;
  logoLinkUrl?: string;
}) => {
  return (
    <div
      className={toClassNames([
        'bg-white',
        'flex items-center',
        `h-[${ASSESSMENT_NAVBAR_HEIGHT}px]`,
      ])}
    >
      <Container fluid={true} className="flex items-center flex-wrap !max-w-none">
        <Link to={logoLinkUrl}>
          <Text variant="sm" className="text-grey-500 px-4 mb-1 sm:hidden">
            EdSpeak by
          </Text>
          <Image src={'/assets/images/edsy-logo.png'} alt="edsy" className="w-[128px] px-4" />
        </Link>
        {children}
      </Container>
    </div>
  );
};

export default AssessmentNavBar;
