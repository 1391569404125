import { useState } from 'react';
import { Modal, Image, Heading, Button, Text } from '../../../../components/base';
import { useTranslation } from 'react-i18next';

const RatingModal = ({
  onNext,
  onRating,
  open,
  questionNumber,
  total,
  isTestCompleted,
}: {
  onNext: () => void;
  onRating: () => void;
  open: boolean;
  questionNumber: number;
  total?: number;
  isTestCompleted: boolean;
}) => {
  // const [stars, setStars] = useState<number>(0);

  const { t } = useTranslation('assessment');
  const handleNext = () => {
    // setStars(0);
    onNext();
  };

  return (
    <Modal open={open} size="md">
      <div className="p-4">
        <Image
          src="/assets/images/speaking-test/rating-icon.png"
          alt="loading"
          className="mx-auto mb-6"
        />
        <Heading is="h5" variant="h5" className="text-primary text-center mb-1">
        {t('assessment_speaking_test_quiz_rating_modal_heading_question_number')}{questionNumber}/{total}
        </Heading>
        <Heading is="h2" variant="h2" className="text-center mb-8">
          {isTestCompleted ? t('assessment_speaking_test_quiz_rating_modal_heading_is_test_completed') : t('assessment_speaking_test_quiz_rating_modal_heading_is_test_not_completed')}
        </Heading>
        {/* <div className="mt-6 mb-4">
          <Text variant="md" className="text-gray-500 text-center">
            มั่นใจคำตอบของข้อนี้อยู่ที่กี่ดาว?
          </Text>
          <div className="flex flex-row justify-center pt-2">
            {StarRender({ rating: stars, setRating: setStars })}
          </div>
        </div> */}
        <Button
          // disabled={stars === undefined}
          variant="primary"
          onClick={handleNext}
          className="text-center mx-auto"
        >
          {isTestCompleted ? t('assessment_speaking_test_quiz_rating_modal_button_is_test_completed') : t('assessment_speaking_test_quiz_rating_modal_button_is_test_not_completed')}
        </Button>
      </div>
    </Modal>
  );
};

const StarRender = ({
  rating,
  setRating,
}: {
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
}) => {
  let star: JSX.Element[] = [];

  for (let initRate = 0; initRate < 5; initRate++) {
    if (initRate <= rating) {
      star.push(
        <div
          key={`star-${initRate}`}
          onClick={() => setRating(initRate)}
          className="w-7 h-7 mx-1 cursor-pointer"
        >
          <Image src={'/assets/images/speaking-test/fill-star.png'} alt="loading" />
        </div>
      );
    } else {
      star.push(
        <div
          key={`star-${initRate}`}
          onClick={() => setRating(initRate)}
          className="w-7 h-7 mx-1 cursor-pointer"
        >
          <Image src={'/assets/images/speaking-test/blank-star.png'} alt="loading" />
        </div>
      );
    }
  }

  return star;
};

export default RatingModal;
