import { useState } from 'react';
import { Heading, Modal, Button, Dropdown, TextArea } from '../../../../components/base';
import { CreateSpeakingTestQuestionPromptParams } from '../../../../api/test';
import { SpeakingTestQuestionPromptContentType } from '../../../../model/speakingTestQuestion';
import { createEnumTypeGuard } from '../../../../utils/validators';
import { PROMPT_TYPE_OPTIONS } from './formData';

interface CreateModalProps {
  open: boolean;
  onPrimaryCtaClick: (values: CreateSpeakingTestQuestionPromptParams) => void;
  onSecondaryCtaClick: () => void;
}

const CreateSpeakingTestQuestionPromptModal = ({
  open,
  onSecondaryCtaClick,
  onPrimaryCtaClick,
}: CreateModalProps) => {
  const [type, setType] = useState<SpeakingTestQuestionPromptContentType>();
  const [content, setContent] = useState<string>('');

  const handleChangeType = (e: React.FormEvent<HTMLSelectElement>) => {
    const isValidPromptType = createEnumTypeGuard(SpeakingTestQuestionPromptContentType);
    const value = e.currentTarget.value;
    if (isValidPromptType(value)) {
      setType(value);
    }
  };

  const handleChangeContent = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setContent(e.currentTarget.value);
  };

  const handleCreatePrompt = () => {
    if (!!type && !!content) {
      const values: CreateSpeakingTestQuestionPromptParams = {
        type,
        content,
      };
      onPrimaryCtaClick(values);
    } else {
      alert('Missing type or content');
    }
  };

  return (
    <Modal open={open} size="lg">
      <div className="flex flex-col justify-center items-start">
        <Heading variant="h1" className="mb-8">
          Create Speaking Test with Questions
        </Heading>
        <div className="flex flex-col gap-4 mb-12 w-full items-stretch">
          <Dropdown
            value={type || ''}
            onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChangeType(e)}
            placeholder="Selete type Type"
            options={PROMPT_TYPE_OPTIONS}
          />
          <TextArea value={content} onChange={handleChangeContent} placeholder="Content" />
        </div>

        <div className="flex gap-4 justify-end w-full">
          <Button variant="secondary" onClick={onSecondaryCtaClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreatePrompt}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSpeakingTestQuestionPromptModal;
