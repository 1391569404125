import { Report } from '../../../types/report';
import style from './Template1.module.css';
import Footer from '../components/Footer';
import TableScore from '../components/TableScore';
import StudentInfo from '../components/StudentInfo';
import OverallResult from '../components/OverallResult';
import { School } from '../../../model/school';
import { GRADE_OPTIONS } from '../../../routes/assessmentApp/Registration/constants';

const ReportsPrint = ({ report, school }: { report: Report; school: School }) => {
  if (!report) return null;
  return (
    <div className={style['report-print-wrapper']}>
      <div className={style['report-print-container']}>
        {/* <h2 className={style['report-print-id']}>ID: {report.testId}</h2> */}
        <img
          src="/assets/images/edsy-logo.png"
          alt="edsy"
          className={style['report-print-edsy-logo']}
        />
        <h2 className={style['report-print-title']}>
          รายงานการวัดระดับทักษะการพูดภาษาอังกฤษ <br />
          ด้วยเครื่องมือ EdSpeak ของ Edsy
        </h2>
        <div className={style['report-print-inner-background']}>
          <div className={style['report-print-overall-info']}>
            <StudentInfo
              fullName={report.tester.fullName}
              school={school.name}
              grade={
                GRADE_OPTIONS.find((option) => option.value === report.tester.grade)?.text || '-'
              }
              room={report.tester.room}
              testDate={report.date}
            />

            <OverallResult
              cefrResult={report.result.englishStandard.cefr}
              cambridgeResult={report.result.englishStandard.cambridge}
            />
          </div>

          <TableScore
            category={report.result.category}
            totalScore={report.result.overall.score}
            showTotalScore={true}
          />

          <Footer
            testId={report.testId}
            qrCodeUrl={
              school.sumReportQrToReportUrl
                ? school.sumReportQrToReportUrl
                : '/assets/images/edsy-report-qr-code.svg'
            }
          />
        </div>
      </div>
      <div className={style['report-poster']}>
        {school.sumReportAdsUrl && (
          <img src={school.sumReportAdsUrl} alt={'poster'} className={style['poster']} />
        )}
      </div>
    </div>
  );
};

export default ReportsPrint;
