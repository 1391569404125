import { StarsCount, StarsCountText } from '../types/starsRating';

// A helper function to combine classNames. Only use when necessary.
export const toClassNames = (classNameArr: (string | undefined)[]): string => {
  return classNameArr.filter((className) => !!className).join(' ');
};

export const formatScore = (score: number, decimal: boolean = true): string => {
  return score === -1 ? 'N/A' : decimal ? score.toFixed(1) : score.toFixed(0);
};

export const formatEdSpeakScore = (score: number, fallbackText?: string): string => {
  const fallbackString = fallbackText || 'N/A';
  return score === -1 ? fallbackString : Math.ceil(score).toString();
};

export const formatCefrLevel = (cefr: string, fallbackText?: string): string => {
  const fallbackString = fallbackText || '';
  if (cefr === 'N/A') {
    return fallbackString;
  } else {
    return cefr;
  }
};

export const formatCambridgeLevel = (cambridge: string, fallbackText?: string): string => {
  const fallbackString = fallbackText || '';
  if (cambridge === '') {
    return fallbackString;
  } else {
    return cambridge;
  }
};

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

export const roundToNearestHalf = (value: number): number => {
  return Math.round(value * 2) / 2;
};

/**
 * Calculate starCount for EdSpeak metric score from raw score.
 * If score is -1, return 0
 * If score is 0 < score <0.25, return 0.5
 * If remainder is between 0.25 - 0.74, return 0.5
 * If remainder is < 0.25, return 0
 * If remainder is >= 0.75, return 1
 */
export const scoreToStarCount = (score: number) => {
  // Case score leass than 0.25, we still want to showthat they get 0.5 star at least
  if (score > 0 && score < 0.25) return 0.5;

  let starCount = 0;
  while (score > 0) {
    if (score >= 0.75) {
      starCount++;
    } else if (score < 0.75 && score >= 0.25) {
      starCount = starCount + 0.5;
    }
    score--;
  }
  return starCount;
};

/**
 *
 * @description Convert number of stars to string representation
 * @param starCount number of stars (multiple of 0.5), *raw score is not allowed*
 */
export const starsCountToText = (starCount: number | StarsCount): StarsCountText => {
  // switch case will throw error if input is not valid
  // starCount = roundToNearestHalf(clamp(starCount, 0, 5));
  switch (starCount) {
    case StarsCount.Zero:
      return StarsCountText.Zero;
    case StarsCount.ZeroHalf:
      return StarsCountText.ZeroHalf;
    case StarsCount.One:
      return StarsCountText.One;
    case StarsCount.OneHalf:
      return StarsCountText.OneHalf;
    case StarsCount.Two:
      return StarsCountText.Two;
    case StarsCount.TwoHalf:
      return StarsCountText.TwoHalf;
    case StarsCount.Three:
      return StarsCountText.Three;
    case StarsCount.ThreeHalf:
      return StarsCountText.ThreeHalf;
    case StarsCount.Four:
      return StarsCountText.Four;
    case StarsCount.FourHalf:
      return StarsCountText.FourHalf;
    case StarsCount.Five:
      return StarsCountText.Five;
    default:
      throw new Error('Invalid star count');
  }
};
