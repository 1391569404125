import React from 'react';
import { Button, Dropdown, Input, LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as assessmentApiClient from '../../../api/assessment';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import AccessCodeStatusTable from './components/AccessCodeStatusTable';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { CEFRLevel } from '../../../types/cefr';
import { createEnumTypeGuard } from '../../../utils/validators';
import { Result } from '../../../types/report';
dayjs.extend(isBetween);

const isCEFRLevel = createEnumTypeGuard(CEFRLevel);

// TODO: may be confused with model `AccessCode`
export interface AccessCodeStatusData {
  accessCode: string;
  testerId: string;
  firstname: string;
  lastname: string;
  testinstanceId: string;
  status: string;
  testInstanceCreatedAt: string;
  testInstanceUpdatedAt: string;
  result?: Result | undefined;
  customCEFR?: CEFRLevel | null;
}

const AccessCodeStatus = () => {
  const [loading, setLoading] = React.useState(false);
  const [schoolOptions, setSchoolOption] = React.useState<DropdownOption[]>([
    { value: '', text: '' },
  ]);
  const [schoolId, setSchoolId] = React.useState('');
  const [startIndex, setStartIndex] = React.useState('1');
  const [limit, setLimit] = React.useState('100');
  // TODO: ambiguous name, may be confused with model `AccessCode`
  const [accessCodeStatuses, setAccessCodeStatuses] = React.useState<AccessCodeStatusData[]>([]);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [includeInvalidDate, setIncludeInvalidDate] = React.useState(true);

  const includeInvalidDateCheckboxId = React.useId();

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const schools = await assessmentApiClient.getAllSchools();
        const schoolOptions = schools.map((school) => {
          return { text: school.name, value: school.id };
        });
        setSchoolOption(schoolOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  const search = async () => {
    if (!schoolId) return;
    setLoading(true);
    setAccessCodeStatuses([]);
    try {
      const start = parseInt(startIndex);
      const queryLimit = parseInt(limit);
      const data = await assessmentApiClient.getAccessCodeStatusBySchoolId(
        schoolId,
        queryLimit,
        start
      );
      if (Array.isArray(data)) {
        const formattedData = data.map((d) => {
          const result: Result | undefined = d.testResult || undefined;
          const customCEFR = d.customCEFR;
          const validCEFR = isCEFRLevel(customCEFR) ? customCEFR : null;
          return {
            result: result,
            customCEFR: validCEFR,
            ...d,
          };
        });
        setAccessCodeStatuses(formattedData);
      } else {
        throw new Error('Data is not an array');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSchoolIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSchoolId(e.currentTarget.value);
  };

  const onIncludeInvalidDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeInvalidDate(e.currentTarget.checked);
  };

  const handleStartIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartIndex(e.currentTarget.value);
  };

  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(e.currentTarget.value);
  };

  const handleSearch = async () => {
    await search();
  };

  const filteredAccessCodeStatuses = React.useMemo(() => {
    return accessCodeStatuses.filter((accessCodeStatus) => {
      const accessCodeUpdatedDate = dayjs(accessCodeStatus.testInstanceUpdatedAt);
      if (!accessCodeUpdatedDate.isValid()) {
        return includeInvalidDate;
      }
      const nonnullStartDate = startDate || dayjs('1970-01-01');
      const nonnullEndDate = endDate || dayjs('9999-12-31');
      return accessCodeUpdatedDate.isBetween(nonnullStartDate, nonnullEndDate, 'day', '[]');
    });
  }, [accessCodeStatuses, startDate, endDate, includeInvalidDate]);

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="Access Code Status"
        description=""
        className="mb-6"
      />

      <div className="mb-6">
        <label className="text-sm mb-2">Select school</label>
        <div className="flex flex-row gap-4 w-full">
          <Dropdown
            value={schoolId}
            onChange={onSchoolIdChange}
            placeholder="Select School"
            options={schoolOptions}
          />
        </div>
      </div>

      <div className="mb-6 flex gap-4">
        <div className="flex flex-col gap-1 w-full">
          <label className="text-sm mb-2">Start Index (Start from 1)</label>
          <Input
            value={startIndex}
            type={'number'}
            onChange={handleStartIndexChange}
            placeholder="start from"
          />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label className="text-sm mb-2">Limit (No more than 2000 please)</label>
          <Input value={limit} type={'number'} onChange={handleLimitChange} placeholder="limit" />
        </div>
      </div>

      <Button variant={'primary'} size={'large'} onClick={handleSearch} className={'mb-6'}>
        Search
      </Button>
      {/* <div className="mb-6">
        <label className="inline-block text-xl mb-2">Filter updated date</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="flex items-center gap-x-4">
            <DatePicker value={startDate} onChange={setStartDate} format="DD MMMM YYYY" />
            <div className="text-lg">-</div>
            <DatePicker value={endDate} onChange={setEndDate} format="DD MMMM YYYY" />
            <div>
              <input
                type="checkbox"
                id={includeInvalidDateCheckboxId}
                className="cursor-pointer mr-2"
                checked={includeInvalidDate}
                onChange={onIncludeInvalidDateChange}
              />
              <label htmlFor={includeInvalidDateCheckboxId} className="cursor-pointer">
                Include invalid dates
              </label>
            </div>
          </div>
        </LocalizationProvider>
      </div> */}

      <div className="w-full text-center">
        {schoolId ? <AccessCodeStatusTable accessCodeStatus={filteredAccessCodeStatuses} /> : null}
      </div>
      <LoadingModal open={loading} />
    </div>
  );
};

export default AccessCodeStatus;
