import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../../components/base';
import ConfirmModal from '../../components/ConfirmModal';
import { useAssessmentContext } from '../../contexts/assessmentContext';
import Icon, { IconName } from '../../components/base/Icon';

const AssessmentSessionAppMenu = () => {
  const { t } = useTranslation('assessment');
  const { cancelTest, tester, school } = useAssessmentContext();
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const handleCancelSessionClick = () => {
    setConfirmModalOpen(true);
  };

  const handleCancelSession = () => {
    cancelTest();
  };

  const handleContinueSession = () => {
    setConfirmModalOpen(false);
  };
  return (
    <div className="flex flex-col items-end sm:flex-row sm:items-center gap-x-2 gap-y-1">
      <div className="flex flex-row gap-1 items-center">
        {school && (
          <>
            <Icon
              icon={IconName.AwardOutlined}
              size={'20px'}
              className="hidden sm:block text-primary-500"
            />
            <Text variant="sm" className="hidden sm:block text-grey-500 font-semibold">
              {school.name}
            </Text>
          </>
        )}
        {tester && (
          <Text variant="sm" className="text-grey-500">
            <span className="capitalize">{tester.firstName}</span>{' '}
            <span className="lowercase">{tester.lastName?.[0] + '.'}</span>
          </Text>
        )}
      </div>
      <Button
        variant="outlined"
        size="small"
        onClick={handleCancelSessionClick}
        className="!w-fit !px-[10px] !py-[5px]"
      >
        {t('assessment_app_cancel_test_button')}
      </Button>
      <ConfirmModal
        open={confirmModalOpen}
        title={t('assessment_app_cancel_test_modal_title')}
        description={t('assessment_app_cancel_test_modal_description')}
        secondaryButtonText={t('assessment_app_cancel_test_modal_button_cancel')}
        primaryButtonText={t('assessment_app_cancel_test_modal_button_continue')}
        onSecondaryCtaClick={handleCancelSession}
        onPrimaryCtaClick={handleContinueSession}
      />
    </div>
  );
};

export default AssessmentSessionAppMenu;
