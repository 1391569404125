import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Input, Text, LoadingModal, Heading } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { getAccessCodeByCode, getLatestTestByAccessCode } from '../../../api/assessment';
import { assessmentAppRoutes } from '../../../router';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import { Test } from '../../../model/test';
import ContinueModal from './components/ContinueModal';
import { AccessCode } from '../../../model/accessCode';
import useRegistrationFlowHelpers from '../../../utils/useRegistrationFlowHelpers';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const { navigateToAssessmentSessionAppFromRegistrationFlow } = useRegistrationFlowHelpers();
  const [accessCode, setAccessCode] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [continueModalOpen, setContinueModalOpen] = React.useState(false);
  const [latestTest, setLatestTest] = React.useState<Test>();
  const [accessCodeObj, setAccessCodeObj] = React.useState<AccessCode>();

  const handleChangeAccessCode = (e: React.FormEvent<HTMLInputElement>): void => {
    // Clear errorMessage when users start modifying input again.
    if (errorMessage) setErrorMessage('');

    setAccessCode(e.currentTarget.value);
  };

  const submitAccessCode = async (code: string) => {
    try {
      const [accessCodeObj, latestTest] = await Promise.all([
        getAccessCodeByCode(code),
        getLatestTestByAccessCode(code),
      ]);
      if (latestTest) {
        setLatestTest(latestTest);
        setAccessCodeObj(accessCodeObj);
        setContinueModalOpen(true);
      } else {
        navigateToRegistrationPage(accessCodeObj.code);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t('assessment_home_submit_access_code_error'));
    }
  };

  const handleSubmitAccessCode = async () => {
    setLoading(true);
    await submitAccessCode(accessCode);
    setLoading(false);
  };

  const navigateToRegistrationPage = (code: string) => {
    navigate(assessmentAppRoutes.AssessmentAppRegistration.getRoute(code));
  };

  const handleContinueSession = () => {
    if (latestTest) navigateToAssessmentSessionAppFromRegistrationFlow(latestTest?.id);
  };

  const handleCancelSession = () => {
    navigateToRegistrationPage(accessCode);
  };

  return (
    <LayoutCard className="my-10 flex flex-col items-center">
      <CardHeader
        variant={CardHeaderVariant.SECONDARY}
        title={t('assessment_home_cardheader_title')}
        subtitle={t('assessment_home_cardheader_subtitle')}
        className="mb-6"
      />

      <Image
        src="/assets/images/assessment-app-home.png"
        alt="homepage"
        className="w-[280px] mb-6"
      />

      <Heading variant="h5" className="text-grey-500 mb-3 font-medium">
        {t('assessment_home_heading_text')}
      </Heading>

      <Input
        value={accessCode}
        onChange={handleChangeAccessCode}
        placeholder={t('assessment_home_input_placeholder')}
        className="w-full max-w-[320px] mb-1 text-center"
      />
      <Text variant="xs" className="text-red-500 h-2">
        {errorMessage}
      </Text>

      <Button
        variant="primary"
        size="large"
        className="w-full max-w-[320px] mb-4 mt-5"
        disabled={!accessCode}
        onClick={handleSubmitAccessCode}
      >
        {t('assessment_home_submit_button')}
      </Button>

      <LoadingModal open={loading} text={t('assessment_home_loading_text')} />
      {latestTest && accessCode && (
        <ContinueModal
          test={latestTest}
          open={continueModalOpen}
          accessCode={accessCodeObj}
          onPrimaryCtaClick={handleContinueSession}
          onSecondaryCtaClick={handleCancelSession}
        />
      )}
    </LayoutCard>
  );
};

export default Home;
