import React from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingModal } from '../../../components/base';
import ConfirmModal from '../../../components/ConfirmModal';
import { AssessmentContextProvider } from '../../../contexts/assessmentContext';
import AssessmentLayout from '../../../layouts/AssessmentLayout';
import { assessmentAppRoutes } from '../../../router';
import useAssessmentAppStorage from '../../../utils/useAssessmentAppStorage';
import { useTranslation } from 'react-i18next';

const AssessmentSessionAppRoot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const [searchParams, setSearchParams] = useSearchParams();
  const assessmentAppStorage = useAssessmentAppStorage();

  const [continueSessionModalOpen, setContinueSessionModalOpen] = React.useState(false);
  const [testId, setTestId] = React.useState<string>();
  const [appReady, setAppReady] = React.useState(false);

  React.useEffect(() => {
    const testId = assessmentAppStorage.getTestId();
    const isFromRegistration = searchParams.get('entryMethod') === 'Registration';
    if (!testId) {
      navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
    } else {
      /**
       * TODO: Check with business if we still need to show this in-assessment-app continue modal
       * Or just the continue modal on the whole page is better.
       */
      setTestId(testId);
      if (!isFromRegistration) {
        setContinueSessionModalOpen(true);
      } else {
        setAppReady(true);
      }
    }
  }, []);

  const handleCancelSession = () => {
    navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
  };

  const handleContinueSession = () => {
    setAppReady(true);
  };

  if (!testId) {
    return (
      <AssessmentLayout>
        <LoadingModal open={true} />
      </AssessmentLayout>
    );
  }

  if (!appReady) {
    return (
      <AssessmentLayout>
        <ConfirmModal
          open={continueSessionModalOpen}
          title={t('assessment_session_app_root_confirm_modal_title')}
          description={t('assessment_session_app_root_confirm_modal_description')}
          secondaryButtonText={t('assessment_session_app_root_confirm_modal_secondary_button_text')}
          primaryButtonText={t('assessment_session_app_root_confirm_modal_primary_button_text')}
          onSecondaryCtaClick={handleCancelSession}
          onPrimaryCtaClick={handleContinueSession}
        />
      </AssessmentLayout>
    );
  }

  return (
    <AssessmentContextProvider testId={testId}>
      <AssessmentLayout isSessionApp={true}>
        <Outlet />
      </AssessmentLayout>
    </AssessmentContextProvider>
  );
};

export default AssessmentSessionAppRoot;
