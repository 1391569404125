import style from '../SummaryReportPrint.module.css';

type OverallResultProps = {
  cefrResult: string;
  cambridgeResult: string;
};

const OverallResult = ({ cefrResult, cambridgeResult }: OverallResultProps) => {
  return (
    <div className={style['report-print-overall-result']}>
      <h2 className={style['overall-result-title']}>
        ระดับทักษะ
        <span className="text-primary font-semibold">SPEAKING</span>
        เทียบเท่า:
      </h2>
      <div className={style['overall-result-container']}>
        <div className={style['overall-result-item']}>
          <img
            src="/assets/images/cefr-logo.png"
            alt="cefr assessment"
            className={`${style['cefr-logo']} ${style['standard-logo']}`}
          />
          <div className={style['label']}>{cefrResult}</div>
        </div>
        <div className={style['overall-result-item']}>
          <img
            src="/assets/images/cambridge-assessment-logo.png"
            alt="cambridge assessment"
            className={`${style['cambridge-logo']} ${style['standard-logo']}`}
          />
          <div className={style['label']}>{cambridgeResult}</div>
        </div>
      </div>
    </div>
  );
};

export default OverallResult;
