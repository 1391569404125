import {
  SpeakingTestQuestionPromptContent,
  SpeakingTestQuestionPromptContentType,
} from '../../../../model/speakingTestQuestion';
import { Image, Text } from '../../../../components/base';

interface PromptProps {
  type: SpeakingTestQuestionPromptContentType;
  content: string;
}

const Prompt = ({ type, content }: PromptProps) => {
  return type === SpeakingTestQuestionPromptContentType.IMAGE ? (
    <Image src={content} alt={'question image'} className="mx-auto mt-4" />
  ) : (
    <Text>{content}</Text>
  );
};

const Question = ({ prompts }: { prompts: SpeakingTestQuestionPromptContent[] }) => {
  return (
    <div className="mx-auto text-center bg-primary-50 rounded-xl p-8 max-w-[720px] border border-primary-300 shadow-md">
      {prompts.map((prompt, index) => (
        <div className="mb-1" key={index}>
          <Prompt content={prompt.content} type={prompt.type} key={`${index}-type`} />
        </div>
      ))}
    </div>
  );
};

export default Question;
