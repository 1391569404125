import i18n from '../i18n';

export enum TesterType {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  BusinessEmployee = 'BUSINESS_EMPLOYEE',
}

function parseTesterType(type: string): TesterType {
  switch (type) {
    case TesterType.Student:
      return TesterType.Student;
    case TesterType.Teacher:
      return TesterType.Teacher;
    case TesterType.BusinessEmployee:
      return TesterType.BusinessEmployee;
    default:
      throw new Error(`Given string ${type} does not map to the TesterType value`);
  }
}
export class Tester {
  constructor(
    public id: string,
    /**
     * Short number to display in the report
     * TODO: Activate this when the backend is ready
     */
    // public number: string,
    public type: TesterType,
    public firstName: string,
    public lastName: string,
    public prefix: string,
    public schoolId: string,
    public grade?: string,
    public room?: string,
    public phoneNo?: string,
    public email?: string,
    public organization?: string
  ) {}
  public static parse(json: { [key: string]: any }): Tester {
    const tester = new Tester(
      json['id'],
      // json['number'],
      parseTesterType(json['type']),
      json['firstName'],
      json['lastName'],
      json['prefix'],
      json['schoolId'],
      json['grade'],
      json['room'],
      json['phoneNo'],
      json['email'],
      json['organization']
    );
    return tester;
  }

  // TODO: cast static key = generic `string` is not type-safe
  // * this won't be needed once `tester.grade` is typed (i.e., "1" | "2" | ... | "13")
  private testerGradeShortMapping: Record<string, string> = i18n.t(`tester_grade_short`, {
    ns: 'common',
    returnObjects: true,
  });

  public fullName = `${this.firstName}${this.lastName ? ` ${this.lastName}` : ''}`;
  public gradeShortText = this.grade ? this.testerGradeShortMapping[this.grade] || '' : '';
  public roomText = this.room
    ? `${i18n.t('tester_room_label', { ns: 'common' })} ${this.room}`
    : '';
  public gradeAndRoomText = `${this.gradeShortText}${this.room ? ` ${this.roomText}` : ''}`;
}

export const mockTester = new Tester(
  'mocktesterid',
  TesterType.Student,
  'Edsy',
  'Test',
  'นาย',
  'mockschoolId',
  '5',
  '5'
);
