import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { Tester } from '../../../../model/tester';

interface TestersTableProps {
  testers: Tester[];
  onRowSelectionModelChange: (newSelectRow: GridRowSelectionModel) => void;
  rowSelectionModel: GridRowSelectionModel;
}

const columns: GridColDef[] = [
  {
    field: 'uniqueId',
    headerAlign: 'center',
    headerName: 'Unique id',
    align: 'center',
    flex: 0.2,
  },
  {
    field: 'prefix',
    headerAlign: 'center',
    headerName: 'Prefix',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'schoolId',
    headerAlign: 'center',
    headerName: 'School Id',
    align: 'center',
    flex: 0.5,
  },
  {
    field: 'firstName',
    headerAlign: 'center',
    headerName: 'First name',
    align: 'center',
    flex: 0.5,
  },
  { field: 'lastName', headerAlign: 'center', headerName: 'Last name', align: 'center', flex: 0.3 },
  {
    field: 'gradeAndRoomText',
    headerAlign: 'center',
    headerName: 'Grade and Room',
    align: 'center',
    flex: 0.3,
    valueGetter: (params: GridValueGetterParams) => `${params.row.gradeAndRoomText || '-'}`,
  },
  {
    field: 'email',
    headerAlign: 'center',
    headerName: 'Email',
    align: 'center',
    flex: 0.3,
    valueGetter: (params: GridValueGetterParams) => `${params.row.email || '-'}`,
  },
  {
    field: 'phoneNo',
    headerAlign: 'center',
    headerName: 'Phone no',
    align: 'center',
    flex: 0.3,
    valueGetter: (params: GridValueGetterParams) => `${params.row.phoneNo || '-'}`,
  },
  {
    field: 'type',
    headerAlign: 'center',
    headerName: 'Tester type',
    align: 'center',
    flex: 0.3,
  },
];

const TestersTable = ({
  testers,
  rowSelectionModel,
  onRowSelectionModelChange,
}: TestersTableProps) => {
  return (
    <div className="text-center" style={{ width: '100%', overflow: 'auto' }}>
      <div className="w-[1500px]">
        <DataGrid
          getRowId={(row) => row.id}
          rows={testers}
          columns={columns}
          disableRowSelectionOnClick
          checkboxSelection
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cellContent': {
              overflowWrap:'break-word',
              wordWrap:'break-word',
              whiteSpace:'initial',
              paddingY:1
            },
          }}
        />
      </div>
    </div>
  );
};

export default TestersTable;
