import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { Container, Heading, Text } from '../../../components/base';
import { formatScore } from '../../../utils';

/**
 * This is a workaround to display Label in 2 lines because svg does not have text wrap feature.
 * TODO: Investigate and Find a long term solution.
 */
const renderCustomizedLabel = (textArr: string[]) => (props: any) => {
  const { y, width } = props;
  return (
    <g>
      {textArr.map((text, index) => {
        return (
          <text
            key={text}
            x={width}
            dx={15}
            y={y + 7.5 + 10 * index}
            fill="black"
            textAnchor="start"
            dominantBaseline="middle"
            fontSize={'10px'}
          >
            {text}
          </text>
        );
      })}
    </g>
  );
};

const ScoreChart = ({ score, averageScore }: { score: number; averageScore: number }) => {
  const chartData = [
    {
      name: 'คะแนนของคุณ',
      avgName: 'คะแนนเฉลี่ย EdSpeak',
      score: score > 0 ? score : 0,
      averageScore: averageScore,
      color: '#1f45b1',
      labelColor: 'white',
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout={'vertical'}
        data={chartData}
        margin={{
          top: 0,
          right: 60,
          left: 0,
          bottom: 5,
        }}
        barCategoryGap={10}
        barSize={25}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="score"
          domain={[0, 5]}
          tickCount={5}
          type="number"
          scale="linear"
          label={{
            value: 'score',
            position: 'insideBottom',
            offset: 0,
            style: { fontSize: '12px', fontWeight: 700, color: 'black' },
          }}
          style={{ fontSize: '10px' }}
        />
        <YAxis
          dataKey="name"
          type="category"
          style={{ fontSize: '10px' }}
          width={10}
          tick={false}
        />
        <Bar dataKey="score" name={'คะแนนของคุณ'} fill={'#1f45b1'} isAnimationActive={false}>
          <LabelList
            content={renderCustomizedLabel(['คะแนน', 'ของคุณ'])}
            dataKey="name"
            position="right"
            style={{ fill: 'black', fontSize: '10px' }}
          />
        </Bar>
        <Bar
          dataKey="averageScore"
          name={'คะแนนเฉลี่ย Edsy'}
          fill={'#687280'}
          isAnimationActive={false}
        >
          <LabelList
            content={renderCustomizedLabel(['คะแนนเฉลี่ย', 'Edsy'])}
            dataKey="avgName"
            position="right"
            style={{ fill: 'black', fontSize: '10px' }}
          />
        </Bar>
        <Legend
          height={16}
          verticalAlign="top"
          align="left"
          iconSize={6}
          iconType="square"
          wrapperStyle={{ bottom: -20, fontSize: '10px', fontWeight: 600 }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

// =====================================
// ======== Main Component =============
// =====================================
type HeadingSectionProps = {
  titleTh: string;
  titleEn: string;
  score: number;
  fullScore: number;
  averageScore?: number;
  text: string;
  color: string;
};

const HeadingSection = ({
  titleTh,
  titleEn,
  score,
  fullScore,
  averageScore,
  color,
  text,
}: HeadingSectionProps) => {
  return (
    <Container className="pb-10 pt-8">
      <Heading variant="h2" is={'h1'} className="text-primary mb-10">
        {titleTh} <br />({titleEn})
      </Heading>
      <div className="flex gap-4 items-stretch mb-12">
        <div
          className="w-[112px] h-[112px] rounded-xl flex flex-col justify-center items-center text-primary shrink-0 mx-auto mt-2 mb-4"
          style={{ backgroundColor: color }}
        >
          <Text variant="3xl" className="font-bold">
            {formatScore(score)}
          </Text>
          <Text variant="xs" className="text-center">
            จาก {fullScore} คะแนน
          </Text>
        </div>
        {averageScore && (
          <div className="w-full rounded-xl bg-white">
            <ScoreChart score={score} averageScore={averageScore} />
          </div>
        )}
      </div>
      <Text className="whitespace-pre-line">{text}</Text>
    </Container>
  );
};

export default HeadingSection;
