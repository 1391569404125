import { BaseProps } from '../types';
import { toClassNames } from '../../utils';

interface ContainerProps extends BaseProps {
  fluid?: boolean;
  size?: 'lg' | 'md';
}

const Container = ({ fluid = false, className, size, children, style }: ContainerProps) => {
  return (
    <div
      className={toClassNames([
        'container px-4 md:px-8 lg:px-12 ',
        `${fluid ? '' : size === 'lg' ? 'max-w-[1024px]' : 'max-w-[768px]'}`,
        className || '',
      ])}
      style={style}
    >
      {children}
    </div>
  );
};

export default Container;
