import { GRID_CHECKBOX_SELECTION_FIELD, useGridApiContext } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { CgSpinner } from 'react-icons/cg';
import * as adminApiClient from '../../../../api/admin';
import { Button, Heading } from '../../../../components/base';
import { Checkbox } from '../../../../components/base/Form';
import Icon, { IconName } from '../../../../components/base/Icon';
import Modal, { ModalProps } from '../../../../components/base/Modal';
import Text from '../../../../components/base/Text';
import { AccessCodeStatusData } from '../AccessCodeStatus';

const ExportCSVConfirmationModal: FC<ModalProps> = ({ open, onClose }) => {
  const [isExportIncludedSignedUrls, setIsExportIncludedSignedUrls] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const apiRef = useGridApiContext();

  const selectedRowsCount = apiRef.current.getSelectedRows().size;
  const visibleColumns = apiRef.current.getVisibleColumns();

  useEffect(() => {
    apiRef.current.setColumnVisibility('signedUrls', isExportIncludedSignedUrls);
  }, [apiRef, isExportIncludedSignedUrls]);

  const onConfirmButtonClick = async () => {
    setIsExporting(true);
    try {
      if (isExportIncludedSignedUrls) {
        // get selected rows
        const selectedRows = Array.from(
          apiRef.current.getSelectedRows().values()
        ) as AccessCodeStatusData[];
        const testInstanceIds = selectedRows.map((row) => row.testinstanceId);
        // retrieve signed urls
        const signedUrlDataRecord = await adminApiClient.getSignedUrlByTestInstanceIds(
          testInstanceIds
        );
        // add signed urls to selected rows
        const selectedRowsWithSignedUrl = selectedRows.map((row) => {
          const signedUrlData = signedUrlDataRecord[row.testinstanceId];
          return {
            ...row,
            signedUrls: signedUrlData?.map((d) => d.signedUrl).join('\n') || '',
          };
        });
        // MuiX DataGrid Free not supports multi rows update
        selectedRowsWithSignedUrl.forEach((row) => {
          apiRef.current.updateRows([row]);
        });
      }

      setIsExporting(false);
      // the following line will trigger browser download
      apiRef.current.exportDataAsCsv({
        utf8WithBom: true,
      });
    } catch (error) {
      console.error(error);
    }
    setIsExporting(false);
  };

  const onExportIncludedSignedUrlsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExportIncludedSignedUrls(e.target.checked);
  };

  return (
    <Modal open={open} onClose={onClose} className="text-left">
      <Button variant="flat" className="ml-auto" onClick={onClose}>
        <Icon icon={IconName.Close} className="text-black" />
      </Button>
      <Heading variant="h2" className="text-center">
        Export CSV
      </Heading>
      <Heading variant="h4" className="text-left mt-3">
        Columns to be exported
      </Heading>
      <ul className="list-disc list-inside">
        {visibleColumns.map((column, i) => {
          if (column.field === GRID_CHECKBOX_SELECTION_FIELD) return null;
          return (
            <li key={`${column.field}_${i}`} className="text-left">
              {column.headerName}
            </li>
          );
        })}
      </ul>
      <div className="my-2 flex gap-x-1 items-center">
        <Checkbox
          label="Include audio file urls"
          isChecked={isExportIncludedSignedUrls}
          onChange={onExportIncludedSignedUrlsChange}
        />
      </div>
      {isExportIncludedSignedUrls && (
        <Text variant="md" className="mt-3 text-error-500 text-left font-semibold">
          * Once exported, the audio files will be publicly available until{' '}
          <span className="underline">
            {dayjs().locale('en').add(7, 'days').format('DD MMMM YYYY')}
          </span>{' '}
          (7 days)
        </Text>
      )}
      <Button
        variant="primary"
        className="mx-auto mt-4"
        onClick={onConfirmButtonClick}
        disabled={isExporting}
      >
        <div className="flex items-center">
          <Text variant="sm" className="font-semibold">
            {isExporting ? 'Exporting' : 'Export'} {selectedRowsCount} rows
          </Text>
          {isExporting && <CgSpinner className={`animate-spin w-5 h-5 ml-2`} />}
        </div>
      </Button>
    </Modal>
  );
};

export default ExportCSVConfirmationModal;
