import dayjs from 'dayjs';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { SpeakingTest, SpeakingTestCriteria } from '../../../../model/speakingTest';

interface SpeakingTestsTableProp {
  speakingTests: SpeakingTest[];
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerAlign: 'center',
    headerName: 'id',
    align: 'left',
    flex: 0.25,
  },
  {
    field: 'name',
    headerAlign: 'center',
    headerName: 'Name',
    align: 'center',
    flex: 0.2,
  },
  {
    field: 'questionCriteria',
    headerAlign: 'center',
    headerName: 'Question Criteria',
    align: 'left',
    flex: 0.5,
    renderCell: (params: GridCellParams) => {
      return (
        <ul className="w-full list-decimal p-4">
          {params.row.questionCriteria.map((criteria: SpeakingTestCriteria) => {
            return (
              <li className="text-left">
                <div className="overflow-hidden text-ellipsis">
                  {criteria.type} - {criteria.cefrLevel} - {criteria.speakingQuestionId}
                </div>
              </li>
            );
          })}
        </ul>
      );
    },
  },
  {
    field: 'createdAt',
    headerAlign: 'center',
    headerName: 'createdAt',
    align: 'center',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      `${dayjs(params.row.createdAt).format('YYYY-MM-DD')}`,
  },
  {
    field: 'updatedAt',
    headerAlign: 'center',
    headerName: 'updatedAt',
    align: 'center',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      `${dayjs(params.row.updatedAt).format('YYYY-MM-DD')}`,
  },
];

const SpeakingTestsTable = ({ speakingTests }: SpeakingTestsTableProp) => {
  return (
    <div className="text-center" style={{ width: '100%', overflow: 'auto' }}>
      <div className="w-[1500px]">
        <DataGrid
          getRowId={(row) => row.id}
          rows={speakingTests}
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cellContent': {
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              whiteSpace: 'initial',
              paddingY: 1,
            },
          }}
        />
      </div>
    </div>
  );
};

export default SpeakingTestsTable;
