import { useMemo, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Image, Modal, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import { cefrLevels } from '../../../../data/cefr';
import { toClassNames } from '../../../../utils';

const CEFRLevelDescriptionList = () => {
  const { t } = useTranslation('report');

  const cefrLevelDescription = t('cefr_level_description', {
    ns: 'cefr',
    returnObjects: true,
  });

  return (
    <div className="mt-0 sm:mt-5 grid grid-rows-7-auto grid-cols-1 sm:grid-rows-4-auto sm:grid-cols-2 gap-x-6 gap-y-3">
      {cefrLevels.map((level, index) => {
        return (
          <div
            key={level}
            className={toClassNames([
              'bg-white',
              'px-6',
              'py-3',
              'rounded-2xl',
              'col-span-1',
              index === 0 ? 'sm:col-span-2' : '',
            ])}
          >
            <Text className="text-primary font-semibold capitalize">
              {t('level', { ns: 'common' })} {level}
            </Text>
            <Text variant="sm" className="mt-1 text-gray-600">
              {cefrLevelDescription[level]}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

const WhatIsCEFRSection: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('report');

  const handleToggleCefrModal = () => {
    setModalOpen((val) => !val);
  };
  // TODO: gradient for t('report_detail_what_is_cefr_section_title')
  return (
    <div className="bg-yellow-50 px-8 py-8 rounded-3xl">
      <Image
        className="mx-auto w-full max-w-[340px]"
        src="/assets/images/report-app/report-detail-what-is-cefr-header.png"
        alt="report_detail_what_is_cefr_header"
      />
      <Heading is="h1" variant="h2" className="text-center">
        {t('report_detail_what_is_cefr_section_title')}
      </Heading>

      {/* Desktop */}
      <div className="hidden sm:block">
        <Text variant="sm" className="mt-5 whitespace-pre-line text-center text-green-600">
          {t('report_detail_what_is_cefr_section_description')}
        </Text>
        <CEFRLevelDescriptionList />
      </div>

      {/* Mobile */}
      <div className="flex flex-col items-center sm:hidden">
        <Text variant="sm" className="mt-5 whitespace-pre-line text-center mb-8">
          {t('report_detail_what_is_cefr_section_description_short')}
        </Text>
        <Button
          variant="flat"
          onClick={handleToggleCefrModal}
          dataAnalyticId="report-detail-what-is-cefr-read-more"
        >
          {t('report_detail_what_is_cefr_section_read_more')}
        </Button>

        {/* Modal */}
        <Modal open={modalOpen} onClose={handleToggleCefrModal} noPadding={true}>
          <div className="bg-yellow-50 pt-4 pb-8 px-4">
            <div className="flex justify-end mb-4">
              <Button variant="flat" onClick={handleToggleCefrModal}>
                <Icon icon={IconName.Close} />
              </Button>
            </div>
            <Image
              className="mx-auto w-full max-w-[150px] mb-4"
              src="/assets/images/report-app/report-detail-what-is-cefr-header.png"
              alt="report_detail_what_is_cefr_header"
            />
            <Heading is="h1" variant="h2" className="text-center mb-2">
              {t('report_detail_what_is_cefr_section_title')}
            </Heading>
            <Text variant="sm" className="mt-5 text-center mb-4">
              {t('report_detail_what_is_cefr_section_description')}
            </Text>
            <CEFRLevelDescriptionList />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default WhatIsCEFRSection;
