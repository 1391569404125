import { Heading, Text } from '../base';
import { BaseProps } from '../types';

export enum CardHeaderVariant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

interface CardHeaderProps extends BaseProps {
  subtitle: string;
  title: string;
  description?: string;
  variant?: CardHeaderVariant;
}

const Primary = ({ title, subtitle, description }: CardHeaderProps) => {
  return (
    <>
      <Heading variant="h4" className="text-primary-500 text-center mb-5">
        {subtitle}
      </Heading>
      <Heading variant="h1" className="text-center mb-2 font-semibold">
        {title}
      </Heading>
      <Text className="text-center text-gray-400">{description}</Text>
    </>
  );
};

const Secondary = ({ title, subtitle }: CardHeaderProps) => {
  return (
    <>
      <Text variant="md" className="text-center text-grey-500 font-medium mb-1">
        {subtitle}
      </Text>
      <Heading variant="h1" className="text-primary-500 text-center font-semibold">
        {title}
      </Heading>
    </>
  );
};

const CardHeader = ({ className, title, subtitle, description, variant }: CardHeaderProps) => {
  return (
    <div className={className}>
      {variant === CardHeaderVariant.SECONDARY ? (
        <Secondary title={title} subtitle={subtitle} />
      ) : (
        <Primary title={title} subtitle={subtitle} description={description} />
      )}
    </div>
  );
};

export default CardHeader;
