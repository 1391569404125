import style from './SummaryReportPrint.module.css';

const BlankReportPrint = ({ id }: { id: string }) => {
  return (
    <div className={style['report-print-wrapper']}>
      <div className={style['report-print-container']}>
        <h2 className={style['report-print-id']}>No Report for ID: {id}</h2>
      </div>
    </div>
  );
};

export default BlankReportPrint;
