import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../../components/base';
import Icon, { IconName } from '../../../../../../components/base/Icon';
import { convertSeconds } from '../../../../../../utils/dateTime';

const PreparationTimer = ({ timeLeft }: { timeLeft: number }) => {
  const { t } = useTranslation('assessment');
  return (
    <div className="flex flex-row sm:flex-col gap-1 items-center sm:items-start">
      <Text variant={'sm'} className="text-grey-500 whitespace-nowrap">
        {t('assessment_app_recording_panel_preparation_timeleft')}
      </Text>
      <div className="flex gap-1 sm:gap-2 items-center">
        <Icon icon={IconName.Timer} className="text-primary" size={'22px'} />
        <Text variant="lg" className="font-bold text-primary">
          {convertSeconds(timeLeft, 'mm:ss')}
        </Text>
      </div>
    </div>
  );
};

export default PreparationTimer;
