import { CEFRLevel } from '../types/cefr';

type EdSpeckScoreRange = {
  min: number;
  max: number;
  rangeText: string;
};

export const ED_SPEAK_MAX_SCORE = 230;

export const cefrLevelToEdSpeakScoreRange: Record<CEFRLevel, EdSpeckScoreRange> = {
  [CEFRLevel.PreA1]: { min: 80, max: 100, rangeText: '80-100' },
  [CEFRLevel.A1]: { min: 101, max: 120, rangeText: '101-120' },
  [CEFRLevel.A2]: { min: 121, max: 140, rangeText: '121-140' },
  [CEFRLevel.B1]: { min: 141, max: 160, rangeText: '141-160' },
  [CEFRLevel.B2]: { min: 161, max: 180, rangeText: '161-180' },
  [CEFRLevel.C1]: { min: 181, max: 200, rangeText: '181-200' },
  [CEFRLevel.C2]: { min: 201, max: 230, rangeText: '201-230' },
};
