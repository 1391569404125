import {
  SpeakingTestQuestion,
  SpeakingTestQuestionType,
} from '../../../model/speakingTestQuestion';
import i18n from '../../../i18n';
export interface InstructionData {
  title: string;
  subtitle: string;
  imageUrl: string;
  instruction1: string;
  instruction2?: string;
  timeLimit?: number;
  preparationTimeLimit?: number;
}

export const getInstructionData = (question: SpeakingTestQuestion) => {
  const { type, preparationTimeLimit, timeLimit } = question;
  switch (type) {
    case SpeakingTestQuestionType.DESCRIBE:
      return {
        title: i18n.t('assessment:assessment_speaking_test_question_type_describe_title'),
        subtitle: i18n.t('assessment:assessment_speaking_test_question_type_describe_subtitle'),
        imageUrl: '/assets/images/speaking-test/describe.png',
        timeLimit: timeLimit,
        preparationTimeLimit: preparationTimeLimit,
        instruction1: i18n.t(
          'assessment:assessment_speaking_test_question_type_describe_instruction1'
        ),
      };
    case SpeakingTestQuestionType.OPEN_ENDED:
      return {
        title: i18n.t('assessment:assessment_speaking_test_question_type_open_ended_title'),
        subtitle: i18n.t('assessment:assessment_speaking_test_question_type_open_ended_subtitle'),
        imageUrl: '/assets/images/speaking-test/open-ended.png',
        timeLimit: timeLimit,
        preparationTimeLimit: preparationTimeLimit,
        instruction1: i18n.t(
          'assessment:assessment_speaking_test_question_type_open_ended_instruction1'
        ),
        instruction2: i18n.t(
          'assessment:assessment_speaking_test_question_type_open_ended_instruction2'
        ),
      };
    case SpeakingTestQuestionType.READING:
      return {
        title: i18n.t('assessment:assessment_speaking_test_question_type_reading_title'),
        subtitle: i18n.t('assessment:assessment_speaking_test_question_type_reading_subtitle'),
        imageUrl: '/assets/images/speaking-test/text-reading.png',
        timeLimit: timeLimit,
        preparationTimeLimit: preparationTimeLimit,
        instruction1: i18n.t(
          'assessment:assessment_speaking_test_question_type_reading_instruction1'
         
        ),
      };
    case SpeakingTestQuestionType.ELABORATE:
      return {
        title: i18n.t('assessment:assessment_speaking_test_question_type_elaborate_title'),
        subtitle: i18n.t('assessment:assessment_speaking_test_question_type_elaborate_subtitle'),
        imageUrl: '/assets/images/speaking-test/open-ended.png',
        timeLimit: timeLimit,
        preparationTimeLimit: preparationTimeLimit,
        instruction1: i18n.t(
          'assessment:assessment_speaking_test_question_type_elaborate_instruction1',
          { timeLimit: timeLimit?.toString() }
        ),
        instruction2: i18n.t(
          'assessment:assessment_speaking_test_question_type_elaborate_instruction2',
          { preparationTimeLimit: preparationTimeLimit?.toString() }
        ),
      };
    case SpeakingTestQuestionType.DISCUSSION:
      return {
        title: i18n.t('assessment:assessment_speaking_test_question_type_discussion_title'),
        subtitle: i18n.t('assessment:assessment_speaking_test_question_type_discussion_subtitle'),
        imageUrl: '/assets/images/speaking-test/open-ended.png',
        timeLimit: timeLimit,
        preparationTimeLimit: preparationTimeLimit,
        instruction1: i18n.t(
          'assessment:assessment_speaking_test_question_type_discussion_instruction1',
          { timeLimit: timeLimit?.toString() }
        ),
        instruction2: i18n.t(
          'assessment:assessment_speaking_test_question_type_discussion_instruction2',
          { preparationTimeLimit: preparationTimeLimit?.toString() }
        ),
      };
  }
};
