import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider as BaseRouterProvider,
  Route,
  Outlet,
} from 'react-router-dom';
import Error from '../routes/Error';
import routes, {
  assessmentAppRoutes,
  internalAdminRoute,
  reportAppRoutes,
  RouteData,
  testRoutes,
} from './routes';
import {
  AssessmentRegistrationAppRoot,
  AssessmentSessionAppRoot,
} from '../routes/assessmentApp/AssessmentAppRoot';
import { InternalAdminAppRoot } from '../routes/internalAdminApp/internalAdminAppRoot';
import {
  ASSESSMENT_APP_BASE,
  ASSESSMENT_APP_TEST_SESSION_BASE,
  NEW_REPORT_APP_BASE,
  REPORT_APP_BASE,
  INTERNAL_APP_BASE,
} from './constants';

const toRouteElement = (route: RouteData): React.ReactElement => {
  const RouteComponent = route.component;
  const ErrorComponent = route.errorComponent || Error;
  const LayoutComponent = route.layoutComponent || undefined;
  return (
    <Route
      key={route.key || route.path}
      path={route.path}
      element={
        LayoutComponent ? (
          <LayoutComponent {...route.layoutComponentProps}>
            <RouteComponent />
          </LayoutComponent>
        ) : (
          <RouteComponent />
        )
      }
      errorElement={<ErrorComponent />}
    >
      {route.children?.map(toRouteElement)}
    </Route>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* Report App - TODO: Remove! */}
      <Route path={REPORT_APP_BASE}>{Object.values(routes).map(toRouteElement)}</Route>

      {/* New Report UI */}
      <Route path={NEW_REPORT_APP_BASE}>
        {Object.values(reportAppRoutes)
          .filter((r) => r.component) // TODO: remove this filter when all routes are implemented
          .map(toRouteElement)}
      </Route>

      {/* Test Routes - TODO: Move to Internal Admin app */}
      {Object.values(testRoutes).map(toRouteElement)}

      {/* Assessment App */}
      <Route path={ASSESSMENT_APP_BASE} element={<AssessmentRegistrationAppRoot />}>
        {toRouteElement(assessmentAppRoutes.AssessmentAppHome)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppRegistration)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppSpeakingTestFinishPage)}
      </Route>
      <Route path={ASSESSMENT_APP_TEST_SESSION_BASE} element={<AssessmentSessionAppRoot />}>
        {toRouteElement(assessmentAppRoutes.AssessmentAppInstruction)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppPreTestInstruction)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppPreTestQuiz)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppSpeakingTestTestMicInstruction)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppSpeakingTestTestMic)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppSpeakingTestInstruction)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppSpeakingTestQuiz)}
        {toRouteElement(assessmentAppRoutes.AssessmentAppSpeakingTestQuizInstruction)}

        {/* Test Feature */}
        {toRouteElement(assessmentAppRoutes.AssessmentAppTestFeature)}
      </Route>

      {/* Internal Admin App */}
      <Route path={INTERNAL_APP_BASE} element={<InternalAdminAppRoot />}>
        {toRouteElement(internalAdminRoute.Home)}
        {toRouteElement(internalAdminRoute.CodeGenerate)}
        {toRouteElement(internalAdminRoute.AccessCodeStatus)}
        {toRouteElement(internalAdminRoute.AccessCodeList)}
        {toRouteElement(internalAdminRoute.Testers)}
        {toRouteElement(internalAdminRoute.Schools)}
        {toRouteElement(internalAdminRoute.AllAccessCodeStatus)}
        {toRouteElement(internalAdminRoute.SpeakingQuestions)}
        {toRouteElement(internalAdminRoute.SpeakingTests)}
      </Route>
      <Route path={INTERNAL_APP_BASE} element={<Outlet />}>
        {toRouteElement(internalAdminRoute.InternalAdminSchoolReports)}
        {toRouteElement(internalAdminRoute.InternalAdminSchoolAllReportsPrint)}
      </Route>
    </Route>
  )
);

const RouterProvider = () => {
  return <BaseRouterProvider router={router} />;
};

export { RouterProvider, routes, assessmentAppRoutes, reportAppRoutes, internalAdminRoute };
