import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Heading, Image, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import StarsRating from '../../../../components/StarsRating';
import { reportAppRoutes } from '../../../../router';
import { ResultCategoryName } from '../../../../types/report';
import { toClassNames } from '../../../../utils';
import EdSpeakStarsRatingModal from './EdSpeakStarsRatingModal';

const categoryToCardDataMap = {
  fluency: {
    title: 'Fluency',
    labelTranslationKey: 'report_detail_category_fluency_label',
    imageSrc: '/assets/images/report-app/report-detail-category-fluency.png',
    class: {
      background: 'bg-primary-400',
    },
  },
  vocabulary: {
    title: 'Vocabulary',
    labelTranslationKey: 'report_detail_category_vocabulary_label',
    imageSrc: '/assets/images/report-app/report-detail-category-vocabulary.png',
    class: {
      background: 'bg-secondary-500',
    },
  },
  pronunciation: {
    title: 'Pronunciation',
    labelTranslationKey: 'report_detail_category_pronunciation_label',
    imageSrc: '/assets/images/report-app/report-detail-category-pronunciation.png',
    class: {
      background: 'bg-green-500',
    },
  },
} as const;

type AcceptedCategoryName = keyof typeof categoryToCardDataMap;
interface Props {
  category: Extract<ResultCategoryName, AcceptedCategoryName>;
  score: number;
  fullScore: number;
  starLabel: string;
}
const CategoryRatingCard: FC<Props> = ({ category, starLabel, score, fullScore }) => {
  const cardData = categoryToCardDataMap[category];

  const { t } = useTranslation('report');
  const params = useParams();
  const [isStartStarsRatingModalOpen, setIsStartStarsRatingModalOpen] = useState(false);

  return (
    <div
      className={toClassNames([
        'rounded-2xl py-6 px-4 flex flex-col items-center flex-1 gap-y-4',
        cardData.class.background,
      ])}
    >
      <div className="text-center">
        <Heading variant="h3" className="text-white">
          {cardData.title}
        </Heading>
        <Text className="text-white">{t(cardData.labelTranslationKey)}</Text>
      </div>
      <Image
        src={cardData.imageSrc}
        alt={`${category} Image`}
        className="hidden sm:block w-[98px]"
      />
      <div>
        <div className="flex justify-center items-center">
          <Text className="text-white font-semibold">{starLabel}</Text>
          <Button variant="flat" onClick={() => setIsStartStarsRatingModalOpen(true)}>
            <Icon icon={IconName.QuestionCircleFill} size="16px" className="text-white ml-1" />
          </Button>
          <EdSpeakStarsRatingModal
            open={isStartStarsRatingModalOpen}
            onClose={() => setIsStartStarsRatingModalOpen(false)}
          />
        </div>
        <StarsRating
          starsCount={5}
          score={score}
          fullScore={fullScore}
          className="mt-1 mx-auto"
          starClassName="w-4"
        />
      </div>
      <Button
        variant="secondary"
        isRouterLink
        to={reportAppRoutes.ReportDetailCategory.getRoute({
          ...params,
          category,
        })}
        dataAnalyticId="report-detail-category-rating-card"
        dataAnalyticDetail={category}
      >
        {t('report_detail_category_section_more_detail_button_label')}
      </Button>
    </div>
  );
};

export default CategoryRatingCard;
