import { SpeakingTestQuestion } from '../../model/speakingTestQuestion';
import { TestStatus } from '../../model/test';
import { assessmentAppRoutes } from '../../router';

export const getRouteByTestStatus = (status: TestStatus, isMicrophoneTested: boolean): string => {
  switch (status) {
    // TODO: Could have status for not_started and route PreTest to AssessmentPreTestInstruction
    case TestStatus.PreTest:
      return assessmentAppRoutes.AssessmentAppInstruction.getRoute();
    case TestStatus.SpeakingTest:
      if (isMicrophoneTested) {
        return assessmentAppRoutes.AssessmentAppSpeakingTestQuiz.getRoute();
      } else {
        return assessmentAppRoutes.AssessmentAppSpeakingTestTestMicInstruction.getRoute();
      }

    default:
      return assessmentAppRoutes.AssessmentAppHome.getRoute();
  }
};

export const getLatestSpeakingTestQuestionIndex = (questions: SpeakingTestQuestion[]) => {
  return questions.findIndex((q) => !q.completed);
};
