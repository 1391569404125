import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../components/base';
import { toClassNames } from '../../../../../utils';
import style from './RecordingPanel.module.css';
import PreparationTimer from './components/PreparationTimer';
import RecordingTimer from './components/RecordingTimer';
import FinishButton from './components/FinishButton';
import VoiceGraph, { getVoiceGraphVariant } from './components/VoiceGraph';
import {
  RecordingPanelFeatures,
  recordingPanelStateFeatures,
  RecordingPanelState,
} from './helpers';

type FeatureOverride = Partial<Record<RecordingPanelFeatures, boolean>>;

interface RecordingPanelProps {
  className?: string;
  onClickStartRecording: () => void;
  onClickFinishRecording: () => void;
  disabledFinishRecordingButton: boolean;
  preparationTimeLeft: number;
  recordingTimeLeft: number;
  totalRecordingTime: number;
  state: RecordingPanelState;
  minRecordingTime: number;
  overrideFeatures?: Partial<Record<RecordingPanelState, FeatureOverride>>;
}

const RecordingPanel = ({
  className,
  onClickStartRecording,
  onClickFinishRecording,
  disabledFinishRecordingButton,
  preparationTimeLeft,
  recordingTimeLeft,
  totalRecordingTime,
  state,
  minRecordingTime,
  overrideFeatures = {},
}: RecordingPanelProps) => {
  const { t } = useTranslation('assessment');

  const features: Record<RecordingPanelFeatures, boolean> = React.useMemo(() => {
    return {
      ...recordingPanelStateFeatures[state],
      ...overrideFeatures[state],
    };
  }, [state, overrideFeatures]);

  return (
    <div className={className}>
      <div
        className={toClassNames([
          'px-[24px] sm:px-[64px] py-2 sm:py-4',
          style['recording-panel-container'],
          state === RecordingPanelState.Recording ? style['recording-state'] : '',
        ])}
      >
        {features[RecordingPanelFeatures.PreparationTimer] ? (
          <div className={style['preparation-timer-container']}>
            <PreparationTimer timeLeft={preparationTimeLeft} />
          </div>
        ) : null}

        {features[RecordingPanelFeatures.RecordingTimer] ? (
          <div className={toClassNames([style['recording-timer-container']])}>
            <RecordingTimer timeLeft={recordingTimeLeft} totalTime={totalRecordingTime} />
          </div>
        ) : null}

        {features[RecordingPanelFeatures.RecordingButton] ? (
          <div
            className={toClassNames([
              'flex flex-col items-center',
              style['recording-button-container'],
            ])}
            onClick={onClickStartRecording}
          >
            <div className={style['recording-button']} />
            <Text variant="sm" className="text-grey-500 mt-1">
              {t('assessment_app_recording_panel_record_now')}
            </Text>
          </div>
        ) : null}

        {features[RecordingPanelFeatures.VoiceGraph] ? (
          <div className={toClassNames([style['voice-graph-container']])}>
            <VoiceGraph variant={getVoiceGraphVariant(state)} />
          </div>
        ) : null}

        {features[RecordingPanelFeatures.FinishButton] ? (
          <div className={style['finish-button-container']}>
            <FinishButton
              onClick={onClickFinishRecording}
              disabled={disabledFinishRecordingButton}
              minRecordingTime={minRecordingTime}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RecordingPanel;
