import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import StarsRating from '../../../../components/StarsRating';
import {
  PronunciationResult,
  PronunciationSubCategoryName,
  ResultCategoryName,
} from '../../../../types/report';
import { PronunciationStatistic } from '../../../../types/reportStatistic';
import { clamp, scoreToStarCount, starsCountToText, toClassNames } from '../../../../utils';
import IncorrectPhoneticCard from '../../ReportDetail/components/IncorrectPhonetics';
import CategoryEmptyState from './CategoryEmptyState';
import EdSpeakSuggestions from './EdSpeakSuggestions';
import HowWeMeasureMetrics from './HowWeMeasureMetrics';
import PronunciationComparisonChart from './PronunciationComparisonChart';

interface Props {
  pronunciationResult: PronunciationResult;
  pronunciationStatistic: PronunciationStatistic;
}
const PronunciationTabPanel: FC<Props> = ({ pronunciationResult, pronunciationStatistic }) => {
  const { t } = useTranslation('report');

  const incorrectPhonetics = pronunciationResult[PronunciationSubCategoryName.IncorrectPhonetics];
  const starsCount = scoreToStarCount(pronunciationResult.score);

  const testerScore = clamp(pronunciationResult.score, 0, 5);
  const testerPercentage = parseFloat((testerScore * 20).toFixed(1));

  const edsyMean = pronunciationStatistic.mean;
  const edsyMeanPercentage = parseFloat((edsyMean * 20).toFixed(1));

  if (pronunciationResult.score <= 0) {
    return <CategoryEmptyState category={ResultCategoryName.Pronunciation} />;
  }

  return (
    <div className="mt-6 flex flex-col gap-y-11">
      <StarsPart stars={starsCount} className="hidden sm:flex" />
      <div className="flex flex-col gap-y-7 px-4 py-7 rounded-3xl bg-white sm:bg-primary-50">
        <StarsPart stars={starsCount} className="sm:hidden" />
        <div className={toClassNames(['mt-6 sm:mt-0'])}>
          <div className="text-center">
            <Text variant="2xl" className="font-semibold mb-1">
              {t('report_detail_category_pronunciation_comparison_chart_section_title')}
            </Text>
            <Text variant="xl" className="text-gradient-gd-1 font-semibold">
              {t('report_detail_category_pronunciation_close_to_native_percentage', {
                value: testerPercentage / 100,
              })}
            </Text>
          </div>
          <PronunciationComparisonChart
            testerPercentage={testerPercentage}
            edsyMeanPercentage={edsyMeanPercentage}
            className="mt-5 sm:mt-9"
          />
        </div>
        <HowWeMeasureMetrics category={ResultCategoryName.Pronunciation} className="sm:hidden" />
        <EdSpeakSuggestions
          category={ResultCategoryName.Pronunciation}
          starsCount={starsCount}
          className="sm:hidden"
        />
      </div>

      <HowWeMeasureMetrics category={ResultCategoryName.Pronunciation} className="hidden sm:flex" />
      <EdSpeakSuggestions
        category={ResultCategoryName.Pronunciation}
        starsCount={starsCount}
        className="hidden sm:flex"
      />

      {/* Incorrect Phonetics */}
      <div className={toClassNames(['rounded-2xl', 'sm:bg-primary-50 sm:px-7 sm:py-10'])}>
        <div className="flex flex-col gap-y-6">
          <div>
            <Heading is="h2" variant="h1" className="text-center">
              {t('report_detail_category_pronunciation_title_first_line')}
              <br />
              <span className="text-gradient-gd-1">
                {t('report_detail_category_pronunciation_title_second_line')}
              </span>
            </Heading>
            <Text className="text-grey-600 text-center mt-1">
              {t('report_detail_category_pronunciation_subtitle')}
            </Text>
          </div>
          {incorrectPhonetics.map((incorrectPhonetic, index) => {
            return <IncorrectPhoneticCard key={index} incorrectPhonetic={incorrectPhonetic} />;
          })}
        </div>
      </div>
    </div>
  );
};

const StarsPart: FC<{ stars: number; className?: string }> = ({ stars, className }) => {
  const { t } = useTranslation('report');
  const starsCountString = starsCountToText(stars);

  return (
    <div className={toClassNames([className, 'flex flex-col items-center gap-y-3'])}>
      <Heading variant="h1" className="sm:hidden text-gradient-gd-1">
        Pronunciation
      </Heading>
      <Text className="text-grey-600">
        {t('report_detail_category_pronunciation_tab_subtitle')}
      </Text>
      <StarsRating score={stars} fullScore={5} className="my-2" />
      <Text variant="2xl" className="text-gradient-gd-1 font-bold text-center mb-2">
        {t(`stars_rating_label.${starsCountString}`, { ns: 'common' })}
      </Text>
      <Text className="text-grey-600 max-w-xs text-center">
        {t(`report_detail_category_pronunciation_stars_rating_description.${starsCountString}`)}
      </Text>
    </div>
  );
};

export default PronunciationTabPanel;
