import { createEnumTypeGuard } from '../utils/validators';

export enum CEFRLevel {
  PreA1 = 'PRE A1',
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

const cefrLevelTypeGuard = createEnumTypeGuard(CEFRLevel);

export const parseCefrLevelFromDb = (level: string) => {
  const convertedLevel = level === 'PRE-A1' ? CEFRLevel.PreA1 : level;
  if (cefrLevelTypeGuard(convertedLevel)) {
    return convertedLevel;
  } else {
    throw new Error(`Invalid cefrLevel ${level}`);
  }
};
