import { useState } from 'react';
import { Heading, Modal, Button, Input, Dropdown } from '../../../../components/base';
import {
  CreateSpeakingTestQuestionParams,
  CreateSpeakingTestQuestionPromptParams,
} from '../../../../api/test';
import { SpeakingTestQuestionType } from '../../../../model/speakingTestQuestion';
import { CEFRLevel } from '../../../../types/cefr';
import { createEnumTypeGuard } from '../../../../utils/validators';
import { CEFR_OPTIONS, QUESTION_TYPE } from './formData';
import { Checkbox } from '../../../../components/base/Form';
import CreateSpeakingTestQuestionPromptModal from './CreateSpeakingTestQuestionPromptModal';

interface CreateModalProps {
  open: boolean;
  onPrimaryCtaClick: (values: CreateSpeakingTestQuestionParams) => void;
  onSecondaryCtaClick: () => void;
}

const CreateSpeakingTestQuestionModal = ({
  open,
  onSecondaryCtaClick,
  onPrimaryCtaClick,
}: CreateModalProps) => {
  const [type, setType] = useState<SpeakingTestQuestionType>();
  const [timeLimit, setTimeLimit] = useState<number>(0);
  const [preparationTimeLimit, setPreparationTimeLimit] = useState<number>(0);
  const [cefrLevel, setCefrLevel] = useState<CEFRLevel>();
  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const [prompts, setPrompts] = useState<CreateSpeakingTestQuestionPromptParams[]>([]);
  const [promptModalOpen, setPromptModalOpen] = useState(false);

  const handleChangeCefrLevel = (e: React.FormEvent<HTMLSelectElement>) => {
    const isValidCefrLevel = createEnumTypeGuard(CEFRLevel);
    const value = e.currentTarget.value;
    if (isValidCefrLevel(value)) {
      setCefrLevel(value);
    }
  };

  const handleChangeType = (e: React.FormEvent<HTMLSelectElement>) => {
    const isValidType = createEnumTypeGuard(SpeakingTestQuestionType);
    const value = e.currentTarget.value;
    if (isValidType(value)) {
      setType(value);
    }
  };

  const handleChangeTimelimit = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (parseInt(value)) {
      setTimeLimit(parseInt(value));
    }
  };

  const handleChangePrepTimelimit = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (parseInt(value)) {
      setPreparationTimeLimit(parseInt(value));
    }
  };

  const handleChangeIsPrivate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivate(e.target.checked);
  };

  const handleCreateQuestion = () => {
    if (!!type && !!cefrLevel) {
      const values: CreateSpeakingTestQuestionParams = {
        type,
        timeLimit,
        preparationTimeLimit,
        prompts,
        cefrLevel,
        isPrivate,
      };
      onPrimaryCtaClick(values);
    } else {
      alert('Missing type or cefrLevel');
    }
  };

  const handleDeletePrompt = (index: number) => {
    const newPrompts = [...prompts];
    newPrompts.splice(index, 1);
    setPrompts([...newPrompts]);
  };

  const handleClickAddPrompt = () => {
    setPromptModalOpen(true);
  };

  const handleAddPrompt = (value: CreateSpeakingTestQuestionPromptParams) => {
    const newPrompts = [...prompts];
    newPrompts.push(value);
    setPrompts(newPrompts);
    setPromptModalOpen(false);
  };

  const handleClosePromptModal = () => {
    setPromptModalOpen(false);
  };

  return (
    <Modal open={open} size="lg">
      <div className="flex flex-col justify-center items-start w-full">
        <Heading variant="h1" className="mb-8">
          Create Speaking Test with Questions
        </Heading>
        <div className="flex flex-col gap-4 mb-12 w-full">
          <div className="flex gap-4">
            <Dropdown
              value={type || ''}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChangeType(e)}
              placeholder="Selete Question Type"
              options={QUESTION_TYPE}
              className="basis-0 grow"
            />
            <Dropdown
              value={cefrLevel || ''}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => handleChangeCefrLevel(e)}
              placeholder="Selete CefrLevel"
              options={CEFR_OPTIONS}
              className="basis-0 grow"
            />
          </div>
          <div className="flex gap-4">
            <div className="basis-0 grow">
              <label>Time</label>
              <Input
                value={timeLimit.toString()}
                onChange={handleChangeTimelimit}
                placeholder="Time limit"
                type="number"
                className="w-full"
              />
            </div>
            <div className="basis-0 grow">
              <label>Prep Time</label>
              <Input
                value={preparationTimeLimit.toString()}
                onChange={handleChangePrepTimelimit}
                placeholder="Prep Time limit"
                type="number"
                className="w-full"
              />
            </div>
          </div>
          <Checkbox label="isPrivate" isChecked={isPrivate} onChange={handleChangeIsPrivate} />
        </div>
        <hr className="border" />
        <div className="w-full">
          <Heading variant="h3" className="mb-4">
            Prompts
          </Heading>
          <table className="mb-4 w-full">
            <tr>
              <th>type</th>
              <th>content</th>
              <th>Action</th>
            </tr>
            {prompts.map((p, index) => {
              return (
                <tr key={`${p.content}-${p.type}-${index}`} className="align-top p-2">
                  <td className="text-center">{p.type}</td>
                  <td>{p.content}</td>
                  <td className="flex justify-center">
                    <Button variant="secondary" onClick={() => handleDeletePrompt(index)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </table>

          <Button variant="secondary" onClick={handleClickAddPrompt} className="mb-16">
            Add Prompt
          </Button>
        </div>

        <div className="flex gap-4 justify-end w-full">
          <Button variant="secondary" onClick={onSecondaryCtaClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateQuestion}>
            Create
          </Button>
        </div>
      </div>
      {promptModalOpen && (
        <CreateSpeakingTestQuestionPromptModal
          open={promptModalOpen}
          onPrimaryCtaClick={handleAddPrompt}
          onSecondaryCtaClick={handleClosePromptModal}
        />
      )}
    </Modal>
  );
};

export default CreateSpeakingTestQuestionModal;
