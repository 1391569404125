import React from 'react';
import { Dropdown, Button, Input } from '../../../components/base';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import { RegistrationFormData, RegistrationFormDataKey } from '../../../model/registrationData';
import { Tester, TesterType } from '../../../model/tester';
import {
  requiredFieldForStudent,
  requiredFieldForTeacher,
  requiredFieldForBusinessEmplyee,
  PREFIX_OPTIONS,
  GRADE_OPTIONS,
} from './constants';

import { useTranslation } from 'react-i18next';
interface RegistrationFormProps {
  tester?: Tester;
  testerType: TesterType;
  onSubmitForm: (formData: RegistrationFormData) => void;
}

const validateFormData = (formData: RegistrationFormData, type: TesterType) => {
  if (type === TesterType.Teacher) {
    return requiredFieldForTeacher.every((fieldName) => !!formData[fieldName]);
  } else if (type === TesterType.Student) {
    return requiredFieldForStudent.every((fieldName) => !!formData[fieldName]);
  } else {
    // Business Employee
    return requiredFieldForBusinessEmplyee.every((fieldName) => !!formData[fieldName]);
  }
};

/**
 * Get default formData or convert tester to formData
 */
const getTesterToFormData = (type: TesterType, tester?: Tester): RegistrationFormData => {
  const formData = {
    prefix: tester?.prefix || '',
    firstName: tester?.firstName || '',
    lastName: tester?.lastName || '',
    grade: tester?.grade || '',
    room: tester?.room || '',
    phoneNo: tester?.phoneNo || '',
    email: tester?.email || '',
    type: tester?.type || type,
    organization: tester?.organization || '',
  };
  return formData;
};

/**
 * Render Dropdown when adding data, and render Input when rendering data
 */
interface DropdownOrInputProps {
  value: string;
  formatValue?: (value: string) => string;
  onChange: (e: React.FormEvent<any>) => void;
  className?: string;
  placeholder: string;
  options: DropdownOption[];
  readonly: boolean;
}
const DropdownOrInput = ({
  value,
  onChange,
  formatValue,
  className,
  placeholder,
  options,
  readonly,
}: DropdownOrInputProps) => {
  if (readonly) {
    return (
      <Input
        value={formatValue ? formatValue(value) : value}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        readonly={true}
      />
    );
  }

  return (
    <Dropdown
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      options={options}
    />
  );
};

const RegistrationForm = ({ tester, testerType, onSubmitForm }: RegistrationFormProps) => {
  const { t } = useTranslation('assessment');
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [formData, setFormData] = React.useState<RegistrationFormData>(
    getTesterToFormData(testerType)
  );

  /**
   * Initialize form from tester data
   */
  React.useEffect(() => {
    const initialFormData = getTesterToFormData(testerType, tester);
    setFormData(initialFormData);
  }, [tester, testerType]);

  /**
   * Validate formData
   */
  React.useEffect(() => {
    const isValidFormData = validateFormData(formData, testerType);
    setCanSubmit(isValidFormData);
  }, [formData, testerType]);

  const handleSubmitForm = () => {
    /**
     * This check is just to be safe. The button should already be disabled when canSubmit === false
     */
    if (canSubmit) {
      onSubmitForm(formData);
    }
  };

  const handleInputChange = (value: string, fieldName: RegistrationFormDataKey) => {
    const newFormData = { ...formData, [fieldName]: value };
    setFormData(newFormData);
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      <DropdownOrInput
        value={formData.prefix}
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          handleInputChange(e.currentTarget.value, RegistrationFormDataKey.prefix)
        }
        placeholder={t('assessment_registration_form_placeholder_prefix')}
        className="w-full shrink-0"
        options={PREFIX_OPTIONS}
        readonly={!!tester}
      />
      <Input
        value={formData.firstName}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleInputChange(e.currentTarget.value, RegistrationFormDataKey.firstName)
        }
        placeholder={t('assessment_registration_form_placeholder_firstname')}
        className="w-full"
        readonly={!!tester}
      />
      <Input
        value={formData.lastName}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleInputChange(e.currentTarget.value, RegistrationFormDataKey.lastName)
        }
        placeholder={t('assessment_registration_form_placeholder_lastname')}
        className="w-full"
        readonly={!!tester}
      />

      {testerType === TesterType.Student ? (
        <>
          <DropdownOrInput
            value={formData.grade}
            onChange={(e: React.FormEvent<HTMLSelectElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.grade)
            }
            placeholder={t('assessment_registration_form_placeholder_grade')}
            options={GRADE_OPTIONS}
            formatValue={(val) => {
              const option = GRADE_OPTIONS.find(({ value }) => value === val);
              return option ? option.text : val;
            }}
            readonly={!!tester}
          />
          <Input
            value={formData.room}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.room)
            }
            placeholder={t('assessment_registration_form_placeholder_room')}
            // type="number"
            readonly={!!tester}
          />
        </>
      ) : testerType === TesterType.Teacher ? (
        <>
          <Input
            value={formData.email}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.email)
            }
            placeholder={t('assessment_registration_form_placeholder_email')}
            readonly={!!tester}
          />
          <Input
            value={formData.phoneNo}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.phoneNo)
            }
            placeholder={t('assessment_registration_form_placeholder_phone')}
            type="number"
            readonly={!!tester}
          />
        </>
      ) : (
        <>
          <Input
            value={formData.organization}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.organization)
            }
            placeholder={t('assessment_registration_form_placeholder_organization')}
            readonly={!!tester}
          />
          <Input
            value={formData.email}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.email)
            }
            placeholder={t('assessment_registration_form_placeholder_email')}
            readonly={!!tester}
          />
          <Input
            value={formData.phoneNo}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              handleInputChange(e.currentTarget.value, RegistrationFormDataKey.phoneNo)
            }
            placeholder={t('assessment_registration_form_placeholder_phone')}
            type="number"
            readonly={!!tester}
          />
        </>
      )}

      <Button
        variant="primary"
        size="large"
        onClick={handleSubmitForm}
        disabled={!canSubmit}
        className="mx-auto mt-8 w-full mb-4"
      >
        {tester
          ? t('assessment_registration_form_submit_button_confirm')
          : t('assessment_registration_form_submit_button_register')}
      </Button>
    </div>
  );
};

export default RegistrationForm;
