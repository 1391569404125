import { Report } from '../../types/report';
import { School } from '../../model/school';
import BlankReportPrint from './BlankReportPrint';
import Template1 from './Template1';
import Template2 from './Template2';
import Template3 from './Template3';
import Template4 from './Template4';

export enum SummaryReportTemplate {
  template1 = 'template1',
  template2 = 'template2',
  template3 = 'template3',
  template4 = 'template4',
}

export const getTemplateType = (templateStr: string): SummaryReportTemplate => {
  const template = Object.values(SummaryReportTemplate).find((t) => t === templateStr);
  return template || SummaryReportTemplate.template1;
};

const SummaryReportPrint = ({
  template,
  report,
  school,
  reportId,
}: {
  template: SummaryReportTemplate;
  report?: Report;
  school: School;
  reportId: string;
}) => {
  if (!report) return <BlankReportPrint id={reportId} />;
  switch (template) {
    case SummaryReportTemplate.template4:
      return <Template4 report={report} school={school} />;
    case SummaryReportTemplate.template3:
      return <Template3 report={report} school={school} />;
    case SummaryReportTemplate.template2:
      return <Template2 report={report} school={school} />;
    case SummaryReportTemplate.template1:
    default:
      return <Template1 report={report} school={school} />;
  }
};

export default SummaryReportPrint;
