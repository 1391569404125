import { getCategoryData } from '../../../data/report';
import { ResultCategory, ResultCategoryName } from '../../../types/report';
import style from '../SummaryReportPrint.module.css';

type TableCellProps = {
  result: number;
  descriptionList: string[];
  categoryType: ResultCategoryName;
};
const TableCell = ({ categoryType, result, descriptionList }: TableCellProps) => {
  const categoryData = getCategoryData(categoryType);
  return (
    <div className={style['box-item']}>
      <div className={style['box-item-title']}>
        {categoryData.title.th}
        <br />({categoryData.title.en})
      </div>
      <div className={style['box-item-result']}>
        {result === -1 ? 'N/A' : result.toFixed(1)}
        <span style={{ fontSize: '7pt', fontWeight: 400 }}> / 5.0</span>
      </div>
      <ul className={style['box-item-list']}>
        {descriptionList.map((desc) => (
          <li key={desc}>{desc}</li>
        ))}
      </ul>
    </div>
  );
};

const TotalTableCell = ({ result }: { result: number }) => {
  return (
    <div className={style['box-item']}>
      <div className={style['box-item-title']}>
        คะแนนรวม
        <br />
        (Total)
      </div>
      <div className={style['box-item-result']}>
        {result === -1 ? 'N/A' : result.toFixed(1)}
        <span style={{ fontSize: '7pt', fontWeight: 400 }}> / 5.0</span>
      </div>
    </div>
  );
};

const TableScore = ({
  category,
  totalScore,
  showTotalScore,
}: {
  category: ResultCategory;
  totalScore?: number;
  showTotalScore?: boolean;
}) => {
  return (
    <div className={style['report-print-detail-score']}>
      <h2 className={style['title']}>ผลการประเมินและตัวอย่างตัวชี้วัดหลัก</h2>
      <div className={style['score-table']}>
        {showTotalScore && totalScore && <TotalTableCell result={totalScore} />}
        <TableCell
          categoryType={ResultCategoryName.Fluency}
          result={category.fluency.score}
          descriptionList={['ความเร็วและต่อเนื่องในการพูด', 'ความยาวของรูปประโยค']}
        />
        <TableCell
          categoryType={ResultCategoryName.Vocabulary}
          result={category.vocabulary.score}
          descriptionList={['สัดส่วนความถูกต้องและระดับ CEFR ของคำศัพท์']}
        />
        <TableCell
          categoryType={ResultCategoryName.Pronunciation}
          result={category.pronunciation.score}
          descriptionList={['สัดส่วนการออกเสียงแต่ละคำที่ถูกต้องตามหลัก Phonemes']}
        />

        {/* <TableCell
          categoryType={ResultCategoryName.Intonation}
          result={category.intonation.score}
          descriptionList={['สัดส่วนการใช้น้ำเสียงระดับสูง-ต่ำ ที่เหมาะสมตามรูปประโยค']}
        /> */}
      </div>
    </div>
  );
};

export default TableScore;
