import { useTranslation } from 'react-i18next';
import { ContentComponentProps } from '../helpers';
import ContentHeader from './ContentHeader';

import {
  SpeakingTestQuestionPromptContent,
  SpeakingTestQuestionPromptContentType,
} from '../../../../model/speakingTestQuestion';
import { Heading, Image, Text } from '../../../../components/base';

interface PromptProps {
  type: SpeakingTestQuestionPromptContentType;
  content: string;
}

const Prompt = ({ type, content }: PromptProps) => {
  return type === SpeakingTestQuestionPromptContentType.IMAGE ? (
    <Image src={content} alt={'question image'} className="mx-auto mt-4" />
  ) : (
    <Text variant="xl" className="text-primary-500 !leading-7">
      {content}
    </Text>
  );
};

const Question = ({ prompts }: { prompts: SpeakingTestQuestionPromptContent[] }) => {
  const { t } = useTranslation('assessment');
  return (
    <div className="mx-auto text-center border-2 border-primary-300 rounded-2xl py-7 px-10 max-w-[660px] flex flex-col items-center mb-6">
      <Heading variant="h2" className="mb-5">
        {t('assessment_speaking_test_quiz_open_ended_title_question')}
      </Heading>
      {prompts.map((prompt, index) => (
        <div className="mb-6" key={index}>
          <Prompt content={prompt.content} type={prompt.type} key={`${index}-type`} />
        </div>
      ))}
    </div>
  );
};

const OpenEndedContent = ({
  questionNumber,
  totalQuestion,
  preparationTimeLimit,
  question,
}: ContentComponentProps) => {
  const { t } = useTranslation('assessment');
  return (
    <>
      <ContentHeader
        questionType={question.type}
        questionNumber={questionNumber}
        totalQuestion={totalQuestion}
        timeLimit={question.timeLimit || 60}
        title={t('assessment_speaking_test_quiz_open_ended_content_title')}
        description={t('assessment_speaking_test_quiz_open_ended_content_description', {
          preparationTimeLimit: preparationTimeLimit.toString(),
        })}
      />
      <Question prompts={question.prompt} />
    </>
  );
};

export default OpenEndedContent;
