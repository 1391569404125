import React from 'react';
import { LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as assessmentApiClient from '../../../api/assessment';
import SchoolsTable from './components/SchoolsTable';
import { School } from '../../../model/school';

const Schools = () => {
  const [loading, setLoading] = React.useState(false);
  const [schools, setSchools] = React.useState<School[]>([]);

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const schools = await assessmentApiClient.getAllSchools();
        setSchools(schools);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="School list"
        description=""
        className="mb-6"
      />

      <div className="w-full text-center">
        <SchoolsTable schools={schools} />
      </div>
      <LoadingModal open={loading} />
    </div>
  );
};

export default Schools;
