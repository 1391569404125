import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Heading, Image, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import { cefrLevelToCambridgeLevel } from '../../../../data/cambirdge';
import { isValidCEFRLevel } from '../../../../data/cefr';
import { ED_SPEAK_MAX_SCORE } from '../../../../data/edSpeak';
import { useDefaultDateFormat } from '../../../../hooks/useDefaultDateFormat';
import { reportAppRoutes } from '../../../../router';
import { Report } from '../../../../types/report';
import { SchoolReportStatistic } from '../../../../types/reportStatistic';
import { formatEdSpeakScore } from '../../../../utils';
import style from '../ReportDetailCategory.module.css';

interface Props {
  report: Report;
  schoolStatistic: SchoolReportStatistic;
}
export const SummarySection: FC<Props> = ({ report }) => {
  const { t } = useTranslation('report');
  const dateFormat = useDefaultDateFormat();

  const params = useParams();
  return (
    <div>
      {/* Desktop */}
      <div className="hidden sm:block">
        <Button
          variant="secondary"
          isRouterLink
          to={reportAppRoutes.ReportDetail.getRoute(params)}
          className="!font-normal !w-fit !flex items-center gap-x-2"
          dataAnalyticId="report-detail-category-go-to-home-top"
        >
          <Icon icon={IconName.ArrowLeftShort} size="20px" />
          <Text className="capitalize">{t('back_to_home')}</Text>
        </Button>
        <div className="mt-4 mb-11 flex flex-col gap-y-2">
          <Heading variant="h1">
            <span>{t('report_detail_category_assessment_result')} </span>
            <span className="text-primary-500">
              {t('report_detail_category_group_by_category')}
            </span>
          </Heading>
          <Text is="span" className="text-grey-500">
            {dayjs(report.date).format(dateFormat)}
            <span> &bull; </span>
            <span>{report.school.name}</span>
            <span> &bull; </span>
            <span className="capitalize">{report.tester.gradeAndRoomText}</span>
          </Text>
          <Heading variant="h2">{report.tester.fullName}</Heading>
        </div>
        <OverallScoreBanner report={report} />
      </div>
      {/* Mobile */}
      <div className="block sm:hidden rounded-3xl bg-white p-4">
        <div className="text-center">
          <Text className="font-bold">{report.tester.fullName}</Text>
          <Text className="text-gray-500">{report.tester.gradeAndRoomText}</Text>
        </div>
        <hr className="my-3" />
        <div className="flex justify-between items-center">
          <Text className="text-grey-400 font-semibold">{t('edspeak_score')}</Text>
          <div>
            <Text is="span" variant="2xl" className="text-gradient-gd-1 font-semibold mr-1">
              {formatEdSpeakScore(report.result.overall.score)}
            </Text>
            <Text is="span" variant="sm" className="text-grey-700">
              {t('from', { ns: 'common' })} {ED_SPEAK_MAX_SCORE}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

interface OverallScoreBannerProps {
  report: Report;
}
const OverallScoreBanner: FC<OverallScoreBannerProps> = ({ report }) => {
  const { t } = useTranslation('report');

  const cefrLevel = report.result.englishStandard.cefr;
  // This should always be a value
  const cambridgeLevel = isValidCEFRLevel(cefrLevel) ? cefrLevelToCambridgeLevel(cefrLevel) : '';

  return (
    <div className={style['overall-score-banner']}>
      <Image
        src="/assets/images/report-app/report-detail-category-summary-decoration.png"
        alt="decoration image"
      />
      <div className="flex flex-1 items-center gap-x-4">
        <Text variant="xl" className="font-bold text-white !leading-none whitespace-pre-line">
          {t('edspeak_score_twoline')}
        </Text>
        <Text variant="6xl" className="font-bold text-yellow-300 flex-shrink">
          {formatEdSpeakScore(report.result.overall.score)}
          <Text is="span" className="text-white/70 font-normal">
            /{ED_SPEAK_MAX_SCORE}
          </Text>
        </Text>
        <Divider />
        <div className="flex-1 text-center text-white">
          <Text variant="sm" className="text-white/70">
            CEFR
          </Text>
          <Text variant="lg" className="font-bold">
            {report.result.englishStandard.cefr}
          </Text>
        </div>
        <Divider />
        <div className="flex-1 text-center text-white">
          <Text variant="sm" className="text-white/70">
            Cambridge
          </Text>
          <Text variant="lg" className="font-bold capitalize">
            {cambridgeLevel}
          </Text>
        </div>
      </div>
    </div>
  );
};

const Divider: FC = () => <div className="self-stretch w-[1px] bg-white/30" />;
