import { BaseProps } from '../types';
import { toClassNames } from '../../utils';
import Image from './Image';
import Text from './Text';

interface LoadingProps extends BaseProps {
  text?: string;
}

/**
 * Currently, there are not many case that we will customize the size of this Loading component,
 * therefore, if we want to customize it, just pass className with "!" to make it important.
 *
 * In the future, when we have needs to use multiple sizes of Loading,
 * we can refactor and have the size as enum instead.
 */
const Loading = ({ className, text }: LoadingProps) => {
  return (
    <div className="flex flex-col items-center">
      <Image
        src="/assets/images/loading.svg"
        alt="loading"
        className={toClassNames(['w-[90px]', className])}
      />
      {text && (
        <Text variant="sm" className="text-center">
          {text}
        </Text>
      )}
    </div>
  );
};

export default Loading;
