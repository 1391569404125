import { ResultCategoryName } from '../types/report';

type CategoryTitleObject = {
  th: string;
  en: string;
};

// TODO: Refactor this to not contain custom color code.
enum CategoryColor {
  Yellow = '#ffdf97', // yellow-500
  Green = '#bce6e7', // green-200
}
type CategoryData = {
  color: CategoryColor;
  title: CategoryTitleObject;
};
const categoryDataMapping: { [key: string]: CategoryData } = {
  [ResultCategoryName.Pronunciation]: {
    color: CategoryColor.Green,
    title: {
      th: 'การออกเสียงคำศัพท์',
      en: 'Pronunciation',
    },
  },
  [ResultCategoryName.Fluency]: {
    color: CategoryColor.Green,
    title: {
      th: 'ความคล่องในการพูด',
      en: 'Fluency',
    },
  },
  [ResultCategoryName.Intonation]: {
    color: CategoryColor.Yellow,
    title: {
      th: 'การออกเสียงสูงต่ำ',
      en: 'Intonation',
    },
  },
  [ResultCategoryName.Vocabulary]: {
    color: CategoryColor.Yellow,
    title: {
      th: 'ระดับคำศัพท์ที่ใช้',
      en: 'Vocabulary',
    },
  },
  [ResultCategoryName.Confidence]: {
    color: CategoryColor.Green,
    title: {
      th: 'ความกล้าและมั่นใจ',
      en: 'Confidence',
    },
  },
  [ResultCategoryName.Vocab_Accuracy]: {
    color: CategoryColor.Yellow,
    title: {
      th: 'คำศัพท์-ความถูกต้อง',
      en: 'Vocabulary & Accuracy',
    },
  },
};

export const getCategoryTitle = (type: ResultCategoryName) => {
  return categoryDataMapping[type].title;
};

export const getCategoryData = (type: ResultCategoryName) => {
  return categoryDataMapping[type];
};
