import type { FC } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Container, Heading, Text } from '../../../components/base';
import type { School } from '../../../model/school';
import { CEFRLevel } from '../../../types/cefr';
import type { Report } from '../../../types/report';
import CategorySection from './components/CategorySection';
import CEFRScoreSection from './components/CEFRScoreSection';
import CompareWithGlobalStandardSection from './components/CompareWithGlobalStandardSection';
import ContactUsSection from './components/ContactUsSection';
import ReportSummarySection from './components/ReportSummarySection';
import UpSkillSection from './components/UpSkillSection';
import WhatIsCEFRSection from './components/WhatIsCEFRSection';
import ConsultationSection from './components/ConsultationSection';
import { useDefaultDateFormat } from '../../../hooks/useDefaultDateFormat';

interface Props {
  report: Report;
  school: School;
}
export const ReportDetailView: FC<Props> = ({ report, school }) => {
  const { t, i18n } = useTranslation('report');

  const dateFormat = useDefaultDateFormat();

  return (
    <Container className="px-4 py-6 sm:py-12 flex-1">
      <div className="flex flex-col gap-y-11">
        <div>
          <Heading
            variant="h1"
            className="text-[48px] leading-none tracking-[-.03em] whitespace-pre-line text-center"
          >
            {t('report_detail_title_twoline')}
          </Heading>
          <Text className="text-center text-grey-500 mt-2 capitalize">
            {t('report_detail_test_date_label')} {dayjs(report.date).format(dateFormat)}
            {'・'} {school.name}
          </Text>
        </div>
        <ReportSummarySection report={report} tester={report.tester} />
        <CEFRScoreSection report={report} />
        <WhatIsCEFRSection />
        {/* TODO: Parsed CEFRLevel in Report model */}
        <UpSkillSection currentLevel={report.result.englishStandard.cefr as CEFRLevel} />
        <CategorySection report={report} />
        <CompareWithGlobalStandardSection report={report} />
        {/* <PartnerShipSection school={report.school} /> */}
        <ConsultationSection location="Main report page" />
        <ContactUsSection />
      </div>
    </Container>
  );
};
