import { Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import { toClassNames } from '../../../../utils';
import style from '../SpeakingTestQuizInstruction.module.css';

const IconTimer = ({ title, time, icon }: { title: string; time: string; icon: IconName }) => {
  return (
    <div className="md:flex md:flex-row md:px-8 px-4">
      <div className="w-full md:w-fit">
        <Icon icon={icon} size='28px' className={toClassNames([style['rounded-icon']])} />
      </div>
      <Text className="font-bold text-left pt-2">
        <span className="text-gray-600 text-sm font-medium">{title}</span>
        <br />
        {time}
      </Text>
    </div>
  );
};

export default IconTimer;
