import { Container, Image } from '../../../components/base';

const Banner = ({
  mobileImageUrl,
  tabletImageUrl,
  href,
  alt,
  dataAnalyticId,
}: {
  mobileImageUrl: string;
  tabletImageUrl: string;
  href: string;
  alt: string;
  dataAnalyticId?: String;
}) => {
  return (
    <Container
      className={'!px-0 mb-0 mt-0 lg:mb-6 lg:mt-4 rounded-none lg:rounded-4xl overflow-hidden'}
    >
      <a href={href} target="_blank" rel="noreferrer noopener" data-analytic-id={dataAnalyticId}>
        <Image src={mobileImageUrl} alt={alt} className="block md:hidden" />
        <Image src={tabletImageUrl} alt={alt} className="hidden md:block" />
      </a>
    </Container>
  );
};

export default Banner;
