import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import { Button, Image, LoadingModal, Text } from '../../../components/base';
import ErrorModal from '../../../components/ErrorModal';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { assessmentAppRoutes } from '../../../router';
import { useTranslation } from 'react-i18next';
import HeaderText from './components/HeaderText';
import { IconName } from '../../../components/base/Icon';

const PreTestInstruction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const { startPreTestQuiz } = useAssessmentContext();

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleNext = async () => {
    setLoading(true);
    try {
      await startPreTestQuiz();
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const handleClickErrorModal = () => {
    setError(false);
    navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
  };

  return (
    <LayoutCard className="my-10 flex flex-col items-center ">
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle={t('assessment_pre_test_instruction_cardheader_subtitle')}
        title={t('assessment_pre_test_instruction_cardheader_title')}
        description={t('assessment_pre_test_instruction_cardheader_description')}
      />
      <div className="flex flex-row gap-6 mt-4">
        <HeaderText
          icon={IconName.SquareText}
          title={t('assessment_pre_test_instruction_total_question_title')}
          subtitle={t('assessment_pre_test_instruction_total_question_subtitle')}
        />
        <HeaderText
          icon={IconName.Timer}
          title={t('assessment_pre_test_instruction_test_duration_title')}
          subtitle={t('assessment_pre_test_instruction_test_duration_subtitle')}
        />
      </div>
      <Image
        src="/assets/images/assessment-app-pre-test-instruction.png"
        alt="pre-test instruction"
        className="w-[280px] mb-6"
      />
      <ul className="mb-8 list-disc text-gray-600">
        <li>
          <Text className="">{t('assessment_pre_test_instruction_bottom_text_1')}</Text>
        </li>
        <li>
          <Text className="">{t('assessment_pre_test_instruction_bottom_text_2')}</Text>
        </li>
      </ul>

      <Button variant="primary" onClick={handleNext}>
        {t('assessment_pre_test_instruction_next_button')}
      </Button>

      <LoadingModal open={loading} />

      <ErrorModal
        open={error}
        title={t('assessment_pre_test_instruction_error_modal_title')}
        description={t('assessment_pre_test_instruction_error_modal_description')}
        buttonText={t('assessment_pre_test_instruction_error_modal_button_text')}
        onButtonClick={handleClickErrorModal}
      />
    </LayoutCard>
  );
};

export default PreTestInstruction;
