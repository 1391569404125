import React from 'react';
import { Button, Text } from '../../../../components/base';
import CardHeader, {
  CardHeaderVariant,
} from '../../../../components/assessmentAppLayouts/CardHeader';
import { getTestResultData, TestResultKey } from '../utils';
import { toClassNames } from '../../../../utils';
import Image from '../../../../components/base/Image';
import { useTranslation } from 'react-i18next';

const TestSpeakingVoice = ({
  result,
  onTestMicAgain,
  onStartSpeakingTest,
  audioBlob,
}: {
  result: TestResultKey;
  onTestMicAgain: () => void;
  onStartSpeakingTest: () => void;
  audioBlob?: Blob;
}) => {
  const { t } = useTranslation('assessment');
  const isBadResult = result === TestResultKey.Bad;
  const audioObjRef = React.useRef<HTMLAudioElement>();
  React.useEffect(() => {
    if (audioBlob && !audioObjRef.current) {
      const audioUrl = URL.createObjectURL(audioBlob);
      const audioObj = new Audio(audioUrl);
      audioObj.controls = true;
      audioObjRef.current = audioObj;
      document.getElementById('audio-container')?.appendChild(audioObj);
    }
  }, [audioBlob]);
  return (
    <>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle={t('assessment_test_mic_finish_testing_cardheader_subtitle')}
        title={getTestResultData(result).title}
        description={getTestResultData(result).headerDescription}
        className="mb-6"
      />
      {result === TestResultKey.Bad ? (
        <div
          className={toClassNames([
            'rounded-xl px-6 py-4 mb-6 w-fit max-w-[480px]',
            getTestResultData(result).bgColorClass,
          ])}
        >
          <Text
            className={toClassNames([
              'font-normal text-center',
              getTestResultData(result).textColorClass,
            ])}
          >
            {getTestResultData(result).description}
          </Text>
        </div>
      ) : (
        <Image
          src="/assets/images/session/test-microphone-result-success.png"
          alt="success"
          className="mb-6 w-[100px] h-[100px]"
        />
      )}
      <Text variant="sm" className="font-semibold mb-6">
        {t('assessment_test_mic_finish_testing_recheck_confirmation_question', {
          context: isBadResult ? 'bad' : '',
        })}
      </Text>
      <div id="audio-container"></div>
      <div className="flex gap-4 mb-12 mt-6">
        <Button variant="outlined" onClick={onTestMicAgain} className="w-[220px]">
          {t('assessment_test_mic_finish_testing_button_on_test_mic_again', {
            context: isBadResult ? 'bad' : '',
          })}
        </Button>
        <Button variant="primary" onClick={onStartSpeakingTest} className="w-[220px]">
          {t('assessment_test_mic_finish_testing_button_on_start_speaking_test', {
            context: isBadResult ? 'bad' : '',
          })}
        </Button>
      </div>
      <Text variant="sm" className="text-grey-400 w-[600px] max-w-full">
        <span className="font-semibold">
          {t('assessment_test_mic_finish_testing_recording_text_span')}
        </span>
        {t('assessment_test_mic_finish_testing_recording_text')}
      </Text>
    </>
  );
};

export default TestSpeakingVoice;
