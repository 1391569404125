import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Text } from '../../../../components/base';
import { cefrLevels } from '../../../../data/cefr';
import { cefrLevelToEdSpeakScoreRange } from '../../../../data/edSpeak';
import { toClassNames } from '../../../../utils';
import style from '../ReportDetail.module.css';

import { CEFRLevel } from '../../../../types/cefr';

interface Props {
  level: CEFRLevel;
  showCEFRScale?: boolean;
  showMOEScale?: boolean;
  showIELTSScale?: boolean;
}
const CEFRLevelChart: FC<Props> = ({
  level,
  showCEFRScale = true,
  showMOEScale = true,
  showIELTSScale = false,
}) => {
  const { t } = useTranslation('report');
  return (
    <div className={toClassNames([style['cefr-score-chart-container']])}>
      {cefrLevels.map((cefrLevel) => {
        const isActive = cefrLevel === level;
        return (
          <div key={cefrLevel} className="[&:first-child]:col-start-2">
            {isActive && (
              <div className="text-center mb-2">
                <Image src="/assets/images/star-icon.svg" alt="star icon" className="inline" />
                <Text className="font-semibold px-2 capitalize">
                  {t('your_level', { ns: 'common' })}
                </Text>
              </div>
            )}
            <ChartLevelBar level={cefrLevel} isActive={isActive} />
          </div>
        );
      })}
      {showCEFRScale && <CEFRScale />}
      {showMOEScale && <MOEScale />}
      {showIELTSScale && <IELTSScale />}
    </div>
  );
};

const ScaleDescription: FC<{ text: string; className?: string }> = ({ text, className }) => {
  return (
    <Text variant="xs" className={toClassNames([className, 'py-1', 'text-center', 'rounded-lg'])}>
      {text}
    </Text>
  );
};

const cefrLevelToBarClasses: Record<CEFRLevel, any> = {
  [CEFRLevel.PreA1]: {
    height: 'h-[106px]',
    background: 'bg-primary-50',
    active: {
      border: 'border-primary-700',
      stripeColorLight: 'from-primary-500',
      stripeColorDark: 'to-primary', // primary-600
    },
    text: {
      cefrLevel: 'text-black whitespace-nowrap',
      edSpeakLabel: 'text-primary-800',
      edSpeakRange: 'text-primary-700',
    },
  },
  [CEFRLevel.A1]: {
    height: 'h-[124px]',
    background: 'bg-primary-50',
    active: {
      border: 'border-primary-700',
      stripeColorLight: 'from-primary-500',
      stripeColorDark: 'to-primary', // primary-600
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-primary-800',
      edSpeakRange: 'text-primary-700',
    },
  },
  [CEFRLevel.A2]: {
    height: 'h-[140px]',
    background: 'bg-primary-50',
    active: {
      border: 'border-primary-700',
      stripeColorLight: 'from-primary-500',
      stripeColorDark: 'to-primary', // primary-600
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-primary-800',
      edSpeakRange: 'text-primary-700',
    },
  },
  [CEFRLevel.B1]: {
    height: 'h-[160px]',
    background: 'bg-green-50',
    active: {
      border: 'border-green-700',
      stripeColorLight: 'from-green-500',
      stripeColorDark: 'to-green-600',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-green-800',
      edSpeakRange: 'text-green-700',
    },
  },
  [CEFRLevel.B2]: {
    height: 'h-[180px]',
    background: 'bg-green-50',
    active: {
      border: 'border-green-700',
      stripeColorLight: 'from-green-500',
      stripeColorDark: 'to-green-600',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-green-800',
      edSpeakRange: 'text-green-700',
    },
  },
  [CEFRLevel.C1]: {
    height: 'h-[200px]',
    background: 'bg-yellow-50',
    active: {
      border: 'border-yellow-700',
      stripeColorLight: 'from-yellow-500',
      stripeColorDark: 'to-yellow-600',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-yellow-800',
      edSpeakRange: 'text-yellow-700',
    },
  },
  [CEFRLevel.C2]: {
    height: 'h-[212px]',
    background: 'bg-yellow-50',
    active: {
      border: 'border-yellow-700',
      stripeColorLight: 'from-yellow-500',
      stripeColorDark: 'to-yellow-600',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-yellow-800',
      edSpeakRange: 'text-yellow-700',
    },
  },
};
const ChartLevelBar: FC<{ level: CEFRLevel; isActive: boolean }> = ({ level, isActive }) => {
  const barClasses = cefrLevelToBarClasses[level];
  return (
    <div
      className={toClassNames([
        style['cefr-score-chart-bar'], // Common bar styles
        barClasses.height,
        isActive ? style['cefr-score-chart-bar-background-stripe'] : barClasses.background,
        isActive ? barClasses.active.border : 'border-transparent',
        isActive ? barClasses.active.stripeColorLight : '',
        isActive ? barClasses.active.stripeColorDark : '',
      ])}
    >
      <Text variant="md" className={toClassNames(['font-semibold', barClasses.text.cefrLevel])}>
        {level}
      </Text>
      <div>
        <Text
          variant="xs"
          className={toClassNames(['text-center', 'font-semibold', barClasses.text.edSpeakLabel])}
        >
          EdSpeak
        </Text>
        <Text
          variant="sm"
          className={toClassNames(['text-center', 'font-semibold', barClasses.text.edSpeakRange])}
        >
          {cefrLevelToEdSpeakScoreRange[level].rangeText}
        </Text>
      </div>
    </div>
  );
};

const CEFRScale: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScaleDescription text="CEFR" className="font-bold bg-gray-100" />
      <ScaleDescription
        text={t('cefr_level_label.PRE A1', { ns: 'cefr' })}
        className="bg-primary-100 col-span-3"
      />
      <ScaleDescription
        text={t('cefr_level_label.B1', { ns: 'cefr' })}
        className="bg-green-300 col-span-2"
      />
      <ScaleDescription
        text={t('cefr_level_label.C1', { ns: 'cefr' })}
        className="bg-yellow-400 col-span-2"
      />
    </>
  );
};

const MOEScale = () => {
  const { t } = useTranslation('report');

  return (
    <>
      <ScaleDescription
        text={t('report_detail_cefr_score_section_moe_scale_label')}
        className="font-bold bg-gray-100"
      />
      <ScaleDescription
        text={t('report_detail_cefr_score_section_moe_scale_cefr_a1_label')}
        className="bg-primary-100 col-start-3"
      />
      <ScaleDescription
        text={t('report_detail_cefr_score_section_moe_scale_cefr_a2_label')}
        className="bg-primary-100 "
      />
      <ScaleDescription
        text={t('report_detail_cefr_score_section_moe_scale_cefr_b1_label')}
        className="bg-green-300 "
      />
      <ScaleDescription
        text={t('report_detail_cefr_score_section_moe_scale_above_standard_label')}
        className="bg-gray-100 text-gray-600 col-span-3"
      />
    </>
  );
};

const IELTSScale = () => {
  // const { i18n } = useTranslation();

  return (
    <>
      <ScaleDescription text={'IELTS'} className="font-bold bg-gray-100" />
      <div className="grid grid-cols-3 gap-x-1 col-start-5">
        <ScaleDescription text={'4'} className="bg-green-300" />
        <ScaleDescription text={'4.5'} className="bg-green-300 " />
        <ScaleDescription text={'5'} className="bg-green-300 " />
      </div>
      <div className="grid grid-cols-3 gap-x-1">
        <ScaleDescription text={'5.5'} className="bg-green-300" />
        <ScaleDescription text={'6'} className="bg-green-300 " />
        <ScaleDescription text={'6.5'} className="bg-green-300 " />
      </div>
      <div className="grid grid-cols-3 gap-x-1">
        <ScaleDescription text={'7'} className="bg-yellow-400" />
        <ScaleDescription text={'7.5'} className="bg-yellow-400 " />
        <ScaleDescription text={'8'} className="bg-yellow-400 " />
      </div>
      <ScaleDescription text={'9'} className="bg-yellow-400" />
    </>
  );
};

export default CEFRLevelChart;
