import { useState } from 'react';
import { Heading, Modal, Button, Input } from '../../../../components/base';

interface CreateModalProps {
  open: boolean;
  onPrimaryCtaClick: (value: string) => void;
  onSecondaryCtaClick: () => void;
}

const CreateModal = ({ open, onSecondaryCtaClick, onPrimaryCtaClick }: CreateModalProps) => {
  const [name, setName] = useState('');
  const onClick = () => {
    onPrimaryCtaClick(name);
  };

  const handleChangeSchoolName = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Heading variant="h1" className="mb-2">
          Create School
        </Heading>
        <Input value={name} onChange={handleChangeSchoolName} placeholder="" className="mb-6" />
        <div className="flex gap-6 mb-4">
          <Button variant="secondary" onClick={onSecondaryCtaClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClick}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
