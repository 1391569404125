import { Container, Heading, Text } from '../../../components/base';
import { CEFRLevel } from '../../../types/cefr';
import { formatEdSpeakScore } from '../../../utils';
import ScoreCircleProgress from './ScoreCircleProgress';

const getOverallScoreDescription = (cefr: CEFRLevel, score: number) => {
  const formattedScore = formatEdSpeakScore(score);
  switch (cefr) {
    case CEFRLevel.PreA1:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ PRE A1 (ระดับเริ่มต้นเรียนรู้ภาษา): คุณเพิ่งเริ่มต้นเรียนรู้ภาษาอังกฤษ สามารถใช้และเข้าใจคำพูดภาษาอังกฤษในระดับเบื้องต้น โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    case CEFRLevel.A1:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ A1 (ระดับพื้นฐาน): คุณสามารถใช้และเข้าใจประโยคง่าย ๆ ในชีวิตประจำวัน โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    case CEFRLevel.A2:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ A2 (ระดับปานกลาง): คุณสามารถใช้และเข้าใจประโยคในชีวิตประจำวันได้ในระดับกลาง โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    case CEFRLevel.B1:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ B1 (ระดับดี): คุณสามารถพูด และจับใจความสำคัญของการสนทนาทั่วๆ ไปได้ จัดการกับสถานการณ์ต่างๆที่เกิดขึ้น ระหว่างการเดินทางในประเทศที่ใช้ภาษาอังกฤษได้ โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    case CEFRLevel.B2:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ B2 (ระดับดีมาก): คุณสามารถใช้ภาษาในระดับดี พูดได้แทบทุกเรื่องอย่างถูกต้อง คล่องแคล่วขึ้น รวมทั้งทำความเข้าใจกับการสนทนาที่ซับซ้อนขึ้นได้ โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    case CEFRLevel.C1:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ C1 (ระดับดีเยี่ยม): คุณสามารถพูดแสดงความรู้สึกได้อย่างเป็นธรรมชาติ โดยไม่ต้องหยุดคิดหาคำศัพท์ และพูดข้อความที่ซับซ้อนได้ชัดเจนถูกต้องตามโครงสร้างไวยากรณ์ โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    case CEFRLevel.C2:
      return `ระดับทักษะ Speaking ตามมาตรฐาน CEFR ของคุณคือ C2 (ระดับใกล้เคียงเจ้าของภาษา): คุณสามารถใช้ภาษาได้อย่างดีเยี่ยมใกล้เคียงเจ้าของภาษาเลย! โดยคะแนน EdSpeak ของคุณคือ ${formattedScore} เต็ม 230`;
    default:
      return '';
  }
};

const LevelPill = ({ text }: { text: string }) => {
  return <div className="py-1 px-6 bg-primary text-white font-bold rounded-full">{text}</div>;
};

type OverallResultSectionProps = {
  testerId: string;
  letterGrade: string;
  score: number;
  analysisText: string;
  cefrResult: string;
  cambridgeResult: string;
};
const OverallResultSection = ({
  testerId,
  score,
  analysisText,
  cefrResult,
  cambridgeResult,
}: OverallResultSectionProps) => {
  return (
    <Container className="border-b py-10">
      <Heading variant="h2" className="text-primary text-center mb-8">
        ผลการวัดระดับของคุณคือ
      </Heading>
      {/* <Heading variant="h6" className="text-grey-500 text-center font-normal ">
        รหัสประจำตัว: {testerId}
      </Heading> */}

      <Heading variant="h5" className="mb-4 text-center">
        ระดับเทียบตามมาตรฐานสากล
      </Heading>

      <div className="flex w-full justify-center mb-16">
        <div className="flex flex-col items-center gap-4 py-2 px-5 border-r">
          <div className="grow flex items-center">
            <img src="/assets/images/cefr-logo.png" alt="cefr assessment" className="w-[96px]" />
          </div>
          <LevelPill text={cefrResult} />
        </div>
        <div className="flex flex-col items-center gap-4 py-2 px-5">
          <div className="grow flex items-center">
            <img
              src="/assets/images/cambridge-assessment-logo.png"
              alt="cambridge assessment"
              className="w-[150px]"
            />
          </div>
          <LevelPill text={cambridgeResult} />
        </div>
      </div>

      <Heading variant="h5" className="mb-4 text-center">
        คะแนนตามมาตรฐาน EdSpeak
      </Heading>

      <div className="flex justify-center w-full mb-6">
        <ScoreCircleProgress score={score} fullScore={230} />
      </div>

      <Text className="mb-8 text-center">
        {getOverallScoreDescription(cefrResult as CEFRLevel, score)}
      </Text>
    </Container>
  );
};

export default OverallResultSection;
