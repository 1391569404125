import React from 'react';
import { useParams } from 'react-router-dom';
import * as assessmentApiClient from '../../../api/assessment';
import { Loading } from '../../../components/base';
import { SpeakingTestQuestion } from '../../../model/speakingTestQuestion';
import SpeakingTestQuiz from './SpeakingTestQuiz';

const SpeakingTestQuizContainerForTest = () => {
  const params = useParams();
  const [question, setQuestion] = React.useState<SpeakingTestQuestion>();

  React.useEffect(() => {
    const initializeData = async (speakingTestId: string) => {
      try {
        const question = await assessmentApiClient.getSingleSpeakingTestQuestion(speakingTestId);
        setQuestion(question)
      } catch (error) {
        console.error(error);
      }
    };
    if (params.speakingTestId) {
      initializeData(params.speakingTestId);
    }
  }, [params.speakingTestId]);

  if (!question) {
    return <Loading />
  }

  return (
    <SpeakingTestQuiz
      question={question}
      totalQuestion={1}
      questionNumber={1}
    />
  );
};

export default SpeakingTestQuizContainerForTest;
