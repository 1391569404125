import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import SummaryReportPrint, { getTemplateType } from '../../../components/SummaryReportPrint';
import { getReportsBySchoolId } from '../../../api/report';
import { getSchoolById } from '../../../api/assessment';
import { School } from '../../../model/school';
import { Report } from '../../../types/report';
import { useTranslation } from 'react-i18next';

const SchoolReportsPrint = () => {
  const { i18n } = useTranslation();
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const templateTypeStr = query.get('templateType') || '';
  const [loading, setLoading] = React.useState(true);
  const [reports, setReport] = React.useState<Report[]>();
  const [school, setSchool] = React.useState<School>();

  React.useEffect(() => {
    const getReportData = async () => {
      if (params.schoolId) {
        try {
          const [report, schoolData]: [report: Report[] | undefined, schoolData: School] =
            await Promise.all([
              getReportsBySchoolId(params.schoolId),
              getSchoolById(params.schoolId),
            ]);
          setReport(report);
          setSchool(schoolData);
          setLoading(false);
        } catch (error: any) {
          alert(error.message);
        }
      }
    };
    getReportData();
  }, [params.schoolCode]);

  if (loading || !reports || !school) return <h2>loading...</h2>;
  return (
    <>
      {reports.map((report) => {
        return (
          <SummaryReportPrint
            template={getTemplateType(templateTypeStr)}
            report={report}
            reportId={report.testId}
            key={report.testId}
            school={school}
          />
        );
      })}
    </>
  );
};

export default SchoolReportsPrint;
