import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../../components/base';
import { cefrLevels } from '../../../../data/cefr';
import { cefrLevelToEdSpeakScoreRange } from '../../../../data/edSpeak';
import { toClassNames } from '../../../../utils';
import style from './CEFRLevelChart.module.css';

import type { CEFRLevel } from '../../../../types/cefr';

interface Props {
  level: CEFRLevel;
  showCEFRScale?: boolean;
  showMOEScale?: boolean;
  showIELTSScale?: boolean;
}
const CEFRLevelChart: FC<Props> = ({
  level,
  showCEFRScale = true,
  showMOEScale = true,
  showIELTSScale = false,
}) => {
  return (
    <div className={toClassNames([style['cefr-score-chart-container']])}>
      {cefrLevels.map((cefrLevel) => {
        const isActive = cefrLevel === level;
        return (
          <div key={cefrLevel} className="[&:first-child]:col-start-2">
            {isActive && (
              <div className="text-center mb-1">
                <Image
                  src="/assets/images/star-icon.svg"
                  alt="star icon"
                  className="inline w-[5mm]"
                />
              </div>
            )}
            <ChartLevelBar level={cefrLevel} isActive={isActive} />
          </div>
        );
      })}
      {showCEFRScale && <CEFRScale />}
      {showMOEScale && <MOEScale />}
      {showIELTSScale && <IELTSScale />}
    </div>
  );
};

const ScaleDescription: FC<{ text: string; className?: string }> = ({ text, className }) => {
  return (
    <div
      className={toClassNames([className, style['scale-description'], 'text-center rounded-lg'])}
    >
      {text}
    </div>
  );
};

const cefrLevelToBarClasses: Record<CEFRLevel, any> = {
  'PRE A1': {
    height: 'h-[24mm]',
    background: 'bg-primary-50',
    active: {
      border: 'border-primary',
      stripeColorLight: 'from-primary-300',
      stripeColorDark: 'to-primary-400',
    },
    text: {
      cefrLevel: 'text-black whitespace-nowrap',
      edSpeakLabel: 'text-primary-800',
      edSpeakRange: 'text-primary-700',
    },
  },
  A1: {
    height: 'h-[27mm]',
    background: 'bg-primary-50',
    active: {
      border: 'border-primary',
      stripeColorLight: 'from-primary-300',
      stripeColorDark: 'to-primary-400',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-primary-800',
      edSpeakRange: 'text-primary-700',
    },
  },
  A2: {
    height: 'h-[30mm]',
    background: 'bg-primary-50',
    active: {
      border: 'border-primary',
      stripeColorLight: 'from-primary-300',
      stripeColorDark: 'to-primary-400',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-primary-800',
      edSpeakRange: 'text-primary-700',
    },
  },
  B1: {
    height: 'h-[33mm]',
    background: 'bg-green-50',
    active: {
      border: 'border-green-700',
      stripeColorLight: 'from-green-300',
      stripeColorDark: 'to-green-400',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-green-800',
      edSpeakRange: 'text-green-700',
    },
  },
  B2: {
    height: 'h-[36mm]',
    background: 'bg-green-50',
    active: {
      border: 'border-green-700',
      stripeColorLight: 'from-green-300',
      stripeColorDark: 'to-green-400',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-green-800',
      edSpeakRange: 'text-green-700',
    },
  },
  C1: {
    height: 'h-[39mm]',
    background: 'bg-yellow-50',
    active: {
      border: 'border-yellow-700',
      stripeColorLight: 'from-yellow-400',
      stripeColorDark: 'to-yellow-500',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-yellow-900',
      edSpeakRange: 'text-yellow-800',
    },
  },
  C2: {
    height: 'h-[42mm]',
    background: 'bg-yellow-50',
    active: {
      border: 'border-yellow-700',
      stripeColorLight: 'from-yellow-400',
      stripeColorDark: 'to-yellow-500',
    },
    text: {
      cefrLevel: 'text-black',
      edSpeakLabel: 'text-yellow-900',
      edSpeakRange: 'text-yellow-800',
    },
  },
};
const ChartLevelBar: FC<{ level: CEFRLevel; isActive: boolean }> = ({ level, isActive }) => {
  const barClasses = cefrLevelToBarClasses[level];
  return (
    <div
      className={toClassNames([
        style['cefr-score-chart-bar'], // Common bar styles
        barClasses.height,
        isActive ? style['cefr-score-chart-bar-background-stripe'] : barClasses.background,
        isActive ? barClasses.active.border : 'border-transparent',
        isActive ? barClasses.active.stripeColorLight : '',
        isActive ? barClasses.active.stripeColorDark : '',
      ])}
    >
      <div className={toClassNames([style['chart-level-bar-level'], barClasses.text.cefrLevel])}>
        {level}
      </div>
      <div>
        <div
          className={toClassNames([
            style['chart-level-bar-label-title'],
            'text-center font-bold',
            barClasses.text.edSpeakLabel,
          ])}
        >
          EdSpeak
        </div>
        <div
          className={toClassNames([
            style['chart-level-bar-label-range'],
            'text-center font-bold',
            barClasses.text.edSpeakRange,
          ])}
        >
          {cefrLevelToEdSpeakScoreRange[level].rangeText}
        </div>
      </div>
    </div>
  );
};

const CEFRScale: FC = () => {
  const { t } = useTranslation('summaryReport');

  return (
    <>
      <ScaleDescription text="CEFR" className="font-bold bg-gray-100" />
      <ScaleDescription
        text={t('cefr_level_label.PRE A1', { ns: 'cefr' })}
        className="bg-primary-100 col-span-3"
      />
      <ScaleDescription
        text={t('cefr_level_label.B1', { ns: 'cefr' })}
        className="bg-green-300 col-span-2"
      />
      <ScaleDescription
        text={t('cefr_level_label.C1', { ns: 'cefr' })}
        className="bg-yellow-400 col-span-2"
      />
    </>
  );
};

const MOEScale = () => {
  const { t } = useTranslation('summaryReport');

  return (
    <>
      <ScaleDescription
        text={t('sum_report_template3_cefr_chart_moe_scale_label')}
        className="font-bold bg-gray-100"
      />
      <ScaleDescription
        text={t('sum_report_template3_cefr_chart_moe_scale_cefr_a1_label')}
        className="bg-primary-100 col-start-3"
      />
      <ScaleDescription
        text={t('sum_report_template3_cefr_chart_moe_scale_cefr_a2_label')}
        className="bg-primary-100 "
      />
      <ScaleDescription
        text={t('sum_report_template3_cefr_chart_moe_scale_cefr_b1_label')}
        className="bg-green-300 "
      />
      <ScaleDescription
        text={t('sum_report_template3_cefr_chart_moe_scale_above_standard_label')}
        className="bg-gray-100 text-gray-600 col-span-3"
      />
    </>
  );
};

const IELTSScale = () => {
  return (
    <>
      <ScaleDescription text={'IELTS'} className="font-bold bg-gray-100" />
      <div className="grid grid-cols-3 gap-x-1 col-start-5">
        <ScaleDescription text={'4'} className="bg-green-300" />
        <ScaleDescription text={'4.5'} className="bg-green-300 " />
        <ScaleDescription text={'5'} className="bg-green-300 " />
      </div>
      <div className="grid grid-cols-3 gap-x-1">
        <ScaleDescription text={'5.5'} className="bg-green-300" />
        <ScaleDescription text={'6'} className="bg-green-300 " />
        <ScaleDescription text={'6.5'} className="bg-green-300 " />
      </div>
      <div className="grid grid-cols-3 gap-x-1">
        <ScaleDescription text={'7'} className="bg-yellow-600" />
        <ScaleDescription text={'7.5'} className="bg-yellow-400 " />
        <ScaleDescription text={'8'} className="bg-yellow-400 " />
      </div>
      <ScaleDescription text={'9'} className="bg-yellow-400" />
    </>
  );
};

export default CEFRLevelChart;
