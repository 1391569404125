import dayjs from 'dayjs';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  SpeakingTestQuestion,
  SpeakingTestQuestionPromptContent,
} from '../../../../model/speakingTestQuestion';

interface SpeakingQuestionsTableProp {
  speakingQuestions: SpeakingTestQuestion[];
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerAlign: 'center',
    headerName: 'id',
    align: 'center',
    flex: 0.3,
  },
  {
    field: 'type',
    headerAlign: 'center',
    headerName: 'Type',
    align: 'center',
    flex: 0.2,
  },
  {
    field: 'prompt',
    headerAlign: 'center',
    headerName: 'Prompt',
    align: 'left',
    flex: 0.6,
    renderCell: (params: GridCellParams) => {
      return (
        <ul className="w-full list-disc p-4">
          {params.row.prompt.map((prompt: SpeakingTestQuestionPromptContent) => {
            return (
              <li className="text-left">
                <div className="overflow-hidden text-ellipsis">
                  {prompt.type}: {prompt.content}
                </div>
              </li>
            );
          })}
        </ul>
      );
    },
  },
  {
    field: 'timeLimit',
    headerAlign: 'center',
    headerName: 'Time Limit',
    align: 'center',
    flex: 0.1,
  },
  {
    field: 'preparationTimeLimit',
    headerAlign: 'center',
    headerName: 'Prep Time Limit',
    align: 'center',
    flex: 0.1,
  },
  {
    field: 'isPrivate',
    headerAlign: 'center',
    headerName: 'isPrivate',
    align: 'center',
    flex: 0.1,
  },
  {
    field: 'createdAt',
    headerAlign: 'center',
    headerName: 'createdAt',
    align: 'center',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      `${dayjs(params.row.createdAt).format('YYYY-MM-DD')}`,
  },
  {
    field: 'updatedAt',
    headerAlign: 'center',
    headerName: 'updatedAt',
    align: 'center',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      `${dayjs(params.row.updatedAt).format('YYYY-MM-DD')}`,
  },
];

const SpeakingQuestionsTable = ({ speakingQuestions }: SpeakingQuestionsTableProp) => {
  return (
    <div className="text-center" style={{ width: '100%', overflow: 'auto' }}>
      <div className="w-[1500px]">
        <DataGrid
          getRowId={(row) => row.id}
          rows={speakingQuestions}
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cellContent': {
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              whiteSpace: 'initial',
              paddingY: 1,
            },
          }}
        />
      </div>
    </div>
  );
};

export default SpeakingQuestionsTable;
