import { IconType } from 'react-icons';
import { FaFacebook, FaTwitter, FaStop } from 'react-icons/fa';
import { IoLinkSharp } from 'react-icons/io5';
import { IoMdBook } from 'react-icons/io';
import { MdAlarm, MdRadioButtonChecked, MdRadioButtonUnchecked, MdClose } from 'react-icons/md';
import { HiChevronDown, HiOutlineLightBulb } from 'react-icons/hi';
import { LuArrowLeftSquare } from 'react-icons/lu';
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  BsArrowLeft,
  BsArrowRight,
  BsQuestionCircleFill,
  BsChatSquareText
} from 'react-icons/bs';
import { BiAward } from 'react-icons/bi';
import { PiClockClockwise } from 'react-icons/pi';
import { BaseProps } from '../types';

export enum IconName {
  Timer = 'Timer',
  Close = 'MdClose',
  Choice = 'Choice',
  ChoiceSelect = 'ChoiceSelect',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Link = 'Link',
  BulbOn = 'BulbOn',
  ArrowLeft = 'ArrowLeft',
  ArrowLeftShort = 'ArrowLeftShort',
  ArrowRight = 'ArrowRight',
  ArrowRightShort = 'ArrowRightShort',
  ArrowLeftSquareOutlined = 'ArrowLeftSquareOutlined',
  QuestionCircleFill = 'QuestionCircleFill',
  ChevronDown = 'ChevronDown',
  StopRecording = 'StopRecording',
  Book = 'Book',
  AwardOutlined = 'AwardOutlined',
  SquareText = 'SquareText',
  Duration = 'Duration',
}

const iconComponent: { [key: string]: IconType } = {
  [IconName.Timer]: MdAlarm,
  [IconName.Close]: MdClose,
  [IconName.Choice]: MdRadioButtonUnchecked,
  [IconName.ChoiceSelect]: MdRadioButtonChecked,
  [IconName.Twitter]: FaTwitter,
  [IconName.Facebook]: FaFacebook,
  [IconName.Link]: IoLinkSharp,
  [IconName.BulbOn]: HiOutlineLightBulb,
  [IconName.ArrowLeft]: BsArrowLeft,
  [IconName.ArrowLeftShort]: BsArrowLeftShort,
  [IconName.ArrowRight]: BsArrowRight,
  [IconName.ArrowRightShort]: BsArrowRightShort,
  [IconName.ArrowLeftSquareOutlined]: LuArrowLeftSquare,
  [IconName.QuestionCircleFill]: BsQuestionCircleFill,
  [IconName.ChevronDown]: HiChevronDown,
  [IconName.StopRecording]: FaStop,
  [IconName.Book]: IoMdBook,
  [IconName.AwardOutlined]: BiAward,
  [IconName.SquareText]:BsChatSquareText,
  [IconName.Duration]: PiClockClockwise,
};

interface IconProps extends BaseProps {
  icon: IconName;
  size?: string;
}

const Icon = ({ icon, size = '24px', className }: IconProps) => {
  const Component = iconComponent[icon];
  return (
    <div className={className}>
      <Component size={size} />
    </div>
  );
};

export default Icon;
