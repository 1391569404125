import React from 'react';
import { Button, LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as testApiClient from '../../../api/test';
import { SpeakingTest } from '../../../model/speakingTest';
import SpeakingTestsTable from './components/SpeakingTestsTable';
import CreateSpeakingTestWithQuestionModal from './components/CreateSpeakingTestWithQuestionModal';
import { CreateSpeakingTestWithQuestionsParams } from '../../../api/test';

const SpeakingTests = () => {
  const [loading, setLoading] = React.useState(false);
  const [speakingTests, setSpeakingTests] = React.useState<SpeakingTest[]>([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const speakingTests = await testApiClient.getAllSpeakingTests();
        setSpeakingTests(speakingTests);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCancelSession = () => {
    setModalOpen(false);
  };

  const handleContinueSession = async (values: CreateSpeakingTestWithQuestionsParams) => {
    setLoading(true);

    const speakingTest = await testApiClient.createSpeakingTestWithQuestions(values);
    window.location.reload();

    setLoading(false);
    setModalOpen(false);
  };

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="Speaking Tests"
        description=""
        className="mb-6"
      />
      <div className="flex justify-end py-4">
        <Button variant="primary" onClick={handleOpenModal}>
          Create Speaking Test
        </Button>
      </div>

      <SpeakingTestsTable speakingTests={speakingTests} />
      <CreateSpeakingTestWithQuestionModal
        open={modalOpen}
        onSecondaryCtaClick={handleCancelSession}
        onPrimaryCtaClick={handleContinueSession}
      />
      <LoadingModal open={loading} />
    </div>
  );
};

export default SpeakingTests;
