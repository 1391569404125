import { CSSProperties, FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Text } from '../../../../components/base';
import { useElementSize } from '../../../../hooks/useElementSize';
import { toClassNames } from '../../../../utils';
import style from './WordsPerMinute.module.css';

interface CircularProgressGradientCSS extends CSSProperties {
  '--knob-size': string;
  '--x-offset': string;
  '--y-offset': string;
}

interface Props {
  value: number;
  min?: number;
  max?: number;
  className?: string;
  thickness?: string | number;
}
export const WordsPerMinute: FC<PropsWithChildren<Props>> = ({ value, min = 0, max = 150 }) => {
  const [containerRef, { width: containerWidth }] = useElementSize();

  const { t } = useTranslation('report');
  value = Math.min(Math.max(value, min), max);

  const outerRadius = containerWidth / 2;
  const barThickness = 28;
  const knobSize = barThickness * 1.25;
  const innerRadius = outerRadius - barThickness;

  const middleRadius = (outerRadius + innerRadius) / 2;
  const angleRad = Math.PI - (value / max) * Math.PI;

  const baseOffset = outerRadius - knobSize / 2;
  const xOffset = baseOffset + Math.cos(angleRad) * middleRadius;
  const yOffset = baseOffset - Math.sin(angleRad) * middleRadius;

  return (
    <div className="w-full max-w-[320px] mx-auto">
      <div
        ref={containerRef}
        className={style['circular-progress-container']}
        style={
          {
            '--knob-size': `${knobSize}px`,
            '--x-offset': `${xOffset}px`,
            '--y-offset': `${yOffset}px`,
          } as CircularProgressGradientCSS
        }
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart margin={{ left: 0, top: 0, right: 0, bottom: 0 }}>
            <Pie
              cx="50%"
              cy="100%"
              data={[
                {
                  value: value,
                },
                {
                  value: max - value,
                },
              ]}
              dataKey="value"
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              startAngle={-180}
              endAngle={-360}
              cornerRadius={8}
              isAnimationActive={false}
            >
              <Cell fill="#275DF8" />
              <Cell fill="#E9EEFB" />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 text-center" style={{}}>
          <Text className={toClassNames(['text-grey-600', containerWidth < 310 ? 'hidden' : ''])}>
            {t('your_word_per_minute')}
          </Text>
          <Text variant="5xl" className="font-bold !leading-none">
            {value}
          </Text>
          <Text className="text-grey-600">{t('word_per_minute')}</Text>
        </div>
      </div>
      <div className="mt-1 w-full flex justify-between text-gray-600 sm:px-3">
        <div className="text-left sm:text-center sm:-translate-x-1/2">
          <Text>{min}</Text>
          <Text>Beginner</Text>
        </div>
        <div className="text-right sm:text-center sm:translate-x-1/2">
          <Text>{max}</Text>
          <Text>Native Speaker</Text>
        </div>
      </div>
    </div>
  );
};
