import React from 'react';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Heading.module.css';

type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadingProps extends BaseProps {
  variant: HeadingVariant;
  is?: HeadingVariant;
}

const Heading = ({ variant, is, className, children }: HeadingProps) => {
  const classNames = toClassNames([
    style[variant],
    style.heading, // Base style
    className,
  ]);
  const elementType = is || variant; // Allow setting custom html element type for semantic reason.

  return React.createElement(elementType, { className: classNames }, children);
};

export default Heading;
