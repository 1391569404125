import { Text, Chip } from './base';
import Icon, { IconName } from './base/Icon';

interface FixedPositionTimerProps {
  time: string;
  text?: string;
}
const FixedPositionTimer = ({ time, text = 'เหลือเวลาอีก' }: FixedPositionTimerProps) => {
  return (
    <Chip className="bg-red-100 fixed top-[160px] right-[16px] !rounded-xl shadow-xl !p-8 flex-col z-10">
      <div className="flex gap-1 mb-1">
        <Icon icon={IconName.Timer} size={'20px'} className="text-red-500" />
        <Text className="text-red-500">{text}</Text>
      </div>
      <Text variant="xl" className="text-red-500 font-medium">
        {time}
      </Text>
    </Chip>
  );
};

export default FixedPositionTimer;
