import React from 'react';
import { Button, Heading } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { RECORDER_STATUS, useMediaRecorder } from '../RecordingButton/useMediaRecorder';

const TestFeature = () => {
  const { status, startRecording, stopRecording, initializeRecorder, resetRecorder } =
    useMediaRecorder({
      onFinish: handleFinishRecording,
      onDataAvailable: handleDataAvailable,
      onError: handleError,
    });
  const [timeLeft, setTimeLeft] = React.useState(30);
  const timeIntervalInstanceRef = React.useRef<number>();

  React.useEffect(() => {
    if (timeLeft <= 0) {
      stopRecording();
      clearInterval(timeIntervalInstanceRef.current);
      timeIntervalInstanceRef.current = undefined;
    }
  }, [timeLeft]);

  function handleFinishRecording(blob: Blob) {
    const audioUrl = URL.createObjectURL(blob);
    const audioObj = new Audio(audioUrl);
    audioObj.controls = true;
    document.getElementById('audio-container')?.appendChild(audioObj);
  }

  function handleDataAvailable(blob: Blob) {
    console.log('on data available', blob);
  }

  function handleError(message: string) {
    console.error(message);
  }

  async function handleStartRecording() {
    try {
      const success = await initializeRecorder();
      if (success) {
        const timeIntervalInstance = window.setInterval(() => {
          setTimeLeft((timeLeft) => timeLeft - 1);
        }, 1000);
        timeIntervalInstanceRef.current = timeIntervalInstance;
        startRecording(200);
      }
    } catch (error) {
      console.error(error);
      alert('something went wrong');
      resetRecorder();
    }
  }

  function handleStopRecording() {
    stopRecording();
    clearInterval(timeIntervalInstanceRef.current);
    timeIntervalInstanceRef.current = undefined;
  }

  return (
    <LayoutCard className="my-10">
      <Heading variant="h1" is="h1" className="text-primary text-left mb-10">
        Test MediaRecorder
      </Heading>
      <Heading variant="h4" className="mb-10">
        Media Recorder Status: <span className="text-primary">{status}</span>
      </Heading>
      {status === RECORDER_STATUS.RECORDING && (
        <Heading variant="h4" className="mb-10">
          Time left <span className="text-primary">{timeLeft}</span>
        </Heading>
      )}
      <div className="flex gap-4 justify-start">
        <Button variant="primary" onClick={handleStartRecording}>
          Start Recording
        </Button>
        <Button variant="outlined" onClick={handleStopRecording}>
          Stop Recording
        </Button>
      </div>
      <div id="audio-container"></div>
    </LayoutCard>
  );
};

export default TestFeature;
