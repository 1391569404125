import { useTranslation } from 'react-i18next';

export const useDefaultDateFormat = () => {
  const { i18n } = useTranslation();

  const dateFormat =
    i18n.language === 'en'
      ? 'D MMMM YYYY'
      : i18n.language === 'th'
      ? 'D MMMM BBBB'
      : 'YYYY-MM-DDTHH:mm:ssZ'; // * ISO-8601, should be unreachable

  return dateFormat;
};
