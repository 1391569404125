import { RecordingButtonVariant } from '../../../components/RecordingButton';
import i18n from '../../../i18n';

export enum TestResultKey {
  Excellent = 'Excellent',
  Good = 'Good',
  Bad = 'Bad',
}

const TestMicThreshold = {
  [TestResultKey.Excellent]: 18,
  [TestResultKey.Good]: 12,
  [TestResultKey.Bad]: 0,
};

function getStandardDeviation(array: number[]) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b, 0) / n;
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n);
}

function getAverageVoiceData(voiceData: number[]) {
  const data = [...voiceData];
  const avg = data.reduce((a, b) => a + b, 0) / data.length;
  const sd = getStandardDeviation(data);
  const filteredData = data.filter((d) => {
    return d >= avg - sd - sd && d <= avg + sd + sd;
  });

  const sortedData = filteredData.sort((a, b) => b - a);
  const count = Math.floor(sortedData.length / 3);
  const slicedData = sortedData.slice(0, count);

  const values = slicedData.reduce((a, b) => a + b, 0);
  return values / slicedData.length;
}

export function getNoiseTestingResult(
  noiseData: number[],
  speakingVoiceData: number[]
): TestResultKey {
  const avgNoise = getAverageVoiceData(noiseData);
  const avgSpeakingVoice = getAverageVoiceData(speakingVoiceData);
  const diff = avgSpeakingVoice - avgNoise;

  if (diff > TestMicThreshold[TestResultKey.Excellent]) {
    return TestResultKey.Excellent;
  } else if (diff >= TestMicThreshold[TestResultKey.Good]) {
    return TestResultKey.Good;
  } else {
    return TestResultKey.Bad;
  }
}

export function getTestResultData(result: TestResultKey) {
  const TEST_RESULT = {
    [TestResultKey.Excellent]: {
      title: i18n.t('assessment:assessment_test_mic_utils_test_result_key_excellent_title'),
      headerDescription: i18n.t(
        'assessment:assessment_test_mic_utils_test_result_key_excellent_header_description'
      ),
      description: i18n.t(
        'assessment:assessment_test_mic_utils_test_result_key_excellent_description'
      ),
      recordingButtonVariant: RecordingButtonVariant.DONE,
      bgColorClass: 'bg-green-200',
      textColorClass: 'text-green-700',
    },
    [TestResultKey.Good]: {
      title: i18n.t('assessment:assessment_test_mic_utils_test_result_key_good_title'),
      headerDescription: i18n.t(
        'assessment:assessment_test_mic_utils_test_result_key_good_header_description'
      ),
      description: i18n.t('assessment:assessment_test_mic_utils_test_result_key_good_description'),
      recordingButtonVariant: RecordingButtonVariant.DONE,
      bgColorClass: 'bg-green-200',
      textColorClass: 'text-green-700',
    },
    [TestResultKey.Bad]: {
      title: i18n.t('assessment:assessment_test_mic_utils_test_result_key_bad_title'),
      headerDescription: i18n.t(
        'assessment:assessment_test_mic_utils_test_result_key_bad_header_description'
      ),
      description: i18n.t('assessment:assessment_test_mic_utils_test_result_key_bad_description'),
      recordingButtonVariant: RecordingButtonVariant.WARNING,
      color: '#D21919',
      bgColorClass: 'bg-error-100',
      textColorClass: 'text-error-700',
    },
  };

  return TEST_RESULT[result];
}
