import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import {
  SpeakingTestQuestionType,
  SpeakingTestQuestionTypeTitle,
} from '../../../../model/speakingTestQuestion';

const ContentHeader = ({
  questionType,
  questionNumber,
  totalQuestion,
  timeLimit,
  title,
  description,
}: {
  questionType: SpeakingTestQuestionType;
  questionNumber: number;
  totalQuestion: number;
  timeLimit: number;
  title: string;
  description: string;
}) => {
  const { t } = useTranslation('assessment');
  return (
    <>
      {/* General Task Header */}
      <div className="px-6 sm:px-16 mb-6 pb-3 border-b border-divider">
        <div className="w-full flex justify-between items-center">
          <Heading variant="h4" is="h4">
            <span className="text-primary-500">Speaking Test &bull; </span>
            <span>{SpeakingTestQuestionTypeTitle[questionType]}</span>
          </Heading>
          <div className="px-5 py-2 flex items-center gap-x-1 rounded-[10px] bg-grey-100">
            <Icon icon={IconName.Duration} size="20px" className="text-grey-500" />
            <Text variant="xs" is="span" className="text-grey-600">
              {t('assessment_speaking_test_quiz_content_header_heading_answer_duration')}
            </Text>
            <Text variant="xs" is="span" className="font-semibold text-grey-500">
              {t('assessment_speaking_test_quiz_content_header_heading_duration_seconds', {
                duration: timeLimit,
              })}
            </Text>
          </div>
        </div>
      </div>

      {/* Question Header */}
      <div className="mb-6">
        <Heading variant="h5" is="h5" className="text-primary text-center">
          {t('assessment_speaking_test_quiz_content_header_heading_question_n_of_total', {
            n: questionNumber,
            total: totalQuestion,
          })}
        </Heading>
        <Heading variant="h1" is="h1" className="text-center font-normal mt-4">
          {title}
        </Heading>
        {/* <Text variant="md" className="text-grey-500 text-center mt-1">
          {description}
        </Text> */}
      </div>
    </>
  );
};

export default ContentHeader;
