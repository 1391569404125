import React from 'react';
import { RECORDER_STATUS, useMediaRecorder } from '../../RecordingButton/useMediaRecorder';
import CardHeader, {
  CardHeaderVariant,
} from '../../../../components/assessmentAppLayouts/CardHeader';
import useTimer from '../../../../utils/useTimer';
import { LoadingModal } from '../../../../components/base';
import { useTranslation } from 'react-i18next';
import { debounce as _debounce } from 'lodash';
import Image from '../../../../components/base/Image';
import RecordingPanel from '../../SpeakingTestQuiz/components/RecordingPanel';
import {
  RecordingPanelFeatures,
  RecordingPanelState,
} from '../../SpeakingTestQuiz/components/RecordingPanel/helpers';

const TestSpeakingVoice = ({
  onFinishRecordingSpeakingVoice,
}: {
  onFinishRecordingSpeakingVoice: (averageNoiseData: number[], blob: Blob) => void;
}) => {
  const { t } = useTranslation('assessment');
  const [averageNoiseData, setAverageNoiseData] = React.useState<number[]>([]);
  const [loading, setLoading] = React.useState(false);

  const { status, startRecording, stopRecording, initializeRecorder, analyserRef } =
    useMediaRecorder({
      onFinish: handleFinishRecording,
      onDataAvailable: handleDataAvailable,
      onError: handleRecordingError,
      activateAnalyser: true,
    });

  function handleDataAvailable(blob: Blob) {
    if (analyserRef.current) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);

      let values = 1;
      for (var i = 0; i < dataArray.length; i++) {
        values += dataArray[i];
      }

      const average = values / dataArray.length;
      setAverageNoiseData((prev) => [...prev, average]);
    } else {
      stopRecording();
      throw new Error('something went wrong');
    }
  }

  function handleFinishRecording(blob: Blob) {
    onFinishRecordingSpeakingVoice(averageNoiseData, blob);
    setLoading(false);
  }

  function handleRecordingError() {}

  const timer = useTimer({
    onTimeUp: handleTimeup,
    time: 20,
  });

  React.useEffect(() => {
    if (status === RECORDER_STATUS.NOT_STARTED) {
      const success = initializeRecorder();
      if (!success) {
        throw new Error('Cannot initialize recorder');
      }
    }
  }, [status, initializeRecorder]);

  /**
   * Only allow click to start
   */
  const handleStartRecordingSpeakingVoice = _debounce(
    async () => {
      startRecording(200);
      timer.startTimer();
    },
    1000,
    { leading: true, trailing: false }
  );

  const handleStopRecordingSpeakingVoice = () => {
    stopRecordingSpeakingVoice();
  };

  function handleTimeup() {
    stopRecordingSpeakingVoice();
  }

  async function stopRecordingSpeakingVoice() {
    setLoading(true);
    stopRecording();
  }

  const recordingPanelState = React.useMemo((): RecordingPanelState => {
    switch (status) {
      case RECORDER_STATUS.SETUP_RECORDER:
        return RecordingPanelState.Loading;
      case RECORDER_STATUS.RECORDER_READY:
        return RecordingPanelState.Preparing;
      case RECORDER_STATUS.RECORDING:
        return RecordingPanelState.Recording;
      case RECORDER_STATUS.STOPPED:
        return RecordingPanelState.Completed;
      default:
        // NOT_STARTED falls here, but will be handled by useEffect above
        return RecordingPanelState.Default;
    }
  }, [status]);

  return (
    <>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle={t('assessment_test_mic_test_speaking_voice_cardheader_subtitle')}
        title={t('assessment_test_mic_test_speaking_voice_cardheader_title')}
        description={t('assessment_test_mic_test_speaking_voice_cardheader_description')}
        className="mb-6"
      />
      <div className="flex items-center gap-x-6">
        <Image
          src="/assets/images/session/test-microphone-speaking-voice.png"
          alt="Speaking voice test"
        />
        <div className="bg-primary-50 rounded-xl px-8 py-6 w-[480px] max-w-full leading-loose">
          &ldquo;Hello, my friends. Today, I am here to do a microphone test. I love to study
          English. I am feeling great, and I am feeling happy. I hope everything works well. We will
          start soon. One, two, three. Go!&rdquo;
        </div>
      </div>
      <RecordingPanel
        className="w-full absolute inset-0 top-auto shadow-[0px_-5px_40px_10px_rgba(0,0,0,0.2)]"
        preparationTimeLeft={0}
        recordingTimeLeft={timer.timeLeft}
        totalRecordingTime={timer.totalTime}
        minRecordingTime={10}
        onClickStartRecording={handleStartRecordingSpeakingVoice}
        onClickFinishRecording={handleStopRecordingSpeakingVoice}
        disabledFinishRecordingButton={timer.timeLeft > timer.totalTime - 10}
        state={recordingPanelState}
        overrideFeatures={{
          [RecordingPanelState.Preparing]: {
            [RecordingPanelFeatures.PreparationTimer]: false,
          },
        }}
      />
      <LoadingModal
        open={loading}
        text={t('assessment_test_mic_test_speaking_voice_loading_text')}
      />
    </>
  );
};

export default TestSpeakingVoice;
