import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import style from '../ReportDetail.module.css';
import type { Tester } from '../../../../model/tester';
import type { Report } from '../../../../types/report';
import { formatEdSpeakScore, formatCefrLevel, formatCambridgeLevel } from '../../../../utils';
import { isValidCEFRLevel } from '../../../../data/cefr';
import { cefrLevelToCambridgeLevel } from '../../../../data/cambirdge';

interface Props {
  report: Report;
  tester: Tester;
}

const ReportSummarySection: FC<Props> = ({ report, tester }) => {
  const { t } = useTranslation('report');

  const cefrLevel = report.result.englishStandard.cefr;
  // This should always be a value
  const cambridgeLevel = isValidCEFRLevel(cefrLevel) ? cefrLevelToCambridgeLevel(cefrLevel) : '';

  return (
    <div className={style['report-summary-box-container']}>
      <Heading is="h2" variant="h1" className="text-white text-center">
        {tester.fullName}
      </Heading>

      {tester.grade && <Text className="capitalize">{tester.gradeAndRoomText}</Text>}

      <div className="mt-10 mb-2">
        <Text variant="lg" className="font-semibold text-center">
          ระดับ CEFR
        </Text>
        <Text variant="7xl" className="text-center text-yellow-300 font-semibold">
          {formatCefrLevel(report.result.englishStandard.cefr, '--')}
          {/* {report.result.englishStandard.cefr === 'N/A' ? '--' : report.result.englishStandard.cefr} */}
        </Text>
      </div>
      {/* <div className="mt-3 grid grid-cols-2 w-full max-w-xs border-t border-primary-100/20 mb-6">
        <div className="pt-4 pb-3 flex flex-col items-center w-full border-r border-primary-100/20"> */}
      <div className="mt-3  w-full max-w-xs border-t border-primary-100/20 mb-6">
        <div className="pt-4 pb-3 flex flex-col items-center w-full">
          <Text>เทียบเท่า Cambridge</Text>
          <Text variant="lg" className="font-semibold capitalize">
            {formatCambridgeLevel(cambridgeLevel)}
            {/* {!cambridgeLevel ? '--' : cambridgeLevel} */}
          </Text>
        </div>
        {/* <div className="pt-4 pb-3 flex flex-col items-center w-full">
          <Text>{t('edspeak_score')}</Text>
          <Text variant="lg" className="font-semibold">
            {formatEdSpeakScore(report.result.overall.score, '--')} / 230
          </Text>
        </div> */}
      </div>
      <Text variant="sm" className="text-grey-400 text-center px-12 sm:px-14">
        หมายเหตุ: หากระบบไม่สามารถประมวลผลได้ จะแสดงผลเป็น "--" เนื่องจากคำตอบของคุณอาจสั้นเกินไป
        หรือไม่ได้ตอบอย่างครบถ้วน
      </Text>
      {/* TODO: Enable share section when ready */}
      {/* <div className="mt-7">
        <Text>{t('report_detail_report_summary_section_share_score_label')}</Text>
        <div className="mt-2 flex gap-x-3 justify-center">
          <Link to="" className={style['report-summary-share-icon']}>
            <Icon icon={IconName.Twitter} size="16px" />
          </Link>
          <Link to="" className={style['report-summary-share-icon']}>
            <Icon icon={IconName.Facebook} size="16px" />
          </Link>
          <Link to="" className={style['report-summary-share-icon']}>
            <Icon icon={IconName.Link} size="16px" />
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default ReportSummarySection;
