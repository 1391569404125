import { FC, Fragment } from 'react';
import { ResultCategoryName } from '../../../../types/report';
import { Image, Text } from '../../../../components/base';
import { useTranslation } from 'react-i18next';
import { toClassNames } from '../../../../utils';

type AcceptedCategory = 'fluency' | 'pronunciation' | 'vocabulary';
interface Props {
  category: Extract<ResultCategoryName, AcceptedCategory>;
  className?: string;
}

const HowWeMeasureMetrics: FC<Props> = ({ category, className }) => {
  const { t } = useTranslation('report');

  const titleKey = `report_detail_category_${category}_measurement_title` as const;
  const descriptionsKey = `report_detail_category_${category}_measurement_method_arr` as const;
  const measurementDescriptions = t(descriptionsKey, { returnObjects: true });

  return (
    <div
      className={toClassNames([className, 'rounded-2xl border border-green-100 p-7 flex gap-x-5'])}
    >
      <Image
        src="/assets/images/report-app/report-detail-category-measurement.png"
        alt="How we measure metrics?"
        className="hidden sm:block h-full"
      />
      <div>
        <Text variant="xl" className="font-bold text-primary sm:text-2xl">
          {t(titleKey)}
        </Text>
        <div className="mt-3 grid grid-cols-[auto,1fr] gap-x-2 text-gray-600">
          {measurementDescriptions.map((description, index) => (
            <Fragment key={index}>
              <Text>&bull;</Text>
              <Text className="whitespace-pre-line" key={index}>
                {description}
              </Text>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowWeMeasureMetrics;
