import React from 'react';
import { useNavigate } from 'react-router-dom';
import { validateStudentId } from '../../api';
import { Heading, Text, Container, Button, Input } from '../../components/base';
import { routes } from '../../router';

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [reportId, setReportId] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setErrorMessage('');
    setReportId(e.currentTarget.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await validateStudentId(reportId);
      if (result) {
        navigate(routes.ReportDetail.getRoute(reportId));
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`ไม่พบรหัสประจำตัว ${reportId} กรุณาตรวจสอบใหม่อีกครั้ง`);
    }
    setLoading(false);
  };

  return (
    <div className="py-4 px-4">
      <Container className="bg-primary-50 py-10 rounded-2xl shadow-md flex flex-col items-center">
        <Heading variant="h5" is="h1" className="text-primary text-center mb-12">
          ผลการวัดระดับทักษะการพูดภาษาอังกฤษ
          <br />
          <Text variant="3xl" is="span" className="font-bold">
            EdSpeak
          </Text>{' '}
          by Edsy
        </Heading>
        <div className="w-full px-4 mb-6">
          <Input
            value={reportId}
            onChange={handleChange}
            placeholder={'กรุณากรอกรหัสประจำตัว'}
            disabled={loading}
            className={'w-full'}
          />
        </div>
        <Button variant="primary" size={'xlarge'} onClick={handleSubmit} disabled={loading}>
          {loading ? 'กำลังตรวจสอบรหัสประจำตัว...' : 'ค้นหาผลการทดสอบ'}
        </Button>
        <Text variant="sm" className="text-red-500 leading-none h-[16px] mt-4">
          {errorMessage || ' '}
        </Text>
      </Container>
    </div>
  );
};

export default Home;
