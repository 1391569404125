import { useNavigate } from 'react-router-dom';
import CardHeader from '../../../components/assessmentAppLayouts/CardHeader';
import { Button } from '../../../components/base';
import InstructionContentCard from '../../../components/InstructionContentCard';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { assessmentAppRoutes } from '../../../router';
import { useTranslation } from 'react-i18next';

const Instruction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');

  const handleNext = () => {
    navigate(assessmentAppRoutes.AssessmentAppPreTestInstruction.getRoute());
  };
  return (
    <LayoutCard className="my-10 flex flex-col items-center">
      <CardHeader
        subtitle={t('assessment_instruction_cardheader_subtitle')}
        title={t('assessment_instruction_cardheader_title')}
        description={t('assessment_instruction_cardheader_description')}
        className="mb-6"
      />
      <div className="md:flex gap-6 justify-center mb-6">
        <InstructionContentCard
          imageUrl="/assets/images/assessment-app-instruction-multiple-choice.png"
          title={t('assessment_instruction_instruction_content_card_multiple_choice_title')}
          description1={t('assessment_instruction_instruction_content_card_multiple_choice_description1')}
          description2={t('assessment_instruction_instruction_content_card_multiple_choice_description2')}
        />
        <InstructionContentCard
          imageUrl="/assets/images/speaking-test/test-mic-instruction.png"
          title={t('assessment_instruction_instruction_content_card_speaking_title')}
          description1={t('assessment_instruction_instruction_content_card_speaking_description1')}
          description2={t('assessment_instruction_instruction_content_card_speaking_description2')}
        />
      </div>
      <Button variant="primary" size="large" onClick={handleNext}>
      {t('assessment_instruction_next_button')}
      </Button>
    </LayoutCard>
  );
};

export default Instruction;
