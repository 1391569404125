import { type FC, type ForwardedRef, forwardRef } from 'react';
import Select, { SelectProps, SelectRootSlotProps } from '@mui/base/Select';
import Option from '@mui/base/Option';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../base/Icon';
import { Popper } from '@mui/base';
import { toClassNames } from '../../utils';

const languageToFlagSrc = {
  th: '/assets/images/country-flags/thailand.png',
  en: '/assets/images/country-flags/united-kingdom.png',
} as const;

interface Props {
  className?: string;
}
export const SelectLangaugeButton: FC<Props> = ({ className }) => {
  const { i18n } = useTranslation();

  return (
    <div className={className}>
      <Select
        value={i18n.language}
        onChange={(_e, value) => {
          if (!value) return;
          i18n.changeLanguage(value);
        }}
        slots={{
          root: SelectRootButton,
          popper: StyledPopper,
        }}
        renderValue={renderValue}
      >
        <StyledOption value="th">ภาษาไทย</StyledOption>
        <StyledOption value="en">English</StyledOption>
      </Select>
    </div>
  );
};

// ############################
// ##### Below are slots  #####
// ##### for the `Select` #####
// ############################

// * Popper component (the dropdown)
const StyledPopper: typeof Popper = (props) => (
  <Popper {...props} className={'z-10 bg-white rounded-xl border border-gray-200 p-1'} />
);

// * Option component (the choice itself)
const StyledOption: typeof Option = (props) => {
  if (props.value !== 'en' && props.value !== 'th') return null;
  const lang = props.value as keyof typeof languageToFlagSrc;
  return (
    <Option
      {...props}
      className="flex items-center gap-x-2 px-2 py-1 cursor-pointer rounded-lg hover:bg-gray-200"
    >
      <img src={languageToFlagSrc[lang]} alt="" className="w-5 h-5 mt-1" />
      {props.children}
    </Option>
  );
};

// * custom render of button's children (default is the selected-value )
const renderValue: SelectProps<string, false>['renderValue'] = (options) => {
  if (!options || !options.value) return null;

  const lang = options.value;
  if (lang !== 'en' && lang !== 'th') return null;

  return (
    <div className="flex items-center gap-x-1 text-white">
      <img src={languageToFlagSrc[lang]} alt="" className="w-5 h-5 hidden sm:block" />
      <span className="uppercase ">{options?.value}</span>
    </div>
  );
};

// * custom root of Select (uses button element)
const SelectRootButton = forwardRef(function Button(
  props: SelectRootSlotProps<string, false>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, ...other } = props;

  return (
    <button
      type="button"
      {...other}
      ref={ref}
      className="px-1 flex items-center gap-x-1 text-white rounded-lg border border-white sm:border-none"
    >
      {other.children}
      <Icon
        size="20px"
        icon={IconName.ChevronDown}
        className={toClassNames([
          'border border-none sm:border-solid border-white rounded-lg w-fit h-fit',
          '[&>svg]:transition-transform',
          ownerState.open ? '[&>svg]:transform [&>svg]:rotate-180' : '',
        ])}
      />
    </button>
  );
});
