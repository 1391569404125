/**
 * Speed Graph
 *
 * This component is a workaround solution to draw graph for word per minute value.
 * It is currently drawn by HTML element without optimizing anything.
 * There are some hard-coding and magic number around the component.
 *
 * TODO: Refactor this component to use Graph/Chart library for better performance and maintainability.
 */
import { Text } from '../../../components/base';
import { toClassNames } from '../../../utils';
import style from '../ReportDetailCategory.module.css';

/**
 * TODO: Refactor and Move these colors to theme if they are used somewhere else.
 */
const graphColors: string[] = ['#82DBDF', '#5ABCCC', '#4989B6', '#334D9C', '#253B7F'];
const baseGraphColor = '#D9D9D9';
const speedRange: { name: string; range: number[]; label: string }[] = [
  { name: 'Beginner', range: [0, 60], label: '≤ 60' },
  { name: '', range: [61, 90], label: '60-90' },
  { name: '', range: [91, 120], label: '91-120' },
  { name: '', range: [121, 150], label: '121-150' },
  { name: 'Native', range: [151, 180], label: '> 150' },
];

/**
 * This function is used to calculate the value of "left" for the averageSpeed marker.
 */
const getAverageSpeedPercent = (averageSpeed: number) => {
  /**
   * Check averageSpeed out of range.
   * This should not be possible, but check just in case the data
   * from backend is not accurate in the beta version.
   */
  if (averageSpeed < speedRange[0].range[0]) return 0;
  if (averageSpeed > speedRange[speedRange.length - 1].range[1]) return 100;

  /**
   * Calculate how many full cells that average speed is higer.
   * The percentage of this section should be at the max of the latest full cell range.
   *
   * For example: If the averageSpeed is 100 and each cell the range of 30,
   * the averageSpeedPercentForFullCell should point at the end of the 3rd cell
   * which will cover value of 90 (30 * 3).
   * The remaining 10 (100 - 90) will be calculated as the targetCell in the next section.
   */
  const possibleCellIndex = speedRange.findIndex(
    (data) => averageSpeed >= data.range[0] && averageSpeed <= data.range[1]
  );
  const averageSpeedCellIndex = possibleCellIndex >= 0 ? possibleCellIndex : speedRange.length - 1;
  const averageSpeedPercentForFullCell = (averageSpeedCellIndex / speedRange.length) * 100;

  /**
   * Calculate the exact position on the target cell.
   */
  const targetCellRange = speedRange[averageSpeedCellIndex].range;
  const averageSpeedPercentInsideTargetCell =
    ((averageSpeed - targetCellRange[0]) / (targetCellRange[1] - targetCellRange[0])) * 100;

  /**
   * Calculate the percent for the whole graph.
   */
  const averageSpeedPercent =
    averageSpeedPercentForFullCell + averageSpeedPercentInsideTargetCell / speedRange.length;

  return averageSpeedPercent;
};

const SpeedGraph = ({ speed, averageSpeed }: { speed: number; averageSpeed: number }) => {
  const cellData = speedRange.map(({ name, range, label }, index) => {
    return {
      name,
      label,
      color: speed >= range[0] ? graphColors[index] : baseGraphColor,
    };
  });

  const averageSpeedPercent = getAverageSpeedPercent(averageSpeed);
  return (
    <div className="relative pt-9 pb-4">
      {averageSpeed && (
        <div
          className={`absolute top-0 bottom-[15%] flex gap-1`}
          style={{ left: `${averageSpeedPercent}%` }}
        >
          <div className="h-full border-l-2 border-dashed border-red-500"></div>
          <div>
            <Text variant="xs" className="text-red-500 font-semibold mb-0.5">
              {averageSpeed}
            </Text>
            <Text variant="xs">ค่าเฉลี่ย Edsy</Text>
          </div>
        </div>
      )}
      <div className="flex gap-0.5 w-fit mb-2">
        {cellData.map(({ label, color }) => {
          return (
            <div
              className={toClassNames([
                style['speed-graph-cell-container'],
                'flex flex-col items-center',
              ])}
              key={label}
            >
              <div className={style['speed-graph-cell']} style={{ backgroundColor: color }}></div>
              <Text
                variant="xs"
                className={toClassNames([
                  style['speed-graph-cell-label'],
                  'text-grey-600 mt-2 text-center font-semibold',
                ])}
              >
                {label}
              </Text>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between">
        <Text variant="xs" className="text-primary font-semibold">
          Beginner
        </Text>
        <Text variant="xs" className="text-primary font-semibold text-right">
          Native
        </Text>
      </div>
    </div>
  );
};

export default SpeedGraph;
