import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart,
} from 'recharts';
import { Container, Heading, Text, Image } from '../../../components/base';
import { IntonationResult } from '../../../types/report';
import Banner from './Banner';

const formatDataForGraph = (
  data: number[][],
  areaDataRange: number[]
): { key: number; value: number }[] => {
  return data.map(([second, value]) => ({
    key: second,
    value: value,
    area: areaDataRange,
  }));
};

const PitchGraph = ({ data, areaDataRange }: { data: number[][]; areaDataRange: number[] }) => {
  const graphData = formatDataForGraph(data, areaDataRange);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="key"
          label={{
            value: 'seconds',
            position: 'insideBottom',
            offset: -5,
            style: { fontSize: '12px', fontWeight: 700, color: 'black' },
          }}
          domain={[0, data.length]}
          style={{ fontSize: '10px' }}
        />
        <YAxis
          label={{
            value: 'Hz',
            angle: -90,
            position: 'insideLeft',
            offset: 20,
            style: { fontSize: '12px', fontWeight: 700, color: 'black' },
          }}
          domain={[0, 200]}
          style={{ fontSize: '10px' }}
        />
        <Legend
          height={16}
          verticalAlign="top"
          align="center"
          iconSize={8}
          iconType="circle"
          wrapperStyle={{ bottom: -30, fontSize: '14px', fontWeight: 600 }}
        />
        <Area
          type="monotone"
          dataKey="area"
          fill="#70C9CB"
          stroke="#399B9D"
          fillOpacity={0.5}
          name="natural pitch range"
        />
        <Line name="pitch value" type="monotone" dataKey="value" stroke="#1f45b1" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const Intonation = ({ result: { pitch } }: { result: IntonationResult }) => {
  return (
    <>
      <Banner
        mobileImageUrl="/assets/images/marketing/intonation-banner-mobile.png"
        tabletImageUrl="/assets/images/marketing/intonation-banner-tablet.png"
        alt="intonation-banner"
        href="https://line.me/ti/p/@edsy"
        dataAnalyticId="category-banner-intonation"
      />
      <div className="bg-primary-50">
        <Container className="py-10 flex flex-col items-center gap-6">
          <Heading variant="h5">ระดับเสียง (PITCH) ระหว่างการทดสอบ</Heading>
          <div className="w-full h-[300px] rounded-xl bg-white pt-8 pb-12">
            <PitchGraph data={pitch.pitchData} areaDataRange={pitch.naturalPitchRange} />
          </div>
        </Container>
      </div>
      <Container className="py-10 flex flex-col items-center">
        <Heading variant="h3" className="mb-6 text-primary">
          วิเคราะห์การออกเสียงสูงต่ำ
        </Heading>
        <Text className="text-center">{pitch.analysisText}</Text>
      </Container>
    </>
  );
};

export default Intonation;
