import { DropdownOption } from '../../../../components/base/Form/Dropdown';
import {
  SpeakingTestQuestionPromptContentType,
  SpeakingTestQuestionType,
} from '../../../../model/speakingTestQuestion';
import { CEFRLevel } from '../../../../types/cefr';

export const QUESTION_TYPE: DropdownOption[] = [
  { value: SpeakingTestQuestionType.READING, text: 'READING' },
  { value: SpeakingTestQuestionType.OPEN_ENDED, text: 'OPEN_ENDED' },
  { value: SpeakingTestQuestionType.DESCRIBE, text: 'DESCRIBE' },
];

export const CEFR_OPTIONS: DropdownOption[] = Object.values(CEFRLevel).map((cefr) => ({
  value: cefr,
  text: cefr,
}));

export const PROMPT_TYPE_OPTIONS: DropdownOption[] = Object.values(
  SpeakingTestQuestionPromptContentType
).map((type) => ({ value: type, text: type }));
