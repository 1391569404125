import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useParams } from 'react-router-dom';
import { Text } from '../../../../components/base';
import { reportAppRoutes } from '../../../../router';
import { Report, ResultCategoryName } from '../../../../types/report';
import { SchoolReportStatistic } from '../../../../types/reportStatistic';
import { toClassNames } from '../../../../utils';
import style from '../ReportDetailCategory.module.css';

const categoryTabs = [
  {
    name: ResultCategoryName.Fluency,
    label: 'Fluency',
    labelTranslationKey: 'report_detail_category_fluency_tab_button_label',
    focusClass: 'focus-visible:text-white focus-visible:bg-primary-500',
    bgClass: 'bg-primary-500',
  },
  {
    name: ResultCategoryName.Vocabulary,
    label: 'Vocabulary',
    labelTranslationKey: 'report_detail_category_vocabulary_tab_button_label',
    focusClass: 'focus-visible:text-white focus-visible:bg-secondary-500',
    bgClass: 'bg-secondary-500',
  },
  {
    name: ResultCategoryName.Pronunciation,
    label: 'Pronunciation',
    labelTranslationKey: 'report_detail_category_pronunciation_tab_button_label',
    focusClass: 'focus-visible:text-white focus-visible:bg-green-500',
    bgClass: 'bg-green-500',
  },
] as const;

export type CategoryTabsOutletContext = {
  report: Report;
  schoolStatistic: SchoolReportStatistic;
  activeCategory: ResultCategoryName;
};

interface Props {
  report: Report;
  schoolStatistic: SchoolReportStatistic;
}
const CategoryTabsSection: FC<Props> = ({ report, schoolStatistic }) => {
  const { t } = useTranslation('report');
  const params = useParams();
  const testId = params.testId;
  const categoryName = params.category as ResultCategoryName; // this value should already be valid

  const outletContext = useMemo<CategoryTabsOutletContext>(() => {
    return {
      report,
      schoolStatistic,
      activeCategory: categoryName,
    };
  }, [report, schoolStatistic, categoryName]);

  return (
    <div>
      <div className={style['category-tabs-container']}>
        {categoryTabs.map((tab) => {
          const isActive = categoryName === tab.name;
          return (
            <Link
              preventScrollReset
              key={tab.name}
              to={reportAppRoutes.ReportDetailCategory.getRoute({
                testId: testId,
                category: tab.name,
              })}
              className={toClassNames([
                style['category-tab'],
                'focus-visible:outline-none',
                tab.focusClass,
                isActive ? `!text-white ${tab.bgClass}` : 'text-green-600',
              ])}
              data-analytic-id="report-detail-category-tab-item"
              data-analytic-detail={tab.name}
            >
              <Text variant="sm" className="text-[15px] sm:!text-lg font-semibold">
                {tab.label}
              </Text>
              <Text variant="2xs" className="sm:!text-base">
                {t(tab.labelTranslationKey)}
              </Text>
            </Link>
          );
        })}
      </div>
      <Outlet context={outletContext} />
    </div>
  );
};

export default CategoryTabsSection;
