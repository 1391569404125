import { FC } from 'react';
import { toClassNames } from '../utils';
import { Image } from './base';

const starImageMap = {
  full: '/assets/images/stars/star-full.svg',
  half: '/assets/images/stars/star-half.svg',
  empty: '/assets/images/stars/star-empty.svg',
  'empty-grey': '/assets/images/stars/star-empty-grey.svg',
};

type StarType = keyof typeof starImageMap;

const getStarsArray = (
  starsCount: number,
  score: number,
  fullScore: number,
  halfStarThresholdFactor = 0.25,
  fullStarThresholdFactor = 1 - halfStarThresholdFactor
): Array<StarType> => {
  const stars: Array<StarType> = [];
  const scorePerStar = fullScore / starsCount;
  const halfStarThreshold = scorePerStar * halfStarThresholdFactor;
  const fullStarThreshold = scorePerStar * fullStarThresholdFactor;
  for (let i = 0; i < starsCount; i++) {
    const currentStarScore = score - i * scorePerStar;
    if (currentStarScore >= fullStarThreshold) {
      stars.push('full');
    } else if (currentStarScore >= halfStarThreshold) {
      stars.push('half');
    } else {
      stars.push('empty');
    }
  }
  return stars;
};

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

interface Props {
  score: number;
  fullScore?: number;
  starsCount?: number;
  halfStarThresholdFactor?: number;
  fullStarThresholdFactor?: number;
  className?: string;
  starClassName?: string;
  noZeroStar?: boolean;
  greyScale?: boolean;
}
const StarsRating: FC<Props> = ({
  score,
  fullScore = 5,
  starsCount = 5,
  halfStarThresholdFactor = 0.25,
  fullStarThresholdFactor = 1 - halfStarThresholdFactor,
  className = '',
  starClassName = '',
  noZeroStar,
  greyScale,
}) => {
  const starsArr = getStarsArray(
    starsCount,
    clamp(score, 0, fullScore),
    fullScore,
    halfStarThresholdFactor,
    fullStarThresholdFactor
  );

  if (starsArr.length === 0) {
    return null;
  }

  if (noZeroStar && starsArr.every((star) => star === 'empty')) {
    starsArr[0] = 'half';
  }

  return (
    <div className={toClassNames([className, 'flex', 'gap-x-1', 'w-fit'])}>
      {starsArr.map((starType, index) => {
        // TODO: support for half and full grey stars
        if (greyScale && starType === 'empty') {
          starType = 'empty-grey';
        }
        return (
          <Image
            src={starImageMap[starType]}
            alt={`star-${index}-${starType}`}
            key={`star-${index}`}
            className={toClassNames([starClassName, 'w-11', 'aspect-square'])}
          />
        );
      })}
    </div>
  );
};

export default StarsRating;
