export enum TestStatus {
  PreTest = 'PRETEST',
  SpeakingTest = 'SPEAKING_TEST',
  Terminated = 'TERMINATED',
  Completed = 'COMPLETED',
  Graded = 'GRADED',
}

function parseTestStatus(type: string): TestStatus {
  switch (type) {
    case TestStatus.PreTest:
      return TestStatus.PreTest;
    case TestStatus.SpeakingTest:
      return TestStatus.SpeakingTest;
    case TestStatus.Terminated:
      return TestStatus.Terminated;
    case TestStatus.Completed:
      return TestStatus.Completed;
    case TestStatus.Graded:
      return TestStatus.Graded;
    default:
      throw new Error(`Given string ${type} does not map to the Test value`);
  }
}

export class Test {
  constructor(
    public id: string,
    public status: TestStatus,
    public accessCodeId: string,
    public testerId: string,
    public createdAt: string,
    public updatedAt: string
  ) {}

  public static parse(json: { [key: string]: any }): Test {
    const tester = new Test(
      json['id'],
      parseTestStatus(json['status']),
      json['accessCodeId'],
      json['testerId'],
      json['createdAt'],
      json['updatedAt']
    );
    return tester;
  }
}
