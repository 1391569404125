import React from 'react';
import LayoutCard from '../../../layouts/components/LayoutCard';
import ConfirmModal from '../../../components/ConfirmModal';
import { getNoiseTestingResult, TestResultKey } from './utils';
import TestBackgroundNoise from './components/TestBackgroundNoise';
import TestSpeakingVoice from './components/TestSpeakingVoice';
import FinishTesting from './components/FinishTesting';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import { useTranslation } from 'react-i18next';

enum TestMicStatus {
  RECORDING_NOISE = 'RECORDING_NOISE',
  RECORDING_SPEAKING_VOICE = 'RECORDING_SPEAKING_VOICE',
  FINISH = 'FINISH',
}

const TestMic = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const { finishTestMic } = useAssessmentContext();
  const [averageBackgroundNoiseData, setAverageBackgroundNoiseData] = React.useState<number[]>();
  const [averageSpeakingVoiceData, setAverageSpeakingVoiceData] = React.useState<number[]>();
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [result, setResult] = React.useState<TestResultKey>();
  const [speakingVoiceBlob, setSpeakingVoiceBlob] = React.useState<Blob>();
  const [testMicStatus, setTestMicStatus] = React.useState(TestMicStatus.RECORDING_NOISE);

  React.useEffect(() => {
    if (
      testMicStatus === TestMicStatus.FINISH &&
      speakingVoiceBlob &&
      averageBackgroundNoiseData &&
      averageSpeakingVoiceData
    ) {
      const result = getNoiseTestingResult(averageBackgroundNoiseData, averageSpeakingVoiceData);
      setResult(result);
      const audioUrl = URL.createObjectURL(speakingVoiceBlob);
      const audioObj = new Audio(audioUrl);
      audioObj.controls = true;
      document.getElementById('audio-container')?.appendChild(audioObj);
    }
  }, [testMicStatus, speakingVoiceBlob, averageBackgroundNoiseData, averageSpeakingVoiceData]);

  const startSpeakingTest = () => {
    finishTestMic();
    navigate(assessmentAppRoutes.AssessmentAppSpeakingTestInstruction.getRoute());
  };

  const resetTestMicSession = () => {
    setTestMicStatus(TestMicStatus.RECORDING_NOISE);
    setAverageBackgroundNoiseData(undefined);
    setAverageSpeakingVoiceData(undefined);
    setSpeakingVoiceBlob(undefined);
    setResult(undefined);
  };

  const handleFinishRecordingBackgroundNoise = (averageNoiseData: number[]) => {
    setAverageBackgroundNoiseData(averageNoiseData);
    setTestMicStatus(TestMicStatus.RECORDING_SPEAKING_VOICE);
  };

  const handleFinishRecordingSpeakingVoice = (averageNoiseData: number[], blob: Blob) => {
    setAverageSpeakingVoiceData(averageNoiseData);
    setSpeakingVoiceBlob(blob);
    setTestMicStatus(TestMicStatus.FINISH);
  };

  const handleStartSpeakingTest = () => {
    if (result === TestResultKey.Bad) {
      setConfirmModalOpen(true);
    } else {
      startSpeakingTest();
    }
  };

  const handleTestMicAgain = () => {
    resetTestMicSession();
  };

  const handleConfirm = () => {
    startSpeakingTest();
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
    resetTestMicSession();
  };

  return (
    <LayoutCard className="relative my-10 flex flex-col items-center overflow-hidden">
      {testMicStatus === TestMicStatus.RECORDING_NOISE && (
        <TestBackgroundNoise
          onFinishRecordingBackgroundNoise={handleFinishRecordingBackgroundNoise}
        />
      )}

      {testMicStatus === TestMicStatus.RECORDING_SPEAKING_VOICE && (
        <TestSpeakingVoice onFinishRecordingSpeakingVoice={handleFinishRecordingSpeakingVoice} />
      )}

      {testMicStatus === TestMicStatus.FINISH && !!result && (
        <FinishTesting
          result={result}
          onStartSpeakingTest={handleStartSpeakingTest}
          onTestMicAgain={handleTestMicAgain}
          audioBlob={speakingVoiceBlob}
        />
      )}
      <ConfirmModal
        open={confirmModalOpen}
        title={t('assessment_test_mic_confirm_modal_title')}
        description={t('assessment_test_mic_confirm_modal_description')}
        primaryButtonText={t('assessment_test_mic_confirm_modal_primary_button_text')}
        secondaryButtonText={t('assessment_test_mic_confirm_modal_secondary_button_text')}
        onPrimaryCtaClick={handleConfirm}
        onSecondaryCtaClick={handleCancel}
      />
    </LayoutCard>
  );
};

export default TestMic;
