import React from 'react';
import * as assessmentApiClient from '../../../api/assessment';
import { Button, Loading } from '../../../components/base';
import { SpeakingTestQuestion } from '../../../model/speakingTestQuestion';
import SpeakingTestQuiz from './SpeakingTestQuiz';

const SpeakingTestQuizContainerForTest = () => {
  const [questions, setQuestions] = React.useState<SpeakingTestQuestion[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);

  React.useEffect(() => {
    const initializeData = async () => {
      try {
        const questions = await assessmentApiClient.getAllSpeakingTestQuestions();
        setQuestions(questions);
        setCurrentQuestionIndex(0);
      } catch (error) {
        console.error(error);
      }
    };
    initializeData();
  }, []);

  const handleNext = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleBack = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  if (questions.length <= 0) {
    return <Loading />;
  }

  return (
    <>
      <SpeakingTestQuiz
        question={questions[currentQuestionIndex]}
        totalQuestion={questions.length}
        questionNumber={currentQuestionIndex + 1}
      />
      <div className="text-center mb-4">Type: {questions[currentQuestionIndex].type}</div>

      <div className="flex justify-center gap-4">
        <Button variant="secondary" onClick={handleBack} disabled={currentQuestionIndex === 0}>
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          disabled={currentQuestionIndex === questions.length - 1}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default SpeakingTestQuizContainerForTest;
