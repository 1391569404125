import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '../../../../components/base';
import type { CEFRLevel } from '../../../../types/cefr';
import type { Report } from '../../../../types/report';
import style from '../ReportDetail.module.css';
import CEFRLevelChart from './CEFRLevelChart';
import CEFRLevelChartMobile from './CEFRLevelChartMobile';

interface Props {
  report: Report;
}

const CEFRScoreSection: FC<Props> = ({ report }) => {
  const { t } = useTranslation('report');

  return (
    <div>
      <Heading is="h2" variant="h1" className="text-center leading-[1.2em] mb-2">
        <span>{t('report_detail_cefr_score_section_title_first_line')}</span>
        <br />
        <span className={style['cefr-score-gradient-title']}>
          {t('report_detail_cefr_score_section_title_second_line')}
        </span>
      </Heading>
      <div className="hidden sm:block">
        <CEFRLevelChart
          level={report.result.englishStandard.cefr as CEFRLevel}
          showCEFRScale={true}
          showMOEScale={true}
          showIELTSScale={false}
        />
      </div>
      <div className="sm:hidden mt-5">
        <CEFRLevelChartMobile level={report.result.englishStandard.cefr as CEFRLevel} />
      </div>
    </div>
  );
};

export default CEFRScoreSection;
