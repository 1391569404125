import { useEffect, useRef, useState } from 'react';

const SMOOTH_TIME_CONSTANT = 0.1;

export enum RECORDER_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  SETUP_RECORDER = 'SETUP_RECORDER',
  RECORDER_READY = 'RECORDER_READY', // Recorder is ready to be started
  RECORDING = 'RECORDING',
  STOPPED = 'STOPPED',
  ERROR = 'ERROR',
}

interface RecordingProps {
  onFinish?: (audioBlob: Blob, mimeType: MimeTypes) => void;
  onDataAvailable?: (audioBlob: Blob) => void;
  onError: (errorMessage: string) => void;
  activateAnalyser?: boolean;
}

export enum MimeTypes {
  webm = 'audio/webm',
  mp4 = 'audio/mp4',
}

const getSupportedMimeType = () => {
  if (MediaRecorder.isTypeSupported(MimeTypes.webm)) {
    return MimeTypes.webm;
  }

  if (MediaRecorder.isTypeSupported(MimeTypes.mp4)) {
    return MimeTypes.mp4;
  }

  return undefined;
};

export function useMediaRecorder({
  onFinish = () => {},
  onDataAvailable = () => {},
  onError = () => {},
  activateAnalyser = false,
}: RecordingProps) {
  const [status, setStatus] = useState(RECORDER_STATUS.NOT_STARTED);
  const [mimeType, setMimeType] = useState<MimeTypes>();
  const audioChunkRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder>();
  const audioAnalyserRef = useRef<AnalyserNode>();

  useEffect(() => {
    const mimeType = getSupportedMimeType();
    if (!mimeType) {
      alert(
        'This device does not support any allowed mimeType (webm, mp4), please change device or contact us for further information'
      );
    } else {
      setMimeType(mimeType);
    }
  }, []);

  useEffect(() => {
    statusWatcher();

    async function statusWatcher() {
      switch (status) {
        case RECORDER_STATUS.NOT_STARTED:
          break;
        case RECORDER_STATUS.SETUP_RECORDER:
          /** Recorder is setting up. */
          break;
        case RECORDER_STATUS.RECORDER_READY:
          /**
           * Waiting for startRecording() to be triggered
           * onRecorderReady() can be added later to enhance user experience.
           */
          break;
        case RECORDER_STATUS.RECORDING:
          /** Recorder is recording*/
          break;
        case RECORDER_STATUS.STOPPED:
          /**
           * Workaround: Set timeout to make sure all the audio data is available.
           * Possible solution: set state to stopped when call stop, then check state in
           * ondataavailable. If state is stopped => call onFinish in there.
           */
          setTimeout(async () => {
            if (mimeType) {
              const audioBlob = new Blob(audioChunkRef.current, { type: mimeType });
              onFinish(audioBlob, mimeType);
            } else {
              throw new Error('No mimeType specified');
            }
          }, 1000);

          break;
        case RECORDER_STATUS.ERROR:
        default:
          /**
           * TODO: Implement error modal and remove alert
           */
          const errorMessage =
            'ขออภัยมีบางอย่างผิดพลาด กรุณารีเฟรชหน้าเว็บแล้วทำการทดสอบใหม่อีกครั้ง';
          alert(errorMessage);
          console.error(errorMessage);
          onError(errorMessage);
      }
    }
  }, [status]);

  const setupRecorder = async () => {
    try {
      /**
       * Setup mediaRecorder
       */
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: mimeType,
      });

      /**
       * Setup Audio Analyser
       */
      if (activateAnalyser) {
        const context = new AudioContext();
        const source = context.createMediaStreamSource(mediaRecorder.stream);
        const analyser = context.createAnalyser();
        analyser.smoothingTimeConstant = SMOOTH_TIME_CONSTANT;
        source.connect(analyser);
        audioAnalyserRef.current = analyser;
      }

      /**
       * Setup actions on data available
       */
      mediaRecorder.ondataavailable = (e) => {
        onDataAvailable(e.data);
        audioChunkRef.current = [...audioChunkRef.current, e.data];
      };

      mediaRecorderRef.current = mediaRecorder;
    } catch (error) {
      /**
       * TODO: Implement error modal and remove alert
       */
      const errorMessage = `Sorry, mediaDevices not supported! ${error}`;
      alert(errorMessage);
      console.error(error);
      onError(errorMessage);
    }
  };

  const resetRecorder = () => {
    setStatus(RECORDER_STATUS.NOT_STARTED);
    audioChunkRef.current = [];
    mediaRecorderRef.current = undefined;
    audioAnalyserRef.current = undefined;
  };

  const initializeRecorder = async () => {
    try {
      setStatus(RECORDER_STATUS.SETUP_RECORDER);
      await setupRecorder();
      setStatus(RECORDER_STATUS.RECORDER_READY);
      /**
       * If success, return true, else throw error
       */
      return true;
    } catch (error) {
      console.error(error);
      setStatus(RECORDER_STATUS.ERROR);
    }
  };

  const startRecording = (timeSlice?: number) => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.start(timeSlice);
      setStatus(RECORDER_STATUS.RECORDING);
    } else {
      setStatus(RECORDER_STATUS.ERROR);
    }
  };

  const stopRecording = () => {
    try {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        setStatus(RECORDER_STATUS.STOPPED);
      } else {
        setStatus(RECORDER_STATUS.ERROR);
      }
    } catch (error) {
      console.error(error);
      setStatus(RECORDER_STATUS.ERROR);
    }
  };

  return {
    status,
    startRecording,
    stopRecording,
    initializeRecorder,
    resetRecorder,
    analyserRef: audioAnalyserRef,
  };
}
