import { Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';

const HeaderText = ({
  title,
  subtitle,
  icon,
}: {
  title: string;
  subtitle: string;
  icon: IconName;
}) => {
  return (
    <div className="flex items-start md:items-center bg-primary-50 rounded-md px-2 py-2 md:gap-4 md:px-6">
      <Icon icon={icon} size='30px' className="text-primary-500 mr-2 mt-1 md:mt-0 hidden md:block" />
      <Icon icon={icon} size='18px' className="text-primary-500 mr-2 mt-1 md:mt-0 md:hidden" />
      <Text className="font-bold text-left ">
        <span className="text-gray-600 text-sm font-normal">{subtitle}</span>
        <br />
        {title}
      </Text>
    </div>
  );
};

export default HeaderText;
