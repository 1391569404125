import { Report } from '../types/report';
import { getRequest } from './utils';
import { BASE_URL } from './constant';
import { Tester } from '../model/tester';
import { School } from '../model/school';

export const getReportByTestId = async (testId: string): Promise<Report | null> => {
  try {
    const fetchUrl = new URL(`/tests/${testId}/report`, BASE_URL);
    const data = await getRequest(fetchUrl);

    if (!data.result) {
      return null;
    }

    return {
      testId: data.testId,
      date: data.result.date,
      tester: Tester.parse(data.tester),
      school: School.parse(data.school),
      result: data.result,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getReportsBySchoolId = async (schoolId: string): Promise<Report[] | undefined> => {
  try {
    const fetchUrl = new URL(`/schools/${schoolId}/reports`, BASE_URL);
    const data = await getRequest(fetchUrl);
    const reports = data.reports;

    /**
     * CRITICAL TODO: This is a workaround to filter null report or empty result object from api.
     * Api should not send not graded or !result to frontend,
     * but we use this workaround to be able to deliver report to user on 2023/06/26
     */
    const filteredReports = reports.filter((report: any) => {
      return report.result !== null && Object.keys(report.result).length > 0;
    });
    return filteredReports.map((report: any) => {
      return {
        testId: report.testId,
        date: report.result.date,
        tester: Tester.parse(report.tester),
        school: School.parse(report.school),
        result: report.result,
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
