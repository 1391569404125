export class School {
  constructor(
    public id: string,
    public name: string,
    /**
     * Url of school logo
     */
    public logoUrl: string,
    /**
     * Url of ads images
     */
    public fullReportAdsUrl: FullReportAdsUrl,
    public sumReportAdsUrl: string,
    public sumReportTemplate2BackgroundUrl: string,
    /* href of full report main banner */
    public fullReportAdsLink: string,
    /**
     * Url of qr code images
     * They should be removed and replaced by auto generated qr code feature.
     */
    public sumReportQrToReportUrl: string,
    public sumReportQrToFormUrl: string
  ) {}
  public static parse(json: { [key: string]: any }): School {
    const school = new School(
      json['id'],
      json['name'],
      json['logoUrl'],
      FullReportAdsUrl.parse(json['fullReportAdsUrl']),
      json['sumReportAdsUrl'],
      json['sumReportTemplate2BackgroundUrl'],
      json['fullReportAdsLink'],
      json['sumReportQrToReportUrl'],
      json['sumReportQrToFormUrl']
    );

    return school;
  }
}

class FullReportAdsUrl {
  constructor(public desktop: string, public mobile: string) {}

  public static parse(json: { [key: string]: any }): FullReportAdsUrl {
    const fullReportAdsUrl = new FullReportAdsUrl(json['desktop'], json['mobile']);
    return fullReportAdsUrl;
  }
}

/**
 * Mock object for testing and mocking apis.
 */
export const mockSchool = new School(
  'mockSchoolId',
  'Hello World School',
  '',
  new FullReportAdsUrl('', ''),
  '',
  '',
  '',
  '',
  ''
);
