import { Modal, Text, Image, Heading, Button } from './base';

interface ErrorModalProps {
  open: boolean;
  title: string;
  description: string;
  buttonText: string;
  secondaryButtonText?: string;
  onButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const ErrorModal = ({
  open,
  title,
  description,
  buttonText,
  secondaryButtonText,
  onButtonClick,
  onSecondaryButtonClick,
}: ErrorModalProps) => {
  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Image
          src={'/assets/images/modal-error.png'}
          alt="submitting-modal"
          className="w-[180px] mb-6"
        />
        <Heading variant="h1" className="mb-2">
          {title}
        </Heading>
        <Text className="text-grey-500 mb-8 text-center px-4">{description}</Text>

        <div className="flex gap-6 mb-4">
          {secondaryButtonText && onSecondaryButtonClick && (
            <Button variant="secondary" onClick={onSecondaryButtonClick}>
              {secondaryButtonText}
            </Button>
          )}
          <Button variant="primary" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
