import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../../components/base';
import { convertSeconds } from '../../../../../../utils/dateTime';
import Icon, { IconName } from '../../../../../../components/base/Icon';
import style from '../RecordingPanel.module.css';

const RecordingTimer = ({ timeLeft, totalTime }: { timeLeft: number; totalTime: number }) => {
  const { t } = useTranslation('assessment');
  const scale = (totalTime - timeLeft) / totalTime;
  const timerIconLeftValue = scale * 100;
  return (
    <div className="flex flex-row sm:flex-col items-center sm:items-start">
      <Text variant={'sm'} className="text-grey-500 whitespace-nowrap mr-2">
        {t('assessment_app_recording_panel_recording_timeleft')}
      </Text>
      <div className="flex gap-1 sm:gap-2 items-center">
        <div className="relative hidden sm:block">
          <div className="h-[8px] bg-grey-400 rounded-full w-[160px] overflow-hidden">
            <div
              className="h-full bg-primary rounded-full w-full"
              style={{
                transform: `scaleX(${scale})`,
                transformOrigin: 'left',
              }}
            ></div>
          </div>
          <div className={style['timer-bar-icon']} style={{ left: `${timerIconLeftValue}%` }}>
            <Icon icon={IconName.Timer} className="text-primary" size={'14px'} />
          </div>
        </div>
        <Icon icon={IconName.Timer} className="text-primary block sm:hidden" size={'20px'} />
        <Text variant="md" className="font-bold text-primary">
          {convertSeconds(timeLeft, 'mm:ss')}
        </Text>
      </div>
    </div>
  );
};

export default RecordingTimer;
