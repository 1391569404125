export class PreTestQuestionChoice {
  constructor(public id: string, public content: string) {}

  public static parse(json: { [key: string]: any }): PreTestQuestionChoice {
    const choice = new PreTestQuestionChoice(json['choiceId'], json['content']);
    return choice;
  }
}

export class PreTestQuestion {
  constructor(
    public id: string,
    public question: string,
    public choices: PreTestQuestionChoice[]
  ) {}

  public static parse(json: { [key: string]: any }): PreTestQuestion {
    const question = new PreTestQuestion(
      json['id'],
      json['question'],
      json['choices'].map((c: PreTestQuestionChoice) => PreTestQuestionChoice.parse(c))
    );
    return question;
  }
}

export class PreTestQuestionSet {
  constructor(
    public id: string,
    /**
     * Total number of question set the test will give to the user.
     */
    public totalSet: number,
    /**
     * number of the current question set
     */
    public setNo: number,
    public questions: PreTestQuestion[]
  ) {}

  public static parse(json: { [key: string]: any }): PreTestQuestionSet {
    const questionSet = new PreTestQuestionSet(
      json['id'],
      json['totalSet'],
      json['setNo'],
      json['questions'].map((q: PreTestQuestion) => PreTestQuestion.parse(q))
    );
    return questionSet;
  }
}

export class PreTestQuestionAnswer {
  constructor(public questionId: string, public choiceId: string) {}

  public toJson(): any {
    const questionAnswer = {
      questionId: this.questionId,
      choiceId: this.choiceId,
    };

    return questionAnswer;
  }
}

const getMockPreTestQuestion = (id: string) => {
  return new PreTestQuestion(
    id,
    `Question ${id} - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam`,
    [
      new PreTestQuestionChoice(`${id}-choice-1`, `${id}-choice-1`),
      new PreTestQuestionChoice(`${id}-choice-2`, `${id}-choice-2`),
      new PreTestQuestionChoice(`${id}-choice-3`, `${id}-choice-3`),
      new PreTestQuestionChoice(`${id}-choice-4`, `${id}-choice-4`),
    ]
  );
};

export const getMockPreTestQuestionSet = (id: string, setNo: number) => {
  return new PreTestQuestionSet(`${id}`, 2, setNo, [
    getMockPreTestQuestion(`1-${id}`),
    getMockPreTestQuestion(`2-${id}`),
    getMockPreTestQuestion(`3-${id}`),
    getMockPreTestQuestion(`4-${id}`),
    getMockPreTestQuestion(`5-${id}`),
  ]);
};
