import { FC, PropsWithChildren } from 'react';
import { ResultCategoryName } from '../types/report';
import MainLayout from '../layouts/MainLayout';
import BlankLayout from '../layouts/BlankLayout';
import PrintLayout from '../layouts/PrintLayout';
import Home from '../routes/Home/Home';
import Error from '../routes/Error';
import ReportDetail from '../routes/ReportDetail';
import ReportDetailCategory from '../routes/ReportDetailCategory';
import SchoolHome from '../routes/SchoolHome/SchoolHome';
import SchoolReports from '../routes/internalAdminApp/SchoolReports';
import SchoolReportsPrint from '../routes//internalAdminApp/SchoolReportsPrint';
import { SummaryReportTemplate } from '../components/SummaryReportPrint';
import * as AssessmentApp from '../routes/assessmentApp';
import * as internalAdminApp from '../routes/internalAdminApp';
import * as ReportApp from '../routes/reportApp';
import AssessmentLayout from '../layouts/AssessmentLayout';
import ReportAppLayout from '../layouts/ReportAppLayout';
import {
  ASSESSMENT_APP_BASE,
  ASSESSMENT_APP_TEST_SESSION_BASE,
  INTERNAL_APP_BASE,
  NEW_REPORT_APP_BASE,
  REPORT_APP_BASE,
} from './constants';
import { createQueryString } from '../utils/url';

export type RouteData = {
  key?: string;
  path: string;
  component: FC;
  errorComponent?: FC;
  layoutComponent?: FC<PropsWithChildren>;
  layoutComponentProps?: Record<string, any>;
  getRoute: (params?: any) => string;
  children?: RouteData[];
};

enum AssessmentRouteKeys {
  Home = 'AssessmentAppHome',
  Registration = 'AssessmentAppRegistration',
  Instruction = 'AssessmentAppInstruction',
  PreTestInstruction = 'AssessmentAppPreTestInstruction',
  PreTestQuiz = 'AssessmentAppPreTestQuiz',
  SpeakingTestInstruction = 'AssessmentAppSpeakingTestInstruction',
  SpeakingTestMicrophoneTesting = 'AssessmentAppSpeakingTestTestMic',
  SpeakingTestMicrophoneTestingInstruction = 'AssessmentAppSpeakingTestTestMicInstruction',
  SpeakingTestQuiz = 'AssessmentAppSpeakingTestQuiz',
  SpeakingTestQuizInstruction = 'AssessmentAppSpeakingTestQuizInstruction',
  SpeakingTestFinishPage = 'AssessmentAppSpeakingTestFinishPage',
  TestFeature = 'AssessmentAppTestFeature',
}

/**
 * TODO: Remove whole ReportRouteKeys and routes object in favor of
 * ReportAppRouteKeys and reportAppRoutes object
 */
enum ReportRouteKeys {
  Home = 'Home',
  ReportDetail = 'ReportDetail',
  ReportDetailCategory = 'ReportDetailCategory',
  SchoolHome = 'SchoolHome',
  SchoolReports = 'SchoolReports',
  SchoolReportsPrint = 'SchoolReportsPrint',
}

enum ReportAppRouteKeys {
  ReportDetail = 'ReportDetail',
  ReportDetailCategory = 'ReportDetailCategory',
}

/**
 * RoutesKey for internal admin pages
 */
enum TestRouteKeys {
  SpeakingTestQuiz = 'TestSpeakingTestQuiz',
  AllSpeakingTestQuiz = 'TestAllSpeakingTestQuiz',
}

enum internalAdminRouteKeys {
  CodeGenerate = 'CodeGenerate',
  AccessCodeStatus = 'AccessCodeStatus',
  Home = 'Home',
  Testers = 'Testers',
  Schools = 'Schools',
  SchoolReports = 'InternalAdminSchoolReports',
  SchoolAllReportsPrint = 'InternalAdminSchoolAllReportsPrint',
  AccessCodeList = 'AccessCodeList',
  AllAccessCodeStatus = 'AllAccessCodeStatus',
  SpeakingQuestions = 'SpeakingQuestions',
  SpeakingTests = 'SpeakingTests',
}

export const testRoutes: { [key in TestRouteKeys]: RouteData } = {
  [TestRouteKeys.SpeakingTestQuiz]: {
    path: `${INTERNAL_APP_BASE}/speaking-tests/:speakingTestId`,
    component: AssessmentApp.SpeakingTestQuizForTest,
    layoutComponent: AssessmentLayout,
    errorComponent: Error,
    getRoute: (speakingTestId: string) => `/_internal_/speaking-tests/${speakingTestId}`,
  },
  [TestRouteKeys.AllSpeakingTestQuiz]: {
    path: `${INTERNAL_APP_BASE}/speaking-tests-test`,
    component: AssessmentApp.AllSpeakingTestQuizForTest,
    layoutComponent: AssessmentLayout,
    errorComponent: Error,
    getRoute: () => `/_internal_/speaking-tests-test`,
  },
};

export const internalAdminRoute: { [key in internalAdminRouteKeys]: RouteData } = {
  [internalAdminRouteKeys.Home]: {
    path: ``,
    component: internalAdminApp.Home,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}`,
  },
  [internalAdminRouteKeys.CodeGenerate]: {
    path: `codegen`,
    component: internalAdminApp.CodeGenerate,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/codegen`,
  },
  [internalAdminRouteKeys.AccessCodeStatus]: {
    path: `code-status`,
    component: internalAdminApp.AccessCodeStatus,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/code-status`,
  },
  [internalAdminRouteKeys.Testers]: {
    path: `testers`,
    component: internalAdminApp.Testers,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/testers`,
  },
  [internalAdminRouteKeys.Schools]: {
    path: `schools`,
    component: internalAdminApp.Schools,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/schools`,
  },
  [internalAdminRouteKeys.SchoolReports]: {
    path: `${INTERNAL_APP_BASE}/schools/:schoolId/reports`,
    component: SchoolReports,
    layoutComponent: PrintLayout,
    errorComponent: Error,
    getRoute: (schoolId: string) => `${INTERNAL_APP_BASE}/schools/${schoolId}/reports`,
  },
  [internalAdminRouteKeys.SchoolAllReportsPrint]: {
    path: `${INTERNAL_APP_BASE}/schools/:schoolId/reports/print`,
    component: SchoolReportsPrint,
    layoutComponent: PrintLayout,
    errorComponent: Error,
    getRoute: ({
      schoolId,
      templateType,
    }: {
      schoolId: string;
      templateType: SummaryReportTemplate;
    }) => {
      return `${INTERNAL_APP_BASE}/schools/${schoolId}/reports/print?templateType=${templateType}`;
    },
  },
  [internalAdminRouteKeys.AccessCodeList]: {
    path: `access-codes`,
    component: internalAdminApp.AccessCodeList,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/access-codes`,
  },
  [internalAdminRouteKeys.AllAccessCodeStatus]: {
    path: `all-status`,
    component: internalAdminApp.AllAccessCodeStatus,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/all-status`,
  },

  [internalAdminRouteKeys.SpeakingQuestions]: {
    path: `speaking-questions`,
    component: internalAdminApp.SpeakingQuestions,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/speaking-questions`,
  },
  [internalAdminRouteKeys.SpeakingTests]: {
    path: `speaking-tests`,
    component: internalAdminApp.SpeakingTests,
    errorComponent: Error,
    getRoute: () => `${INTERNAL_APP_BASE}/speaking-tests`,
  },
};

export const routes: { [key: string]: RouteData } = {
  [ReportRouteKeys.Home]: {
    path: '',
    component: Home,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: () => `${REPORT_APP_BASE}`,
  },
  [ReportRouteKeys.ReportDetail]: {
    path: ':testId',
    component: ReportDetail,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: (testId: string) => `${REPORT_APP_BASE}/${testId}`,
  },
  [ReportRouteKeys.ReportDetailCategory]: {
    path: ':testId/category',
    component: ReportDetailCategory,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: ({ testId, category }: { testId: string; category: ResultCategoryName }) => {
      return `${REPORT_APP_BASE}/${testId}/category/${category}`;
    },
  },
  [ReportRouteKeys.SchoolHome]: {
    path: 'schools',
    component: SchoolHome,
    layoutComponent: MainLayout,
    errorComponent: Error,
    getRoute: () => `${REPORT_APP_BASE}/schools`,
  },
};

export const reportAppRoutes: { [key: string]: RouteData } = {
  [ReportAppRouteKeys.ReportDetail]: {
    key: ReportAppRouteKeys.ReportDetail,
    path: ':testId',
    component: ReportApp.ReportDetail,
    layoutComponent: ReportAppLayout,
    errorComponent: Error,
    getRoute: ({ testId }: { testId: string }) => `${NEW_REPORT_APP_BASE}/${testId}`,
  },
  [ReportAppRouteKeys.ReportDetailCategory]: {
    key: ReportAppRouteKeys.ReportDetailCategory,
    path: ':testId/category',
    component: ReportApp.ReportDetailCategory,
    layoutComponent: ReportAppLayout,
    layoutComponentProps: { showBackToHomeOnMobile: true },
    errorComponent: Error,
    getRoute: ({ testId, category }: { testId: string; category: string }) => {
      return `${NEW_REPORT_APP_BASE}/${testId}/category/${category}`;
    },
    children: [
      {
        path: ':category',
        component: ReportApp.ReportDetailCategoryTabPanel,
      },
    ],
  } as any,
};

/**
 * All assessment app routes should be a subroute of the Assessment App
 */
export const assessmentAppRoutes: { [key in AssessmentRouteKeys]: RouteData } = {
  [AssessmentRouteKeys.Home]: {
    path: '',
    component: AssessmentApp.Home,
    errorComponent: Error,
    getRoute: () => {
      return '/';
    },
  },
  [AssessmentRouteKeys.Registration]: {
    path: 'registration',
    component: AssessmentApp.Registration,
    errorComponent: Error,
    getRoute: (accessCode: string) => {
      return `${ASSESSMENT_APP_BASE}/registration?accessCode=${accessCode}`;
    },
  },
  [AssessmentRouteKeys.Instruction]: {
    path: 'instruction',
    component: AssessmentApp.Instruction,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/instruction`;
    },
  },
  [AssessmentRouteKeys.PreTestInstruction]: {
    path: 'pre-test/instruction',
    component: AssessmentApp.PreTestInstruction,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/pre-test/instruction`;
    },
  },
  [AssessmentRouteKeys.PreTestQuiz]: {
    path: 'pre-test/quiz',
    component: AssessmentApp.PreTestQuiz,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/pre-test/quiz`;
    },
  },
  [AssessmentRouteKeys.SpeakingTestInstruction]: {
    path: 'speaking-test-instruction',
    component: AssessmentApp.SpeakingTestInstruction,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/speaking-test-instruction`;
    },
  },
  [AssessmentRouteKeys.SpeakingTestMicrophoneTesting]: {
    path: 'speaking-test-test-microphone',
    component: AssessmentApp.TestMic,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/speaking-test-test-microphone`;
    },
  },
  [AssessmentRouteKeys.SpeakingTestMicrophoneTestingInstruction]: {
    path: 'speaking-test-test-microphone-instruction',
    component: AssessmentApp.TestMicInstruction,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/speaking-test-test-microphone-instruction`;
    },
  },
  [AssessmentRouteKeys.SpeakingTestQuiz]: {
    path: 'speaking-tests/quiz',
    component: AssessmentApp.SpeakingTestQuiz,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/speaking-tests/quiz`;
    },
  },
  [AssessmentRouteKeys.SpeakingTestQuizInstruction]: {
    path: 'speaking-tests/instruction',
    component: AssessmentApp.SpeakingTestQuizInstruction,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/speaking-tests/instruction`;
    },
  },
  [AssessmentRouteKeys.SpeakingTestFinishPage]: {
    path: 'finish',
    component: AssessmentApp.FinishPage,
    errorComponent: Error,
    getRoute: ({ beta, testId }: { beta?: boolean; testId?: string }) => {
      const params = {
        beta: beta ? beta.toString() : undefined,
        testId: testId,
      };
      const queryString = createQueryString(params);
      return `${ASSESSMENT_APP_BASE}/finish?${queryString}`;
    },
  },
  [AssessmentRouteKeys.TestFeature]: {
    path: 'test-feature',
    component: AssessmentApp.TestFeature,
    errorComponent: Error,
    getRoute: () => {
      return `${ASSESSMENT_APP_TEST_SESSION_BASE}/test-feature`;
    },
  },
};

export default routes;
