import { Portal } from '@mui/base';
import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import Modal, { ModalProps } from '../../../../components/base/Modal';
import StarsRating from '../../../../components/StarsRating';
import { StarsCount } from '../../../../types/starsRating';
import { starsCountToText } from '../../../../utils';

interface Props extends ModalProps {}

const EdSpeakStarsRatingModal: FC<Props> = ({ ...props }) => {
  const { t } = useTranslation('report');
  const { t: tStarsRatingLabel } = useTranslation('common', { keyPrefix: 'stars_rating_label' });
  const noDataText = t('no_data', { ns: 'common' });

  useEffect(() => {
    if (props.open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [props.open]);

  return (
    <Portal>
      <Modal {...props} size="lg">
        <div className="flex justify-end mb-4 sm:mb-0">
          <Button variant="flat" onClick={props.onClose}>
            <Icon icon={IconName.Close} className="text-black" />
          </Button>
        </div>
        <div className="sm:px-12 sm:py-7">
          <Heading variant="h1" className="text-primary-500 text-center">
            {t('report_detail_edspeak_stars_rating_modal_title')}
          </Heading>
          <div className="mt-6 max-w-[244px] mx-auto flex flex-col items-center gap-y-3">
            <div className="w-full flex justify-between items-center">
              <div>
                <Text className="font-semibold">{tStarsRatingLabel('zero')}</Text>
                <Text variant="sm" className="text-grey-500">
                  0 {t('star_unit', { count: 0, ns: 'common' })} / {noDataText}
                </Text>
              </div>
              <StarsRating score={0} fullScore={5} starClassName="w-4" />
            </div>
            {[1, 2, 3, 4, 5].map((starsCount) => {
              const starsCountString = starsCountToText(starsCount as StarsCount);
              return (
                <Fragment key={starsCount}>
                  <hr className="w-full" />
                  <div className="w-full flex justify-between items-center">
                    <div>
                      <Text className="font-semibold">{tStarsRatingLabel(starsCountString)}</Text>
                      <Text variant="sm" className="text-grey-500">
                        {starsCount - 0.5} - {starsCount} {/* // TODO: check plurals unit */}
                        {t('star_unit', { count: starsCount, ns: 'common' })}
                      </Text>
                    </div>
                    <div>
                      <StarsRating score={starsCount - 0.5} fullScore={5} starClassName="w-4" />
                      <StarsRating
                        score={starsCount}
                        fullScore={5}
                        starClassName="w-4"
                        className="mt-2"
                      />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

export default EdSpeakStarsRatingModal;
