import { Tester, TesterType } from '../../../../model/tester';
import style from '../CodeGenerate.module.css';

interface CreatingTestersTableProps {
  testers: Tester[];
  type: TesterType;
}

const CreatingTestersTable = ({ testers, type }: CreatingTestersTableProps) => {
  const columnOptions = {
    [TesterType.Student]: ['#', 'Prefix', 'Firstname', 'Lastname', 'Grade', 'Room'],
    [TesterType.Teacher]: ['#', 'Prefix', 'Firstname', 'Lastname', 'Email', 'Phone'],
    [TesterType.BusinessEmployee]: [
      '#',
      'Prefix',
      'Firstname',
      'Lastname',
      'Email',
      'Phone',
      'Organization',
    ],
  };

  const headerTable = columnOptions[type];

  return (
    <table className={style.table}>
      <thead>
        <tr>
          {headerTable.map((h, i) => (
            <th key={i}>{h}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {testers.map((t, i) => (
          <tr key={i}>
            <td>{i}</td>
            <td>{t.prefix}</td>
            <td>{t.firstName}</td>
            <td>{t.lastName}</td>
            <td>{type === TesterType.Student ? t.grade : t.email}</td>
            <td>{type === TesterType.Student ? t.room : t.phoneNo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CreatingTestersTable;
