import { Heading, Image } from './base';

const InstructionContentCardV2 = ({
  imageUrl,
  title,
}: {
  imageUrl: string;
  title: string;
}) => {
  return (
    <div className="px-4 md:p-6 flex flex-row-reverse md:flex-col items-center basis-0 grow gap-6 md:gap-0">
      <Heading variant="h4" className="text-primary-500 text-left md:text-center w-full">
        {title}
      </Heading>
      <Image src={imageUrl} alt={title} className=" w-20 md:w-full max-w-[200px]" />
    </div>
  );
};

export default InstructionContentCardV2;
