import { Image, Text } from '../base';
import { toClassNames } from '../../utils';
import style from './RecordingButton.module.css';

export enum RecordingButtonVariant {
  DEFAULT = 'DEFAULT',
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
  WARNING = 'WARNING',
}

interface RecordingButtonProps {
  variant: RecordingButtonVariant;
  primaryLabel?: string;
  secondaryLabel?: string;
  onClick?: () => void;
  disabled?: boolean;
  // Prevent click - E.g. prevent user from clicking when recording and stop recording by timer instead.
  preventClick?: boolean;
}

const variantData = {
  [RecordingButtonVariant.DEFAULT]: {
    className: 'default',
    defaultPrimaryLabel: 'กดปุ่มบันทึกเสียงเมื่อพร้อม',
  },
  [RecordingButtonVariant.ACTIVE]: {
    className: 'active',
    defaultPrimaryLabel: 'กำลังบันทึกเสียง..',
  },
  [RecordingButtonVariant.DONE]: {
    className: 'done',
    defaultPrimaryLabel: 'บันทึกแล้ว',
  },
  [RecordingButtonVariant.WARNING]: {
    className: 'warning',
    defaultPrimaryLabel: '',
  },
};

const RecordingButton = ({
  variant,
  primaryLabel,
  secondaryLabel,
  onClick,
  disabled,
  preventClick,
}: RecordingButtonProps) => {
  const handleClick = () => {
    if (!preventClick && onClick) {
      onClick();
    }
  };
  return (
    <div className="max-w-[210px] flex flex-col items-center">
      <div
        onClick={handleClick}
        className={toClassNames([
          style['recording-button'],
          style[variantData[variant].className],
          disabled ? style.disabled : '',
          preventClick ? '!cursor-default' : '',
          preventClick ? style['no-hover'] : '',
        ])}
      ></div>
      <Text variant="md" className="text-grey-600 text-center">
        {primaryLabel === undefined ? variantData[variant].defaultPrimaryLabel : primaryLabel}
      </Text>
      <Text
        variant="sm"
        className={toClassNames([
          variant === RecordingButtonVariant.ACTIVE ? 'text-green-500' : 'text-grey-400',
          'text-center',
        ])}
      >
        {secondaryLabel}
      </Text>
    </div>
  );
};

export default RecordingButton;
