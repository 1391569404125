import { getRequest, postRequest } from './utils';
import { BASE_URL } from './constant';
import { CEFRLevel } from '../types/cefr';
import {
  SpeakingTestQuestion,
  SpeakingTestQuestionPromptContentType,
  SpeakingTestQuestionType,
} from '../model/speakingTestQuestion';
import { SpeakingTest } from '../model/speakingTest';

export const ADMIN_API_PATH_PREFIX = '/admin';

export const getAllSpeakingQuestions = async (): Promise<SpeakingTestQuestion[]> => {
  try {
    const fetchUrl = new URL(`/speaking-questions`, BASE_URL);
    const data = (await getRequest(fetchUrl)) ?? [];
    const parsedData = data.map((d: any) => SpeakingTestQuestion.parse(d));
    return parsedData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllSpeakingTests = async (): Promise<SpeakingTest[]> => {
  try {
    const fetchUrl = new URL(`/speaking-tests`, BASE_URL);
    const data = (await getRequest(fetchUrl)) ?? [];
    const parsedData = data.map((d: any): SpeakingTest => SpeakingTest.parse(d));

    return parsedData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export interface CreateSpeakingTestQuestionPromptParams {
  type: SpeakingTestQuestionPromptContentType;
  content: string;
}

export interface CreateSpeakingTestQuestionParams {
  type: SpeakingTestQuestionType;
  prompts: CreateSpeakingTestQuestionPromptParams[];
  timeLimit: number;
  preparationTimeLimit: number;
  cefrLevel: CEFRLevel;
  isPrivate: boolean;
}

export interface CreateSpeakingTestWithQuestionsParams {
  name: string;
  questions: CreateSpeakingTestQuestionParams[];
}

export const createSpeakingTestWithQuestions = async (
  params: CreateSpeakingTestWithQuestionsParams
) => {
  try {
    const fetchUrl = new URL(`/speaking-tests-with-questions`, BASE_URL);
    const data = await postRequest(fetchUrl, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        name: params.name,
        questions: params.questions.map((q) => {
          return {
            ...q,
            cefrLevel: q.cefrLevel === CEFRLevel.PreA1 ? 'PRE-A1' : q.cefrLevel,
          };
        }),
      }),
    });

    return SpeakingTest.parse(data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
