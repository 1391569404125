import { useTranslation } from 'react-i18next';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import { Button, Heading, Image, Text } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { InstructionData } from './data';
import { IconName } from '../../../components/base/Icon';
import IconTimer from './components/IconTimer';

interface SpeakingTestQuizInstructionProps {
  questionNumber: number;
  totalQuestion: number;
  instructionData: InstructionData;
  onNext: () => void;
  partNo: string;
}

const SpeakingTestQuizInstruction = ({
  questionNumber,
  totalQuestion,
  instructionData,
  onNext,
  partNo,
}: SpeakingTestQuizInstructionProps) => {
  const { t } = useTranslation('assessment');
  const { title, subtitle, imageUrl, instruction1, instruction2, preparationTimeLimit, timeLimit } =
    instructionData;
  return (
    <LayoutCard className="my-10 flex flex-col items-center">
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        title={''}
        subtitle={t('assessment_speaking_test_quiz_instruction_card_header_title', { no: partNo })}
        className=""
      />
      <Heading variant="h5" is="h5" className="text-center mb-6 font-bold">
        {t('assessment_speaking_test_quiz_instruction_heading')} {questionNumber}{' '}
        {t('assessment_speaking_test_quiz_instruction_heading_question_of')} {totalQuestion}
      </Heading>
      <div className="rounded-[30px] border-solid border-2 border-primary-300 bg-primary-50 drop-shadow-md px-6 md:px-16 py-12 mb-6">
        <Heading variant="h1" is="h1" className="text-primary-500 text-center">
          {title}
        </Heading>
        <Text variant="lg" className="text-center mb-6">
          {subtitle}
        </Text>
        <Image src={imageUrl} alt={subtitle} className="w-[180px] mb-6 mx-auto bg-primary-50" />
        <div className="text-center justify-center mx-auto flex flex-row divide-x-2 divide-primary-300 w-full">
          <IconTimer
            title={t('assessment_speaking_test_quiz_instruction_preparation_time')}
            time={`${preparationTimeLimit} ${t(
              'assessment_speaking_test_quiz_instruction_seconds'
            )}`}
            icon={IconName.Book}
          />
          <IconTimer
            title={t('assessment_speaking_test_quiz_instruction_answer_time')}
            time={`${timeLimit} ${t('assessment_speaking_test_quiz_instruction_seconds')}`}
            icon={IconName.Timer}
          />
        </div>
      </div>
      <ul className="list-disc text-gray-600 mb-6 mx-auto">
        <li>{instruction1}</li>
        {instruction2 && <li>{instruction2}</li>}
      </ul>
      <Button variant="primary" onClick={onNext}>
        {t('assessment_speaking_test_quiz_instruction_button_on_next', {
          question: questionNumber.toString(),
        })}
      </Button>
    </LayoutCard>
  );
};

export default SpeakingTestQuizInstruction;
