import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, PieLabel, ResponsiveContainer } from 'recharts';
import { Heading, Text } from '../../../../components/base';
import StarsRating from '../../../../components/StarsRating';
import { CEFRLevel } from '../../../../types/cefr';
import {
  ResultCategoryName,
  VocabularyResult,
  VocabularySubCategoryName,
} from '../../../../types/report';
import { VocabularyStatistic } from '../../../../types/reportStatistic';
import { scoreToStarCount, starsCountToText, toClassNames } from '../../../../utils';
import CategoryEmptyState from './CategoryEmptyState';
import EdSpeakSuggestions from './EdSpeakSuggestions';
import HowWeMeasureMetrics from './HowWeMeasureMetrics';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = (outerRadius + innerRadius) / 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize={17}
      fontWeight={600}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {percent > 0 ? `${(percent * 100).toFixed(0)}%` : ''}
    </text>
  );
};

type LowercaseCEFRLevel = Lowercase<CEFRLevel>;
const basicCEFRLevels: Array<LowercaseCEFRLevel> = ['pre a1', 'a1'];
const intermediateCEFRLevels: Array<LowercaseCEFRLevel> = ['a2'];
const advancedCEFRLevels: Array<LowercaseCEFRLevel> = ['b1', 'b2', 'c1', 'c2'];

const vocabularyLevels = [
  {
    name: 'basic',
    cefrLevels: basicCEFRLevels,
    color: '#2A59DA',
    bgClass: 'bg-primary-500',
    labelTranslationKey: 'report_detail_category_vocabulary_level_group_label_basic',
  },
  {
    name: 'intermediate',
    cefrLevels: intermediateCEFRLevels,
    color: '#AF7AFF',
    bgClass: 'bg-secondary-500',
    labelTranslationKey: 'report_detail_category_vocabulary_level_group_label_intermediate',
  },
  {
    name: 'advanced',
    cefrLevels: advancedCEFRLevels,
    color: '#08112B',
    bgClass: 'bg-primary-900',
    labelTranslationKey: 'report_detail_category_vocabulary_level_group_label_advanced',
  },
] as const;
interface Props {
  vocabularyResult: VocabularyResult;
  vocabularyStatistic: VocabularyStatistic;
}

const VocabularyTabPanel: FC<Props> = ({ vocabularyResult, vocabularyStatistic }) => {
  const { t } = useTranslation('report');

  const vocabularyLevelResult = vocabularyResult[VocabularySubCategoryName.VocabularyLevel];
  const starsCount = scoreToStarCount(vocabularyResult.score);
  const starsCountString = starsCountToText(starsCount);

  const chartData = useMemo(() => {
    return vocabularyLevels
      .slice()
      .reverse()
      .map((level) => {
        return {
          name: level.name,
          value: vocabularyLevelResult.distribution
            .filter((d) => level.cefrLevels.includes(d.key as LowercaseCEFRLevel))
            .reduce((acc, cur) => acc + cur.value, 0),
          color: level.color,
        };
      });
  }, [vocabularyLevelResult]);

  const advancedLevelPercentage = useMemo(() => {
    const total = vocabularyLevelResult.distribution.reduce((acc, cur) => acc + cur.value, 0);
    const advancedLevel = vocabularyLevelResult.distribution
      .filter((d) => advancedCEFRLevels.includes(d.key as LowercaseCEFRLevel))
      .reduce((acc, cur) => acc + cur.value, 0);
    return (advancedLevel / total) * 100;
  }, [vocabularyLevelResult]);

  if (vocabularyResult.score <= 0) {
    return <CategoryEmptyState category={ResultCategoryName.Vocabulary} />;
  }

  return (
    <div className="bg-white rounded-3xl px-4 py-7 sm:p-0">
      <div className="flex flex-col items-center gap-y-3">
        <Heading variant="h1" className="sm:hidden text-gradient-gd-1">
          Vocabulary
        </Heading>
        <Text className="text-grey-600">{t('report_detail_category_vocabulary_tab_subtitle')}</Text>
        <StarsRating score={starsCount} fullScore={5} className="my-2" />
        <Text variant="2xl" className="text-gradient-gd-1 font-bold text-center mb-2">
          {t(`stars_rating_label.${starsCountString}`, { ns: 'common' })}
        </Text>
        <Text className="text-grey-600 max-w-xs text-center">
          {t(`report_detail_category_vocabulary_stars_rating_description.${starsCountString}`)}
        </Text>
      </div>

      <div className="w-[310px] max-w-xs aspect-square mt-8 mx-auto relative isolate grid place-content-center">
        <ResponsiveContainer width="100%" height="100%" className="absolute">
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              outerRadius={150}
              innerRadius={100}
              startAngle={90}
              endAngle={-270}
              paddingAngle={2}
              cornerRadius={12}
              label={renderCustomizedLabel}
              labelLine={false}
              isAnimationActive={false}
            >
              {chartData.map((d, index) => (
                <Cell key={index} fill={d.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="text-center z-10">
          <Text variant="4xl" className="font-semibold text-grey-900">
            {advancedLevelPercentage.toFixed(0)}%
          </Text>
          <Text variant="md" className="text-grey-500 mt-1 whitespace-pre-line">
            {t('report_detail_category_vocabulary_is_advanced_vocabulary')}
          </Text>
        </div>
      </div>
      <div className="mt-6 flex justify-center flex-wrap gap-y-4 gap-x-6">
        {vocabularyLevels.map((level, index) => {
          return (
            <div key={index} className="flex gap-x-2  items-center">
              <div className={toClassNames(['w-3 h-3 rounded-full', level.bgClass])} />
              <Text>{t(level.labelTranslationKey)}</Text>
            </div>
          );
        })}
      </div>

      <HowWeMeasureMetrics category={ResultCategoryName.Vocabulary} className="mt-7 sm:mt-11" />
      <EdSpeakSuggestions
        category={ResultCategoryName.Vocabulary}
        starsCount={starsCount}
        className="mt-7 sm:mt-11"
      />
    </div>
  );
};

export default VocabularyTabPanel;
