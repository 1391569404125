import { ResultCategoryName } from '../../types/report';
import { scoreToStarCount } from '../../utils';

export const getAnalysisText = (score: number, category: ResultCategoryName) => {
  const starCount = scoreToStarCount(score);
  switch (category) {
    case ResultCategoryName.Fluency:
      return fluencyAnalysisText[starCount.toString()];
    case ResultCategoryName.Vocabulary:
      return vocabularyAnalysisText[starCount.toString()];
    case ResultCategoryName.Pronunciation:
      return pronunciationAnalysisText[starCount.toString()];
    default:
      return '';
  }
};

/**
 * All text below used only with old full report.
 */
export const fluencyAnalysisText: { [key: string]: string } = {
  '0': 'เราไม่สามารถวิเคราะห์ความคล่องในการพูดของคุณได้ เพราะคุณอาจจะตอบคำถามบางข้อสั้นเกินไป หรือไม่ได้ตอบอย่างครบถ้วน เป็นไปได้ว่าคุณอาจจะนึกคำพูดไม่ได้ หรือเรียงประโยคพูดไม่ถูก',
  '0.5': `ระดับความคล่องในการพูดของคุณ อยู่ในระดับฝึกฝน (WPM: น้อยกว่า 70 คำต่อนาที) คุณสามารถพูดเป็นกลุ่มคำหรือประโยคสั้นๆ แต่ยังขาดความต่อเนื่อง ความสมบูรณ์ หรือความถูกต้อง จึงทำให้การสื่อสารยังขาดความเป็นธรรมชาติในบางครั้ง \n คุณสามารถพัฒนาโดยการฝึกอ่านออกเสียงหรือฝึกพูดโต้ตอบภาษาอังกฤษอย่างเป็นประจำ (เช่น ฝึกทุกวัน วันละ 15 นาที) เพื่อเพิ่มความคุ้นชินในการพูด และซึมซัมรูปประโยคต่างๆ การฝึกฟังภาษาอังกฤษบ่อยๆ เช่น การฟัง Podcast หรือการร่วมกิจกรรมที่เปิดโอกาสให้ได้ฝึกใช้ภาษาในสภาพแวดล้อมที่เป็นธรรมชาติ เข่น การเข้าค่ายภาษาอังกฤษหรือการเดินทางไปแลกเปลี่ยน`,
  '1': `ระดับความคล่องในการพูดของคุณ อยู่ในระดับฝึกฝน (WPM: น้อยกว่า 70 คำต่อนาที) คุณสามารถพูดเป็นกลุ่มคำหรือประโยคสั้นๆ แต่ยังขาดความต่อเนื่อง ความสมบูรณ์ หรือความถูกต้อง จึงทำให้การสื่อสารยังขาดความเป็นธรรมชาติในบางครั้ง \n คุณสามารถพัฒนาโดยการฝึกอ่านออกเสียงหรือฝึกพูดโต้ตอบภาษาอังกฤษอย่างเป็นประจำ (เช่น ฝึกทุกวัน วันละ 15 นาที) เพื่อเพิ่มความคุ้นชินในการพูด และซึมซัมรูปประโยคต่างๆ การฝึกฟังภาษาอังกฤษบ่อยๆ เช่น การฟัง Podcast หรือการร่วมกิจกรรมที่เปิดโอกาสให้ได้ฝึกใช้ภาษาในสภาพแวดล้อมที่เป็นธรรมชาติ เข่น การเข้าค่ายภาษาอังกฤษหรือการเดินทางไปแลกเปลี่ยน`,
  '1.5': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับพอใช้ (WPM: 70 - 89 คำต่อนาที) คุณสามารถพูดเป็นประโยคที่สมบูรณ์ได้บ้าง จึงเริ่มสามารถสื่อสารได้อย่างเป็นธรรมชาติ แต่ยังขาดความต่อเนื่อง ความสมบูรณ์ หรือความถูกต้องในบางครั้ง \n คุณสามารถพัฒนาโดยการฝึกพูดโต้ตอบภาษาอังกฤษอย่างเป็นประจำ (เช่น ฝึกทุกวัน วันละ 15 นาที) การเลือกรับชมสื่อต่างๆ ในรูปแบบภาษาอังกฤษเพื่อซึมซัมรูปประโยคใหม่ๆ รวมถึงรูปแบบการใช้คำและไวยกรณ์ที่มีความซับซ้อนมากขึ้น และการร่วมกิจกรรมที่เปิดโอกาสให้ได้ฝึกใช้ภาษาในสภาพแวดล้อมที่เป็นธรรมชาติ เข่น การร่วมโครงการแลกเปลี่ยน`,
  '2': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับพอใช้ (WPM: 70 - 89 คำต่อนาที) คุณสามารถพูดเป็นประโยคที่สมบูรณ์ได้บ้าง จึงเริ่มสามารถสื่อสารได้อย่างเป็นธรรมชาติ แต่ยังขาดความต่อเนื่อง ความสมบูรณ์ หรือความถูกต้องในบางครั้ง \n คุณสามารถพัฒนาโดยการฝึกพูดโต้ตอบภาษาอังกฤษอย่างเป็นประจำ (เช่น ฝึกทุกวัน วันละ 15 นาที) การเลือกรับชมสื่อต่างๆ ในรูปแบบภาษาอังกฤษเพื่อซึมซัมรูปประโยคใหม่ๆ รวมถึงรูปแบบการใช้คำและไวยกรณ์ที่มีความซับซ้อนมากขึ้น และการร่วมกิจกรรมที่เปิดโอกาสให้ได้ฝึกใช้ภาษาในสภาพแวดล้อมที่เป็นธรรมชาติ เข่น การร่วมโครงการแลกเปลี่ยน `,
  '2.5': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับดี (WPM: 90 - 109 คำต่อนาที) คุณสามารถพูดได้อย่างเป็นธรรมชาติ ด้วยรูปประโยคที่เริ่มมีความต่อเนื่อง สมบูรณ์ และซับซ้อนขึ้น \n นอกจากการฝึกพูดโต้ตอบภาษาอังกฤษอย่างเป็นประจำแล้ว คุณสามารถพัฒนาเพิ่มเติมด้วยการเลือกรับชมสื่อต่างๆ รวมถึงการอ่านหนังสือ-บทความภาษาอังกฤษเพื่อเรียนรู้คำศัพท์และไวยกรณ์ที่มีความซับซ้อนมากขึ้น และการฝึกโต้ตอบผ่านกิจกรรมต่างๆ เช่น การอภิปรายกลุ่ม หรือ การโต้วาที`,
  '3': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับดี (WPM: 90 - 109 คำต่อนาที) คุณสามารถพูดได้อย่างเป็นธรรมชาติ ด้วยรูปประโยคที่เริ่มมีความต่อเนื่อง สมบูรณ์ และซับซ้อนขึ้น \n นอกจากการฝึกพูดโต้ตอบภาษาอังกฤษอย่างเป็นประจำแล้ว คุณสามารถพัฒนาเพิ่มเติมด้วยการเลือกรับชมสื่อต่างๆ รวมถึงการอ่านหนังสือ-บทความภาษาอังกฤษเพื่อเรียนรู้คำศัพท์และไวยกรณ์ที่มีความซับซ้อนมากขึ้น และการฝึกโต้ตอบผ่านกิจกรรมต่างๆ เช่น การอภิปรายกลุ่ม หรือ การโต้วาที`,
  '3.5': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับดีมาก (WPM: 110 - 134 คำต่อนาที) คุณสามารถพูดได้อย่างสมบูรณ์และคล่องอย่างเป็นธรรมชาติมาก คุณสามารถเลือกใช้คำศัพท์และรูปประโยคที่เหมาะสมและสื่อความหมายได้อย่างมีประสิทธิภาพ \n คุณสามารถสื่อสารภาษาอังกฤษได้เป็นอย่างดีมากแล้ว นอกจากการฝึกใช้อย่างเป็นประจำ คุณสามารถพัฒนาเพิ่มเติมด้วยการฝึกพูดสื่อสารในสถานการณ์ที่ท้าทายและหลากหลายมากขึ้น เช่น การร่วมวงเสวนาแลกเปลี่ยนความเห็น การฝึกเล่าเรื่องและนำเสนอผลงา`,
  '4': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับดีมาก (WPM: 110 - 134 คำต่อนาที) คุณสามารถพูดได้อย่างสมบูรณ์และคล่องอย่างเป็นธรรมชาติมาก คุณสามารถเลือกใช้คำศัพท์และรูปประโยคที่เหมาะสมและสื่อความหมายได้อย่างมีประสิทธิภาพ \n คุณสามารถสื่อสารภาษาอังกฤษได้เป็นอย่างดีมากแล้ว นอกจากการฝึกใช้อย่างเป็นประจำ คุณสามารถพัฒนาเพิ่มเติมด้วยการฝึกพูดสื่อสารในสถานการณ์ที่ท้าทายและหลากหลายมากขึ้น เช่น การร่วมวงเสวนาแลกเปลี่ยนความเห็น การฝึกเล่าเรื่องและนำเสนอผลงา`,
  '4.5': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับใกล้เคียง Native speakers (WPM: อย่างน้อย 135 คำต่อนาที) คุณสามารถพูดได้อย่างสมบูรณ์และเป็นธรรมชาติ คุณสามารถเลือกใช้คำศัพท์และรูปประโยคที่เหมาะสมและสื่อความหมายได้อย่างมีประสิทธิภาพสูงสุด \n คุณสามารถสื่อสารภาษาอังกฤษได้อย่างมั่นใจและสมบูรณ์ใกล้เคียงเจ้าของภาษา คุณสามารถพัฒนาต่อยอดด้วยการหมั่นใช้ภาษาอังกฤษอย่างเป็นประจำในสภาพแวดล้อมที่ใช้ภาษาอังกฤษอย่างเป็นธรรมชาติ (Authentic English environment) กับชาวต่างชาติเจ้าของภาษา เพื่อซึมซับเทคนิคการสื่อสารชั้นสูงต่าง`,
  '5': `ระดับความคล่องในการพูดของคุณอยู่ใน ระดับใกล้เคียง Native speakers (WPM: อย่างน้อย 135 คำต่อนาที) คุณสามารถพูดได้อย่างสมบูรณ์และเป็นธรรมชาติ คุณสามารถเลือกใช้คำศัพท์และรูปประโยคที่เหมาะสมและสื่อความหมายได้อย่างมีประสิทธิภาพสูงสุด \n คุณสามารถสื่อสารภาษาอังกฤษได้อย่างมั่นใจและสมบูรณ์ใกล้เคียงเจ้าของภาษา คุณสามารถพัฒนาต่อยอดด้วยการหมั่นใช้ภาษาอังกฤษอย่างเป็นประจำในสภาพแวดล้อมที่ใช้ภาษาอังกฤษอย่างเป็นธรรมชาติ (Authentic English environment) กับชาวต่างชาติเจ้าของภาษา เพื่อซึมซับเทคนิคการสื่อสารชั้นสูงต่าง`,
};

export const pronunciationAnalysisText: { [key: string]: string } = {
  '0': `เราไม่สามารถวิเคราะห์การออกเสียงคำศัพท์ของคุณได้ เพราะคุณอาจจะตอบคำถามบางข้อสั้นเกินไป หรือไม่ได้ตอบอย่างครบถ้วน เป็นไปได้ว่าคุณอาจจะยังอ่านคำภาษาอังกฤษบางคำไม่ได้`,
  '0.5': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับฝึกฝน โดยคุณออกเสียงได้ถูกต้อง น้อยกว่า ร้อยละ 40 ของเจ้าของภาษา คุณยังออกเสียงคำศัพท์ภาษาอังกฤษไม่ค่อยถูกต้อง เมื่อเทียบกับเจ้าของภาษา เพราะคุณอาจจะยังติดการออกเสียงในภาษาไทย \n คุณสามารถพัฒนาโดยการเริ่มต้นฟังการออกเสียงของเจ้าของภาษาให้มากที่สุดเท่าที่จะเป็นไปได้ ลองฝึกออกเสียงตาม และขอคำแนะนำจากครูเจ้าของภาษา เพื่อแก้ไขข้อผิดพลาดในการออกเสียงของคุณ`,
  '1': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับฝึกฝน โดยคุณออกเสียงได้ถูกต้อง น้อยกว่า ร้อยละ 40 ของเจ้าของภาษา คุณยังออกเสียงคำศัพท์ภาษาอังกฤษไม่ค่อยถูกต้อง เมื่อเทียบกับเจ้าของภาษา เพราะคุณอาจจะยังติดการออกเสียงในภาษาไทย \n คุณสามารถพัฒนาโดยการเริ่มต้นฟังการออกเสียงของเจ้าของภาษาให้มากที่สุดเท่าที่จะเป็นไปได้ ลองฝึกออกเสียงตาม และขอคำแนะนำจากครูเจ้าของภาษา เพื่อแก้ไขข้อผิดพลาดในการออกเสียงของคุณ`,
  '1.5': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับพอใช้ โดยคุณออกเสียงได้ถูกต้อง ร้อยละ 40 - 60 ของเจ้าของภาษา คุณมีความเข้าใจพื้นฐานในการออกเสียงภาษาอังกฤษ คุณสามารถออกเสียงขั้นพื้นฐานได้อย่างถูกต้อง แต่คุณอาจพบความยากลำบากกับเสียงที่ซับซ้อนมากขึ้น \n คุณสามารถพัฒนาโดยฝึกออกเสียงที่ท้าทายมากขึ้น จากการดูภาพยนตร์หรือรายการทีวีซึ่งเป็นภาษาอังกฤษและมีคำบรรยายประกอบ แล้วลองฝึกลอกการออกเสียงของนักแสดง รวมถึงการขอคำแนะนำจากครูเจ้าของภาษา เพื่อพัฒนาการออกเสียงของคุณ`,
  '2': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับพอใช้ โดยคุณออกเสียงได้ถูกต้อง ร้อยละ 40 - 60 ของเจ้าของภาษา คุณมีความเข้าใจพื้นฐานในการออกเสียงภาษาอังกฤษ คุณสามารถออกเสียงขั้นพื้นฐานได้อย่างถูกต้อง แต่คุณอาจพบความยากลำบากกับเสียงที่ซับซ้อนมากขึ้น \n คุณสามารถพัฒนาโดยฝึกออกเสียงที่ท้าทายมากขึ้น จากการดูภาพยนตร์หรือรายการทีวีซึ่งเป็นภาษาอังกฤษและมีคำบรรยายประกอบ แล้วลองฝึกลอกการออกเสียงของนักแสดง รวมถึงการขอคำแนะนำจากครูเจ้าของภาษา เพื่อพัฒนาการออกเสียงของคุณ`,
  '2.5': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับดี โดยคุณออกเสียงได้ถูกต้อง ร้อยละ 60 - 77.5 ของเจ้าของภาษา คุณมีพื้นฐานในการออกเสียงภาษาอังกฤษที่ดี คุณสามารถออกเสียงได้อย่างถูกต้องเป็นส่วนมาก คุณอาจจะยังติดการออกเสียงในภาษาไทยอยู่บ้าง แต่ยังอยู่ในระดับที่เจ้าของภาษายังสามารถทำความเข้าใจคุณได้ \n คุณสามารถพัฒนาโดยการฝึกออกเสียงตามเจ้าของภาษา แล้วลองบันทึกเสียงของคุณ เพื่อนำมาเปรียบเทียบกับการออกเสียงของเจ้าของภาษา และแก้ไขการออกเสียงจุดที่แตกต่าง โดยการขอคำแนะนำจากครูเจ้าของภาษา`,
  '3': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับดี โดยคุณออกเสียงได้ถูกต้อง ร้อยละ 60 - 77.5 ของเจ้าของภาษา คุณมีพื้นฐานในการออกเสียงภาษาอังกฤษที่ดี คุณสามารถออกเสียงได้อย่างถูกต้องเป็นส่วนมาก คุณอาจจะยังติดการออกเสียงในภาษาไทยอยู่บ้าง แต่ยังอยู่ในระดับที่เจ้าของภาษายังสามารถทำความเข้าใจคุณได้ \n คุณสามารถพัฒนาโดยการฝึกออกเสียงตามเจ้าของภาษา แล้วลองบันทึกเสียงของคุณ เพื่อนำมาเปรียบเทียบกับการออกเสียงของเจ้าของภาษา และแก้ไขการออกเสียงจุดที่แตกต่าง โดยการขอคำแนะนำจากครูเจ้าของภาษา`,
  '3.5': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับดีมาก โดยคุณออกเสียงได้ถูกต้อง ร้อยละ 77.5 - 90 ของเจ้าของภาษา คุณสามารถออกเสียงคำศัพท์ภาษาอังกฤษได้ดีมาก ใกล้เคียงการออกเสียงของเจ้าของภาษา คุณสามารถออกเสียงพื้นฐาน รวมถึงเสียงที่ท้าทาย ได้อย่างถูกต้องเกือบทั้งหมด แต่อาจจะยังมีการออกเสียงที่ละเอียดอ่อนบางจุดที่ยังไม่ตรงกับเจ้าของภาษา \n คุณสามารถพัฒนาโดยการสนทนากับเจ้าของภาษาในสภาพแวดล้อมที่ใช้ภาษาอังกฤษมากขึ้น เช่น โครงการแลกเปลี่ยน หรือ English Club รวมทั้งการฝึกอ่านข้อความหรือบทกวีที่ยากและซับซ้อนขึ้น ประกอบการขอคำแนะนำจากครูเจ้าของภาษา`,
  '4': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับดีมาก โดยคุณออกเสียงได้ถูกต้อง ร้อยละ 77.5 - 90 ของเจ้าของภาษา คุณสามารถออกเสียงคำศัพท์ภาษาอังกฤษได้ดีมาก ใกล้เคียงการออกเสียงของเจ้าของภาษา คุณสามารถออกเสียงพื้นฐาน รวมถึงเสียงที่ท้าทาย ได้อย่างถูกต้องเกือบทั้งหมด แต่อาจจะยังมีการออกเสียงที่ละเอียดอ่อนบางจุดที่ยังไม่ตรงกับเจ้าของภาษา \n คุณสามารถพัฒนาโดยการสนทนากับเจ้าของภาษาในสภาพแวดล้อมที่ใช้ภาษาอังกฤษมากขึ้น เช่น โครงการแลกเปลี่ยน หรือ English Club รวมทั้งการฝึกอ่านข้อความหรือบทกวีที่ยากและซับซ้อนขึ้น ประกอบการขอคำแนะนำจากครูเจ้าของภาษา`,
  '4.5': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับใกล้เคียง Native speakers โดยคุณออกเสียงได้ถูกต้อง อย่างน้อย ร้อยละ 90 ของเจ้าของภาษา คุณสามารถออกเสียงได้ใกล้เคียงกับเจ้าของภาษาเป็นอย่างมาก จนเจ้าของภาษาแทบจะไม่สังเกตเห็นความแตกต่างในการออกเสียงของคุณ \n คุณสามารถพัฒนาส่วนที่ยังติดการออกเสียงภาษาไทย ด้วยการเข้าร่วมคอร์สเรียนการออกเสียงขั้นสูง และการขอคำแนะนำแบบตัวต่อตัวจากเจ้าของภาษา เพื่อเสริมสร้างทักษะการออกเสียงให้เหมือนเจ้าของภาษาเลย`,
  '5': `ระดับการออกเสียงคำศัพท์ของคุณอยู่ใน ระดับใกล้เคียง Native speakers โดยคุณออกเสียงได้ถูกต้อง อย่างน้อย ร้อยละ 90 ของเจ้าของภาษา คุณสามารถออกเสียงได้ใกล้เคียงกับเจ้าของภาษาเป็นอย่างมาก จนเจ้าของภาษาแทบจะไม่สังเกตเห็นความแตกต่างในการออกเสียงของคุณ \n คุณสามารถพัฒนาส่วนที่ยังติดการออกเสียงภาษาไทย ด้วยการเข้าร่วมคอร์สเรียนการออกเสียงขั้นสูง และการขอคำแนะนำแบบตัวต่อตัวจากเจ้าของภาษา เพื่อเสริมสร้างทักษะการออกเสียงให้เหมือนเจ้าของภาษาเลย`,
};

export const vocabularyAnalysisText: { [key: string]: string } = {
  '0': `เราไม่สามารถระดับคำศัพท์ของคุณได้ เพราะคุณอาจจะตอบคำถามบางข้อสั้นเกินไป หรือไม่ได้ตอบอย่างครบถ้วน เป็นไปได้ว่าคุณอาจจะยังอ่านคำภาษาอังกฤษบางคำไม่ได้`,
  '0.5': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับฝึกฝน โดยคุณใช้คำศัพท์ระดับสูงในการพูด น้อยกว่า ร้อยละ 3.75  ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณมีคลังคำศัพท์ในการพูดจำกัด คุณสามารถใช้คำศัพท์ที่เรียบง่าย เพื่อสื่อสารความต้องการขั้นพื้นฐาน และการสนทนาในเรื่องที่เรียบง่ายได้ \n คุณสามารถพัฒนาโดยการฝึกท่องคำศัพท์ขั้นสูงขึ้นสำหรับการใช้ในชีวิตประจำวันเพิ่มเติม ให้มีพื้นฐานที่แข็งแกร่งสำหรับการเพิ่มคลังคำศัพท์ขั้นสูงต่อไป`,
  '1': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับฝึกฝน โดยคุณใช้คำศัพท์ระดับสูงในการพูด น้อยกว่า ร้อยละ 3.75  ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณมีคลังคำศัพท์ในการพูดจำกัด คุณสามารถใช้คำศัพท์ที่เรียบง่าย เพื่อสื่อสารความต้องการขั้นพื้นฐาน และการสนทนาในเรื่องที่เรียบง่ายได้ \n คุณสามารถพัฒนาโดยการฝึกท่องคำศัพท์ขั้นสูงขึ้นสำหรับการใช้ในชีวิตประจำวันเพิ่มเติม ให้มีพื้นฐานที่แข็งแกร่งสำหรับการเพิ่มคลังคำศัพท์ขั้นสูงต่อไป`,
  '1.5': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับพอใช้ โดยคุณใช้คำศัพท์ระดับสูงในการพูด ร้อยละ 3.75 - 7.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณมีคลังคำศัพท์ที่ทำให้คุณสามารถแสดงความคิดเห็นในหัวข้อพื้นฐานในชีวิตประจำวันได้อย่างมั่นใจ \n คุณสามารถพัฒนาโดยการซึมซับคำศัพท์ขั้นสูงขึ้นเพิ่มเติมจากสื่อภาษาอังกฤษ เช่น ภาพยนตร์ และรายการทีวี และการอ่านหนังสือหรือบทความที่เรียบง่าย เพื่อขยายคลังคำศัพท์ระดับสูงเพิ่มขึ้น`,
  '2': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับพอใช้ โดยคุณใช้คำศัพท์ระดับสูงในการพูด ร้อยละ 3.75 - 7.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณมีคลังคำศัพท์ที่ทำให้คุณสามารถแสดงความคิดเห็นในหัวข้อพื้นฐานในชีวิตประจำวันได้อย่างมั่นใจ \n คุณสามารถพัฒนาโดยการซึมซับคำศัพท์ขั้นสูงขึ้นเพิ่มเติมจากสื่อภาษาอังกฤษ เช่น ภาพยนตร์ และรายการทีวี และการอ่านหนังสือหรือบทความที่เรียบง่าย เพื่อขยายคลังคำศัพท์ระดับสูงเพิ่มขึ้น`,
  '2.5': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับดี โดยคุณใช้คำศัพท์ระดับสูงในการพูด ร้อยละ 7.5 - 12.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณมีพื้นฐานคำศัพท์ขั้นสูงในระดับดี คุณสามารถแสดงความคิดเห็นเกี่ยวกับหัวข้อต่างๆในชีวิตประจำวันที่กว้างขวางขึ้นได้เป็นอย่างดี \n คุณสามารถพัฒนาโดยการสนทนากับเจ้าของภาษา สังเกตและท่องจำคำศัพท์ที่เจ้าของภาษาใช้ การอ่านและฟังเนื้อหาสถานการณ์จริง เช่น ข่าว หรือบทสัมภาษณ์ เพื่อขยายคลังคำศัพท์ระดับสูง`,
  '3': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับดี โดยคุณใช้คำศัพท์ระดับสูงในการพูด ร้อยละ 7.5 - 12.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณมีพื้นฐานคำศัพท์ขั้นสูงในระดับดี คุณสามารถแสดงความคิดเห็นเกี่ยวกับหัวข้อต่างๆในชีวิตประจำวันที่กว้างขวางขึ้นได้เป็นอย่างดี \n คุณสามารถพัฒนาโดยการสนทนากับเจ้าของภาษา สังเกตและท่องจำคำศัพท์ที่เจ้าของภาษาใช้ การอ่านและฟังเนื้อหาสถานการณ์จริง เช่น ข่าว หรือบทสัมภาษณ์ เพื่อขยายคลังคำศัพท์ระดับสูง`,
  '3.5': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับดีมาก โดยคุณใช้คำศัพท์ระดับสูงในการพูด ร้อยละ 12.5 - 17.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณสามารถใช้คำศัพท์สนทนาในเรื่องที่ซับซ้อนได้อย่างคล่องแคล่ว และสามารถแสดงความคิดเห็นได้ในสถานการณ์ที่หลากหลาย \n คุณสามารถพัฒนาโดยการสังเกตและท่องจำคำศัพท์ จากการเข้าร่วมการอภิปรายหรือการโต้วาทีในเรื่องที่ซับซ้อน เพื่อขยายคลังคำศัพท์ระดับสูงสำหรับการสนทนาประเด็นที่ซับซ้อนขึ้น`,
  '4': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับดีมาก โดยคุณใช้คำศัพท์ระดับสูงในการพูด ร้อยละ 12.5 - 17.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณสามารถใช้คำศัพท์สนทนาในเรื่องที่ซับซ้อนได้อย่างคล่องแคล่ว และสามารถแสดงความคิดเห็นได้ในสถานการณ์ที่หลากหลาย \n คุณสามารถพัฒนาโดยการสังเกตและท่องจำคำศัพท์ จากการเข้าร่วมการอภิปรายหรือการโต้วาทีในเรื่องที่ซับซ้อน เพื่อขยายคลังคำศัพท์ระดับสูงสำหรับการสนทนาประเด็นที่ซับซ้อนขึ้น`,
  '4.5': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับใกล้เคียง Native speakers โดยคุณใช้คำศัพท์ระดับสูงในการพูด อย่างน้อย ร้อยละ 17.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณสามารถสนทนาในเรื่องที่ซับซ้อนได้อย่างมั่นใจ คุณยังสามารถใช้ภาษาได้อย่างแม่นยำและคล่องตัวในการสื่อสารทุกประเด็น \n คุณสามารถพัฒนาโดยการสังเกตและท่องจำคำศัพท์ จากการเข้าร่วมการประชุม การสัมมนา หรือการฝึกอบรมที่ใช้ภาษาอังกฤษเชิงวิชาการ ร่วมถึงการร่วมอภิปรายลงลึงในเรื่องเฉพาะทางที่ซับซ้อน เพื่อขยายคลังคำศัพท์ด้วยคำศัพท์เชิงวิชาการและเฉพาะทางเพิ่มขึ้นไปอีก`,
  '5': `ระดับคำศัพท์ที่ใช้ของคุณอยู่ใน ระดับใกล้เคียง Native speakers โดยคุณใช้คำศัพท์ระดับสูงในการพูด อย่างน้อย ร้อยละ 17.5 ของคำศัพท์ทั้งหมดที่คุณใช้พูดตอบคำถามการทดสอบ คุณสามารถสนทนาในเรื่องที่ซับซ้อนได้อย่างมั่นใจ คุณยังสามารถใช้ภาษาได้อย่างแม่นยำและคล่องตัวในการสื่อสารทุกประเด็น \n คุณสามารถพัฒนาโดยการสังเกตและท่องจำคำศัพท์ จากการเข้าร่วมการประชุม การสัมมนา หรือการฝึกอบรมที่ใช้ภาษาอังกฤษเชิงวิชาการ ร่วมถึงการร่วมอภิปรายลงลึงในเรื่องเฉพาะทางที่ซับซ้อน เพื่อขยายคลังคำศัพท์ด้วยคำศัพท์เชิงวิชาการและเฉพาะทางเพิ่มขึ้นไปอีก`,
};

export const getHeadingDescription = (category: ResultCategoryName, score?: number) => {
  switch (category) {
    case ResultCategoryName.Fluency:
      return 'ความคล่องในการพูด สามารถวัดได้จากจำนวนคำที่พูดต่อนาที (WPM: word per minute) สำหรับตัวชี้วัดนี้ เจ้าของภาษา (Native speakers) มีค่าเฉลี่ยอยู่ที่ประมาณ 150-200 คำต่อนาที';
    case ResultCategoryName.Vocabulary:
      return 'ระดับคำศัพท์ที่ใช้ วัดได้จากสัดส่วนของคำศัพท์ระดับสูง (คำศัพท์ระดับ CEFR B1 ขึ้นไป) ที่คุณใช้ในการพูดตอบคำถามการทดสอบ เนื่องจากการใช้คำศัพท์ที่อยู่ในระดับที่สูงขึ้น แสดงถึงความสามารถในการเลือกใช้คำศัพท์ที่จำเพาะกับสถานการณ์มากขึ้น สำหรับตัวชี้วัดนี้ เจ้าของภาษา (Native speakers) มีค่าเฉลี่ยสัดส่วนของคำศัพท์ระดับสูงอยู่ที่ ร้อยละ 20 ขึ้นไป';
    case ResultCategoryName.Pronunciation:
      return `การประเมินความถูกต้องของการออกเสียง สามารถวัดได้จากการออกเสียงในแต่ละหน่วยย่อยของเสียง (Phoneme)

      ${score ? getAnalysisText(score, ResultCategoryName.Pronunciation) : ''}`;
    default:
      return '';
  }
};
