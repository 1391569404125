export function createEnumTypeGuard<T>(enumType: T) {
  return (value: unknown): value is T[keyof T] => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }
    for (const key in enumType) {
      if (enumType[key] === value) {
        return true;
      }
    }
    return false;
  };
}
