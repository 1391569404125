import Modal from './Modal';
import Loading from './Loading';
import Text from './Text';

interface LoadingModalProps {
  open: boolean;
  text?: string | null;
}

const LoadingModal = ({ open, text = 'กำลังโหลด...' }: LoadingModalProps) => {
  return (
    <Modal open={open} size="sm">
      <div className="flex flex-col justify-center items-center">
        {text && (
          <Text variant="sm" className="mt-4">
            {text}
          </Text>
        )}
        <Loading />
      </div>
    </Modal>
  );
};

export default LoadingModal;
