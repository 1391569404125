const storageKey = 'assessmentApp';
const formatItemKey = (key: AssessmentAppStorageKey) => {
  return storageKey + '-' + key;
};
enum AssessmentAppStorageKey {
  testId = 'testId',
  microphoneChecked = 'microphoneChecked',
}

interface useAssessmentAppStorageReturnObject {
  setTestId: (testId: string) => void;
  getTestId: () => string | null;
  clearTestId: () => void;
  setMicrophoneChecked: () => void;
  getMicrophoneChecked: () => boolean;
  clearMicrophoneChecked: () => void;
}

const useAssessmentAppStorage = (): useAssessmentAppStorageReturnObject => {
  const setStorageItem = (key: AssessmentAppStorageKey, value: string) => {
    const itemKey = formatItemKey(key);
    sessionStorage.setItem(itemKey, value);
  };
  const getStorageItem = (key: AssessmentAppStorageKey) => {
    const itemKey = formatItemKey(key);
    return sessionStorage.getItem(itemKey);
  };
  const removeStorageItem = (key: AssessmentAppStorageKey) => {
    const itemKey = formatItemKey(key);
    sessionStorage.removeItem(itemKey);
  };

  const setTestId = (testId: string) => {
    setStorageItem(AssessmentAppStorageKey.testId, testId);
  };

  const getTestId = () => {
    const testId = getStorageItem(AssessmentAppStorageKey.testId);
    return testId;
  };

  const clearTestId = () => {
    removeStorageItem(AssessmentAppStorageKey.testId);
  };

  const setMicrophoneChecked = () => {
    setStorageItem(AssessmentAppStorageKey.microphoneChecked, 'true');
  };

  const getMicrophoneChecked = () => {
    const isChecked = getStorageItem(AssessmentAppStorageKey.microphoneChecked);
    return isChecked !== null ? true : false;
  };

  const clearMicrophoneChecked = () => {
    removeStorageItem(AssessmentAppStorageKey.microphoneChecked);
  };

  return {
    setTestId,
    getTestId,
    clearTestId,
    setMicrophoneChecked,
    getMicrophoneChecked,
    clearMicrophoneChecked,
  };
};

export default useAssessmentAppStorage;
