import React from 'react';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Text.module.css';

// Always make sure this type aligns with fontSizes in tailwind theme.
type TextVariant =
  | '3xs'
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl';
type TextElementType = 'div' | 'span' | 'p';

interface TextProps extends BaseProps {
  variant?: TextVariant;
  is?: TextElementType;
}

const getVariantClassName = (variant: TextVariant): string => {
  if (variant === 'md') return `${style.md}`;
  return `${style[`${variant}`]}`;
};

const Text = ({ variant = 'md', is = 'div', className, children }: TextProps) => {
  const classNames = toClassNames([getVariantClassName(variant), className]);
  return React.createElement(is, { className: classNames }, children);
};

export default Text;
