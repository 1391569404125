import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translation Files
import * as AssessmentTranslationEN from './resources/en/assessment.json';
import * as AssessmentTranslationTH from './resources/th/assessment.json';
import * as CEFRTranslationEN from './resources/en/cefr.json';
import * as CEFRTranslationTH from './resources/th/cefr.json';
import * as CommonTranslationEN from './resources/en/common.json';
import * as CommonTranslationTH from './resources/th/common.json';
import * as ReportTranslationEN from './resources/en/report.json';
import * as ReportTranslationTH from './resources/th/report.json';
import * as SummaryReportTranslationEN from './resources/en/summary-report.json';
import * as SummaryReportTranslationTH from './resources/th/summary-report.json';
import * as PronunciationTranslationEN from './resources/en/pronunciation.json';
import * as PronunciationTranslationTH from './resources/th/pronunciation.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    cefr: CEFRTranslationEN,
    common: CommonTranslationEN,
    pronunciation: PronunciationTranslationEN,
    assessment: AssessmentTranslationEN,
    report: ReportTranslationEN,
    summaryReport: SummaryReportTranslationEN,
  },
  th: {
    cefr: CEFRTranslationTH,
    common: CommonTranslationTH,
    pronunciation: PronunciationTranslationTH,
    assessment: AssessmentTranslationTH,
    report: ReportTranslationTH,
    summaryReport: SummaryReportTranslationTH,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // TODO: Added en back when supported
    supportedLngs: ['th', 'en'],
    fallbackLng: 'th',
    debug: false,
    interpolation: {
      escapeValue: true,
    },
    ns: ['cefr', 'common', 'pronunciation', 'assessment', 'report', 'summaryReport'],
    defaultNS: 'common',
    returnNull: true,
    returnEmptyString: true,
    resources,
    react: {
      bindI18n: 'languageChanged',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
      useSuspense: false,
    },
    detection: {
      order: ['querystring', 'localStorage'],
      lookupQuerystring: 'lang',
    },
  });

export default i18n;
