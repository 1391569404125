import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dropdown, LoadingModal, Text } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import { Tester, TesterType } from '../../../model/tester';
import { assessmentAppRoutes } from '../../../router';
import RegistrationForm from './RegistrationForm';
import { REGISTRATION_TYPE_OPTIONS } from './constants';
import { RegistrationFormData } from '../../../model/registrationData';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import { AccessCode, AccessCodeType } from '../../../model/accessCode';
import { School } from '../../../model/school';
import { getInitialDataForRegistration, registerTesterToAccessCode } from './helpers';
import ErrorModal from '../../../components/ErrorModal';
import useRegistrationFlowHelpers from '../../../utils/useRegistrationFlowHelpers';
import { useTranslation } from 'react-i18next';

const Registration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const [searchParams, setSearchParams] = useSearchParams();
  const { navigateToAssessmentSessionAppFromRegistrationFlow } = useRegistrationFlowHelpers();

  const [accessCode, setAccessCode] = React.useState<AccessCode>();
  const [tester, setTester] = React.useState<Tester>();
  const [school, setSchool] = React.useState<School>();
  const [type, setType] = React.useState<TesterType>();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  /**
   * Check if user should be redirected to Home to fill in an access code again.
   * Only call once on mounted, if it does not fulfill the conditions below
   * or there is any errors, redirect users back to Home
   * - There is no code in queryString
   * - Cannot get accessCode or School object from apis
   */
  React.useEffect(() => {
    const initializeData = async (code: string) => {
      setLoading(true);
      try {
        const { accessCode, tester, school } = await getInitialDataForRegistration(code);
        const type =
          accessCode.type === AccessCodeType.Business ? TesterType.BusinessEmployee : tester?.type;
        setAccessCode(accessCode);
        setTester(tester);
        setSchool(school);
        setType(type);
      } catch (error: any) {
        console.error(error);
        setErrorMessage(error.message);
      }
      setLoading(false);
    };

    const code = searchParams.get('accessCode');
    if (!code) {
      handleGoToHome();
    } else {
      initializeData(code);
    }
  }, []);

  const handleGoToHome = () => {
    navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
  };

  const handleSubmitForm = async (formData: RegistrationFormData) => {
    setLoading(true);
    try {
      if (!accessCode) {
        throw new Error(t('assessment_registration_handle_submit_form_error'));
      }
      const testId = await registerTesterToAccessCode(accessCode, formData);
      navigateToAssessmentSessionAppFromRegistrationFlow(testId);
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message);
    }

    setLoading(false);
  };

  const handleDropdownChange = (e: React.FormEvent<HTMLSelectElement>) => {
    /**
     * Check if value is the enum of TesterType.
     * We have to check like this because the value from form event is string.
     * This is just to be safe. The options in the Dropdown should only have the value of TesterType.
     */
    if ((Object as any).values(TesterType).includes(e.currentTarget.value)) {
      setType(e.currentTarget.value as TesterType);
    }
  };

  return (
    <LayoutCard className="my-10">
      <CardHeader
        title={
          !!tester
            ? t('assessment_registration_cardheader_title_with_tester')
            : t('assessment_registration_cardheader_title_without_tester')
        }
        subtitle={
          accessCode?.type === AccessCodeType.Business
            ? `${t('assessment_registration_cardheader_subtitle_business', { name: school?.name })}`
            : `${t('assessment_registration_cardheader_subtitle', { name: school?.name })}`
        }
        variant={CardHeaderVariant.SECONDARY}
        className={'mb-6'}
      />

      <div className="w-full max-w-[360px] mx-auto">
        {accessCode?.type !== AccessCodeType.Business && (
          <Dropdown
            value={type || ''}
            onChange={handleDropdownChange}
            placeholder={t('assessment_registration_dropdown_placeholder')}
            options={REGISTRATION_TYPE_OPTIONS}
            readonly={!!tester}
            className="mb-12 w-full"
          />
        )}

        {!!type && (
          <RegistrationForm tester={tester} onSubmitForm={handleSubmitForm} testerType={type} />
        )}
      </div>
      {!!tester && (
        <Text variant="md" className="text-grey-500 text-center max-w-[550px] mt-6 mx-auto mb-4">
          {t('assessment_registration_text')}
        </Text>
      )}
      <LoadingModal open={loading} text={t('assessment_registration_loading_text')} />
      <ErrorModal
        open={!!errorMessage}
        title={t('assessment_registration_error_modal_title')}
        description={errorMessage}
        buttonText={t('assessment_registration_error_modal_button_text')}
        onButtonClick={handleGoToHome}
      />
    </LayoutCard>
  );
};

export default Registration;
