import React from 'react';
import { Dropdown, LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as assessmentApiClient from '../../../api/assessment';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import AccessCodeStatusTable from '../AccessCodeStatus/components/AccessCodeStatusTable';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { AccessCode } from '../../../model/accessCode';
import { Tester } from '../../../model/tester';
import { Test } from '../../../model/test';
dayjs.extend(isBetween);

// TODO: may be confused with model `AccessCode`
export interface AccessCodeStatusData {
  accessCode: string;
  testerId: string;
  firstname: string;
  lastname: string;
  testinstanceId: string;
  status: string;
  testInstanceCreatedAt: string;
  testInstanceUpdatedAt: string;
}

const AccessCodeStatus = () => {
  const [loading, setLoading] = React.useState(false);
  const [schoolOptions, setSchoolOption] = React.useState<DropdownOption[]>([
    { value: '', text: '' },
  ]);
  const [schoolId, setSchoolId] = React.useState('');
  // TODO: ambiguous name, may be confused with model `AccessCode`
  const [accessCodeStatuses, setAccessCodeStatuses] = React.useState<AccessCodeStatusData[]>([]);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [includeInvalidDate, setIncludeInvalidDate] = React.useState(true);

  const includeInvalidDateCheckboxId = React.useId();

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const schools = await assessmentApiClient.getAllSchools();
        const schoolOptions = schools.map((school) => {
          return { text: school.name, value: school.id };
        });
        setSchoolOption(schoolOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  const onSchoolIdChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      setSchoolId(e.currentTarget.value);
      setLoading(true);
      setAccessCodeStatuses([]);
      const data = await assessmentApiClient.getAllAccessCodeStatusBySchoolId(
        e.currentTarget.value
      );
      const accessCodeList = data.map(
        (data: { accessCode: AccessCode; tester: Tester; testInstance: Test }) => {
          return {
            accessCode: data.accessCode.code,
            testerId: data.accessCode.testerId,
            firstname: data.tester ? data.tester.firstName : '-',
            lastname: data.tester ? data.tester.lastName : '-',
            testinstanceId: data.testInstance ? data.testInstance.id : '-',
            status: data.testInstance ? data.testInstance.status : '-',
            testInstanceCreatedAt: data.testInstance ? data.testInstance.createdAt : '-',
            testInstanceUpdatedAt: data.testInstance ? data.testInstance.updatedAt : '-',
          };
        }
      );
      setAccessCodeStatuses(accessCodeList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onIncludeInvalidDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeInvalidDate(e.currentTarget.checked);
  };

  const filteredAccessCodeStatuses = React.useMemo(() => {
    return accessCodeStatuses.filter((accessCodeStatus) => {
      const accessCodeUpdatedDate = dayjs(accessCodeStatus.testInstanceUpdatedAt);
      if (!accessCodeUpdatedDate.isValid()) {
        return includeInvalidDate;
      }
      const nonnullStartDate = startDate || dayjs('1970-01-01');
      const nonnullEndDate = endDate || dayjs('9999-12-31');
      return accessCodeUpdatedDate.isBetween(nonnullStartDate, nonnullEndDate, 'day', '[]');
    });
  }, [accessCodeStatuses, startDate, endDate, includeInvalidDate]);

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="All Access Code Status"
        description=""
        className="mb-6"
      />

      <div className="mb-6">
        <label className="inline-block text-xl mb-2">Select school</label>
        <div className="flex flex-row gap-4 w-full">
          <Dropdown
            value={schoolId}
            onChange={onSchoolIdChange}
            placeholder="Select School"
            options={schoolOptions}
          />
        </div>
      </div>

      <div className="mb-6">
        <label className="inline-block text-xl mb-2">Filter updated date</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="flex items-center gap-x-4">
            <DatePicker value={startDate} onChange={setStartDate} format="DD MMMM YYYY" />
            <div className="text-lg">-</div>
            <DatePicker value={endDate} onChange={setEndDate} format="DD MMMM YYYY" />
            <div>
              <input
                type="checkbox"
                id={includeInvalidDateCheckboxId}
                className="cursor-pointer mr-2"
                checked={includeInvalidDate}
                onChange={onIncludeInvalidDateChange}
              />
              <label htmlFor={includeInvalidDateCheckboxId} className="cursor-pointer">
                Include invalid dates
              </label>
            </div>
          </div>
        </LocalizationProvider>
      </div>

      <div className="w-full text-center">
        {schoolId ? <AccessCodeStatusTable accessCodeStatus={filteredAccessCodeStatuses} /> : null}
      </div>
      <LoadingModal open={loading} />
    </div>
  );
};

export default AccessCodeStatus;
