import { CambridgeLevel } from '../types/cambridge';
import { CEFRLevel } from '../types/cefr';

export const cambridgeLevels = Object.values(CambridgeLevel);

export const cefrLevelToCambridgeLevel = (cefrLevel: CEFRLevel): CambridgeLevel => {
  switch (cefrLevel) {
    case CEFRLevel.PreA1:
      return CambridgeLevel.Starters;
    case CEFRLevel.A1:
      return CambridgeLevel.Movers;
    case CEFRLevel.A2:
      return CambridgeLevel.Flyers;
    case CEFRLevel.B1:
      return CambridgeLevel.Preliminary;
    case CEFRLevel.B2:
      return CambridgeLevel.First;
    case CEFRLevel.C1:
      return CambridgeLevel.Advanced;
    case CEFRLevel.C2:
      return CambridgeLevel.Proficiency;
  }
};
