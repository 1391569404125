import { Heading, Text, Image } from './base';

const InstructionContentCard = ({
  imageUrl,
  title,
  description1,
  description2,
}: {
  imageUrl: string;
  title: string;
  description1?: string;
  description2?: string;
}) => {
  return (
    <div className="border border-grey-300 rounded-lg p-6 flex flex-col items-center basis-0 grow mb-4 md:mb-0">
      <Text variant="sm" className="font-bold text-center">
        {description1}
      </Text>
      <Image src={imageUrl} alt={title} className="w-full max-w-[200px]" />
      <Heading variant="h4" className="text-primary-400 text-center">
        {title}
      </Heading>
      <Text variant="sm" className="text-grey-500 font-light text-center">
        {description2}
      </Text>
    </div>
  );
};

export default InstructionContentCard;
