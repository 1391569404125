import React from 'react';
import NavBar from './components/NavBar';

const MainLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div>
      <div className="text-white text-center py-2 px-2 bg-green-500 font-semibold text-xs">
        Beta version 0.1 สำหรับโรงเรียนในโครงการความร่วมมือทางวิชาการ
      </div>
      <NavBar />
      {children}
    </div>
  );
};

export default MainLayout;
