export enum StarsCount {
  Zero = 0,
  ZeroHalf = 0.5,
  One = 1,
  OneHalf = 1.5,
  Two = 2,
  TwoHalf = 2.5,
  Three = 3,
  ThreeHalf = 3.5,
  Four = 4,
  FourHalf = 4.5,
  Five = 5,
}

export enum StarsCountText {
  Zero = 'zero',
  ZeroHalf = 'zero_half',
  One = 'one',
  OneHalf = 'one_half',
  Two = 'two',
  TwoHalf = 'two_half',
  Three = 'three',
  ThreeHalf = 'three_half',
  Four = 'four',
  FourHalf = 'four_half',
  Five = 'five',
}
