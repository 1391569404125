import { School } from '../model/school';
import { Tester } from '../model/tester';

export enum Sex {
  Male = 'male',
  Female = 'female',
}

export type Student = {
  fullName: string;
  school: string;
  schoolCode: string;
  grade: string;
  room?: string;
  sex: Sex;
};

// ==============================
// ======= Overall Result =======
// ==============================
export type OverallResult = {
  letterGrade: string;
  score: number;
  resultAnalysisText: string;
};

export enum EnglishStandard {
  CEFR = 'cefr',
  Cambridge = 'cambridge',
}

// ==============================
// ====== Category Result =======
// ==============================
export enum ResultCategoryName {
  Fluency = 'fluency',
  Pronunciation = 'pronunciation',

  Vocabulary = 'vocabulary',
  Intonation = 'intonation',
  /**
   * TODO: Removed below if confirmed that they are unused.
   */
  Confidence = 'confidence',
  Vocab_Accuracy = 'vocabAccuracy',
}

export enum IntonationSubCategoryName {
  Pitch = 'pitch',
}
export enum FluencySubCategoryName {
  Speed = 'speed',
  WordSeparation = 'wordSeparation',
}
export enum VocabularySubCategoryName {
  VocabularyLevel = 'vocabularyLevel',
}
export enum PronunciationSubCategoryName {
  IncorrectPhonetics = 'incorrectPhonetics',
}

type CategoryResultBase = {
  score: number;
  analysisText: string;
};

export type IncorrectPhonetic = {
  phonetic: string;
  detailedAnalysis: string[];
};
export interface PronunciationResult extends CategoryResultBase {
  [PronunciationSubCategoryName.IncorrectPhonetics]: IncorrectPhonetic[];
}

export interface FluencyResult extends CategoryResultBase {
  [FluencySubCategoryName.Speed]: { wordCountPerMinute: number; analysisText: string };
  [FluencySubCategoryName.WordSeparation]: {
    score: number;
    analysisText: string;
  };
}

export interface IntonationResult extends CategoryResultBase {
  [IntonationSubCategoryName.Pitch]: {
    pitchData: number[][]; // Example: [[pitch, timestamp], [pitch, timestamp]]
    naturalPitchRange: number[]; // [min, max]
    analysisText: string;
  };
}

export interface VocabularyResult extends CategoryResultBase {
  [VocabularySubCategoryName.VocabularyLevel]: {
    distribution: { key: string; value: number }[];
    analysisText: string;
  };
}
export interface ConfidenceResult extends CategoryResultBase {}
export interface Vocab_AccuracyResult extends CategoryResultBase {}

export type ResultCategory = {
  [ResultCategoryName.Fluency]: FluencyResult;
  [ResultCategoryName.Pronunciation]: PronunciationResult;
  [ResultCategoryName.Vocabulary]: VocabularyResult;
  [ResultCategoryName.Intonation]: IntonationResult;
  /**
   * TODO: Removed below if confirmed that they are unused.
   */
  [ResultCategoryName.Confidence]?: ConfidenceResult;
  [ResultCategoryName.Vocab_Accuracy]?: Vocab_AccuracyResult;
};

export type CategoryResult = ResultCategory[keyof ResultCategory];

export const validateResultCategoryName = (
  categoryName: string
): categoryName is ResultCategoryName => {
  return Object.values<string>(ResultCategoryName).includes(categoryName);
};

// ==============================
// ========= Combined ===========
// ==============================
export type Result = {
  overall: OverallResult;
  englishStandard: { [key in EnglishStandard]: string };
  category: ResultCategory;
};

export type Report = {
  testId: string;
  tester: Tester;
  school: School;
  result: Result;
  date: Date;
};
