import { CEFRLevel } from '../types/cefr';

export const cefrLevels = Object.values(CEFRLevel);

export const cefrLevelUpTrainingHours: Record<CEFRLevel, number> = {
  [CEFRLevel.PreA1]: 20,
  [CEFRLevel.A1]: 20,
  [CEFRLevel.A2]: 40,
  [CEFRLevel.B1]: 40,
  [CEFRLevel.B2]: 40,
  [CEFRLevel.C1]: 60,
  [CEFRLevel.C2]: 60,
};

export const isValidCEFRLevel = (level: string): level is CEFRLevel => {
  return cefrLevels.includes(level as CEFRLevel);
};
