import { FC } from 'react';
import { Container } from '../../../components/base';
import { Report } from '../../../types/report';
import { SchoolReportStatistic } from '../../../types/reportStatistic';
import CategoryTabsSection from './components/CategoryTabsSection';
import { SummarySection } from './components/SummarySection';

interface Props {
  report: Report;
  schoolStatistic: SchoolReportStatistic;
}
export const ReportDetailCategoryView: FC<Props> = ({ report, schoolStatistic }) => {
  return (
    <Container className="px-4 py-6 sm:py-12 bg-primary-50 sm:bg-transparent flex-1">
      <div className="flex flex-col gap-y-6 sm:gap-y-11">
        <SummarySection report={report} schoolStatistic={schoolStatistic} />
        <CategoryTabsSection report={report} schoolStatistic={schoolStatistic} />
      </div>
    </Container>
  );
};
