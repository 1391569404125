import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Image, Text } from '../../../../components/base';
import { cefrLevelToCambridgeLevel } from '../../../../data/cambirdge';
import { isValidCEFRLevel } from '../../../../data/cefr';
import type { Report } from '../../../../types/report';
import { toClassNames } from '../../../../utils';
import style from '../ReportDetail.module.css';

interface Props {
  report: Report;
}
const CompareWithGlobalStandardSection: FC<Props> = ({ report }) => {
  const { t } = useTranslation('report');

  const cefrLevel = report.result.englishStandard.cefr;
  const cambridgeLevel = isValidCEFRLevel(cefrLevel) ? cefrLevelToCambridgeLevel(cefrLevel) : null;

  if (!cambridgeLevel) {
    // This should not happen. But just in case, we wont render this section
    return null;
  }

  return (
    <div className="px-8 py-5 sm:py-8">
      <Heading is="h2" variant="h1" className="text-center">
        <span>{t('report_detail_compare_with_other_standards_section_title_first_line')}</span>
        <br />
        <span className={style['gradient-text-primary']}>
          {t('report_detail_compare_with_other_standards_section_title_second_line')}
        </span>
      </Heading>
      <div className="mt-5 flex flex-col justify-center sm:flex-row gap-y-8 sm:gap-x-8 rounded-2xl">
        <StandardScoreCard standard="cambridge">
          <Text variant="2xl" className="font-bold text-gray-800 uppercase">
            {cambridgeLevel}
          </Text>
          <Text className="text-gray-600">Level</Text>
        </StandardScoreCard>
        {/* // TODO IELTS not available */}
        {/* <StandardScoreCard standard="ielts">
          <Text variant="2xl" className="font-bold text-gray-800">
            {'-'}
          </Text>
          <Text className="text-gray-600">Band</Text>
        </StandardScoreCard> */}
        {/* // TODO IELTS not available */}
        {/* <StandardScoreCard standard="toefl">
          <Text>
            <Text is="span" variant="2xl" className="font-bold text-gray-800">
              {'-'}
            </Text>
            <Text is="span"> / 30</Text>
          </Text>
          <Text className="text-gray-600">Score</Text>
        </StandardScoreCard> */}
      </div>
    </div>
  );
};

const standardScoreCardConfigs = {
  cambridge: {
    logoUrl: '/assets/images/cambridge-logo.png',
    classes: { borderColor: 'border-primary-100', headingBackground: 'bg-primary-50' },
  },
  ielts: {
    logoUrl: '/assets/images/ielts-logo.png',
    classes: { borderColor: 'border-error-100', headingBackground: 'bg-error-50' },
  },
  toefl: {
    logoUrl: '/assets/images/toefl-logo.png',
    classes: { borderColor: 'border-primary-100', headingBackground: 'bg-primary-50' },
  },
};
interface StandardScoreCardProps {
  standard: 'cambridge' | 'ielts' | 'toefl';
}
const StandardScoreCard: FC<PropsWithChildren<StandardScoreCardProps>> = ({
  standard,
  children,
}) => {
  const config = standardScoreCardConfigs[standard];
  return (
    <div
      className={toClassNames([
        'flex-1',
        'border',
        'rounded-2xl',
        'overflow-hidden',
        'sm:max-w-[33%]',
        config.classes.borderColor,
      ])}
    >
      <div
        className={toClassNames([
          'py-3',
          'h-10',
          'flex',
          'justify-center',
          'items-center',
          config.classes.headingBackground,
        ])}
      >
        <Image src={config.logoUrl} alt={`${standard} logo`} className="h-full" />
      </div>
      <div
        className={toClassNames([
          'p-3',
          'h-[72px]',
          'flex',
          'flex-col',
          'justify-center',
          'items-center',
        ])}
      >
        {children}
      </div>
    </div>
  );
};

export default CompareWithGlobalStandardSection;
