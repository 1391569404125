export const encloseWithSlash = (str: string, sanitize = true) => {
  return encloseWith(str, '/', sanitize);
};

export const encloseWithDoubleQuote = (str: string, sanitize = true) => {
  return encloseWith(str, '"', sanitize);
};

export const encloseWith = (str: string, char: string, sanitize = false) => {
  if (sanitize) {
    const leadingRegex = new RegExp(`^${char}+`);
    const trailingRegex = new RegExp(`${char}+$`);
    str = str.replace(leadingRegex, '');
    str = str.replace(trailingRegex, '');
  }

  return `${char}${str}${char}`;
};
