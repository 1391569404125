import { Outlet, Link } from 'react-router-dom';
import NavBar from '../../../layouts/components/NavBar';
import { internalAdminRoute } from '../../../router/routes';
import { Text } from '../../../components/base';

const NavLink = ({ to, label }: { to: string; label: string }) => {
  return (
    <Link
      to={to}
      className="cursor-pointer border-b border-color-grey-500 py-2 hover:bg-primary-400"
    >
      <Text variant="md">{label}</Text>
    </Link>
  );
};

const InternalAdminAppRoot = () => {
  return (
    <div className="relative min-h-[100vh]">
      <NavBar />
      <div className="bg-primary-200 absolute top-[80px] left-0 bottom-0 w-[150px] flex flex-col pt-8 px-4">
        <NavLink to={internalAdminRoute.Home.getRoute()} label={'Home'} />
        <NavLink to={internalAdminRoute.CodeGenerate.getRoute()} label={'AccessCode generator'} />
        <NavLink
          to={internalAdminRoute.AccessCodeStatus.getRoute()}
          label={'Test completed Status'}
        />
        <NavLink to={internalAdminRoute.Testers.getRoute()} label={'Testers'} />
        <NavLink to={internalAdminRoute.Schools.getRoute()} label={'Schools'} />
        <NavLink to={internalAdminRoute.AccessCodeList.getRoute()} label={'Access Code List'} />
        <NavLink to={internalAdminRoute.AllAccessCodeStatus.getRoute()} label={'Test all Status'} />
        <NavLink to={internalAdminRoute.SpeakingTests.getRoute()} label={'Speaking Tests'} />
        <NavLink
          to={internalAdminRoute.SpeakingQuestions.getRoute()}
          label={'Speaking Questions'}
        />
      </div>
      <div className="pl-[150px]">
        <div className="p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default InternalAdminAppRoot;
