import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cefrLevels, cefrLevelUpTrainingHours } from '../../../../data/cefr';
import { CEFRLevel } from '../../../../types/cefr';
import { toClassNames } from '../../../../utils';
import { Text, Image, Button } from '../../../../components/base';

interface Props {
  currentLevel: CEFRLevel;
  toLevel?: CEFRLevel | null;
}
/**
 *
 * @param {CEFRLevel} currentLevel  **required**
 * @param {CEFRLevel=} toLevel optional: Auto assigned to next level if applicable
 * @returns
 */
const UpSkillSection: FC<Props> = ({ currentLevel, toLevel }) => {
  const { t } = useTranslation('report');

  const isHighestLevel = currentLevel === cefrLevels[cefrLevels.length - 1];
  const currentLevelIndex = cefrLevels.findIndex((cefrLevel) => cefrLevel === currentLevel);

  if (!toLevel && !isHighestLevel) {
    toLevel = cefrLevels[currentLevelIndex + 1];
  }

  const trainingHours = useMemo(() => {
    if (!toLevel) return 0;
    const targetLevelIndex = cefrLevels.findIndex((cefrLevel) => cefrLevel === toLevel);
    const levelsRequired = cefrLevels.slice(currentLevelIndex + 1, targetLevelIndex + 1); // exclude current, include target
    return levelsRequired.reduce(
      (acc, levelRequired) => acc + cefrLevelUpTrainingHours[levelRequired],
      0
    );
  }, [currentLevelIndex, toLevel]);

  if (!toLevel) {
    return null;
  }

  return (
    <div
      className={toClassNames([
        'flex',
        'flex-col',
        'sm:flex-row',
        'p-5',
        'gap-3',
        'items-center',
        'bg-gradient-to-tl',
        'from-[#0E34A0]',
        'from-10%',
        'to-[#275DF8]',
        'to-[110%]',
        'rounded-3xl',
      ])}
    >
      <Image
        src="/assets/images/report-app/report-detail-up-skill-section.png"
        alt="up skill section decoration image"
      />
      <div className="flex-1 text-center sm:text-left">
        <div>
          <Text is="span" variant="2xl" className="font-bold text-yellow-200">
            {isHighestLevel
              ? t('report_detail_up_skill_section_maintain_level', { level: currentLevel })
              : t('report_detail_up_skill_section_level_up_to', { level: toLevel })}
          </Text>{' '}
          <Text is="span" variant="2xl" className="font-bold text-white whitespace-pre">
            {isHighestLevel
              ? t('report_detail_up_skill_section_maintain_text')
              : t('report_detail_up_skill_section_train_for_hours', { hours: trainingHours })}
          </Text>
        </div>
        <Text is="span" className="inline-block mt-1 text-white">
          {isHighestLevel
            ? t('report_detail_up_skill_section_maintain_description')
            : t('report_detail_up_skill_section_train_description')}
        </Text>
        <Button
          is="a"
          href="https://forms.gle/LXmXYSMMMMPxdhPN7"
          target="_blank"
          variant="on-dark"
          className="mt-4 w-full sm:w-fit"
          dataAnalyticId="report-detail-up-cefr-level-ads"
        >
          {/* // TODO: base text color unmatched in variant `on-dark` */}
          <Text className="text-primary-500 hover:text-inherit">
            {t('report_detail_up_skill_section_consult_cta_button')}
          </Text>
        </Button>
      </div>
    </div>
  );
};

export default UpSkillSection;
