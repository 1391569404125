import { Container, Heading, Text } from '../../../components/base';
import { FluencyResult, ResultCategoryName } from '../../../types/report';
import SpeedGraph from './SpeedGraph';
import Banner from './Banner';
import { FluencyStatistic } from '../../../types/reportStatistic';
import { formatScore } from '../../../utils';
import { getAnalysisText } from '../utils';

type AnalysisSectionProps = {
  title: string;
  mainScoreText: string;
  mainScoreSubtitle: string;
  analysisText: string;
  speed: number;
  averageSpeed: number;
};
const AnalysisSection = ({
  title,
  mainScoreText,
  mainScoreSubtitle,
  analysisText,
  speed,
  averageSpeed,
}: AnalysisSectionProps) => {
  return (
    <Container className="flex flex-col items-center">
      <Heading variant="h3" className="mb-6 text-primary">
        {title}
      </Heading>
      <Text variant="3xl" className="font-bold mb-1">
        {mainScoreText}
      </Text>
      <Text className="mb-8">{mainScoreSubtitle}</Text>
      <SpeedGraph speed={speed} averageSpeed={averageSpeed} />
      <Text className="text-center mt-10">{analysisText}</Text>
    </Container>
  );
};

const Fluency = ({
  result: { speed },
  statistic,
  overallScore,
}: {
  result: FluencyResult;
  statistic: FluencyStatistic;
  overallScore: number;
}): JSX.Element => {
  return (
    <>
      <div className="bg-primary-50 flex flex-col items-center py-10">
        <AnalysisSection
          title={'วิเคราะห์ความเร็วในการพูด'}
          mainScoreText={formatScore(speed.wordCountPerMinute, false)}
          mainScoreSubtitle={'คำ/นาที'}
          analysisText={getAnalysisText(overallScore, ResultCategoryName.Fluency)}
          speed={speed.wordCountPerMinute > 0 ? speed.wordCountPerMinute : 0}
          averageSpeed={statistic?.speed?.mean}
        />
      </div>
      <Banner
        mobileImageUrl="/assets/images/marketing/fluency-banner-mobile.png"
        tabletImageUrl="/assets/images/marketing/fluency-banner-tablet.png"
        alt="fluency-banner"
        href="https://youtube.com/playlist?list=PL-cyPXfXIj4sFQu0hgg22c4EvwRwBJhWe"
        dataAnalyticId="category-banner-fluency"
      />
    </>
  );
};

export default Fluency;
