import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Heading, Image } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import { toClassNames } from '../../../../utils';
import style from '../ReportDetail.module.css';

const consultationData = [
  {
    link: 'https://forms.gle/LXmXYSMMMMPxdhPN7',
    image: '/assets/images/report-app/report-detail-consultation-cefr.png',
    alt: 'cefr consultation',
    subTranslationKey: 'report_detail_consultation_section_consult_for',
    mainTranslationKey: 'report_detail_consultation_section_improve_cefr',
  },
  {
    link: 'https://forms.gle/F9BucQAzgj5jikqVA',
    image: '/assets/images/report-app/report-detail-consultation-pronunciation.png',
    alt: 'pronunciation consultation',
    subTranslationKey: 'report_detail_consultation_section_consult_about',
    mainTranslationKey: 'report_detail_consultation_section_improve_pronunciation',
  },
  {
    link: 'https://forms.gle/oSh27Uqd7HdECP9R6',
    image: '/assets/images/report-app/report-detail-consultation-vocabulary.png',
    alt: 'vocabulary consultation',
    subTranslationKey: 'report_detail_consultation_section_consult_for',
    mainTranslationKey: 'report_detail_consultation_section_improve_vocabulary',
  },
] as const;

interface Props {
  className?: string;
  location?: string; // Route that use this component
}

const ConsultationSection: FC<Props> = ({ className, location }) => {
  const { t } = useTranslation('report');
  return (
    <div className={toClassNames([className, style['consultation-section-container']])}>
      <div>
        <Heading
          is="h2"
          variant="h4"
          className="sm:!text-3xl text-white text-center whitespace-pre-line"
        >
          {t('report_detail_consultation_section_title')}
        </Heading>
      </div>
      <div className="pt-3 flex flex-col gap-y-3 sm:flex-row sm:justify-between">
        {consultationData.map((consultation, index) => {
          return (
            <a
              target="_blank"
              rel="noreferrer noopener"
              key={consultation.alt}
              href={consultation.link}
              className="text-center p-4 flex flex-row sm:flex-col items-center gap-x-2 bg-primary-700 sm:bg-transparent rounded-xl"
              data-analytic-id="report-detail-consultation-card"
              data-analytic-location={location}
              data-analytic-detail={consultation.alt}
            >
              <Image
                src={consultation.image}
                alt={consultation.alt}
                className="w-10 sm:w-[140px] "
              />
              <Text className="hidden sm:block text-white font-semibold">
                {t(consultation.subTranslationKey)}
              </Text>
              <Text className="text-white font-semibold text-left sm:text-center">
                {t(consultation.mainTranslationKey)}
              </Text>
              <Icon
                icon={IconName.ArrowRight}
                size="24px"
                className="sm:hidden text-white ml-auto"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ConsultationSection;
