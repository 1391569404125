import { Heading } from '../../../components/base';

const Home = () => {
  return (
    <div className="p-8 bg-white m-8 flex flex-col">
      <Heading variant="h1">Internal Admin Page</Heading>
    </div>
  );
};

export default Home;
