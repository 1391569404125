export enum SpeakingTestQuestionType {
  READING = 'READING',
  OPEN_ENDED = 'OPEN_ENDED',
  DESCRIBE = 'DESCRIBE',
  ELABORATE = 'ELABORATE',
  DISCUSSION = 'DISCUSSION',
}

export const parseSpeakingTestQuestionType = (type: string): SpeakingTestQuestionType => {
  if ((Object as any).values(SpeakingTestQuestionType).includes(type)) {
    return type as SpeakingTestQuestionType;
  } else {
    throw new Error(`Type ${type} is not a valid SpeakingTestQuestionType`);
  }
};

export enum SpeakingTestQuestionPromptContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export const SpeakingTestQuestionTypeTitle: Record<SpeakingTestQuestionType, string> = {
  [SpeakingTestQuestionType.READING]: 'Reading Aloud',
  [SpeakingTestQuestionType.OPEN_ENDED]: 'Open-ended Question',
  [SpeakingTestQuestionType.DESCRIBE]: 'Describing Pictures',
  [SpeakingTestQuestionType.ELABORATE]: 'Elaborate',
  [SpeakingTestQuestionType.DISCUSSION]: 'Discussion',
};

const parseSpeakingTestQuestionPromptType = (
  type: string
): SpeakingTestQuestionPromptContentType => {
  if ((Object as any).values(SpeakingTestQuestionPromptContentType).includes(type)) {
    return type as SpeakingTestQuestionPromptContentType;
  } else {
    throw new Error(`Type ${type} is not a valid SpeakingTestQuestionPromptType`);
  }
};

export class SpeakingTestQuestionPromptContent {
  constructor(public type: SpeakingTestQuestionPromptContentType, public content: string) {}

  public static parse(json: { [key: string]: any }): SpeakingTestQuestionPromptContent {
    const prompt = new SpeakingTestQuestionPromptContent(
      parseSpeakingTestQuestionPromptType(json['type']),
      json['content']
    );
    return prompt;
  }
}

export class SpeakingTestQuestion {
  constructor(
    public id: string,
    public type: SpeakingTestQuestionType,
    public prompt: SpeakingTestQuestionPromptContent[],
    public completed: boolean,
    public createdAt: Date,
    public updatedAt: Date,
    public timeLimit?: number,
    /**
     * Time for reading question in seconds
     */
    public preparationTimeLimit?: number,

    public isPrivate?: boolean
  ) {}

  public static parse(json: { [key: string]: any }): SpeakingTestQuestion {
    const speakingTestQuestion = new SpeakingTestQuestion(
      json['id'],
      parseSpeakingTestQuestionType(json['type']),
      json['prompt'].map((q: SpeakingTestQuestionPromptContent) =>
        SpeakingTestQuestionPromptContent.parse(q)
      ),
      json['completed'] === 'true' ? true : false,
      new Date(json['createdAt']),
      new Date(json['updatedAt']),
      parseInt(json['timeLimit']) || undefined,
      parseInt(json['preparationTimeLimit']) || undefined,
      json['isPrivate'] === 'true' ? true : false
    );
    return speakingTestQuestion;
  }

  public minRecordingTime = this.timeLimit ? (this.timeLimit > 30 ? 15 : 5) : 0;
}

export const getMockSpeakingTestQuestion = (id: string, type: SpeakingTestQuestionType) => {
  return new SpeakingTestQuestion(
    id,
    type,
    [
      {
        type: SpeakingTestQuestionPromptContentType.TEXT,
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      },
      {
        type: SpeakingTestQuestionPromptContentType.IMAGE,
        content: '/assets/images/rocket-avatar.svg',
      },
    ],
    false,
    new Date(),
    new Date(),
    60,
    15,
    false
  );
};
