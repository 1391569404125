import React from 'react';
import { Dropdown, LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as assessmentApiClient from '../../../api/assessment';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import AccessCodeListTable from './components/AccessCodeListTable';
import { AccessCode } from '../../../model/accessCode';
import { Tester } from '../../../model/tester';

const AccessCodeList = () => {
  const [loading, setLoading] = React.useState(false);
  const [schoolOptions, setSchoolOptions] = React.useState<DropdownOption[]>([
    { value: '', text: '' },
  ]);
  const [schoolId, setSchoolId] = React.useState('');
  const [schoolName, setSchoolName] = React.useState('');
  const [accessCodeList, setAccessCodeLists] = React.useState<
    { accessCode: AccessCode; tester?: Tester }[]
  >([]);

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const schools = await assessmentApiClient.getAllSchools();
        const schoolOptions = schools.map((school) => {
          return { text: school.name, value: school.id };
        });
        setSchoolOptions(schoolOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  const handleSchoolChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      setSchoolId(e.currentTarget.value);
      setLoading(true);
      setAccessCodeLists([]);
      const data = await assessmentApiClient.getAccessCodeListBySchoolId(e.currentTarget.value);
      setAccessCodeLists(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSchoolName = () => {
    const found = schoolOptions.find((school) => school.value === schoolId);
    return found?.text ?? '';
  };

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="Access Code List"
        description=""
        className="mb-6"
      />

      <div className="mb-6">
        <label className="inline-block text-xl mb-2">Select school</label>
        <div className="flex flex-row gap-4 w-full">
          <Dropdown
            value={schoolId}
            onChange={handleSchoolChange}
            placeholder="Select School"
            options={schoolOptions}
          />
        </div>
      </div>

      <div className="w-full text-center">
        {schoolId ? (
          <AccessCodeListTable accessCodeList={accessCodeList} school={getSchoolName()} />
        ) : null}
      </div>
      <LoadingModal open={loading} />
    </div>
  );
};

export default AccessCodeList;
