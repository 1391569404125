import { toClassNames } from '../../utils';
import { BaseProps } from '../types';

const Chip = ({ children, className }: BaseProps) => {
  return (
    <div className={toClassNames(['rounded-full py-1 px-6 flex items-center w-fit', className])}>
      {children}
    </div>
  );
};

export default Chip;
