import { CEFRLevel, parseCefrLevelFromDb } from '../types/cefr';
import { SpeakingTestQuestionType, parseSpeakingTestQuestionType } from './speakingTestQuestion';

export class SpeakingTestCriteria {
  constructor(
    public type: SpeakingTestQuestionType,
    public cefrLevel: CEFRLevel,
    public speakingQuestionId: string
  ) {}

  public static parse(json: { [key: string]: any }): SpeakingTestCriteria {
    const criteria = new SpeakingTestCriteria(
      parseSpeakingTestQuestionType(json['type']),
      parseCefrLevelFromDb(json['cefrLevel']),
      json['speakingQuestionId']
    );
    return criteria;
  }

  public static parseArray(jsonArray: any[]): SpeakingTestCriteria[] {
    return jsonArray.map((json) => SpeakingTestCriteria.parse(json));
  }
}

export class SpeakingTest {
  constructor(
    public id: string,
    public name: string,
    public questionCriteria: SpeakingTestCriteria[],
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  public static parse(json: { [key: string]: any }): SpeakingTest {
    const speakingTest = new SpeakingTest(
      json['id'],
      json['name'],
      SpeakingTestCriteria.parseArray(json['questionCriteria']),
      new Date(json['createdAt']),
      new Date(json['updatedAt'])
    );
    return speakingTest;
  }
}
