import dayjs, { type ConfigType, WeekdayNames } from 'dayjs';
import 'dayjs/locale/th';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

export const getLocalizedDayNames = (locale: 'en' | 'th' | string, short = false): WeekdayNames => {
  const localizedDayjs = dayjs().locale(locale);

  return short
    ? localizedDayjs.localeData().weekdaysShort()
    : localizedDayjs.localeData().weekdays();
};

export const getLocalizedDayNameByDayIndex = (
  dayIndex: number,
  locale: 'en' | 'th' | string,
  short = false
): string => {
  const weekdayNames = getLocalizedDayNames(locale, short);
  const weekdayName = weekdayNames[dayIndex];
  // in 'th' short & full is the same (e.g. monday = จันทร์)
  if (!short && locale === 'th') return `วัน${weekdayName}`;
  return weekdayName;
};

type DayName = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
export const getLocalizedDayName = (
  day: DayName,
  locale: 'en' | 'th' | string,
  short = false
): string => {
  const dayNameToIndex: Record<string, number> = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  return getLocalizedDayNameByDayIndex(dayNameToIndex[day], locale, short);
};

export const formatNullableDate = (
  dateInput: ConfigType,
  format = 'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO-8601
  fallbackValue = '',
  locale = 'en'
): string => {
  const date = dayjs(dateInput);
  if (!date.isValid()) return fallbackValue;
  return date.locale(locale).format(format);
};
