import { Container, Heading, Text } from '../../../components/base';
import { PronunciationResult } from '../../../types/report';
import { toClassNames } from '../../../utils';
import Banner from './Banner';

type PronunciationProps = {
  result: PronunciationResult;
};

const Pronunciation = ({ result: { incorrectPhonetics } }: PronunciationProps) => {
  if (!incorrectPhonetics) return null;
  return (
    <>
      <Banner
        mobileImageUrl="/assets/images/marketing/pronunciation-banner-mobile.png"
        tabletImageUrl="/assets/images/marketing/pronunciation-banner-tablet.png"
        alt="pronunciation-banner"
        href="https://youtube.com/playlist?list=PL-cyPXfXIj4uFlI7OAgIMSpYV9sOASUTe"
        dataAnalyticId="category-banner-pronunciation"
      />
      <div className="bg-primary-50">
        <Container className="py-10 bg-primary-50">
          <Heading variant="h3" is={'h2'} className="text-primary text-center mb-6">
            เสียงที่ผิดมากที่สุด 3 อันดับ
          </Heading>
          <div className="flex gap-4">
            {incorrectPhonetics.map((data, index) => (
              <div
                className="flex flex-col justify-center items-center rounded-xl bg-yellow-500 basis-0 grow py-4 gap-1"
                key={`${data.phonetic}-${index}`}
              >
                <Text>เสียง</Text>
                <Text variant={'3xl'} className="font-semibold">
                  {data.phonetic}
                </Text>
              </div>
            ))}
          </div>
        </Container>
      </div>
      <Container>
        {incorrectPhonetics.map((data, index) => (
          <div
            className={toClassNames([
              'pt-8 pb-10',
              index < incorrectPhonetics.length - 1 ? 'border-b' : undefined,
            ])}
            key={`${data.phonetic}-${index}`}
          >
            <Heading variant={'h3'} className="text-primary font-normal text-center mb-6">
              วิเคราะห์การออกเสียง
              <br />
              <Text is="span" variant="2xl" className="font-bold">
                {data.phonetic}
              </Text>
            </Heading>
            <div className="flex flex-col gap-2">
              {data.detailedAnalysis.map((text, index) => (
                <div
                  className="py-4 px-6 bg-primary-50 rounded-xl border border-primary-200"
                  key={`analysisText-${index}`}
                >
                  <Text>{text}</Text>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Pronunciation;
