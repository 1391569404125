import React from 'react';
import { Button, Dropdown, LoadingModal } from '../../../components/base';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import * as assessmentApiClient from '../../../api/assessment';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import TestersTable from './components/TestersTable';
import { Tester } from '../../../model/tester';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { Text } from '../../../components/base';
import CreateModal from './components/CreateModal';
import * as adminApiClient from '../../../api/admin';
import { downloadFile } from '../../../utils/downloadFile';

const Testers = () => {
  const [loading, setLoading] = React.useState(false);
  const [schoolOptions, setSchoolOption] = React.useState<DropdownOption[]>([
    { value: '', text: '' },
  ]);
  const [schoolId, setSchoolId] = React.useState('');
  const [testers, setTesters] = React.useState<Tester[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      try {
        const schools = await assessmentApiClient.getAllSchools();
        const schoolOptions = schools.map((school) => {
          return { text: school.name, value: school.id };
        });
        setSchoolOption(schoolOptions);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  React.useEffect(() => {
    const getTesters = async () => {
      setLoading(true);
      if (!schoolId) return;
      setTesters([]);
      try {
        const data = await assessmentApiClient.getTesterBySchoolId(schoolId);
        setTesters(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getTesters();
  }, [schoolId]);

  const handleSelectRow = (newSelectRow: GridRowSelectionModel) => {
    setRowSelectionModel(newSelectRow);
  };

  const handleCreateCode = async () => {
    setModalOpen(true);
  };

  const handleCancelSession = () => {
    setModalOpen(false);
  };

  const handleContinueSession = async (prefix: string) => {
    setLoading(true);

    const selectedRowData = rowSelectionModel.map((row) => row.toString());
    const csv = await adminApiClient.createAccessCodeFromTester(prefix, selectedRowData);
    downloadFile(new Blob([csv], { type: 'text/csv' }));
    alert('Create code success!');

    setLoading(false);
    setModalOpen(false);
  };

  

  return (
    <div>
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle=""
        title="Testers"
        description=""
        className="mb-6"
      />

      <div className="flex flex-row w-full justify-between items-center mb-6">
        <Dropdown
          value={schoolId || ''}
          onChange={(e) => setSchoolId(e.currentTarget.value)}
          placeholder="Select School"
          options={schoolOptions}
          className=""
        />
        <Button
          variant="secondary"
          size="small"
          className=""
          onClick={handleCreateCode}
          disabled={rowSelectionModel.length == 0}
        >
          Create code
        </Button>
      </div>

      {rowSelectionModel.length > 0 && (
        <Text className="text-right font-bold">{`${rowSelectionModel.length} rows selected`}</Text>
      )}
      <div className="w-full text-center">
        {schoolId ? (
          <TestersTable
            testers={testers}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleSelectRow}
          />
        ) : null}
      </div>
      <CreateModal
        open={modalOpen}
        onSecondaryCtaClick={handleCancelSession}
        onPrimaryCtaClick={handleContinueSession}
      />
      <LoadingModal open={loading} />
    </div>
  );
};

export default Testers;
