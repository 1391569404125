import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, ScrollRestoration, useParams } from 'react-router-dom';
import { Container, Image, Text } from '../components/base';
import Icon, { IconName } from '../components/base/Icon';
import { SelectLangaugeButton } from '../components/i18n/SelectLanguageButton';
import { reportAppRoutes } from '../router';
import { toClassNames } from '../utils';

interface ReportAppLayoutProps extends PropsWithChildren {
  showBackToHomeOnMobile?: boolean;
}
const ReportAppLayout: FC<PropsWithChildren> = ({
  children,
  showBackToHomeOnMobile = false,
}: ReportAppLayoutProps) => {
  const { t } = useTranslation('report');
  const params = useParams();

  return (
    <div className="min-h-screen flex flex-col">
      <ScrollRestoration />
      {/* <div className="py-2 px-2 bg-green-500">
        <Text variant="xs" className="text-white font-semibold text-center">
          {t('beta_banner_text')}
        </Text>
      </div> */}
      <div className={`bg-primary`}>
        <Container>
          <div className="py-5 sm:py-6 w-full grid grid-cols-[1fr_auto_1fr] gap-x-2 items-center col-start-2">
            {showBackToHomeOnMobile && (
              <Link
                to={reportAppRoutes.ReportDetail.getRoute({ testId: params.testId })}
                className="sm:invisible text-white flex items-center"
                data-analytic-id="report-app-go-to-home-navbar"
              >
                <Icon icon={IconName.ArrowLeftSquareOutlined} size="20px" className="mr-1" />
                {/* <Text variant="sm">{t('back_to_home')}</Text> */}
              </Link>
            )}
            <Link
              to={reportAppRoutes.ReportDetail.getRoute({ testId: params.testId })}
              data-analytic-id="report-app-logo"
              className={toClassNames([showBackToHomeOnMobile ? '' : 'col-start-2'])}
            >
              <div className="flex items-center">
                <Text variant="xl" className=" sm:!text-3xl text-white font-bold">
                  EdSpeak by
                </Text>
                <Image
                  src={'/assets/images/edsy-logo-white.png'}
                  alt="edsy logo white"
                  className={`w-[60px] sm:w-[88px] ml-2`}
                />
              </div>
            </Link>
            <SelectLangaugeButton className="justify-self-end" />
          </div>
        </Container>
      </div>
      <div className="flex flex-col flex-1">{children}</div>
      {/* // TODO unmatched primary-800 (recheck figma)  */}
      <div className="bg-primary-800 py-3 px-3">
        <Text variant="sm" className="text-primary-200 text-center">
          Copyright 2024, Edsy
        </Text>
      </div>
    </div>
  );
};

export default ReportAppLayout;
