import React from 'react';

interface UseTimerProps {
  onTimeUp: () => void;
  time: number;
}
const useTimer = ({ onTimeUp, time }: UseTimerProps) => {
  const [timeLeft, setTimeLeft] = React.useState<number>(time);
  const intervalIdRef = React.useRef<number>();

  React.useEffect(() => {
    if (timeLeft === 0 && intervalIdRef.current) {
      stopTimer();
      onTimeUp();
    }
  }, [onTimeUp, timeLeft]);

  /**
   * @param time - Initial time for timer in seconds
   */
  const startTimer = () => {
    if (intervalIdRef.current === undefined) {
      const intervalId = window.setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      intervalIdRef.current = intervalId;
    }
  };

  const stopTimer = () => {
    if (intervalIdRef.current !== undefined) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
    }
  };

  const resetTimer = () => {
    stopTimer();
    setTimeLeft(time);
  };

  return { startTimer, stopTimer, resetTimer, timeLeft, totalTime: time };
};

export default useTimer;
