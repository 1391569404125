import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { ResultCategoryName, validateResultCategoryName } from '../../../types/report';
import { CategoryTabsOutletContext } from './components/CategoryTabsSection';
import FluencyTabPanel from './components/FluencyTabPanel';
import PronunciationTabPanel from './components/PronunciationTabPanel';
import VocabularyTabPanel from './components/VocabularyTabPanel';
import { Text } from '../../../components/base';
import { Link } from 'react-router-dom';
import { reportAppRoutes } from '../../../router';
import Icon, { IconName } from '../../../components/base/Icon';
import { toClassNames } from '../../../utils';
import ConsultationSection from '../ReportDetail/components/ConsultationSection';

const categoryNames = [
  ResultCategoryName.Fluency,
  ResultCategoryName.Vocabulary,
  ResultCategoryName.Pronunciation,
] as const;

const ReportDetailCategoryTabPanel: FC = () => {
  const { activeCategory, report, schoolStatistic } = useOutletContext<CategoryTabsOutletContext>();
  const isCategoryNameValid = !!activeCategory && validateResultCategoryName(activeCategory);

  const { t } = useTranslation('report');

  const shouldShowFluencyTab =
    activeCategory === ResultCategoryName.Fluency &&
    !!report.result.category[ResultCategoryName.Fluency] &&
    !!schoolStatistic.category[ResultCategoryName.Fluency];

  const shouldShowVocabularyTab =
    activeCategory === ResultCategoryName.Vocabulary &&
    !!report.result.category[ResultCategoryName.Vocabulary] &&
    !!schoolStatistic.category[ResultCategoryName.Vocabulary];

  const shouldShowPronunciationTab =
    activeCategory === ResultCategoryName.Pronunciation &&
    !!report.result.category[ResultCategoryName.Pronunciation] &&
    !!schoolStatistic.category[ResultCategoryName.Pronunciation];

  if (!isCategoryNameValid) {
    return null;
  }

  return (
    <>
      <div className="mt-6">
        {shouldShowFluencyTab && (
          <FluencyTabPanel
            fluencyResult={report.result.category[ResultCategoryName.Fluency]}
            fluencyStatistic={schoolStatistic.category[ResultCategoryName.Fluency]}
          />
        )}
        {shouldShowVocabularyTab && (
          <VocabularyTabPanel
            vocabularyResult={report.result.category[ResultCategoryName.Vocabulary]}
            vocabularyStatistic={schoolStatistic.category[ResultCategoryName.Vocabulary]}
          />
        )}
        {shouldShowPronunciationTab && (
          <PronunciationTabPanel
            pronunciationResult={report.result.category[ResultCategoryName.Pronunciation]}
            pronunciationStatistic={schoolStatistic.category[ResultCategoryName.Pronunciation]}
          />
        )}
      </div>
      <div className="sm:hidden text-center mt-6">
        <Text variant="xl" className="font-semibold">
          {t('report_detail_category_your_other_assessment_result')}
        </Text>
        <div className="my-6 flex flex-col gap-y-3">
          {categoryNames.map((category) => {
            const shouldHide = category === activeCategory;
            return (
              <Link
                key={category}
                to={reportAppRoutes.ReportDetailCategory.getRoute({
                  testId: report.testId,
                  category,
                })}
                // we want smooth here
                preventScrollReset
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className={toClassNames([
                  shouldHide ? 'hidden' : 'flex',
                  'p-4 rounded-2xl justify-between items-center bg-white',
                ])}
                data-analytic-id="report-detail-category-go-to-other-category"
                data-analytic-detail={category}
              >
                <span>
                  <Text is="span" variant="lg" className="font-semibold capitalize">
                    {category}{' '}
                  </Text>
                  <Text is="span" variant="md" className="font-normal">
                    {t(`report_detail_category_other_indicators_section_${category}_button_label`)}
                  </Text>
                </span>
                <Icon icon={IconName.ArrowRight} size="24px" />
              </Link>
            );
          })}
        </div>
        <Link
          to={reportAppRoutes.ReportDetail.getRoute({ testId: report.testId })}
          data-analytic-id="report-detail-category-go-to-home-bottom"
        >
          <Text className="text-primary">{t('report_detail_category_back_to_overall_result')}</Text>
        </Link>
      </div>
      <ConsultationSection className="mt-7" location={`Report ${activeCategory} page`} />
    </>
  );
};

export default ReportDetailCategoryTabPanel;
