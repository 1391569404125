import i18n from '../../../i18n';
import { DropdownOption } from '../../../components/base/Form/Dropdown';
import { RegistrationFormDataKey } from '../../../model/registrationData';
import { TesterType } from '../../../model/tester';

export const requiredFieldForStudent: RegistrationFormDataKey[] = [
  RegistrationFormDataKey.prefix,
  RegistrationFormDataKey.firstName,
  RegistrationFormDataKey.lastName,
  RegistrationFormDataKey.grade,
  RegistrationFormDataKey.room,
];

export const requiredFieldForTeacher: RegistrationFormDataKey[] = [
  RegistrationFormDataKey.prefix,
  RegistrationFormDataKey.firstName,
  RegistrationFormDataKey.lastName,
  RegistrationFormDataKey.phoneNo,
  RegistrationFormDataKey.email,
];

export const requiredFieldForBusinessEmplyee: RegistrationFormDataKey[] = [
  RegistrationFormDataKey.prefix,
  RegistrationFormDataKey.firstName,
  RegistrationFormDataKey.lastName,
  RegistrationFormDataKey.email,
];

export const PREFIX_OPTIONS: DropdownOption[] = [
  { value: 'ด.ช.', text: 'ด.ช.' },
  { value: 'ด.ญ.', text: 'ด.ญ.' },
  { value: 'นาย', text: 'นาย' },
  { value: 'นางสาว', text: 'นางสาว' },
];

export const GRADE_OPTIONS = [
  { value: '0', text: 'Kindergarten (อนุบาล)' },
  { value: '1', text: 'Grade 1 (ป.1)' },
  { value: '2', text: 'Grade 2 (ป.2)' },
  { value: '3', text: 'Grade 3 (ป.3)' },
  { value: '4', text: 'Grade 4 (ป.4)' },
  { value: '5', text: 'Grade 5 (ป.5)' },
  { value: '6', text: 'Grade 6 (ป.6)' },
  { value: '7', text: 'Grade 7 (ม.1)' },
  { value: '8', text: 'Grade 8 (ม.2)' },
  { value: '9', text: 'Grade 9 (ม.3)' },
  { value: '10', text: 'Grade 10 (ม.4)' },
  { value: '11', text: 'Grade 11 (ม.5)' },
  { value: '12', text: 'Grade 12 (ม.6)' },
  { value: '13', text: 'College (มหาวิทยาลัย)' },
];

export const REGISTRATION_TYPE_OPTIONS: DropdownOption[] = [
  { value: TesterType.Student, text: i18n.t('tester_type.student', { ns: 'common' }) },
  { value: TesterType.Teacher, text: i18n.t('tester_type.teacher', { ns: 'common' }) },
];
