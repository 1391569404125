import { useState } from 'react';
import { Heading, Modal, Button, Input } from '../../../../components/base';
import {
  CreateSpeakingTestQuestionParams,
  CreateSpeakingTestWithQuestionsParams,
} from '../../../../api/test';
import CreateSpeakingTestQuestionModal from './CreateSpeakingTestQuestionModal';
import { SpeakingTestQuestionPromptContent } from '../../../../model/speakingTestQuestion';

interface CreateModalProps {
  open: boolean;
  onPrimaryCtaClick: (values: CreateSpeakingTestWithQuestionsParams) => void;
  onSecondaryCtaClick: () => void;
}

const CreateSpeakingTestWithQuestionModal = ({
  open,
  onSecondaryCtaClick,
  onPrimaryCtaClick,
}: CreateModalProps) => {
  const [name, setName] = useState('');
  const [questions, setQuestions] = useState<CreateSpeakingTestQuestionParams[]>([]);
  const [createSpeakingQuesitonModalOpen, setCreateSpeakingQuesitonModalOpen] =
    useState<boolean>(false);

  const onClick = () => {
    const values: CreateSpeakingTestWithQuestionsParams = {
      name,
      questions,
    };
    onPrimaryCtaClick(values);
  };

  const handleChangeName = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleClickAddQuestion = () => {
    setCreateSpeakingQuesitonModalOpen(true);
  };

  const handleCreateSpeakingQuestion = (createQuestionValue: CreateSpeakingTestQuestionParams) => {
    const newQuestions = [...questions, createQuestionValue];
    setQuestions(newQuestions);
    setCreateSpeakingQuesitonModalOpen(false);
  };

  const handleCancelCreatingSpeakingQuestion = () => {
    setCreateSpeakingQuesitonModalOpen(false);
  };

  const handleDeleteQuesiton = (index: number) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions([...newQuestions]);
  };

  return (
    <Modal open={open} size="xl">
      <div className="flex flex-col justify-center items-start">
        <Heading variant="h1" className="mb-8">
          Create Speaking Test with Questions
        </Heading>
        <div className="flex flex-col gap-4 mb-12 w-full">
          <Input
            value={name}
            onChange={handleChangeName}
            placeholder="Test Name"
            className="mb-4"
          />
          <table>
            <tr>
              <th>type</th>
              <th>prompts</th>
              <th>CEFRLevel</th>
              <th>timeLimit</th>
              <th>prepTimeLimit</th>
              <th>isPrivate</th>
              <th>Action</th>
            </tr>
            {questions.map((q, index) => {
              return (
                <tr
                  key={`${q.prompts
                    .map((p) => `${p.content}-${p.type}-${index}`)
                    .join('')}-${index}`}
                  className="align-top p-2"
                >
                  <td>{q.type}</td>
                  <td className="pl-8 pr-2 py-2">
                    <ul className="w-full list-decimal">
                      {q.prompts.map((prompt: SpeakingTestQuestionPromptContent) => {
                        return (
                          <li className="text-left" key={`${prompt.type}-${prompt.content}`}>
                            <div className="overflow-hidden text-ellipsis">
                              {prompt.type} - {prompt.content}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td>{q.cefrLevel}</td>
                  <td>{q.timeLimit}</td>
                  <td>{q.preparationTimeLimit}</td>
                  <td>{q.isPrivate}</td>
                  <td>
                    <Button variant="secondary" onClick={() => handleDeleteQuesiton(index)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </table>
          <Button variant="secondary" onClick={handleClickAddQuestion}>
            Add Question
          </Button>
        </div>
        <div className="flex gap-4 mb-4 justify-end">
          <Button variant="secondary" onClick={onSecondaryCtaClick}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClick}>
            Create
          </Button>
        </div>
      </div>
      {createSpeakingQuesitonModalOpen && (
        <CreateSpeakingTestQuestionModal
          open={createSpeakingQuesitonModalOpen}
          onPrimaryCtaClick={handleCreateSpeakingQuestion}
          onSecondaryCtaClick={handleCancelCreatingSpeakingQuestion}
        />
      )}
    </Modal>
  );
};

export default CreateSpeakingTestWithQuestionModal;
