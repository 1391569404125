import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../components/base';
import { toClassNames } from '../../../../utils';

interface Props {
  testerPercentage: number;
  edsyMeanPercentage: number;
  className?: string;
}
const PronunciationComparisonChart: FC<Props> = ({
  testerPercentage,
  edsyMeanPercentage,
  className,
}) => {
  const { t } = useTranslation('report');
  return (
    <div
      className={toClassNames([
        className,
        'p-5 bg-white rounded-2xl border-primary-100 border sm:border-none',
      ])}
    >
      <Text variant="lg" className="text-primary-500 font-semibold">
        {t('report_detail_category_pronunciation_comparison_chart_title_first_line')}
      </Text>
      <Text>{t('report_detail_category_pronunciation_comparison_chart_title_second_line')}</Text>
      <div className="mt-4 flex items-center gap-x-4">
        <Text className="whitespace-nowrap">
          {t('report_detail_category_pronunciation_comparison_chart_your_level')}
        </Text>
        {/* Bar */}
        <div className="flex items-center gap-x-2 w-full h-[35px] rounded-xl bg-green-50">
          <div
            style={{ width: `${testerPercentage}%` }}
            className="flex justify-end items-center sm:min-w-0 h-full bg-primary-900 px-2 py-1 rounded-xl"
          >
            <Text
              className={toClassNames([
                testerPercentage <= 70 ? 'hidden' : '',
                'text-white font-semibold',
              ])}
            >
              {testerPercentage}%
            </Text>
          </div>
          <Text
            className={toClassNames([
              testerPercentage > 70 ? 'hidden' : '',
              'text-primary-900 font-semibold',
            ])}
          >
            {testerPercentage}%
          </Text>
        </div>
      </div>

      <div className="mt-4 flex items-center gap-x-4">
        <Text className="whitespace-nowrap">
          {t('report_detail_category_pronunciation_comparison_chart_edsy_mean')}
        </Text>
        {/* Bar */}
        <div className="flex items-center gap-x-2 w-full h-[25px] rounded-xl bg-grey-50">
          <div
            style={{ width: `${edsyMeanPercentage}%` }}
            className="flex justify-end items-center sm:min-w-0 h-full bg-grey-200 px-2 py-1 rounded-xl"
          >
            <Text
              className={toClassNames([
                testerPercentage <= 70 ? 'hidden' : '',
                'text-grey-500 font-semibold',
              ])}
            >
              {edsyMeanPercentage}%
            </Text>
          </div>
          <Text
            className={toClassNames([
              testerPercentage > 70 ? 'hidden' : '',
              'text-grey-500 font-semibold',
            ])}
          >
            {edsyMeanPercentage}%
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PronunciationComparisonChart;
