import QRCode from 'react-qr-code';
import style from '../SummaryReportPrint.module.css';

const Footer = ({
  withQrCode = true,
  qrCodeUrl,
  testId,
}: {
  withQrCode?: boolean;
  qrCodeUrl: string;
  testId: string;
}) => {
  return (
    <div className={`${style['report-print-detail-footer']} flex justify-between`}>
      <div className={style['footer-item']}>
        <h2 className={style['footer-item-title']}>เครื่องมือ EdSpeak ของ Edsy</h2>
        <div className={style['footer-item-content']}>
          พัฒนาโดยผู้เชี่ยวชาญของ Edsy จากมหาวิทยาลัย Carnegie Mellon (USA) และ Oxford (UK)
          ผ่านโครงการวิจัยที่ทำร่วมกับมหาวิทยาลัย KMUTT (มจธ. บางมด)
          โดยอ้างอิงงานวิจัยซึ่งใช้ระยะเวลากว่า 15 ปีขององค์กร ETS (ผู้สร้าง-ดำเนินการสอบ TOEFL และ
          TOEIC)
        </div>
      </div>
      <div className={`${style['footer-item']} flex gap-4`}>
        <div>
          <h2 className={`${style['footer-item-title']} text-primary`}>
            {withQrCode ? 'สแกน QR เพื่อดูรายละเอียดเพิ่มเติม:' : 'รายงานฉบับเต็มประกอบด้วย'}
          </h2>
          <div className={style['footer-item-content']}>
            <ul>
              <li>
                <span>ผลการประเมินสำหรับแต่ละตัวชี้วัดหลัก</span>
              </li>
              <li>
                <span>
                  <strong>Personalized Recommendation</strong> แนวทาง <br />
                  ในการพัฒนาแต่ละส่วนเพิ่มเติม
                </span>
              </li>
              <li>
                <span>
                  แนะนำ <strong>Resources</strong> ต่างๆ ที่เป็นประโยชน์
                </span>
              </li>
            </ul>
          </div>
        </div>
        {withQrCode && (
          <div className={style['report-print-qr-code']}>
            <QRCode
              value={`https://edspeak.edsy.co/reports/${testId}`}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              size={256}
              viewBox={`0 0 256 256`}
            />
            <h5 className={style['report-print-qr-code-desc']}>สแกนเพื่อดู report ฉบับเต็ม</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
