import React from 'react';
import NavBar from './components/NavBar';

const PrintLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div>
      <div className="print:hidden">
        <NavBar />
      </div>
      {children}
    </div>
  );
};

export default PrintLayout;
