import { Modal, Text, Image, Heading, Button } from './base';

interface ConfirmModalProps {
  open: boolean;
  title: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  onPrimaryCtaClick: () => void;
  onSecondaryCtaClick: () => void;
}

const ConfirmModal = ({
  open,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  onSecondaryCtaClick,
  onPrimaryCtaClick,
}: ConfirmModalProps) => {
  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Image
          src={'/assets/images/modal-confirm.png'}
          alt="submitting-modal"
          className="w-[180px] mb-6"
        />
        <Heading variant="h1" className="mb-2">
          {title}
        </Heading>
        <Text className="text-grey-500 mb-8 text-center px-4">{description}</Text>
        <div className="flex gap-6 mb-4">
          <Button variant="secondary" onClick={onSecondaryCtaClick}>
            {secondaryButtonText}
          </Button>
          <Button variant="primary" onClick={onPrimaryCtaClick}>
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
