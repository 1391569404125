import React from 'react';
import { useParams } from 'react-router-dom';
import { Report } from '../../types/report';
import { Container, Loading } from '../../components/base';
import ReporDetailtView from './ReportDetail.view';
import { getReportByTestId } from '../../api/report';

const ReportDetail = () => {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [report, setReport] = React.useState<Report | undefined>();

  React.useEffect(() => {
    const getReportData = async () => {
      if (params.testId) {
        try {
          const report = await getReportByTestId(params.testId);
          if (report) {
            setReport(report);
          }
          setLoading(false);
        } catch (error: any) {
          console.error(error);
        }
      }
    };
    getReportData();
  }, [params.testId]);

  if (loading || !report)
    return (
      <Container className="p-8">
        <Loading />
      </Container>
    );
  return <ReporDetailtView report={report} school={report.school} />;
};

export default ReportDetail;
