import React from 'react';

import { Container } from '../../components/base';
import { toClassNames } from '../../utils';

const LayoutCard = ({
  children,
  noPadding,
  className,
}: {
  children?: React.ReactNode;
  noPadding?: boolean;
  className?: string;
}) => {
  return (
    <Container size="lg">
      <div
        className={toClassNames([
          'bg-white rounded-2xl shadow-md min-h-[480px] sm:min-h-[520px]',
          !noPadding ? 'p-10' : '',
          className,
        ])}
      >
        {children}
      </div>
    </Container>
  );
};

export default LayoutCard;
