import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import StarsRating from '../../../../components/StarsRating';
import {
  FluencyResult,
  FluencySubCategoryName,
  ResultCategoryName,
} from '../../../../types/report';
import { FluencyStatistic } from '../../../../types/reportStatistic';
import { scoreToStarCount, starsCountToText } from '../../../../utils';
import CategoryEmptyState from './CategoryEmptyState';
import EdSpeakSuggestions from './EdSpeakSuggestions';
import HowWeMeasureMetrics from './HowWeMeasureMetrics';
import { WordsPerMinute } from './WordsPerMinute';

interface Props {
  fluencyResult: FluencyResult;
  fluencyStatistic: FluencyStatistic;
}
const FluencyTabPanel: FC<Props> = ({ fluencyResult, fluencyStatistic }) => {
  const { t } = useTranslation('report');
  const speedResult = fluencyResult[FluencySubCategoryName.Speed];
  const speedStatistic = fluencyStatistic[FluencySubCategoryName.Speed];
  const starsCount = scoreToStarCount(fluencyResult.score);
  const starsCountString = starsCountToText(starsCount);

  if (fluencyResult.score <= 0) {
    return <CategoryEmptyState category={ResultCategoryName.Fluency} />;
  }

  return (
    <div className="bg-white rounded-3xl px-4 py-7 sm:p-0">
      <div className="flex flex-col items-center gap-y-3">
        <Heading variant="h1" className="sm:hidden text-gradient-gd-1">
          Fluency
        </Heading>
        <Text className="text-grey-600">{t('report_detail_category_fluency_tab_subtitle')}</Text>
        <StarsRating score={fluencyResult.score} fullScore={5} className="my-2" />
        <Text variant="2xl" className="text-gradient-gd-1 font-bold text-center mb-2">
          {t(`stars_rating_label.${starsCountString}`, { ns: 'common' })}
        </Text>
        <Text className="text-grey-600 max-w-xs text-center">
          {t(`report_detail_category_fluency_stars_rating_description.${starsCountString}`)}
        </Text>
      </div>
      <div className="mt-8">
        <WordsPerMinute value={speedResult.wordCountPerMinute} max={150} />
      </div>
      <div className="bg-primary-50 rounded-xl flex justify-center items-center mt-7 mx-auto sm:w-fit px-3 py-[6px] ">
        <Icon icon={IconName.BulbOn} size="20px" className="text-primary inline-block mr-1" />
        <Text is="span" variant="sm" className="sm:whitespace-normal">
          {t('report_detail_category_fluency_edsy_student_average_wpm_is', {
            wpm: speedStatistic.mean,
          })}
        </Text>
      </div>
      <HowWeMeasureMetrics category={ResultCategoryName.Fluency} className="mt-7 sm:mt-11" />
      <EdSpeakSuggestions
        category={ResultCategoryName.Fluency}
        starsCount={starsCount}
        className="mt-7 sm:mt-11"
      />
    </div>
  );
};

export default FluencyTabPanel;
