import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Image, Heading } from '../../../../components/base';
import StarsRating from '../../../../components/StarsRating';
import { ResultCategoryName } from '../../../../types/report';
import { toClassNames } from '../../../../utils';

type AcceptedCategory =
  | ResultCategoryName.Fluency
  | ResultCategoryName.Vocabulary
  | ResultCategoryName.Pronunciation;
interface Props {
  category: Extract<ResultCategoryName, AcceptedCategory>;
  className?: string;
}
const CategoryEmptyState: FC<Props> = ({ category, className }) => {
  const { t } = useTranslation('report');
  return (
    <div
      className={toClassNames([
        className,
        'bg-white px-4 py-7 rounded-3xl flex flex-col items-center gap-y-3',
      ])}
    >
      <Heading variant="h1" className="sm:hidden text-gradient-gd-1 capitalize">
        {category}
      </Heading>
      <Text className="text-grey-400">{t(`report_detail_category_${category}_tab_subtitle`)}</Text>
      <StarsRating score={0} fullScore={5} greyScale />
      <Text variant="2xl" className="text-gradient-gd-1 font-bold">
        {t(`stars_rating_label.zero`, { ns: 'common' })}
      </Text>
      <Text className="text-grey-600">{t(`please_take_the_test_again`)}</Text>
      <Image
        src={`/assets/images/report-app/report-detail-category-empty-state.png`}
        alt="empty state mascot"
        className="w-[150px]"
      />
      <Text className="text-grey-400 max-w-xs text-center">
        {t(`report_detail_category_${category}_stars_rating_description.zero`)}
      </Text>
    </div>
  );
};

export default CategoryEmptyState;
