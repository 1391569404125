import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../router';
import { Container, Image } from '../../components/base';
import { toClassNames } from '../../utils';

const NavBar = ({
  children,
  logoLinkUrl = routes.Home.getRoute(),
  whiteBackground = false,
}: {
  children?: React.ReactNode;
  logoLinkUrl?: string;
  whiteBackground?: boolean;
}) => {
  return (
    <div
      className={toClassNames([
        whiteBackground ? 'bg-white' : 'bg-primary',
        'h-[80px] flex items-center',
      ])}
    >
      <Container fluid={true}>
        <Link to={logoLinkUrl}>
          <Image
            src={
              whiteBackground
                ? '/assets/images/edsy-logo.png'
                : '/assets/images/edsy-logo-white.png'
            }
            alt="edsy"
            className="w-[96px]"
          />
        </Link>
        {children}
      </Container>
    </div>
  );
};

export default NavBar;
