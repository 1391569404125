import { useTranslation } from 'react-i18next';
import { Heading, Text } from '../../../../components/base';
import { PreTestQuestionChoice } from '../../../../model/preTestQuestion';
import { toClassNames } from '../../../../utils';
import style from './PreTestQuestion.module.css';

interface PreTestChoiceProps {
  selected: boolean;
  onClick: () => void;
  content: string;
}
const PreTestChoice = ({ selected, onClick, content }: PreTestChoiceProps) => {
  return (
    <div
      onClick={onClick}
      className={toClassNames([style['choice-container'], selected ? style['selected'] : ''])}
    >
      {content}
    </div>
  );
};

interface PreTestQuestionProps {
  questionNo: number;
  question: string;
  choices: PreTestQuestionChoice[];
  selectedChoiceId?: string;
  onSelectChoice: (choiceId: string) => void;
}
const PreTestQuestion = ({
  questionNo,
  question,
  choices,
  selectedChoiceId,
  onSelectChoice,
}: PreTestQuestionProps) => {
  const { t } = useTranslation('assessment');
  const handleSelectChocie = (choiceId: string) => {
    onSelectChoice(choiceId);
  };

  return (
    <div className="mb-16">
      <Heading variant="h3" is="h1" className="font-semibold mb-4">
        {t('assessment_pre_test_question_heading')} {questionNo}
      </Heading>
      <Text className="mb-4">{question}</Text>
      <div className="flex flex-col gap-3">
        {choices.map((c) => {
          const content =
            c.content === 'ไม่แน่ใจ' ? t('assessment_pre_test_choice_i_do_not_know') : c.content;
          return (
            <PreTestChoice
              onClick={() => handleSelectChocie(c.id)}
              key={c.id}
              selected={selectedChoiceId === c.id}
              content={content}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PreTestQuestion;
