import dayjs from 'dayjs';
import { Container, Heading, Text, Image } from '../../../components/base';

type TesterInfoSectionProps = {
  fullName: string;
  schoolName: string;
  grade: string;
  room?: string;
  testDate: Date;
};
const TesterInfoSection = ({
  fullName,
  schoolName,
  grade,
  room,
  testDate,
}: TesterInfoSectionProps) => {
  return (
    <div className="bg-primary-50 py-10">
      <Container>
        <Heading variant="h5" is="h1" className="text-primary text-center mb-6">
          ผลการวัดระดับทักษะการพูดภาษาอังกฤษ
          <br />
          <Text variant="3xl" is="span" className="font-bold">
            EdSpeak
          </Text>{' '}
          by Edsy
        </Heading>

        <div className="rounded-4xl bg-yellow-500 p-5">
          <Heading variant="h5" className="mb-4 text-center font-bold">
            ข้อมูลผู้ทดสอบ
          </Heading>
          <div className="flex gap-3 items-center">
            {/* Mock profile image */}
            <div className="rounded-4xl bg-primary-50 p-2 w-[92px] h-[92px]">
              <Image src="/assets/images/rocket-avatar.svg" alt="avatar" />
            </div>
            <div>
              <Text>
                <span className="font-semibold">ชื่อ:</span> {fullName}
              </Text>
              <Text>
                <span className="font-semibold">โรงเรียน:</span> {schoolName}
              </Text>
              <Text>
                <span className="font-semibold">ระดับชั้น:</span> {grade}{' '}
                {room ? `ห้อง ${room}` : null}
              </Text>
              <Text>
                <span className="font-semibold">วันที่สอบ:</span>{' '}
                {dayjs(testDate).format('D MMMM BBBB')}
              </Text>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TesterInfoSection;
