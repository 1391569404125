import * as assessmentApiClient from '../../../api/assessment';
import { Tester } from '../../../model/tester';
import { AccessCode } from '../../../model/accessCode';
import { School } from '../../../model/school';
import { RegistrationFormData } from '../../../model/registrationData';

/**
 * Get accessCodeObj, tester, and school, from accessCode
 */
export const getInitialDataForRegistration = async (
  accessCode: string
): Promise<{ accessCode: AccessCode; tester?: Tester; school: School }> => {
  /**
   * Get accessCodeObj from accessCode
   */
  let accessCodeObj;
  try {
    accessCodeObj = await assessmentApiClient.getAccessCodeByCode(accessCode);
  } catch (error) {
    console.error(error);
    throw new Error(`ไม่พบโค้ด ${accessCode} ในระบบ`);
  }

  /**
   * Check if there is a schoolId in accessCode
   */
  if (!accessCodeObj.schoolId) {
    throw new Error(`ไม่พบ schoolId ในโค้ด ${accessCode}`);
  }

  /**
   * Get Tester and School from accessCode
   */
  let tester;
  let school;
  try {
    [tester, school] = await Promise.all([
      accessCodeObj.testerId
        ? assessmentApiClient.getTesterById(accessCodeObj.testerId)
        : undefined,
      assessmentApiClient.getSchoolById(accessCodeObj.schoolId),
    ]);
  } catch (error) {
    console.error(error);
    throw new Error(`ไม่สามารถหาข้อมูล tester หรือ school ในระบบได้`);
  }

  return { accessCode: accessCodeObj, tester, school };
};

/**
 * Register users to access code
 */
export const registerTesterToAccessCode = async (
  accessCode: AccessCode,
  formData: RegistrationFormData
): Promise<string> => {
  try {
    const { testId } = await assessmentApiClient.registerTester(accessCode, formData);
    return testId;
  } catch (error) {
    throw new Error(`ไม่สามารถลงทะเบียนผู้ทดสอบเข้ากับโค้ด ${accessCode.code} ได้`);
  }
};
