import { FC } from 'react';
import { ResultCategoryName } from '../../../../types/report';
import { Image, Text } from '../../../../components/base';
import { useTranslation } from 'react-i18next';
import { starsCountToText, toClassNames } from '../../../../utils';

type AcceptedCategory = 'fluency' | 'pronunciation' | 'vocabulary';
interface Props {
  category: Extract<ResultCategoryName, AcceptedCategory>;
  starsCount: number;
  className?: string;
}

const EdSpeakSuggestions: FC<Props> = ({ category, starsCount, className }) => {
  const { t, i18n } = useTranslation('report');
  const categoryLabelKey = `report_detail_category_${category}_label` as const;
  const keyPrefix = `report_detail_category_${category}_stars_rating_suggestion` as const;
  const starsCountText = starsCountToText(starsCount);
  const suggestion = t(`${keyPrefix}.${starsCountText}`);

  return (
    <div className={toClassNames([className, 'rounded-2xl bg-green-50 p-7 flex gap-x-5'])}>
      <Image
        src={`/assets/images/report-app/report-detail-category-${category}.png`}
        alt="How we measure metrics?"
        className="hidden sm:block h-full"
      />
      <div>
        <Text>
          <span className="capitalize">{category}</span>
          <span className={i18n.language === 'en' ? 'hidden' : ''}> {t(categoryLabelKey)}</span>
        </Text>
        <Text variant="2xl" className="mt-1 font-bold text-green-600">
          {t('suggestion_from')} EdSpeak
        </Text>
        <div className="mt-3 grid grid-cols-[auto,1fr] gap-x-2 text-gray-600">
          <Text className="whitespace-pre-line">{suggestion}</Text>
          {/* {suggestions.map((description, index) => (
            <Fragment key={index}>
              <Text>&bull;</Text>
            </Fragment>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default EdSpeakSuggestions;
