import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Text } from '../../../components/base';
import { formatEdSpeakScore } from '../../../utils';

type ScoreCircleProgressProps = {
  score: number;
  fullScore: number;
};
const CircleProgress = ({ score, fullScore }: ScoreCircleProgressProps) => {
  const data = [
    { name: 'rest', value: fullScore - score, color: '#D9D9D9' },
    { name: 'score', value: score, color: '#334D9C' },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          innerRadius={60}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          isAnimationActive={false}
        >
          {data.map(({ color }, index) => (
            <Cell key={`cell-${index}`} fill={color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

const ScoreCircleProgress = ({ score, fullScore }: ScoreCircleProgressProps) => {
  return (
    <div className="h-[210px] w-[210px] relative">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
        <Text variant="4xl" className="text-primary font-bold">
          {formatEdSpeakScore(score)}
        </Text>
        <Text variant="sm">จาก 230 คะแนน</Text>
      </div>
      <CircleProgress score={score > 0 ? score : 0} fullScore={fullScore} />
    </div>
  );
};

export default ScoreCircleProgress;
