import { Button, LoadingModal } from '../../../../components/base';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { School } from '../../../../model/school';
import { internalAdminRoute } from '../../../../router';
import UploadModal from './UploadModal';
import React from 'react';
import * as adminApiClient from '../../../../api/admin';

interface SchoolsTableProps {
  schools: School[];
}

const SchoolsTable = ({ schools }: SchoolsTableProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerAlign: 'center',
        headerName: 'School id',
        align: 'center',
        flex: 0.5,
      },
      {
        field: 'name',
        headerAlign: 'center',
        headerName: 'Name',
        align: 'center',
        flex: 0.3,
      },
      {
        field: 'logoUrl',
        headerAlign: 'center',
        headerName: 'logoUrl',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.logoUrl ?? '-',
      },
      {
        field: 'fullReportAdsUrl',
        headerAlign: 'center',
        headerName: 'fullReportAdsUrl',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.fullReportAdsUrl.desktop ?? '-',
      },
      {
        field: 'sumReportAdsUrl',
        headerAlign: 'center',
        headerName: 'sumReportAdsUrl',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.sumReportAdsUrl ?? '-',
      },
      {
        field: 'sumReportTemplate2BackgroundUrl',
        headerAlign: 'center',
        headerName: 'sumReportTemplate2BackgroundUrl',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.sumReportTemplate2BackgroundUrl ?? '-',
      },
      {
        field: 'fullReportAdsLink',
        headerAlign: 'center',
        headerName: 'fullReportAdsLink',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.fullReportAdsLink ?? '-',
      },
      {
        field: 'sumReportQrToReportUrl',
        headerAlign: 'center',
        headerName: 'sumReportQrToReportUrl',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.sumReportQrToReportUrl ?? '-',
      },
      {
        field: 'sumReportQrToFormUrl',
        headerAlign: 'center',
        headerName: 'sumReportQrToFormUrl',
        align: 'center',
        flex: 0.3,
        valueGetter: (params) => params.row.sumReportQrToFormUrl ?? '-',
      },
      {
        field: 'seeReport',
        headerAlign: 'center',
        headerName: 'Report',
        align: 'center',
        flex: 0.3,
        renderCell: ({ row }) => (
          <Button
            variant="outlined"
            isRouterLink={true}
            to={internalAdminRoute.InternalAdminSchoolReports.getRoute(row.id)}
            target="_blank"
          >
            See Reports
          </Button>
        ),
      },
      {
        field: 'uploadLogo',
        headerAlign: 'center',
        headerName: 'Logo',
        align: 'center',
        flex: 0.3,
        renderCell: ({ row }) => (
          <Button variant="outlined" onClick={() => handleOpenUploadLogoModal(row.id)}>
            New Logo
          </Button>
        ),
      },
    ],
    []
  );

  const handleOpenUploadLogoModal = (id: string) => {
    setSelectedSchoolId(id);
    setOpenModal(true);
  };

  const handleCloseUploadLogoModal = () => {
    setOpenModal(false);
  };

  const handleUploadLogo = async (file: File) => {
    setLoading(true);
    try {
      if (selectedSchoolId) {
        await adminApiClient.uploadNewSchoolLogo(selectedSchoolId, file);
        alert('Upload success!');
      } else {
        throw new Error('no selected school Id');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setSelectedSchoolId('');
      setOpenModal(false);
      window.location.reload();
    }
  };

  return (
    <div className="text-center" style={{ width: '100%', overflow: 'auto' }}>
      <div className="w-[1700px]">
        <DataGrid
          getRowId={(row) => row.id}
          rows={schools}
          columns={columns}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cellContent': {
              overflowWrap:'break-word',
              wordWrap:'break-word',
              whiteSpace:'initial',
              paddingY:1
            },
          }}
        />
      </div>
      <UploadModal
        open={openModal}
        onCancel={handleCloseUploadLogoModal}
        onUploadLogo={handleUploadLogo}
      />
      <LoadingModal open={loading} />
    </div>
  );
};

export default SchoolsTable;
