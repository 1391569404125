export enum AccessCodeType {
  School = 'SCHOOL',
  Business = 'BUSINESS',
}
export class AccessCode {
  constructor(
    public code: string,
    public credits: number,
    public beta: boolean,
    public schoolId?: string,
    public testerId?: string,
    public speakingTestId?: string,
    public type?: AccessCodeType
  ) {}
  public static parse(json: { [key: string]: any }): AccessCode {
    const accessCode = new AccessCode(
      json['code'],
      json['credits'],
      json['beta'] ?? false,
      json['schoolId'],
      json['testerId'],
      json['speakingTestId'],
      json['type']
    );
    return accessCode;
  }
}

/**
 * Mock object for testing and mocking apis.
 */
export const mockAccessCodeStudent = new AccessCode(
  'AAAAA',
  1,
  false,
  'mockSchoolId',
  'mockTesterIdStudent'
);

export const mockAccessCodeTeacher = new AccessCode(
  'AAAAA',
  1,
  false,
  'mockSchoolId',
  'mockTesterIdTeacher'
);

export const mockAccessCodeWithoutTester = new AccessCode('AAAAA', 1, false, 'mockSchoolId');
