import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getReportByTestId } from '../../../api/report';
import { Container, Loading, Heading, Image, Text } from '../../../components/base';
import { reportAppRoutes } from '../../../router';
import type { Report } from '../../../types/report';
import { ReportDetailView } from './ReportDetailView.view';

const ReportDetail: FC = () => {
  const { t } = useTranslation('report');
  const params = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState<Report>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!navigate) return;
    const fetchReport = async () => {
      if (!params.testId) {
        navigate(reportAppRoutes.Home.getRoute());
        return;
      }
      try {
        const report = await getReportByTestId(params.testId);
        if (report) {
          setReport(report);
        }
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setLoading(false);
      }
    };
    fetchReport();
  }, [navigate, params.testId]);

  if (loading) {
    return (
      <Container className={`p-8 flex-1`}>
        <Loading />
      </Container>
    );
  }

  if (!report) {
    return (
      <Container className={`p-8 flex-1 flex flex-col items-center`}>
        <Image
          src="/assets/images/report-app/report-detail-no-report.png"
          alt="no-report"
          className="w-[240px] mb-8"
        />
        <Heading variant="h2" is="h1" className="text-primary">
          {t('report_detail_no_report_heading')}
        </Heading>
        <Text variant="xl" className="mb-6">
          {t('report_detail_no_report_please_try_again')}
        </Text>
        <div className="flex gap-1 justify-center">
          <Text variant="md" className="text-center text-grey-500">
            {t('report_detail_no_report_please_contact')}
          </Text>
          <Image src="/assets/images/line-icon.png" alt="line" />
          <Text variant="md" className="text-center text-grey-500">
            @edsy
          </Text>
        </div>
      </Container>
    );
    // return <Navigate to={reportAppRoutes.Home.getRoute()} />;
  }

  return <ReportDetailView report={report} school={report.school} />;
};

export default ReportDetail;
