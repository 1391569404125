import React from 'react';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Form.module.css';

interface InputProps extends BaseProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  disabled?: boolean;
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  readonly?: boolean;
}

const Input = ({
  value,
  onChange,
  disabled,
  placeholder,
  className,
  name,
  type = 'text',
  readonly,
}: InputProps) => {
  return (
    <input
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled || readonly}
      className={toClassNames([
        style.base,
        style['base-spacing'],
        style.input,
        readonly ? style.readonly : '',
        disabled ? style.disabled : '',
        className,
      ])}
      name={name}
      type={type}
    />
  );
};

export default Input;
