import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * See https://day.js.org/docs/en/durations/format for format options
 */
export const convertSeconds = (seconds: number, format: string) => {
  return dayjs.duration(seconds, 'seconds').format(format);
};
