import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Text } from '../../../../components/base';
import { cefrLevels } from '../../../../data/cefr';
import { cefrLevelToEdSpeakScoreRange } from '../../../../data/edSpeak';
import { CEFRLevel } from '../../../../types/cefr';
import { toClassNames } from '../../../../utils';
import style from '../ReportDetail.module.css';

interface Props {
  level: CEFRLevel;
}
export const CEFRLevelChartMobile: FC<Props> = ({ level: currentLevel }) => {
  const { t } = useTranslation();

  const cefrLevelLebel = t('cefr_level_label', { ns: 'cefr', returnObjects: true });

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex justify-between">
        <Text variant="xs" className="font-semibold rounded-lg py-1 px-3 bg-grey-100">
          CEFR Level
        </Text>
        <Text variant="xs" className="font-semibold rounded-lg py-1 px-3 bg-grey-100">
          EdSpeak
        </Text>
      </div>
      {cefrLevels
        .slice()
        .reverse()
        .map((cefrLevel) => {
          const isCurrent = cefrLevel === currentLevel;
          const isAbove = cefrLevels.indexOf(cefrLevel) > cefrLevels.indexOf(currentLevel);
          return (
            <div
              key={cefrLevel}
              className={toClassNames([
                'px-4 py-3',
                'border-[3px] border-transparent rounded-xl',
                isCurrent
                  ? //darker stripe require opacity to blend with background-color, lighter strip is same as background-color
                    `text-white !border-primary-800 ${style['cefr-score-chart-mobile-bar-background-stripe']} bg-primary-400 from-primary-400 to-primary-700/10`
                  : isAbove
                  ? 'bg-primary-50'
                  : 'bg-grey-50',
              ])}
            >
              <div className={'flex justify-between items-center'}>
                <Text
                  variant="sm"
                  className={toClassNames([
                    'font-semibold',
                    isCurrent ? '' : isAbove ? 'text-primary-700/60' : 'text-grey-700/60',
                  ])}
                >
                  {cefrLevel}
                </Text>
                <Text
                  variant="sm"
                  className={toClassNames([
                    'font-semibold',
                    isCurrent ? '' : isAbove ? 'text-primary-700/60' : 'text-grey-700/60',
                  ])}
                >
                  {cefrLevelToEdSpeakScoreRange[cefrLevel].rangeText}
                </Text>
              </div>
              {isCurrent && (
                <>
                  <hr className="my-2 opacity-30" />
                  <div className="flex gap-x-[6px] items-center">
                    <Image
                      src="/assets/images/stars/star-full.svg"
                      alt="star icon"
                      className="w-3"
                    />
                    <Text variant="xs" className="capitalize">
                      {t('your_level')}:
                    </Text>
                    <Text variant="xs">{cefrLevelLebel[cefrLevel]}</Text>
                  </div>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default CEFRLevelChartMobile;
