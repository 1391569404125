import { useState } from 'react';
import { Heading, Modal, Button, Input } from '../../../../components/base';

interface UploadModalProps {
  open: boolean;
  onUploadLogo: (file: File) => void;
  onCancel: () => void;
}

const UploadModal = ({ open, onCancel, onUploadLogo }: UploadModalProps) => {
  const [file, setFile] = useState<File>();
  const handleClick = () => {
    if (!file) return;
    onUploadLogo(file);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <Modal open={open} size="md">
      <div className="flex flex-col justify-center items-center py-8">
        <Heading variant="h1" className="mb-2">
          Upload school logo
        </Heading>
        <input type='file' onChange={handleFileChange} className="mb-6 p-6" />
        <div className="flex gap-6 mb-4">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClick} disabled={!file}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;