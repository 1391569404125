import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Image, Text } from '../../../../components/base';
import Icon, { IconName } from '../../../../components/base/Icon';
import { reportAppRoutes } from '../../../../router';
import { ResultCategoryName } from '../../../../types/report';
import { StarsCount } from '../../../../types/starsRating';
import { starsCountToText, toClassNames } from '../../../../utils';

const categoryToCardDataMapping = {
  fluency: {
    subTitle: 'Fluency',
    titleTranslationKey: 'report_detail_category_fluency_label',
    imageSrc: '/assets/images/report-app/report-detail-category-fluency-summary.png',
    descriptionTranslationKeyPrefix: 'report_detail_category_fluency_stars_rating_description',
    className: {
      title: 'text-primary',
    },
  },
  vocabulary: {
    subTitle: 'Vocabulary',
    titleTranslationKey: 'report_detail_category_vocabulary_label',
    imageSrc: '/assets/images/report-app/report-detail-category-vocabulary-summary.png',
    descriptionTranslationKeyPrefix: 'report_detail_category_vocabulary_stars_rating_description',
    className: {
      title: 'text-yellow-600',
    },
  },
  pronunciation: {
    subTitle: 'Pronunciation',
    titleTranslationKey: 'report_detail_category_pronunciation_label',
    imageSrc: '/assets/images/report-app/report-detail-category-pronunciation-summary.png',
    descriptionTranslationKeyPrefix:
      'report_detail_category_pronunciation_stars_rating_description',
    className: {
      title: 'text-green-600',
    },
  },
} as const;

type AcceptedCategoryName = keyof typeof categoryToCardDataMapping;
interface Props {
  testId: string;
  category: Extract<ResultCategoryName, AcceptedCategoryName>; // ResultCategoryName is too broad
  starsCount: StarsCount;
}
const CategorySummaryCard: FC<PropsWithChildren<Props>> = ({
  testId,
  category,
  starsCount,
  children,
}) => {
  const cardData = categoryToCardDataMapping[category];

  const { t } = useTranslation('report');
  const { t: tStarsDescriptions } = useTranslation('report', {
    keyPrefix: cardData.descriptionTranslationKeyPrefix,
  });

  const starsCountString = starsCountToText(starsCount);

  return (
    <div className="p-5 flex gap-x-5 rounded-2xl bg-white">
      <Image
        src={cardData.imageSrc}
        alt={`${category} summary mascot image`}
        className="hidden sm:block w-[140px] self-start"
      />
      <div className="w-full flex flex-col gap-y-5 sm:gap-y-6">
        <div>
          <div className="flex justify-between items-center">
            <Text variant="sm" className="text-grey-300 font-semibold">
              {cardData.subTitle}
            </Text>
            <div className="hidden sm:block">
              <MoreDetailLinkButton testId={testId} category={category} />
            </div>
          </div>
          <Text variant="xl" className={toClassNames(['font-semibold', cardData.className.title])}>
            {t(cardData.titleTranslationKey)}
          </Text>
          <Text variant="md" className="text-grey-600">
            {tStarsDescriptions(starsCountString)}
          </Text>
        </div>
        {children}
        <div className="block sm:hidden">
          <MoreDetailLinkButton testId={testId} category={category} />
        </div>
      </div>
    </div>
  );
};

const MoreDetailLinkButton: FC<{
  testId: string;
  category: AcceptedCategoryName;
  className?: string;
}> = ({ testId, category, className }) => {
  const { t } = useTranslation('report');
  return (
    <Link
      to={reportAppRoutes.ReportDetailCategory.getRoute({
        testId,
        category,
      })}
      className={toClassNames([className, 'text-primary flex gap-x-2'])}
      data-analytic-id="report-detail-category-summary-card"
      data-analytic-detail={category}
    >
      {t('report_detail_category_section_more_detail_button_label')}
      <Icon icon={IconName.ArrowRightShort} size="24px" />
    </Link>
  );
};

export default CategorySummaryCard;
