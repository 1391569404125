import React from 'react';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Form.module.css';

export interface DropdownOption {
  /**
   * value should not be empty string.
   *
   * TODO: Find the way to validate value !== empty string.
   * We might be able to write a function to check it on render.
   */
  value: string;
  text: string;
}

interface DropdownProps extends BaseProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  placeholder: string;
  disabled?: boolean;
  options: DropdownOption[];
  readonly?: boolean;
}

const Dropdown = ({
  value,
  onChange,
  disabled,
  placeholder,
  options,
  className,
  readonly,
}: DropdownProps) => {
  return (
    <div className={toClassNames([style.base, style.dropdown, className])}>
      <select
        value={value}
        onChange={onChange}
        disabled={disabled || readonly}
        className={toClassNames([
          style['dropdown-select'],
          readonly ? style.readonly : '',
          disabled ? style.disabled : '',
        ])}
      >
        <option value="" disabled key="default" >
          {placeholder}
        </option>
        {options.map((o: DropdownOption) => (
          <option value={o.value} key={o.value}>
            {o.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
