import { useAssessmentContext } from '../../../contexts/assessmentContext';
import RatingModal from './components/RatingModal';
import { MimeTypes, useMediaRecorder } from '../RecordingButton/useMediaRecorder';
import React from 'react';
import { SpeakingTestQuestion } from '../../../model/speakingTestQuestion';
import useTimer from '../../../utils/useTimer';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';
import QuestionContent from './components/QuestionContent';
import SubmittingAnswerModal from './components/SubmittingAnswerModal';
import FixedPositionTimer from '../../../components/FixedPositionTimer';
import { debounce as _debounce } from 'lodash';
import { RecordingPanel } from './components/RecordingPanel';
import style from './SpeakingTestQuiz.module.css';
import { toClassNames } from '../../../utils';
import { RecordingPanelState } from './components/RecordingPanel/helpers';
import { Container } from '../../../components/base';
import { ASSESSMENT_NAVBAR_HEIGHT } from '../../../layouts/components/AssessmentNavBar';
import { ASSESSMENT_BOTTOM_OFFSET } from '../../../layouts/AssessmentLayout';

const CARD_TOP_OFFSET = 32;
const CARD_BOTTOM_OFFSET = 24;
interface SpeakingTestQuizProps {
  question: SpeakingTestQuestion;
  totalQuestion: number;
  questionNumber: number;
}

/**
 * This is a workaround to make everything fit into one screen without scrolling the whole page.
 *
 * TODO: Restructure the AssessmentLayout to handle both SpeakingTest and PreTest (allow scrolling)
 * or Create new layout for SpeakingTet only
 */
const MAIN_CARD_HEIGHT_OFFSET =
  ASSESSMENT_NAVBAR_HEIGHT + ASSESSMENT_BOTTOM_OFFSET + CARD_TOP_OFFSET;

const SpeakingTestQuiz = ({ question, totalQuestion, questionNumber }: SpeakingTestQuizProps) => {
  const navigate = useNavigate();

  const { clearSession, submitSpeakingTestQuestion, accessCode, testId } = useAssessmentContext();
  const timer = useTimer({ onTimeUp: handleTimeUp, time: question.timeLimit || 60 });
  const preparationTimer = useTimer({
    onTimeUp: handlePreparationTimeUp,
    time: question.preparationTimeLimit || 60,
  });
  const { startRecording, stopRecording, initializeRecorder, resetRecorder } = useMediaRecorder({
    onFinish: handleFinishRecording,
    onError: handleError,
  });

  const [isPreparing, setIsPreaparing] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isInitializingRecorder, setIsIntializingRecorder] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [isTestCompleted, setIsTestCompleted] = React.useState(false);

  function handleTimeUp() {
    setIsRecording(false);
    setIsSubmitting(true);
    timer.stopTimer();
    stopRecording();
  }

  React.useEffect(() => {
    setIsPreaparing(true);
    preparationTimer.startTimer();
  }, []);

  function handlePreparationTimeUp() {
    preparationTimer.resetTimer();
    startRecordingResponse();
  }

  const startRecordingResponse = async () => {
    try {
      setIsIntializingRecorder(true);
      const success = await initializeRecorder();
      if (success) {
        timer.startTimer();
        startRecording();
        setIsRecording(true);
        setIsPreaparing(false);
        setIsIntializingRecorder(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function handleError(message: string) {
    console.error(message);
  }

  async function handleFinishRecording(blob: Blob, mimeType: MimeTypes) {
    try {
      const isCompleted = await submitSpeakingTestQuestion(question.id, blob, mimeType);
      setIsSubmitting(false);
      setIsTestCompleted(isCompleted);
      setIsDone(true);
    } catch (error) {
      // TODO: handle error
      console.error(error);
    }
  }

  const handleClickStartRecording = _debounce(
    () => {
      preparationTimer.stopTimer();
      preparationTimer.resetTimer();
      startRecordingResponse();
    },
    1000,
    { leading: true, trailing: false }
  );

  const handleClickFinishRecording = _debounce(
    () => {
      setIsRecording(false);
      setIsSubmitting(true);
      timer.stopTimer();
      stopRecording();
    },
    1000,
    { leading: true, trailing: false }
  );

  const nextQuestionHandler = () => {
    if (isTestCompleted) {
      clearSession();
      navigate(
        assessmentAppRoutes.AssessmentAppSpeakingTestFinishPage.getRoute({
          beta: accessCode?.beta,
          testId,
        })
      );
    } else {
      navigate(assessmentAppRoutes.AssessmentAppSpeakingTestQuizInstruction.getRoute());
    }
  };

  const recordingPanelState = React.useMemo((): RecordingPanelState => {
    if (isPreparing) return RecordingPanelState.Preparing;
    if (isRecording) return RecordingPanelState.Recording;
    if (isInitializingRecorder || isSubmitting) return RecordingPanelState.Loading;
    if (isDone) return RecordingPanelState.Completed;
    return RecordingPanelState.Default;
  }, [isPreparing, isInitializingRecorder, isSubmitting, isRecording, isDone]);

  return (
    <Container size="lg">
      <div
        className={toClassNames([
          'bg-white rounded-2xl shadow-md flex flex-col items-center relative overflow-hidden',
        ])}
        style={{
          marginTop: `${CARD_TOP_OFFSET}px`,
          maxHeight: `calc(100vh - ${MAIN_CARD_HEIGHT_OFFSET}px)`,
        }}
      >
        <div className="overflow-auto py-10 w-full pb-[160px]">
          <QuestionContent
            questionNumber={questionNumber}
            question={question}
            totalQuestion={totalQuestion}
          />
        </div>
        <RecordingPanel
          className={toClassNames([style['recording-panel']])}
          preparationTimeLeft={preparationTimer.timeLeft}
          recordingTimeLeft={timer.timeLeft}
          totalRecordingTime={timer.totalTime}
          minRecordingTime={question.minRecordingTime}
          onClickStartRecording={handleClickStartRecording}
          onClickFinishRecording={handleClickFinishRecording}
          disabledFinishRecordingButton={
            timer.totalTime - timer.timeLeft < question.minRecordingTime
          }
          state={recordingPanelState}
        />
        {/* <FixedPositionTimer
        time={`${isPreparing ? preparationTimer.timeLeft : timer.timeLeft} ${t(
          'assessment_speaking_test_quiz_fixed_position_timer_time'
        )}`}
        text={
          isPreparing
            ? t('assessment_speaking_test_quiz_fixed_position_timer_text_is_preparing')
            : t('assessment_speaking_test_quiz_fixed_position_timer_text_not_preparing')
        }
      /> */}
        <RatingModal
          open={isDone}
          isTestCompleted={isTestCompleted}
          questionNumber={questionNumber}
          onNext={nextQuestionHandler}
          onRating={() => {}}
          total={totalQuestion}
        />
        <SubmittingAnswerModal open={isSubmitting} isTimeup={false} />
      </div>
    </Container>
  );
};

export default SpeakingTestQuiz;
