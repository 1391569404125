import { ClickAwayListener } from '@mui/base';
import { BaseProps } from '../../types';
import { toClassNames } from '../../../utils';
import style from './Modal.module.css';

export interface ModalProps extends BaseProps {
  onClose?: () => void;
  open: boolean;
  size?: ModalSize;
  noPadding?: boolean;
}

type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const getModalSizeClassName = (size?: ModalSize) => {
  if (!size) return `${style.md}`;
  return `${style[`${size}`]}`;
};

const Modal = ({ onClose, open, size, children, noPadding }: ModalProps) => {
  const handleClickAway = () => {
    if (onClose) {
      onClose();
    }
  };

  if (!open) return null;
  return (
    <div className={style.overlay}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={toClassNames([style.wrapper, getModalSizeClassName(size)])}>
          <div
            className={toClassNames([style['modal-card'], noPadding ? style['no-padding'] : ''])}
          >
            {children}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Modal;
