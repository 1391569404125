import { useTranslation } from 'react-i18next';
import { Image, Text } from '../../../../components/base';
import { EDSY_LINE_AD, EDSY_OPENING_HOURS, EDSY_PHONE_NUMBER } from '../../../../data/edsyInfo';
import { getLocalizedDayName } from '../../../../utils/date';

const ContactUsSection = () => {
  const { t, i18n } = useTranslation('report');
  return (
    <div className="text-center">
      <div>
        {/* Title Desktop */}
        <Text variant="lg" className="hidden sm:block text-grey-600 font-semibold">
          {t('report_detail_contact_us_section_title')}
        </Text>
        {/* Title Mobile */}
        <Text className="sm:hidden text-grey-600 font-semibold">
          {t('report_detail_contact_us_section_title_mobile')}
        </Text>
        <div>
          <Text is="span" className="sm:!text-lg text-grey-600 font-semibold capitalize">
            {t('call', { ns: 'common' })}{' '}
            <a href={`tel:${EDSY_PHONE_NUMBER}`}>{EDSY_PHONE_NUMBER}</a>
          </Text>{' '}
          <br className="sm:hidden" />
          <Text is="span" className="sm:!text-lg text-grey-600 font-semibold">
            {t('report_detail_contact_us_section_or_add_label')} Line{' '}
            <a
              href={`https://lin.ee/syrcEiY`}
              target="_blank"
              rel="noreferrer noopener"
              data-analytic-id="report-detail-add-line-link"
            >
              {EDSY_LINE_AD}
            </a>
          </Text>
        </div>
      </div>
      {/* // TODO LINE QR */}
      {/* <div className="hidden sm:grid mt-3 mx-auto w-[120px] h-[120px] aspect-square bg-grey-400 place-content-center">
        Line QR
      </div> */}
      <Text className="mt-3 capitalize">
        {getLocalizedDayName('monday', i18n.language, true)}
        {' - '}
        {getLocalizedDayName('friday', i18n.language, true)} {EDSY_OPENING_HOURS}{' '}
        {t('o_clock_short', { ns: 'common' })}
      </Text>
      <a
        href="https://lin.ee/syrcEiY"
        target="_blank"
        rel="noreferrer noopener"
        data-analytic-id="report-detail-add-line-button"
      >
        <Image
          src={`https://scdn.line-apps.com/n/line_add_friends/btn/${i18n.language}.png`}
          alt={t('add_friend', { ns: 'common' })}
          className="mt-3 mx-auto h-11 rounded-full"
        />
      </a>
    </div>
  );
};

export default ContactUsSection;
