import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Text } from '../../../../components/base';
import { IncorrectPhonetic } from '../../../../types/report';
import { toClassNames } from '../../../../utils';
import { isKeyOf } from '../../../../utils/object';
import { encloseWithDoubleQuote, encloseWithSlash } from '../../../../utils/string';

interface Props {
  incorrectPhonetic: IncorrectPhonetic;
  className?: string;
  hideDetailedAnalysis?: boolean;
  hideSoundButton?: boolean;
}
const IncorrectPhoneticCard: FC<Props> = ({
  incorrectPhonetic,
  className,
  hideDetailedAnalysis,
  hideSoundButton,
}) => {
  const { t, i18n } = useTranslation('report');

  const { phonetic, detailedAnalysis } = incorrectPhonetic;

  const phonemeSound = t('pronunciation_phoneme_sound', {
    ns: 'pronunciation',
    returnObjects: true,
  });

  const phonemeHowToPronounce = t('pronunciation_phoneme_how_to_pronounce', {
    ns: 'pronunciation',
    returnObjects: true,
  });

  const soundText = isKeyOf(phonetic, phonemeSound) ? phonemeSound[phonetic] : null;
  const howToPronounce = isKeyOf(phonetic, phonemeHowToPronounce)
    ? phonemeHowToPronounce[phonetic]
    : null;

  return (
    <div
      className={toClassNames([
        className,
        'p-5 bg-white rounded-3xl',
        'grid grid-cols-[auto,minmax(0,1fr)] grid-rows-[auto,auto] gap-x-3 gap-y-4 sm:gap-y-0',
        'items-start', // required
      ])}
    >
      <Button
        variant="flat"
        // TODO: remove cursor-default when sound is available (i.e., button is really clickable)
        className={toClassNames([hideSoundButton ? '!hidden' : 'block', '!cursor-default'])}
      >
        <Image
          src="/assets/images/recording-button/recording-button-done.svg"
          alt="Play Sound Button"
          className={'w-[52px]'}
        />
      </Button>
      <Text className="font-semibold self-center">
        {t('sound', { ns: 'common' })}{' '}
        {i18n.language === 'th' && soundText ? encloseWithDoubleQuote(soundText) : null}{' '}
        {encloseWithSlash(phonetic)}
      </Text>
      <div
        className={toClassNames([
          hideDetailedAnalysis ? 'hidden' : 'block',
          'col-span-2',
          'sm:col-start-2 sm:col-span-1',
        ])}
      >
        {howToPronounce && (
          <div>
            <Text className="font-semibold">
              {t('report_detail_incorrect_phonetic_how_to_pronounce')}
            </Text>
            <Text className="text-grey-600 mt-1">{howToPronounce}</Text>
          </div>
        )}
        {/* {detailedAnalysis.map((analysis, index) => {
          return (
            <Text key={index} className="text-green-600">
              &bull; {analysis}
            </Text>
          );
        })} */}
      </div>
    </div>
  );
};

export default IncorrectPhoneticCard;
