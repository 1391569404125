import React from 'react';
import { Button, LoadingModal, Text } from '../../../components/base';
import LayoutCard from '../../../layouts/components/LayoutCard';
import CardHeader, { CardHeaderVariant } from '../../../components/assessmentAppLayouts/CardHeader';
import InstructionContentCardV2 from '../../../components/InstructionContentCardV2';
import { useAssessmentContext } from '../../../contexts/assessmentContext';
import ErrorModal from '../../../components/ErrorModal';
import { useNavigate } from 'react-router-dom';
import { assessmentAppRoutes } from '../../../router';
import { useTranslation } from 'react-i18next';
import HeaderText from '../PreTestInstruction/components/HeaderText';
import { IconName } from '../../../components/base/Icon';

const SpeakingTestInstruction = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('assessment');
  const { startOrContinueSpeakingTestQuiz, checkHasOnlySpeakingTest } = useAssessmentContext();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleStartSpeakingTest = async () => {
    setLoading(true);
    try {
      await startOrContinueSpeakingTestQuiz();
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const handleErrorModalButtonClick = () => {
    setError(false);
  };

  const handleErrorModalSecondaryButtonClick = () => {
    navigate(assessmentAppRoutes.AssessmentAppHome.getRoute());
  };

  const getPartNo = () => {
    return checkHasOnlySpeakingTest() ? '1' : '2';
  };

  return (
    <LayoutCard className="my-10 flex flex-col items-center">
      <CardHeader
        variant={CardHeaderVariant.PRIMARY}
        subtitle={t('assessment_speaking_test_instruction_cardheader_subtitle', {
          no: getPartNo(),
        })}
        title={t('assessment_speaking_test_instruction_cardheader_title')}
        description={t('assessment_speaking_test_instruction_cardheader_description')}
        className="mb-6"
      />
      <div className="flex flex-row gap-6 mb-4">
        {/* Workaround for BU */}
        <HeaderText
          icon={IconName.SquareText}
          title={t('assessment_speaking_test_instruction_total_question_title', {
            questionCount: checkHasOnlySpeakingTest() ? '6' : '5',
          })}
          subtitle={t('assessment_speaking_test_instruction_total_question_subtitle')}
        />
        <HeaderText
          icon={IconName.Timer}
          title={t('assessment_speaking_test_instruction_test_duration_title')}
          subtitle={t('assessment_speaking_test_instruction_test_duration_subtitle')}
        />
      </div>
      <Text className="text-gray-600 font-bold mb-4">
        {/* Workaround for BU */}
        {t('assessment_speaking_test_instruction_text_description', {
          sectionCount: checkHasOnlySpeakingTest() ? '2' : '1',
        })}
      </Text>
      <div className="w-full md:w-fit md:flex justify-center mb-6 rounded-xl border-2 border-gray-200 py-4">
        <InstructionContentCardV2
          imageUrl="/assets/images/speaking-test/text-reading.png"
          title={t('assessment_speaking_test_instruction_instruction_content_card1_title')}
        />
        <hr className="w-3/4 mx-auto md:hidden" />
        <div className="border-solid border-l-[1px] border-gray-300 h-[125px] mt-12 hidden md:block"></div>
        <InstructionContentCardV2
          imageUrl="/assets/images/speaking-test/open-ended.png"
          title={t('assessment_speaking_test_instruction_instruction_content_card2_title')}
        />
        {/* Workaround for BU */}
        {!checkHasOnlySpeakingTest() && (
          <>
            <hr className="w-3/4 mx-auto md:hidden" />
            <div className="border-solid border-l-[1px] border-gray-300 h-[125px] mt-12 hidden md:block"></div>
            <InstructionContentCardV2
              imageUrl="/assets/images/speaking-test/describe.png"
              title={t('assessment_speaking_test_instruction_instruction_content_card3_title')}
            />
          </>
        )}
      </div>
      <ul className="list-disc mb-6 px-4 text-gray-600 leading-loose">
        <li>
          <Text variant="sm" className="">
            {t('assessment_speaking_test_instruction_bottom_text_1')}
          </Text>
        </li>
        <li>
          <Text variant="sm" className="">
            {t('assessment_speaking_test_instruction_bottom_text_2')}
          </Text>
        </li>
      </ul>
      <Button variant="primary" onClick={handleStartSpeakingTest}>
        {t('assessment_speaking_test_instruction_button_start', { no: getPartNo() })}
      </Button>
      <ErrorModal
        open={error}
        title={t('assessment_speaking_test_instruction_error_modal_title')}
        description={t('assessment_speaking_test_instruction_error_modal_description')}
        buttonText={t('assessment_speaking_test_instruction_error_modal_button_text')}
        secondaryButtonText={t(
          'assessment_speaking_test_instruction_error_modal_secondar_button_text'
        )}
        onButtonClick={handleErrorModalButtonClick}
        onSecondaryButtonClick={handleErrorModalSecondaryButtonClick}
      />
      <LoadingModal open={loading} />
    </LayoutCard>
  );
};

export default SpeakingTestInstruction;
