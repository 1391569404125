import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../components/base';
import Icon, { IconName } from '../../../../../../components/base/Icon';
import Tooltip, { TooltipVariant } from '../../../../../../components/base/Tooltip';
import style from '../RecordingPanel.module.css';
import { toClassNames } from '../../../../../../utils';
import React from 'react';

interface FinishButtonProps {
  onClick: () => void;
  disabled: boolean;
  minRecordingTime: number;
}
const FinishButton = ({ onClick, disabled, minRecordingTime }: FinishButtonProps) => {
  const { t } = useTranslation('assessment');
  const tooltipContent = React.useMemo(() => {
    if (disabled) {
      return t('assessment_app_recording_panel_finish_button_tooltip_disabled', {
        minTime: minRecordingTime,
      });
    } else {
      return t('assessment_app_recording_panel_finish_button_tooltip');
    }
  }, [t, disabled, minRecordingTime]);
  return (
    <div className="ml-auto">
      <Tooltip
        content={tooltipContent}
        arrow={true}
        placement={'top'}
        variant={disabled ? TooltipVariant.Disabled : TooltipVariant.Primary}
        tooltipClassName={'w-[200px] text-center'}
      >
        <div>
          <div className={'block sm:hidden'}>
            <Button
              variant="outlined"
              size={'small'}
              onClick={onClick}
              disabled={disabled}
              className="ml-auto"
            >
              <div className="flex gap-1 items-center">
                <Icon
                  icon={IconName.StopRecording}
                  size={'16px'}
                  className={toClassNames([disabled ? '' : 'text-red-600'])}
                />
                <span>{t('assessment_app_recording_panel_finish_button')}</span>
              </div>
            </Button>
          </div>
          <div className={'hidden sm:block'}>
            <Button variant="outlined" onClick={onClick} disabled={disabled}>
              <div className="flex gap-2 items-center">
                <Icon
                  icon={IconName.StopRecording}
                  size={'20px'}
                  className={toClassNames([disabled ? '' : 'text-red-600'])}
                />
                <span>{t('assessment_app_recording_panel_finish_button')}</span>
              </div>
            </Button>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default FinishButton;
