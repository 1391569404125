import React from 'react';
import { useNavigate } from 'react-router-dom';
import { validateSchoolCode } from '../../api';
import { Heading, Text, Container, Button, Input } from '../../components/base';
import { routes } from '../../router';

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [schoolId, setSchoolId] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setErrorMessage('');
    setSchoolId(e.currentTarget.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await validateSchoolCode(schoolId);
      if (result) {
        navigate(routes.SchoolReports.getRoute(schoolId));
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(`ไม่พบโรงเรียนรหัส ${schoolId} กรุณาตรวจสอบใหม่อีกครั้ง`);
    }
    setLoading(false);
  };

  return (
    <div className="py-4 px-4">
      <Container className="bg-primary-50 py-10 rounded-2xl shadow-md flex flex-col items-center">
        <Heading variant="h5" is="h1" className="text-primary text-center mb-6">
          ค้นหาโรงรียน
        </Heading>
        <div className="w-full px-4 mb-6">
          <Input
            value={schoolId}
            onChange={handleChange}
            placeholder={'กรุณากรอกรหัสโรงเรียน'}
            disabled={loading}
            className={'w-full'}
          />
        </div>
        <Button variant="primary" size={'xlarge'} onClick={handleSubmit} disabled={loading}>
          {loading ? 'กำลังตรวจสอบรหัสโรงเรียน...' : 'ค้นหา'}
        </Button>
        <Text variant="sm" className="text-red-500 leading-none h-[16px] mt-4">
          {errorMessage || ' '}
        </Text>
      </Container>
    </div>
  );
};

export default Home;
